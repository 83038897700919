import React, {
    FC,
    MouseEvent,
    useEffect,
    useMemo,
    useState,
    useCallback,
    memo,
    useRef,
} from 'react';
import { checkLengthString, classNames } from 'src/shared/lib';
import { Popup } from 'src/shared/ui';
import { Position } from '../popup/popup';
import styles from './NewSelect.module.scss';

const HEIGHT_EMPTY_BLOCK = 8; // задается в css файле равен padding в .body
const HEIGHT_OPTION = 33; // подбирается

export interface SelectOption {
    value: string;
    content: string;
}

const searchField = (field: string, options: SelectOption[]) => {
    const option = options.find((item) => item.value === field);
    return option?.content;
};

const getMaxHeightPopup = (
    options: SelectOption[],
    heightOption: number,
    heightEmptyBlock: number
) => {
    const PADDING_POPUP = 8; // задается в css файле равен padding в .body

    if (options.length > 4) {
        return heightOption * 4 + heightEmptyBlock;
    }
    return heightOption * options.length + PADDING_POPUP * 2;
};

interface NewSelectProps {
    options: SelectOption[];
    idScrollElement: string;
    className?: string;
    label?: string;
    value?: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
    lengthDisplayValue?: number;
}

export const NewSelect: FC<NewSelectProps> = memo((props) => {
    const {
        options,
        idScrollElement,
        onChange,
        value,
        label,
        disabled = false,
        lengthDisplayValue = 23,
        className,
    } = props;
    const headerRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, setOpen] = useState(false);
    const [currentValue, setCurrentValue] = useState<string | undefined>(label);

    const heightPopup = getMaxHeightPopup(
        options,
        HEIGHT_OPTION,
        HEIGHT_EMPTY_BLOCK
    );

    const onToggle = useCallback(() => {
        setOpen((prev) => !prev);
    }, []);

    const onChangeValue = useCallback(
        (e: MouseEvent<HTMLDivElement>) => {
            const { value } = e.currentTarget.dataset;
            const content = e.currentTarget.innerHTML;
            onChange?.(value || '');
            setCurrentValue(content || '');
            setOpen(false);
        },
        [onChange]
    );

    const renderOptionsList = useMemo(
        () =>
            options?.map((opt) => (
                <div
                    key={opt.value}
                    data-value={opt.value}
                    className={styles.option}
                    onClick={onChangeValue}
                >
                    {checkLengthString(opt.content, lengthDisplayValue)}
                </div>
            )),
        [options, onChangeValue, lengthDisplayValue]
    );

    useEffect(() => {
        if (!label && value) {
            setCurrentValue(searchField(value, options));
        }
    }, [label, value, options]);

    return (
        <div className={classNames(styles.NewSelect, {}, [className])}>
            <div
                ref={headerRef}
                className={classNames(
                    styles.header,
                    { [styles.disabled]: disabled },
                    []
                )}
                onClick={onToggle}
            >
                {currentValue &&
                    checkLengthString(currentValue, lengthDisplayValue)}
            </div>
            {!disabled && isOpen && (
                <Popup
                    position={Position.BOTTOM_LEFT}
                    triggerRef={headerRef}
                    idScrollElement={idScrollElement}
                    maxWidthPopup="trigger"
                    maxHeightPopup={heightPopup}
                    hiddenPopup={onToggle}
                    className={styles.popup}
                >
                    {options.length > 4 ? (
                        <>
                            <div className={styles['empty-block']} />
                            <div
                                className={styles.body}
                                style={{
                                    height: heightPopup - HEIGHT_EMPTY_BLOCK,
                                }}
                            >
                                {renderOptionsList}
                            </div>
                        </>
                    ) : (
                        <div className={styles.body}>{renderOptionsList}</div>
                    )}
                </Popup>
            )}
        </div>
    );
});
