export const LOCAL_STORAGE_TABLE_NAMES = {
    common: 'CommonTableFilters',
    operations: 'OperationsPage',
    operationsSBP: 'OperationsSBPPage',
    settlement: 'SettlementPage',
    reversal: 'ReversalPage',
    ePayment: 'ePaymentPage',
    eInvoiceBank: 'BankEinvoicePage',
    eInvoiceMerchant: 'MerchantEinvoicePage',
    statistic: 'StatisticPage',
} as const;
