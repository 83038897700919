import { TerminalGroup } from 'src/entities/terminal';
import { MainTerminal, Terminal } from './config';

export interface FilterTerminalsArguments {
    query: string;
    terminalsList: MainTerminal[];
    selected: number[];
    selectedOnly: boolean;
    inventoryDisplayFilters: string[];
}

const filtrateInventory = (
    terminal: Terminal,
    inventoryDisplayFilters: string[]
) => {
    if (inventoryDisplayFilters.includes('DISABLED') && terminal.disabled)
        return true;
    if (
        (inventoryDisplayFilters.includes('INACTIVE') &&
            terminal.activity === 'INACTIVE') ||
        (terminal.activity === 'DISABLED' && !terminal.disabled)
    )
        return true;
    if (
        inventoryDisplayFilters.includes('ACTIVE') &&
        terminal.activity === 'ACTIVE' &&
        !terminal.disabled
    )
        return true;
    return false;
};

export const filterTerminals = ({
    query,
    terminalsList,
    selected,
    selectedOnly,
    inventoryDisplayFilters,
}: FilterTerminalsArguments) => {
    // TODO 634: rename this
    const filterTerminals = (terminal: Terminal) => {
        const terminalIsInQuery = [terminal.name, terminal.uid].some((value) =>
            value.toString().toUpperCase().includes(query.toUpperCase())
        );

        const terminalIsSelected = selectedOnly
            ? selected.includes(terminal.id)
            : true;

        const terminalFitsInventoryFilter =
            inventoryDisplayFilters.length > 0
                ? filtrateInventory(terminal, inventoryDisplayFilters)
                : true;

        return (
            terminalIsInQuery &&
            terminalIsSelected &&
            terminalFitsInventoryFilter
        );
    };

    const filteredTerminals = terminalsList.reduce<MainTerminal[]>(
        (prev, organisation) => {
            let organisationContainsQueriedTerminals = false;
            const organisationCopy = { ...organisation };

            organisationCopy.terminalGroups =
                organisation.terminalGroups.reduce<TerminalGroup[]>(
                    (prev, terminalGroup) => {
                        const terminalGroupCopy = { ...terminalGroup };
                        terminalGroupCopy.terminals =
                            terminalGroup.terminals.filter(filterTerminals);

                        if (terminalGroupCopy.terminals.length)
                            organisationContainsQueriedTerminals = true;

                        return [...prev, terminalGroupCopy];
                    },
                    []
                );

            if (organisationContainsQueriedTerminals) {
                return [...prev, organisationCopy];
            } else {
                return [...prev];
            }
        },
        []
    );

    return filteredTerminals;
};

export const getInactivePeriodPerDaysCorrectPostfix = (daysCount: number) => {
    if (daysCount === 1) return `последний ${daysCount} день`;
    if (daysCount > 10 && [11, 12, 13, 14].includes(daysCount % 100))
        return `последние ${daysCount} дней`;
    const daysCountLastDigit = daysCount % 10;
    if (daysCountLastDigit === 1) return `последний ${daysCount} день`;
    if ([2, 3, 4].includes(daysCountLastDigit))
        return `последние ${daysCount} дня`;

    return `последние ${daysCount} дней`;
};
