import React, { FC } from 'react';
import styles from './title.module.scss';

interface AddInfoItemProps {
    subtitle?: string;
    icon?: React.ReactNode;
    className?: string;
}

export const Title: FC<AddInfoItemProps> = ({
    icon,
    subtitle,
    children,
    className = '',
}) => {
    return (
        <div className={`${styles['wrapper']} ${className}`}>
            <div className={styles['title']}>
                {icon} <div>{children}</div>
            </div>
            {subtitle && <div className={styles['subtitle']}>{subtitle}</div>}
        </div>
    );
};
