import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import { PublicFormWidget } from 'src/widgets';
import { URL } from 'src/app/config';
import { logIn } from 'src/redux/actions/auth';
import { Credentials, schema } from '../config';
import { HelpLink } from './help-link';

import { Input, Button, HelpIcon } from 'src/shared/ui';
import { checkAppTitle } from 'src/shared/lib';

import styles from './login-form.module.scss';
import { InputPassword } from 'src/shared/ui/input';

const LoginForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { isMerchantApp } = checkAppTitle();
    const { fetch } = useSelector((state) => state.fetch);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Credentials>({
        defaultValues: { login: '', password: '' },
        resolver: yupResolver(schema),
    });

    const callback = (values: Credentials) => () => {
        history.push(URL.changePasswordUrl, {
            login: values.login,
        });
    };

    const submit = (values: Credentials) => {
        dispatch(logIn(values, callback(values), history));
    };

    return (
        <PublicFormWidget header="Вход в личный кабинет">
            <div className={styles['login-form']}>
                <form onSubmit={handleSubmit(submit)}>
                    <Input
                        {...register('login')}
                        placeholder="Логин"
                        error={errors.login?.message}
                        fluid
                    />
                    <InputPassword
                        {...register('password')}
                        placeholder="Пароль"
                        error={errors.password?.message}
                        fluid
                    />
                    <Button
                        fluid
                        type="submit"
                        loading={fetch}
                        disabled={fetch}
                    >
                        Войти
                    </Button>
                </form>
                <div className={styles['links']}>
                    <HelpLink to={URL.forgotPasswordPageUrl} icon={HelpIcon}>
                        Забыли пароль
                    </HelpLink>

                    {isMerchantApp && (
                        <HelpLink externalUrl to={URL.instructions}>
                            Проблемы с подключением
                        </HelpLink>
                    )}
                </div>
            </div>
        </PublicFormWidget>
    );
};

export { LoginForm };
