import {
    Organisation,
    identificationSBP,
} from './sbp-identificator-selector/type';

export interface FilterIdentificatorsArguments {
    query: string;
    identificatorsList: Organisation[];
    selected: string[];
    selectedOnly: boolean;
}

export const filterSBPIdentificators = ({
    query,
    identificatorsList,
    selected,
    selectedOnly,
}: FilterIdentificatorsArguments) => {
    const filtrateSelectedOnly = (identificator: identificationSBP) =>
        !selectedOnly || selected.includes(identificator.merchantId);
    const isIdentificatorInQuery = (identificator: identificationSBP) =>
        [identificator.merchantId, identificator.merchantName].some(
            (identificatorProperty) =>
                identificatorProperty
                    ?.toUpperCase()
                    ?.includes(query.toUpperCase())
        );

    return identificatorsList.reduce<Organisation[]>((prev, organisation) => {
        const organisationCopy = { ...organisation };
        organisationCopy.identificationsSbp =
            organisation.identificationsSbp.filter(
                (identification) =>
                    filtrateSelectedOnly(identification) &&
                    isIdentificatorInQuery(identification)
            );

        if (organisationCopy.identificationsSbp.length > 0) {
            return [...prev, organisationCopy];
        } else {
            return [...prev];
        }
    }, []);
};
