import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { Button, Input, Modal, Popup } from 'src/shared';
import styles from './modal-ui-popup.module.scss';

interface ModalUiPopupProps {
    triggerRef: React.MutableRefObject<HTMLElement | null>;
    hiddenPopup: () => void;
    search: string;
    onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSelectedAll: () => void;
    onReset: () => void;
}

const HEIGHT_POPUP = 320;

export const ModalUiPopup: FC<ModalUiPopupProps> = (props) => {
    const {
        triggerRef,
        hiddenPopup,
        search,
        onSearch,
        onSelectedAll,
        onReset,
        children,
    } = props;

    if (isMobile) {
        return (
            <Modal
                isOpen={true}
                closeButton="Закрыть"
                onClose={hiddenPopup}
                className={styles.modal}
            >
                <div className={styles.control}>
                    <Input
                        background
                        value={search}
                        onChange={onSearch}
                        placeholder="Поиск"
                        className={styles.search}
                    />
                    <div className={styles.buttons}>
                        <Button clear onClick={onSelectedAll}>
                            + Выбрать все
                        </Button>
                        <Button clear onClick={onReset}>
                            Сбросить выбранное
                        </Button>
                    </div>
                </div>
                <div className={styles.list}>{children}</div>
            </Modal>
        );
    }
    return (
        <Popup
            triggerRef={triggerRef}
            idScrollElement="modal-element-scroll"
            maxWidthPopup="trigger"
            maxHeightPopup={HEIGHT_POPUP}
            hiddenPopup={hiddenPopup}
            className={styles.popup}
        >
            <div className={styles.control}>
                <Input
                    background
                    value={search}
                    onChange={onSearch}
                    placeholder="Поиск"
                    className={styles.search}
                />
                <div className={styles.buttons}>
                    <Button clear onClick={onSelectedAll}>
                        + Выбрать все
                    </Button>
                    <Button clear onClick={onReset}>
                        Сбросить выбранное
                    </Button>
                </div>
            </div>
            <div className={styles.list}>{children}</div>
        </Popup>
    );
};
