import React, { Component } from 'react';

import {
    getClassName,
    sendToSubject,
    localLoad,
    localSave,
} from 'src/shared/lib';
import { SearchField } from 'src/shared/ui';
import { FiltersContainer } from 'src/widgets';

interface State {
    searchQuery: string;
}

class MerchantUserFilter extends Component<{}, State> {
    send: (message: any) => void;
    name: string;

    constructor(props) {
        super(props);
        this.name = getClassName('MerchantUserFilter', 'searchQuery');
        this.state = {
            searchQuery: localLoad(this.name, ''),
        };
        this.send = sendToSubject.bind(this);
    }

    componentDidMount() {
        this.send({
            filter: {
                ...this.state,
            },
        });
    }

    getData() {
        localSave(this.name, this.state.searchQuery);
        this.send({
            offset: 1,
            dropOffset: true,
            filter: {
                ...this.state,
            },
        });
    }

    render() {
        return (
            <FiltersContainer>
                <SearchField
                    fullsize
                    placeholder="Введите ФИО, номер телефона или email"
                    value={this.state.searchQuery}
                    onChange={(value) =>
                        this.setState(
                            {
                                searchQuery: value,
                            },
                            this.getData
                        )
                    }
                />
            </FiltersContainer>
        );
    }
}

export default MerchantUserFilter;
