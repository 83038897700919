import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import { Card } from 'reactstrap';

import { createNotification } from 'src/redux/actions/notification';
import store from 'src/store';
import { Roles, navRoutes, URL } from 'src/app/config';
import { DownloadReport } from 'src/features';

import {
    get,
    post,
    einvoiceDelCases,
    einvoiceQrcEmptyCase,
    einvoiceSendCases,
    einvoiceStaticDownloadCase,
    isPermit,
    log,
    LOCAL_STORAGE_TABLE_NAMES,
} from 'src/shared/lib';
import { Button } from 'src/shared/ui';
import Container from 'src/components/Container';
import './einvoice-details.scss';
import { connect } from 'react-redux';
import { setNeedsUpdate } from 'src/redux/actions/table';

class EinvoiceDetailsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            paymentType: '',
            qrcType: '',
            ecommKey: '',
            orderId: '',
            externalOrderId: '',
            customerEmail: '',
            customerPhone: '',
            amount: '',
            description: '',
            taxation: '',
            dueDate: '',
            merchantName: '',
            terminalId: '',
            paymentUrl: '',
            itemDtos: [],
            created: '',
            updated: '',
            status: '',
            copySuccess: false,
            urlPay: '',
        };
    }

    redirectToOperationsSBP = () => {
        this.props.history.push(navRoutes.payments + this.state.orderId);
    };

    sendEinvoiceDetail = () => {
        post(URL.einvoiceSend + this.props.match.params.id)
            .then((r) => {
                store.dispatch(
                    createNotification(
                        {
                            title: 'Успех',
                            message:
                                'Обновите страницу эл. счета через некоторое время',
                        },
                        'success'
                    )
                );
            })
            .catch((r) => log(r));
    };

    deleteEinvoiceDetail = () => {
        post(URL.einvoiceDelete + this.props.match.params.id)
            .then((r) => {
                store.dispatch(
                    createNotification(
                        {
                            title: 'Успех',
                            message: 'Счет успешно удален',
                        },
                        'success'
                    )
                );

                this.props.dispatch(
                    setNeedsUpdate(
                        LOCAL_STORAGE_TABLE_NAMES.eInvoiceBank,
                        true,
                        true
                    )
                );

                this.props.dispatch(
                    setNeedsUpdate(
                        LOCAL_STORAGE_TABLE_NAMES.eInvoiceMerchant,
                        true,
                        true
                    )
                );

                if (this.props.location.key) {
                    this.props.history.goBack();
                } else {
                    this.props.history.push(navRoutes.einvoice);
                }
            })
            .catch((r) => log(r));
    };

    getEinvoiceInfo() {
        get(URL.einvoice + '/info/' + this.props.match.params.id)
            .then((r) => this.setState({ ...r.data.payload }))
            .catch((r) => log(r));
    }

    copyToClipboard(e, text) {
        copy(text);
        this.setState({ copySuccess: true });
    }

    componentDidMount() {
        this.getEinvoiceInfo();
    }

    render() {
        const {
            ecommKey,
            paymentType,
            qrcType,
            customerEmail,
            externalOrderId,
            customerPhone,
            amount,
            description,
            taxation,
            merchantName,
            terminalId,
            paymentUrl,
            dueDate,
            created,
            updated,
            status,
            itemDtos,
            copySuccess,
            urlPay,
        } = this.state;

        const companyRolesHasSBP = isPermit(Roles.OPERATION_SBP);
        const staticQrcCase = qrcType === 'Статический';

        return (
            <div className="company-details">
                <Container>
                    <div className="container-header">
                        <h2 className="container-header__title">
                            Электронный счёт
                        </h2>
                        <div className="container-header__buttons">
                            <Button
                                className="confirm-einvoice-btn"
                                onClick={this.redirectToOperationsSBP}
                            >
                                Оплаты
                            </Button>
                            {!einvoiceSendCases.includes(status) &&
                                !staticQrcCase && (
                                    <Button
                                        className="confirm-einvoice-btn"
                                        onClick={this.sendEinvoiceDetail}
                                    >
                                        Отправить
                                    </Button>
                                )}
                            {einvoiceStaticDownloadCase(qrcType, status) && (
                                <DownloadReport
                                    defaultFileName="QR код"
                                    url={URL.einvoiceDownloadQrcUrl}
                                    availableFormats={['PDF', 'PNG']}
                                    themeTriggerButton="default"
                                    nameTriggerButton="Скачать QR"
                                />
                            )}
                            {einvoiceDelCases.includes(status) && (
                                <Button
                                    className="confirm-einvoice-btn"
                                    onClick={this.deleteEinvoiceDetail}
                                >
                                    Удалить
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="company-details__info">
                        {companyRolesHasSBP && (
                            <React.Fragment>
                                <div className="company-details__info__box">
                                    <span>Способы оплаты:</span>
                                    <span>{paymentType}</span>
                                </div>
                                <div className="company-details__info__box">
                                    <span>Тип QR:</span>
                                    <span>{qrcType}</span>
                                </div>
                            </React.Fragment>
                        )}
                        <div className="company-details__info__box">
                            <span>Ключ мерчанта:</span>
                            <span>{ecommKey}</span>
                        </div>
                        {terminalId && (
                            <div className="company-details__info__box">
                                <span>Id терминала:</span>
                                <span>{terminalId}</span>
                            </div>
                        )}
                        <div className="company-details__info__box">
                            <span>Номер заказа:</span>
                            <span>{externalOrderId}</span>
                        </div>
                        <div className="company-details__info__box">
                            <span>Создан:</span>
                            <span>{created}</span>
                        </div>
                        <div className="company-details__info__box">
                            <span>Последнее изменение:</span>
                            <span>{updated}</span>
                        </div>
                        <div className="company-details__info__box">
                            <span>Статус:</span>
                            <span>{status}</span>
                        </div>
                        <div className="company-details__info__box">
                            <span>Платежная ссылка:</span>
                            <span
                                id="url"
                                className={`copy copy-${copySuccess}`}
                                onClick={(e) =>
                                    !this.state.copySuccess &&
                                    this.copyToClipboard(
                                        e,
                                        einvoiceQrcEmptyCase(qrcType)
                                            ? paymentUrl
                                            : urlPay
                                    )
                                }
                            >
                                <i
                                    data-tip=""
                                    data-for="url"
                                    className="mdi mdi-content-copy"
                                />
                            </span>
                            <ReactTooltip
                                id="url"
                                className="tool-tip"
                                place="top"
                                type="light"
                                effect="float"
                            >
                                {copySuccess ? 'Скопировано' : 'Копировать'}
                            </ReactTooltip>
                        </div>

                        <div className="company-details__info__box">
                            <span>Дата истечения срока оплаты:</span>
                            <span>{dueDate}</span>
                        </div>
                        <div className="company-details__info__box">
                            <span>Имя мерчанта:</span>
                            <span>{merchantName}</span>
                        </div>
                        <div className="company-details__info__box">
                            <span>E-mail клиента:</span>
                            <span>{customerEmail}</span>
                        </div>
                        <div className="company-details__info__box">
                            <span>Телефон клиента:</span>
                            <span>{customerPhone}</span>
                        </div>
                        <div className="company-details__info__box">
                            <span>Сумма заказа:</span>
                            <span>{amount}</span>
                        </div>
                        <div className="company-details__info__box">
                            <span>Описание:</span>
                            <span className="--extended">{description}</span>
                        </div>
                        <div className="company-details__info__box">
                            <span>Система налогообложения:</span>
                            <span>{taxation}</span>
                        </div>
                        <br />
                        <br />
                        {itemDtos && itemDtos.length ? (
                            <React.Fragment>
                                <div className="company-details__info__box">
                                    <h5>
                                        <b>Товары, работы, услуги:</b>
                                    </h5>
                                </div>
                                <br />
                                {itemDtos.map(
                                    ({
                                        id,
                                        name,
                                        price,
                                        quantity,
                                        amount,
                                        tax,
                                        orderId,
                                        paymentMethod,
                                        paymentObject,
                                        measurementUnit,
                                    }) => (
                                        <React.Fragment
                                            key={id + Math.random().toString()}
                                        >
                                            <Card className="company-details__info">
                                                <div className="company-details__info__box">
                                                    <span>
                                                        <b>Товар:</b>
                                                    </span>
                                                    <span>{id}</span>
                                                </div>
                                                <div className="company-details__info__box">
                                                    <span>Наименование:</span>
                                                    <span>{name}</span>
                                                </div>
                                                <div className="company-details__info__box">
                                                    <span>Стоимость:</span>
                                                    <span>{price}</span>
                                                </div>
                                                <div className="company-details__info__box">
                                                    <span>Количество:</span>
                                                    <span>{quantity}</span>
                                                </div>
                                                <div className="company-details__info__box">
                                                    <span>
                                                        Единица измерения:
                                                    </span>
                                                    <span>
                                                        {measurementUnit
                                                            ? measurementUnit
                                                            : '-'}
                                                    </span>
                                                </div>
                                                <div className="company-details__info__box">
                                                    <span>Сумма:</span>
                                                    <span>{amount}</span>
                                                </div>
                                                <div className="company-details__info__box">
                                                    <span>Ставка налога:</span>
                                                    <span>{tax}</span>
                                                </div>
                                                <div className="company-details__info__box">
                                                    <span>
                                                        Признак способа расчета:
                                                    </span>
                                                    <span>{paymentMethod}</span>
                                                </div>
                                                <div className="company-details__info__box">
                                                    <span>
                                                        Признак предмета
                                                        расчета:
                                                    </span>
                                                    <span>{paymentObject}</span>
                                                </div>
                                            </Card>
                                            <br />
                                        </React.Fragment>
                                    )
                                )}
                            </React.Fragment>
                        ) : null}
                    </div>
                </Container>
            </div>
        );
    }
}

export default connect()(withRouter(EinvoiceDetailsPage));
