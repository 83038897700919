let timeoutId

const renderCustomTooltip = (context, renderFunction: (context) => string) => {
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip');
    // Create element on first render
    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<table></table>';
        document.body.appendChild(tooltipEl);
    }

    if (timeoutId) clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
        tooltipEl?.remove();
    }, 2_000);

    // Hide if no tooltip
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
    }
    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
        tooltipEl.classList.add('no-transform');
    }

    let innerHtml = renderFunction(context);
    let tableRoot = tooltipEl.querySelector('table');
    if (tableRoot) {
        tableRoot.innerHTML = innerHtml;
    }

    // Display, position, and set styles for font
    const position = context.chart.canvas.getBoundingClientRect();

    tooltipEl.style.opacity = '1';
    tooltipEl.style.backgroundColor = '#fff';
    tooltipEl.style.boxShadow = '0px 4px 12px rgba(0, 0, 0, 0.15)';
    tooltipEl.style.borderRadius = '6px';
    tooltipEl.style.zIndex = '1';
    tooltipEl.style.padding = '16px 18px';
    tooltipEl.style.position = 'absolute';
    if (tooltipModel.caretX + tooltipModel.width + 100 > window.innerWidth) {
        tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX - tooltipModel.width + 'px';
    } else {
        tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    }
    tooltipEl.style.top =
        position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    tooltipEl.style.font = 'Open Sans, sans-serif';
    tooltipEl.style.padding =
        tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
    tooltipEl.style.pointerEvents = 'none';
};

export { renderCustomTooltip };
