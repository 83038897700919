import React, { useEffect, useRef, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { chevron } from './chevron';

import * as styles from './dropdown.module.scss';

interface Item {
    href?: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    title: string;
}

interface Props {
    items?: Item[];
    header: React.ReactNode;
    circular?: boolean;
    outline?: boolean;
}

export const Dropdown: React.FC<Props> = ({
    items,
    header,
    circular,
    outline,
    children,
}) => {
    const [open, setOpen] = useState<boolean>();
    const [redirectUrl, setRedirectUrl] = useState<string>();
    const location = useLocation();
    let ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // close menu on location change
        setOpen(false);
    }, [location]);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            ref.current &&
            !ref.current.contains(event.target as HTMLDivElement)
        ) {
            // close dropdown menu on click outside
            setOpen(false);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <div
            ref={ref}
            className={[
                styles['dropdown-wrapper'],
                open ? styles['open'] : styles['closed'],
                circular ? styles['circular'] : '',
                outline ? styles['outline'] : '',
            ].join(' ')}
        >
            {redirectUrl && <Redirect to={redirectUrl} />}
            <button
                className={styles['trigger']}
                onClick={() => {
                    setOpen(!open);
                }}
            >
                <div className={styles['trigger__header']}>{header}</div>
                <div className={styles['trigger__arrow']}>{chevron}</div>
            </button>
            <div className={styles['items']}>
                <div className={styles['placeholder']} />
                {Array.isArray(items) &&
                    (items as Item[]).map(({ onClick, title, href }) => (
                        <div
                            className={styles['dropdown__item']}
                            key={title + href}
                            onClick={(event) => {
                                if (onClick) {
                                    onClick(event);
                                } else if (href) {
                                    setRedirectUrl(href);
                                }
                            }}
                        >
                            {title}
                        </div>
                    ))}
                {children}
            </div>
        </div>
    );
};
