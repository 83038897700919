import React, { useEffect, useRef, useState } from 'react';
import {
    INDICATOR_COLOR_VARIANT_MAP,
    IndicatorColorVariant,
} from 'src/entities/selector-with-groups/config';
import { FixedPositionTooltip, SelectorIcon } from 'src/shared/ui';
import styles from './styles.module.scss';
import { detectTouchEventHold, classNames } from 'src/shared/lib';
import { isMobile } from 'react-device-detect';

interface Props {
    name: string;
    disabled?: boolean;
    onTerminalClick: () => void;
    isCurrentlySelected: () => boolean;
    selectorColor: IndicatorColorVariant;
    organisationName: string;
    tooltip?: JSX.Element;
    statusIcon?: JSX.Element;
}

export const IndividualRow = ({
    name: terminalName,
    disabled,
    onTerminalClick,
    isCurrentlySelected,
    selectorColor,
    tooltip,
    statusIcon,
}: Props) => {
    const rowReference = useRef<HTMLDivElement>(null);

    const [displayTooltip, setDisplayTooltip] = useState(false);
    const [tooltipAbsolutePosition, setTooltipAbsolutePosition] = useState({
        left: 0,
        top: 0,
    });

    useEffect(() => {
        if (rowReference?.current) {
            const { top, left } = rowReference.current.getBoundingClientRect();
            setTooltipAbsolutePosition({
                top: top + window.scrollY,
                left: left + window.scrollX,
            });
        }
    }, [displayTooltip]);

    useEffect(() => {
        const handleTerminalHold = () => {
            setDisplayTooltip(true);
        };
        const handleTerminalTouchEnd = () => {
            setDisplayTooltip(false);
        };

        const cleanup = detectTouchEventHold(
            handleTerminalHold,
            handleTerminalTouchEnd,
            rowReference.current
        );
        return cleanup;
    }, []);

    return (
        <div
            ref={rowReference}
            onClick={() => onTerminalClick()}
            className={classNames(styles['terminal'], {
                [styles['title-with-selector']]: true,
                [styles['filter-row']]: true,
                [styles['selected']]: isCurrentlySelected(),
                [styles['disabled']]: disabled,
                [styles['no-icon']]: !statusIcon,
            })}
        >
            <SelectorIcon
                color={INDICATOR_COLOR_VARIANT_MAP[selectorColor]}
                selected={isCurrentlySelected()}
            />
            {statusIcon}
            <span
                onMouseEnter={() => {
                    // For mobile tooltips are handled by touchevent listener
                    !isMobile && setDisplayTooltip(true);
                }}
                onMouseLeave={() => setDisplayTooltip(false)}
                className={styles['name']}
            >
                {terminalName}
            </span>
            {tooltipAbsolutePosition.top && tooltipAbsolutePosition.left ? (
                <FixedPositionTooltip
                    {...tooltipAbsolutePosition}
                    transformX="72px"
                    transformY="-100%"
                    isDisplayed={displayTooltip && !!tooltip}
                >
                    {tooltip}
                </FixedPositionTooltip>
            ) : (
                <></>
            )}
        </div>
    );
};
