import React, { Component } from 'react';
import { ReplaySubject } from 'rxjs';
import { connect } from 'react-redux';

import { navRoutes, URL } from 'src/app/config';
import {
    getAppTitle,
    getInitialFilter,
    LOCAL_STORAGE_TABLE_NAMES,
} from 'src/shared/lib';
import AwareTable from 'src/components/AwareTable';
import SettlementFilter from './SettlementFilter';
import { OverflowContainer, Paginator } from 'src/shared';
import { DownloadReportStore } from 'src/features/download-report';

import './settlement-page.scss';

const pageTitle = LOCAL_STORAGE_TABLE_NAMES.settlement;

class SettlementPage extends Component {
    filterSubject = new ReplaySubject(1);

    transformBody(body) {
        if (getAppTitle() === 'bank') {
            body.terminalIds = undefined;
        }
    }

    render() {
        return (
            <div className="settlement">
                <SettlementFilter pageTitle={pageTitle} />

                <Paginator name={pageTitle}>
                    <OverflowContainer>
                        <AwareTable
                            useCommonFilters
                            newStatuses
                            tableName={pageTitle}
                            token={this.props.cancelationToken}
                            url={URL.settlementUrl}
                            transformBody={this.transformBody}
                            link={navRoutes.settlement}
                            isStoreTable
                            actions={[
                                <DownloadReportStore
                                    useCommonFilters
                                    pageTitle={pageTitle}
                                    isDetailed
                                    defaultFileName="Возмещения"
                                    getFilterCallback={() =>
                                        getInitialFilter(
                                            ['terminalIds', 'companyIds'],
                                            pageTitle
                                        )
                                    }
                                    url={URL.settlementFileDownloadUrl}
                                />,
                            ]}
                        />
                    </OverflowContainer>
                </Paginator>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        fetch: state.fetch.fetch,
        cancelationToken: state.requestCancelation.token,
    };
}

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(SettlementPage);
