import React from 'react';

export const UserIcon = (
    <svg
        width="41"
        height="43"
        viewBox="0 0 41 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.9033 21.6914C26.3232 21.6914 30.6934 16.9062 30.6934 10.998C30.6934 5.1875 26.3232 0.573242 20.9033 0.573242C15.5078 0.573242 11.0645 5.26074 11.0889 11.0225C11.1133 16.9307 15.4834 21.6914 20.9033 21.6914ZM20.9033 18.6885C17.29 18.6885 14.2871 15.3438 14.2871 11.0225C14.2627 6.84766 17.2656 3.57617 20.9033 3.57617C24.541 3.57617 27.4951 6.79883 27.4951 10.998C27.4951 15.2949 24.5166 18.6885 20.9033 18.6885ZM6.13281 42.9316H35.6006C39.165 42.9316 40.8496 41.833 40.8496 39.416C40.8496 33.3857 33.2568 24.7432 20.8789 24.7432C8.47656 24.7432 0.883789 33.3857 0.883789 39.416C0.883789 41.833 2.56836 42.9316 6.13281 42.9316ZM5.25391 39.9287C4.375 39.9287 4.08203 39.6846 4.08203 39.0742C4.08203 34.8994 10.1367 27.7461 20.8789 27.7461C31.6211 27.7461 37.6758 34.8994 37.6758 39.0742C37.6758 39.6846 37.3584 39.9287 36.4795 39.9287H5.25391Z"
            fill="#002E5A"
        />
    </svg>
);
