import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { getRcMsg } from 'src/redux/actions/terminals';
import {
    operationStatuses,
    operationTypes,
    formatDateForInput,
    getClassName,
    operationStatusMapper,
    operationTypeMapper,
    localSave,
    getInitialFilter,
    getAppTitle,
    localLoadIntInArray,
    LOCAL_STORAGE_TABLE_NAMES,
} from 'src/shared/lib';
import { DateRangePicker, SearchField, FiltersSelector } from 'src/shared/ui';
import { FiltersContainer } from 'src/widgets';
import CompanySelect from 'src/components/CompanySelect';
import { setFilters, setCommonTableFilters } from 'src/redux/actions/table';
import styles from './styles.module.scss';
import { MerchantTerminalsSelector } from 'src/features/select-terminals';

class OperationsFilter extends Component {
    constructor(props) {
        super(props);
        this.startDate = getClassName(
            LOCAL_STORAGE_TABLE_NAMES.common,
            'startDate'
        );
        this.endDate = getClassName(LOCAL_STORAGE_TABLE_NAMES.common, 'endDate');
        this.terminalIds = getClassName(
            LOCAL_STORAGE_TABLE_NAMES.common,
            'terminalIds'
        );
        this.searchQuery = getClassName(this.props.pageTitle, 'searchQuery');
        this.operationTypes = getClassName(
            this.props.pageTitle,
            'operationTypes'
        );
        this.operationStatuses = getClassName(
            this.props.pageTitle,
            'operationStatuses'
        );
        this.responseMessages = getClassName(
            this.props.pageTitle,
            'responseMessages'
        );
        this.companyId = getClassName(
            this.props.pageTitle,
            CompanySelect.companyIds
        );

        let initialFilter = getInitialFilter(
            [
                'operationTypes',
                'operationStatuses',
                'searchQuery',
                'responseMessages',
            ],
            this.props.pageTitle
        );

        const commonSavedState = getInitialFilter(
            ['terminalIds'],
            LOCAL_STORAGE_TABLE_NAMES.common
        );

        // if we're redirected with terminal ID in state, use it as initial value
        this.locationTerminalId = props.location.state?.rowID;
        this.defaultFilter = {
            ...initialFilter,
            companyIds: localLoadIntInArray(this.companyId, []),
            terminalIds: this.locationTerminalId
                ? [Number(this.locationTerminalId)]
                : initialFilter.terminalIds,
        };
        this.state = {
            ...this.defaultFilter,
        };
        this.setStartDate(this.commonSavedState?.startDate);
        this.setEndDate(this.commonSavedState?.endDate);
        this.props.dispatch(setCommonTableFilters(commonSavedState));
        this.props.dispatch(setFilters(this.props.pageTitle, initialFilter));
        if (this.props.table?.filters?.searchQuery) {
            this.props.dispatch(
                setFilters(this.props.pageTitle, {
                    searchQuery: this.props.table?.filters?.searchQuery,
                })
            );
        }
    }

    componentWillUnmount() {
        // clears location.state so that terminals can be changed after page update
        window.history.replaceState({}, document.title);
    }

    componentDidMount() {
        if (this.props.location.state?.rowID) {
            this.props.dispatch(
                setFilters(this.props.pageTitle, {
                    operationTypes: [],
                    operationStatuses: [],
                    responseMessages: [],
                    terminalIds: [Number(this.props.location.state.rowID)],
                })
            );
            this.props.dispatch(
                setCommonTableFilters({
                    terminalIds: [Number(this.props.location.state.rowID)],
                })
            );
            const { state } = this.props.location;
            this.setStartDate(moment(state.date, 'DD.MM.YYYY'));
            this.setEndDate(moment(state.date, 'DD.MM.YYYY'));
        }

        !this.props.rcMsg.length && this.props.dispatch(getRcMsg());
    }

    operationStatusesAsObject(statuses) {
        return statuses.map((status) => {
            return {
                value: status,
                text: operationStatusMapper(status),
                id: status,
            };
        });
    }

    operationTypesAsObject(types) {
        return types.map((type) => {
            return {
                value: type,
                text: operationTypeMapper(type),
                id: type,
            };
        });
    }

    operationRcMsgAsObject(rcMessages) {
        return rcMessages.map((rcMessage) => {
            return {
                value: rcMessage,
                text: rcMessage,
                id: rcMessage,
            };
        });
    }

    saveFilter() {
        localSave(this.startDate, this.getStartDate());
        localSave(this.endDate, this.getEndDate());
        localSave(
            this.terminalIds,
            this.props.commonFilters?.terminalIds ?? []
        );
        localSave(
            this.operationStatuses,
            this.props.table?.filters?.operationStatuses
        );
        localSave(
            this.operationTypes,
            this.props.table?.filters?.operationTypes
        );
        localSave(
            this.responseMessages,
            this.props.table?.filters?.responseMessages
        );
    }

    componentDidUpdate() {
        !this.locationTerminalId && this.saveFilter();
    }

    getStartDate() {
        return this.props.commonFilters?.startDate ?? this.state.startDate;
    }

    setStartDate(date) {
        this.props.dispatch(setCommonTableFilters({ startDate: date }));
    }

    getEndDate() {
        return this.props.commonFilters?.endDate ?? this.state.endDate;
    }

    setEndDate(date) {
        this.props.dispatch(setCommonTableFilters({ endDate: date }));
    }

    render() {
        let [OperationTypes, OperationStatuses, OperationRcMessages] = [
            this.operationTypesAsObject(operationTypes),
            this.operationStatusesAsObject(operationStatuses),
            this.operationRcMsgAsObject(this.props.rcMsg),
        ];

        return (
            <div className={styles['wrapper']}>
                <FiltersContainer>
                    <FiltersSelector
                        columns={[
                            {
                                title: 'Тип',
                                options: OperationTypes,
                                onChange: (value) =>
                                    this.props.dispatch(
                                        setFilters(this.props.pageTitle, {
                                            operationTypes: value,
                                        })
                                    ),

                                selected:
                                    this.props.table?.filters?.operationTypes ??
                                    [],
                            },
                            {
                                title: 'Статус',
                                options: OperationStatuses,
                                onChange: (value) =>
                                    this.props.dispatch(
                                        setFilters(this.props.pageTitle, {
                                            operationStatuses: value,
                                        })
                                    ),
                                selected:
                                    this.props.table?.filters
                                        ?.operationStatuses ?? [],
                            },
                            {
                                title: 'Код отказа',
                                options: OperationRcMessages,
                                onChange: (value) =>
                                    this.props.dispatch(
                                        setFilters(this.props.pageTitle, {
                                            responseMessages: value,
                                        })
                                    ),
                                selected:
                                    this.props.table?.filters
                                        ?.responseMessages ?? [],
                            },
                        ]}
                        reset={() => {
                            this.props.dispatch(
                                setFilters(this.props.pageTitle, {
                                    operationTypes: [],
                                    operationStatuses: [],
                                    responseMessages: [],
                                })
                            );
                        }}
                    />
                    <MerchantTerminalsSelector
                        key={this.props.table?.filters?.terminalIds}
                        tableName={LOCAL_STORAGE_TABLE_NAMES.common}
                        selected={this.props.commonFilters?.terminalIds ?? []}
                        onChange={(terminalIds) =>
                            this.props.dispatch(
                                setCommonTableFilters({
                                    terminalIds,
                                })
                            )
                        }
                        terminalsList={this.props.terminals}
                    />
                    <DateRangePicker
                        datepickers={[
                            {
                                selected: this.getStartDate(),
                                start: true,
                                onChange: (startDate) =>
                                    this.setStartDate(startDate),
                            },
                            {
                                selected: this.getEndDate(),
                                end: true,
                                onChange: (endDate) => this.setEndDate(endDate),
                            },
                        ]}
                        formatter={formatDateForInput}
                        criticalScreenWidth={
                            getAppTitle() === 'merchant' ? 1225 : undefined
                        }
                        startDate={this.getStartDate()}
                        endDate={this.getEndDate()}
                        onReset={() => {
                            this.setStartDate(
                                moment(new Date()).add(-7, 'days')
                            );
                            this.setEndDate(moment(new Date()));
                        }}
                    />

                    <div className={styles['actions-wrapper']}>
                        {getAppTitle() === 'bank' && (
                            <CompanySelect
                                uid={this.props.pageTitle}
                                onChange={({ value }) =>
                                    this.props.dispatch(
                                        setFilters(this.props.pageTitle, {
                                            companyIds: value ? [value] : [],
                                        })
                                    )
                                }
                            />
                        )}
                        {this.props.actions}
                    </div>

                    <SearchField
                        fullsize
                        placeholder="Введите RRN или код авторизации"
                        value={this.props.table?.filters?.searchQuery}
                        onChange={(value) =>
                            this.props.dispatch(
                                setFilters(this.props.pageTitle, {
                                    searchQuery: value,
                                })
                            )
                        }
                    />
                </FiltersContainer>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        table: state.table?.[props.pageTitle],
        commonFilters: state.table?.commonFilters,
        terminals: state.terminals.terminals,
        rcMsg: state.terminals.rcMsg,
    };
}

export default withRouter(
    connect(mapStateToProps, null, null, {
        forwardRef: true,
    })(OperationsFilter)
);
