import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { IndicatorColorVariant } from '../../config';
import { EmptyRowsIcon } from '../empty-rows-icon';
import { GroupRow } from '../group-row';
import { IndividualRow } from '../individual-row';
import { isArray } from 'lodash';

interface Props<OriginalDataType, GroupDataType, RowDataType, idType> {
    selected: Array<idType>;
    data: OriginalDataType[];
    isCurrentlySelected: (terminalId: idType) => boolean;
    query: string;
    selectedOnly: boolean;
    inventoryDisplayFilters: string[];

    rowsGetter: (data: OriginalDataType) => RowDataType[] | RowDataType[][];
    rowNameGetter: (row: RowDataType) => string;
    handleRowClick: (id: idType) => void;
    isSelected: (id: idType) => boolean;
    getRowId: (data: RowDataType) => idType;
    groupsGetter: (data: OriginalDataType) => GroupDataType[];

    tooltip?: (
        data: RowDataType,
        tooltipTitle: string,
        dotColor?: IndicatorColorVariant
    ) => JSX.Element;

    statusIcon?: (data: RowDataType) => JSX.Element;

    isRowDisabled?: (data: RowDataType) => boolean;
    emptyPlaceholderText: string;

    groupNameGetter: (data: OriginalDataType) => string;
    handleGroupClick: (data: OriginalDataType) => void;
    isGroupSelected: (data: OriginalDataType) => boolean;
    isGroupFullySelected: (data: OriginalDataType) => boolean;

    filterData: (data: OriginalDataType[]) => OriginalDataType[];
}

const colorVariants = Object.values(IndicatorColorVariant);

export const SelectorWithGroups = <
    OriginalDataType,
    GroupDataType,
    RowDataType,
    idType
>({
    selected,
    data,
    handleRowClick,
    isCurrentlySelected,
    selectedOnly,
    inventoryDisplayFilters,
    query,

    tooltip,
    statusIcon,

    rowsGetter,
    rowNameGetter,
    getRowId,

    groupNameGetter,
    handleGroupClick,
    isGroupFullySelected,
    isGroupSelected,

    isRowDisabled,
    emptyPlaceholderText,

    filterData,
}: Props<OriginalDataType, GroupDataType, RowDataType, idType>) => {
    let currentColorNumber = 0;
    const nextColor = () => {
        currentColorNumber = (currentColorNumber + 1) % 3;
    };

    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        setFilteredData(filterData(data));
    }, [
        // TODO 634: figure out what to do with theese
        data,
        filterData,
        inventoryDisplayFilters,
        query,
        selected,
        selectedOnly,
    ]);

    const renderGroupName = (
        data: OriginalDataType,
        nameGetter: (data: OriginalDataType) => string,
        handleGroupClick: (data: OriginalDataType) => void,
        isSelected: (data: OriginalDataType) => boolean,
        isFullySelected: (data: OriginalDataType) => boolean
    ) => {
        return (
            <GroupRow
                name={nameGetter(data)}
                isSelected={isSelected(data)}
                isFullySelected={isFullySelected(data)}
                handleGroupClick={() => handleGroupClick(data)}
                selectorColor={colorVariants[currentColorNumber]}
            />
        );
    };

    const renderRows = (
        data: OriginalDataType,
        rowsGetter: (data: OriginalDataType) => RowDataType[] | RowDataType[][],
        rowNameGetter: (row: RowDataType) => string,
        handleRowClick: (id: idType) => void,
        isSelected: (id: idType) => boolean
    ) => {
        let rowsGrouped = false;

        const renderedRows = rowsGetter(data).map(
            (rowOrRowsGroup: RowDataType[] | RowDataType) => {
                const renderRow = (row: RowDataType) => (
                    <IndividualRow
                        organisationName={rowNameGetter(row)}
                        isCurrentlySelected={() => isSelected(getRowId(row))}
                        onTerminalClick={() => handleRowClick(getRowId(row))}
                        selectorColor={colorVariants[currentColorNumber]}
                        name={rowNameGetter(row)}
                        tooltip={tooltip?.(
                            row,
                            groupNameGetter(data),
                            colorVariants[currentColorNumber]
                        )}
                        disabled={isRowDisabled?.(row)}
                        statusIcon={statusIcon?.(row)}
                    />
                );

                if (isArray(rowOrRowsGroup)) {
                    rowsGrouped = true;
                    const rowsGroup = rowOrRowsGroup.map(renderRow);

                    nextColor();
                    return rowsGroup;
                }

                return renderRow(rowOrRowsGroup);
            }
        );

        if (!rowsGrouped) {
            nextColor();
        }
        
        return renderedRows;
    };

    const isEmpty = filteredData.map((data) => rowsGetter(data)).length === 0;

    return isEmpty ? (
        <div className={styles['no-selected-terminals']}>
            <EmptyRowsIcon />
            <p>{emptyPlaceholderText}</p>
        </div>
    ) : (
        <>
            {filteredData.map((data) => {
                const renderGroup = (
                    <div className={styles['wrapper']}>
                        {renderGroupName(
                            data,
                            groupNameGetter,
                            handleGroupClick,
                            isGroupSelected,
                            isGroupFullySelected
                        )}
                        <div className={styles['terminals']}>
                            {renderRows(
                                data,
                                rowsGetter,
                                rowNameGetter,
                                (id) => handleRowClick(id),
                                (id) => isCurrentlySelected(id)
                            )}
                        </div>
                    </div>
                );

                return renderGroup;
            })}
        </>
    );
};
