import * as type from '../constants';
import { post } from '../../shared/lib/request-wrapper';
import { URL } from 'src/app/config';

const defaultConversionChart = [
    {
        name: 'Конверсия',
        groupName: 'Сумма (₽)',
        paramNames: ['Успешные', 'Неуспешные'],
        tuples: [],
    },
    {
        name: 'Конверсия',
        groupName: 'Количество',
        paramNames: ['Успешные', 'Неуспешные'],
        tuples: [],
    },
];

const defaultDeclineChart = [
    {
        name: 'Конверсия',
        groupName: 'Сумма (₽)',
        paramNames: ['Успешные', 'Неуспешные'],
        tuples: [],
    },
    {
        name: 'Конверсия',
        groupName: 'Количество',
        paramNames: ['Успешные', 'Неуспешные'],
        tuples: [],
    },
];

const defaultMpsChart = [
    {
        groupName: 'Сумма (₽)',
        name: 'Доля платежных систем',
        tuples: [],
    },
    {
        groupName: 'Количество',
        name: 'Доля платежных систем',
        tuples: [],
    },
];

export function getDynamicsChart(request) {
    return (dispatch) => {
        return post(URL.dynamicsChartUrl, request)
            .then((response) => {
                dispatch({
                    type: type.GET_DINAMIC_CHARTS_SUCCESS,
                    payload: response.data.payload,
                });
            })
            .catch((response) =>
                dispatch({
                    type: type.GET_DINAMIC_CHARTS_FAIL,
                    error: response.data,
                })
            );
    };
}

export function getConversionChart(request) {
    return (dispatch) => {
        if (request.terminalIds.length === 0) {
            dispatch({
                type: type.GET_CONVERSION_CHARTS_SUCCESS,
                payload: defaultConversionChart,
            });
            return;
        }

        return post(URL.conversionChartUrl, request)
            .then((response) => {
                dispatch({
                    type: type.GET_CONVERSION_CHARTS_SUCCESS,
                    payload: response.data.payload,
                });
            })
            .catch((response) =>
                dispatch({
                    type: type.GET_CONVERSION_CHARTS_FAIL,
                    error: response.data,
                })
            );
    };
}

export function getDeclineChart(request) {
    return (dispatch) => {
        if (request.terminalIds.length === 0) {
            dispatch({
                type: type.GET_DECLINE_CHARTS_SUCCESS,
                payload: defaultDeclineChart,
            });
            return;
        }

        return post(URL.declineChartUrl, request)
            .then((response) => {
                dispatch({
                    type: type.GET_DECLINE_CHARTS_SUCCESS,
                    payload: response.data.payload,
                });
            })
            .catch((response) =>
                dispatch({
                    type: type.GET_DECLINE_CHARTS_FAIL,
                    error: response.data,
                })
            );
    };
}

export function getMpsChart(request) {
    return (dispatch) => {
        if (request.terminalIds.length === 0) {
            dispatch({
                type: type.GET_MPS_CHARTS_SUCCESS,
                payload: defaultMpsChart,
            });
            return;
        }

        return post(URL.mpsChartUrl, request)
            .then((response) => {
                dispatch({
                    type: type.GET_MPS_CHARTS_SUCCESS,
                    payload: response.data.payload,
                });
            })
            .catch((response) =>
                dispatch({
                    type: type.GET_MPS_CHARTS_FAILED,
                    error: response.data,
                })
            );
    };
}

export function toggleCharts(toggle) {
    return (dispatch) => {
        dispatch({ type: type.TOGGLE_CHART, toggle });
    };
}
