import React from 'react';

export const PhoneIcon = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.2695 19.959C15.5332 19.959 19.8789 15.6133 19.8789 10.3398C19.8789 5.07617 15.5234 0.730469 10.2598 0.730469C4.99609 0.730469 0.650391 5.07617 0.650391 10.3398C0.650391 15.6133 4.99609 19.959 10.2695 19.959ZM7.96484 12.5762C5.73828 10.3496 4.32227 7.53711 5.99219 5.85742C6.03125 5.81836 6.07031 5.7793 6.11914 5.74023C6.7832 5.26172 7.35938 5.17383 7.75 5.73047L8.91211 7.38086C9.32227 7.95703 9.21484 8.28906 8.79492 8.73828L8.42383 9.13867C8.29688 9.26562 8.3457 9.44141 8.4043 9.53906C8.58984 9.86133 9.06836 10.4375 9.58594 10.9551C10.1133 11.4824 10.6699 11.9316 11.002 12.1367C11.1289 12.2148 11.3145 12.2246 11.4316 12.1172L11.8027 11.7559C12.2227 11.3359 12.584 11.2285 13.1602 11.6289C13.873 12.127 14.3516 12.4688 14.8301 12.8008C15.3965 13.2305 15.2598 13.875 14.8594 14.3535C14.8008 14.4219 14.752 14.4902 14.6836 14.5586C13.0137 16.2188 10.2012 14.8125 7.96484 12.5762Z"
            fill="#CABFAD"
        />
    </svg>
);
