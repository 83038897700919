import * as type from '../../redux/constants';
import { get, post } from '../../shared/lib/request-wrapper';
import { createNotification } from './notification';
import { Roles, navRoutes, URL } from 'src/app/config';
import { getAppTitle } from 'src/shared/lib';
import { getInactiveStatus } from './terminals';

export function logIn(credentials, callback, history) {
    return (dispatch, state) => {
        if (state().auth.submitted) {
            return;
        }
        dispatch({ type: type.LOG_IN });
        let params = new URLSearchParams();
        params.append('login', credentials.login);
        params.append('password', credentials.password);

        return post(URL.loginUrl, params)
            .then((response) => {
                dispatch({
                    type: type.LOG_IN_SUCCESS,
                    payload: response.data,
                });
                return get(URL.userInfoUrl);
            })
            .then((response) => {
                return response.data.payload;
            })
            .then((payload) => {
                dispatch({
                    type: type.GET_USER_AFTER_LOGIN_ROLES_SUCCESS,
                    payload,
                });

                if (getAppTitle() === 'merchant') {
                    dispatch(getInactiveStatus());
                }

                const redirectUrl = redirectUrlByPermission(
                    payload.roles,
                    payload.companyRoles
                );
                history?.push(redirectUrl);
            })
            .catch((response) => {
                if (response.data && response.data.code === 400) {
                    dispatch(
                        createNotification(
                            {
                                title: 'Ошибка',
                                message: 'Проверьте вводимые данные',
                            },
                            'error'
                        )
                    );
                }
                if (response.data && response.data.code === 4) {
                    dispatch(
                        createNotification(
                            {
                                title: 'Смена пароля',
                                message: 'Необходимо сменить пароль',
                            },
                            'info'
                        )
                    );
                    callback();
                    return;
                }
                if (response.data && response.data.code === 3) {
                    dispatch(
                        createNotification(
                            {
                                title: 'Блокировка',
                                message:
                                    'Пользователь временно заблокирован.\n Попробуйте через несколько минут.',
                            },
                            'error'
                        )
                    );
                } else {
                    if (!(response.status >= 500)) {
                        dispatch(
                            createNotification(
                                {
                                    title: 'Ошибка',
                                    message: 'Проверьте вводимые данные',
                                },
                                'error'
                            )
                        );
                    }
                }
                dispatch({ type: type.LOG_IN_FAILED, error: response.data });
            });
    };
}

export function activateUser(credentials, history) {
    return (dispatch, state) => {
        if (state().auth.submitted) {
            return;
        }
        dispatch({ type: type.LOG_IN });
        let { password, code, token } = credentials;
        return post(URL.userActivateUrl, { password, code, token })
            .then((response) => {
                dispatch({
                    type: type.ACTIVATE_USER_SUCCESS,
                    payload: response.data,
                });
                dispatch(
                    createNotification(
                        {
                            title: 'Активация',
                            message: 'Активация прошла успешно',
                        },
                        'success'
                    )
                );
                if (history) history.push(URL.loginUrl);
            })
            .catch((response) => {
                dispatch({
                    type: type.ACTIVATE_USER_FAILED,
                    error: response.data,
                });
            });
    };
}

export function forgotPassword(credentials, history) {
    return (dispatch, state) => {
        if (state().auth.submitted) {
            return;
        }
        dispatch({ type: type.LOG_IN });
        let { email } = credentials;
        return post(URL.forgotPasswordRequestUrl, { email })
            .then((response) => {
                dispatch({
                    type: type.FORGOT_PASSWORD_USER_SUCCESS,
                    payload: response.data,
                });
                dispatch(
                    createNotification(
                        {
                            title: 'Восстановление пароля',
                            message:
                                'Инструкция направлена вам на указанный email',
                        },
                        'info'
                    )
                );
                if (history) history.push(URL.loginUrl);
            })
            .catch((response) => {
                dispatch({
                    type: type.FORGOT_PASSWORD_USER_FAILED,
                    error: response.data,
                });
            });
    };
}

export function changePassword(request, history) {
    return (dispatch) => {
        return post(URL.changePasswordUrl, request)
            .then((response) => {
                dispatch({
                    type: type.CHANGE_PASSWORD_SUCCESS,
                    payload: response.data,
                });
                if (history) history.push(URL.loginUrl);
            })
            .then(() => {
                dispatch(
                    createNotification(
                        {
                            title: 'Пароль сменен',
                            message: 'Смена пароля успешна',
                        },
                        'success'
                    )
                );
            })
            .catch((response) => {
                dispatch(
                    createNotification(
                        {
                            title: 'Ошибка',
                            message: response.data.message,
                        },
                        'error'
                    )
                );
                dispatch({
                    type: type.CHANGE_PASSWORD_FAILED,
                    error: response.data,
                });
            });
    };
}

export function saveExpiredUserEmail(email) {
    return (dispatch) =>
        dispatch({
            type: type.CHANGE_PASSWORD_USER_EXPIRED,
            payload: email,
        });
}

export function logOut() {
    return (dispatch) =>
        post(URL.logoutUrl)
            .then((response) =>
                dispatch({
                    type: type.LOG_OUT_AND_CLEAR_LOCAL,
                    payload: response,
                })
            )
            .catch((response) =>
                dispatch({
                    type: type.LOG_OUT_AND_CLEAR_LOCAL,
                    error: response.response,
                })
            );
}

export function redirectUrlByPermission(roles, companyRoles) {
    if (!companyRoles && getAppTitle() === 'bank') {
        if (roles.includes(Roles.ADMIN)) return navRoutes.company;
        if (roles.includes(Roles.COMPANY_INFO)) return navRoutes.company;
        if (roles.includes(Roles.USER_MANAGEMENT)) return navRoutes.users;
        if (roles.includes(Roles.OPERATIONS)) return navRoutes.operations;
        if (roles.includes(Roles.ADMIN)) return navRoutes.cashBoxes;
        if (roles.includes(Roles.CASHBOX)) return navRoutes.platformCashBox;
        if (roles.includes(Roles.SETTLEMENT)) return navRoutes.settlement;
        return navRoutes.settings;
    }
    let commonRoles = roles.filter((role) => companyRoles.indexOf(role) > 0);
    let removeValFrom = (roles) => [
        roles.indexOf(Roles.ADMIN),
        roles.indexOf(Roles.SETTING_USER),
        roles.indexOf(Roles.REFUND),
    ];
    let filteredRoles = roles.includes(Roles.ADMIN)
        ? companyRoles.filter(
              (value, index) =>
                  removeValFrom(companyRoles).indexOf(index) === -1
          )
        : commonRoles.filter(
              (value, index) => removeValFrom(commonRoles).indexOf(index) === -1
          );
    if (filteredRoles.includes(Roles.STATISTIC)) return navRoutes.statistic;
    if (filteredRoles.includes(Roles.DYNAMIC)) return navRoutes.dynamics;
    if (filteredRoles.includes(Roles.OPERATIONS))
        return navRoutes.operationLastUrl;
    if (filteredRoles.includes(Roles.SETTLEMENT))
        return navRoutes.settlementLastUrl;
    return navRoutes.support.main;
}
