import { SelectOption } from '../model/types';

export const addValueInObject = (
    value: string,
    object: Record<string, string>
) => {
    object[value] = value;
};

export const deleteKeyFromObject = (
    key: string,
    object: Record<string, string>
) => {
    delete object[key];
};

export const getCountKeyInObject = (object: Record<string, string>) => {
    return Object.keys(object).length;
};

export const filterArray = (query: string, array: SelectOption[]) => {
    if (!query) {
        return array;
    }

    const regexp = new RegExp(query.toLowerCase(), 'g');
    return array.filter((item) => regexp.test(item.content.toLowerCase()));
};
