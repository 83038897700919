import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';

import { URL, Roles } from 'src/app/config';
import { createNotification } from 'src/redux/actions/notification';
import {
    formatCurrency,
    OperationDescriptionSbp,
    OperationDetailsSbp,
} from 'src/entities/operation';
import { get, post, isPermit, log } from 'src/shared/lib';
import {
    Button,
    Spin,
    ArticleLayout,
    Modal,
    ModalField,
    NewSelect,
    Textarea,
    Input,
} from 'src/shared/ui';
import './operation-details.scss';

const revertOptions = [
    { value: 'full', content: 'Полный возврат' },
    { value: 'partial', content: 'Частичный возврат' },
];

class OperationSBPDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            details: {},
            description: '',
            modal: false,
            revertAmount: '',
            revert: 'full',
            isAlreadyRevert: false,
            errors: {},
            operationAmount: null,
        };
        this.toggle = this.toggle.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.revertOperation = this.revertOperation.bind(this);
        this.getOperation = this.getOperation.bind(this);
        this.permission = isPermit.bind(this);
    }

    toggle() {
        this.setState({ modal: !this.state.modal });
    }

    componentDidMount() {
        this.getOperation();
    }

    formatDate(date) {
        return date ? moment(date).format('DD MMMM YYYY HH:mm') : date;
    }

    revertOperation() {
        const { revert, description } = this.state;
        const { trxId, amount, receipt } = this.state.details;

        post(URL.operationSBPRevertUrl + this.props.match.params.id, {
            trxId,
            amount,
            description,
            ...(revert !== 'full' &&
                receipt && {
                    receipt: {
                        ...receipt,
                        items: receipt.items.filter(
                            ({ amount, quantity }) =>
                                ![amount, quantity].includes(0)
                        ),
                    },
                }),
        })
            .then((r) => {
                this.props.notify('Возврат', 'Заявка на возврат принята');
                this.getOperation();
                this.setState({ isAlreadyRevert: true });
            })
            .catch((r) => log(r));
        this.setState({ modal: false });
    }

    getOperation() {
        get(URL.operationSBPDetailUrl + this.props.match.params.id)
            .then((r) => {
                this.setState({
                    details: r.data.payload,
                    operationAmount: r.data.payload.amount,
                });
            })
            .catch((r) => log(r));
    }

    handleAmountChange = ({ target }) => {
        this.setState({
            details: {
                ...this.state.details,
                [target.name]: target.value,
            },
        });

        if (parseFloat(target.value) > this.state.operationAmount) {
            this.setState({
                errors: {
                    amount: 'Значение должно быть меньше всей суммы операции',
                },
            });
        } else {
            this.setState({ errors: {} });
        }
    };

    handleDescrChange = ({ target }) => {
        this.setState({
            [target.name]: target.value,
        });
    };

    handleReceiptChange =
        (id) =>
        ({ target }) => {
            let items = this.state.details.receipt.items;
            let price = this.state.details.receipt.items.find(
                (item) => item.id === id
            ).price;
            items[items.findIndex((item) => item.id === id)][target.name] =
                Number(target.value);

            if (target.name === 'amount') {
                items[items.findIndex((item) => item.id === id)]['quantity'] =
                    Number(target.value) / price;
            } else {
                items[items.findIndex((item) => item.id === id)]['amount'] =
                    Number(target.value) * price;
            }

            this.setState({
                details: {
                    ...this.state.details,
                    amount: this.state.details.receipt.items
                        .map(({ amount }) => amount)
                        .reduce((a, b) => a + b, 0),
                    receipt: {
                        ...this.state.details.receipt,
                        items: [...items],
                    },
                },
            });
        };

    render() {
        const {
            amount,
            amountNumerically,
            reversalDateTime,
            reversalAmount,
            hasRefundOption,
            receipt,
        } = this.state.details;

        return amountNumerically ? (
            <div className="operation-sbp-details">
                <ArticleLayout
                    className="operation-sbp-details__body"
                    bigColumn={
                        <OperationDescriptionSbp data={this.state.details} />
                    }
                    smallColumn={
                        <OperationDetailsSbp data={this.state.details}>
                            {this.permission(Roles.REFUND) &&
                                hasRefundOption && (
                                    <Button onClick={this.toggle}>
                                        Заявка на возврат
                                    </Button>
                                )}

                            {reversalDateTime && (
                                <>
                                    <div>Заявка на возврат</div>
                                    <div>
                                        {this.formatDate(
                                            reversalDateTime,
                                            'long'
                                        )}
                                    </div>
                                    {reversalAmount && (
                                        <div>
                                            {formatCurrency(reversalAmount)}
                                        </div>
                                    )}
                                </>
                            )}
                        </OperationDetailsSbp>
                    }
                />

                <Modal
                    isOpen={this.state.modal}
                    header="Заявка на возврат"
                    closeButton="Отмена"
                    actionButton="Подтвердить"
                    onClose={this.toggle}
                    onAction={this.revertOperation}
                    disabledActionButton={
                        !!Object.entries(this.state.errors).length
                    }
                >
                    <ModalField label="Тип возврата">
                        <NewSelect
                            idScrollElement="modal-element-scroll"
                            options={revertOptions}
                            value={this.state.revert}
                            onChange={(v) => this.setState({ revert: v })}
                        />
                    </ModalField>
                    {this.state.revert === 'partial' && (
                        <>
                            {!isEmpty(receipt?.items) && (
                                <>
                                    <ModalField
                                        boldLabel
                                        label="Товары"
                                        className="modal-field-title"
                                    />
                                    {receipt?.items?.map(
                                        (
                                            { id, name, quantity, amount },
                                            index
                                        ) => (
                                            <Fragment key={id}>
                                                <ModalField
                                                    label="Наименование товара"
                                                    className={
                                                        index !== 0
                                                            ? 'modal-field-title'
                                                            : ''
                                                    }
                                                >
                                                    {name}
                                                </ModalField>
                                                <ModalField label="Количество">
                                                    <Input
                                                        background
                                                        name="quantity"
                                                        value={quantity}
                                                        onChange={this.handleReceiptChange(
                                                            id
                                                        )}
                                                    />
                                                </ModalField>
                                                <ModalField label="Сумма">
                                                    <Input
                                                        background
                                                        name="amount"
                                                        value={amount}
                                                        onChange={this.handleReceiptChange(
                                                            id
                                                        )}
                                                    />
                                                </ModalField>
                                            </Fragment>
                                        )
                                    )}
                                </>
                            )}
                            {isEmpty(receipt?.items) ? (
                                <ModalField
                                    boldLabel
                                    label="Общая сумма возврата"
                                    className="modal-field-title"
                                    errorMessage={this.state.errors.amount}
                                >
                                    <Input
                                        background
                                        name="amount"
                                        value={amount}
                                        onChange={this.handleAmountChange}
                                    />
                                </ModalField>
                            ) : (
                                <ModalField
                                    boldLabel
                                    label="Общая сумма возврата"
                                    className="modal-field-title"
                                >
                                    {amount} ₽
                                </ModalField>
                            )}
                        </>
                    )}

                    <ModalField
                        label="Причина возврата"
                        alignField="align-start"
                    >
                        <Textarea
                            rows={2}
                            name="description"
                            placeholder="Введите причину"
                            value={this.state.description}
                            onChange={this.handleDescrChange}
                        />
                    </ModalField>
                </Modal>
            </div>
        ) : (
            <Spin fetch={this.props.fetch} />
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        fetch: state.fetch.fetch,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        notify: (title, message) =>
            dispatch(
                createNotification(
                    {
                        title: title,
                        message: message,
                    },
                    'success'
                )
            ),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, {
        forwardRef: true,
    })(OperationSBPDetailPage)
);
