import { ApplicationType } from '../model/types/types';

interface Message {
    title: string;
    text: string;
}

const rules = {
    faultyAndLost: {
        title: 'Заявка отправлена',
        text: 'В ближайшее время с вами свяжется сотрудник технической поддержки. Внимание: время работы технической поддержки банка с 9 до 21 без выходных.',
    },
    noOperation: {
        title: 'Информация принята',
        text: 'Спасибо. Рекомендуем провести тестовую операцию по терминалу.',
    },
};

export function requestSuccessTextMapper(
    applicationType: ApplicationType
): Message {
    return {
        FAULTY_TERMINAL: rules['faultyAndLost'],
        LOST_TERMINAL: rules['faultyAndLost'],
        NO_OPERATIONS_ON_TERMINAL: rules['noOperation'],
    }[applicationType];
}
