import * as type from '../constants';
import axios from 'axios';

const initState = {
    token: axios.CancelToken.source(),
};

export default function requestCancelation(state = initState, action) {
    switch (action.type) {
        case type.RESET_TOKEN:
            return {
                ...state,
                token: axios.CancelToken.source(),
            };
        default:
            return state;
    }
}
