import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import './commission-info.scss';

type Props = {
    id: string;
};

const CommissionInfo: FC<Props> = ({ id }) => {
    return (
        <ReactTooltip
            id={id}
            className="tool-tip custom"
            place="right"
            type="light"
            effect="float"
        >
            <div className="tool-tip__container">
                <div className="header">
                    <h5>Дополнительная комиссия</h5>
                    <button type="button" className="close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <p>
                    <b>•</b> для клиентов,обслуживающихся в подразделениях
                    Москвы и Московской области (взимается в последный день
                    месяца, в случае,если объем операций по терминалу за текущий
                    месяц не достиг 300 000 руб.) - 700 рублей.
                </p>
                <br />
                <p>
                    <b>•</b> для клиентов,обслуживающихся в филиалах Банка
                    (взимается в последный день месяца,в случае,если объем
                    операций по терминалу за текущий месяц не достиг 100 000
                    руб.) - 400 рублей.
                </p>
            </div>
        </ReactTooltip>
    );
};

export { CommissionInfo };
