import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReplaySubject } from 'rxjs';
import AwareTable from 'src/components/AwareTable';

import { URL } from 'src/app/config/URL';
import {
    Modal,
    ModalField,
    NewSelect,
    Input,
    InputPassword,
    NewCheckbox,
    OverflowContainer,
    Paginator,
    AddIcon,
} from 'src/shared/ui';
import {
    log,
    sendToSubject,
    statusNameMapper,
    localLoad,
    get,
    post,
    getOptionsForSelect,
} from 'src/shared/lib';
import { setNeedsUpdate } from 'src/redux/actions/table';

/**
 * @type {boolean}
 * TODO: удалить данную переменную после тестирования со стороны заказчика
 */
const IS_PAYMENT_TO_INCLUDE = true;

const cashboxesTableName = 'Ecomm-Cashboxes';
const keysTableName = 'Ecomm-Keys';

class EcommPage extends Component {
    cashboxSubject = new ReplaySubject(1);
    merchkeySubject = new ReplaySubject(1);

    constructor(props) {
        super(props);

        this.clsName = 'ReassignedCompanyFilter';

        this.send = sendToSubject.bind(this);
        this.cashbox = {
            defCashbox: '',
            groupCode: '',
            cashboxId: '',
            companyId: this.props.companyId,
            cashboxLogin: '',
            cashboxPassword: '',
            cashboxStatus: 'Активно',
            address: '',
            ffdVersion: '',
        };
        this.merchkeys = {
            key: '',
            ecommkeyStatus: 'Активно',
            idDefaultETerminal: '',
            passwordDefaultETerminal: '',
            created: '',
            company: '',
            cashboxRequired: false,
            cashbox: undefined,
            password: '',
            merchantId: '',
            merchantName: '',
            merchantUrl: '',
            defaultNotificationUrl: '',
            defaultSuccessUrl: '',
            defaultFailUrl: '',
            timeZone: '-12',
            allowWidgets: false,
            maxAmount: '',
            defaultNotificationEmail: '',
            notifyByEmail: false,
            notifyByUrl: false,
        };
        this.state = {
            ...this.cashbox,
            ...this.merchkeys,
            open_add_cashbox: false,
            confirm_add_cashbox: false,
            open_update_cashbox: false,
            open_add_ecommKey: false,
            open_update_ecommKey: false,
            statuses: ['Активно', 'Выключено'],
            ecomkeyCashboxes: [],
            passwordType: 'password',
            pswdHidden: '',
            reassignIdCompany: undefined,
            cashboxAvailableFfdList: [],
        };
    }

    /*ecommKey */

    toggle_update_ecommKey = () => {
        this.setState({
            open_update_ecommKey: !this.state.open_update_ecommKey,
        });
    };

    updateEcommKey(id) {
        const {
            ecommkeyStatus,
            cashboxRequired,
            password,
            cashbox,
            defaultNotificationUrl,
            defaultSuccessUrl,
            defaultFailUrl,
            defaultNotificationEmail,
            notifyByEmail,
            notifyByUrl,
        } = this.state;
        post(URL.updateEcommKey + id, {
            status: statusNameMapper(ecommkeyStatus, 'en'),
            cashboxRequired,
            password,
            cashbox: Number(cashbox) === 0 ? null : cashbox,
            defaultNotificationUrl,
            defaultSuccessUrl,
            defaultFailUrl,
            defaultNotificationEmail,
            notifyByEmail,
            notifyByUrl,
        })
            .then((r) => {
                this.props.dispatch(setNeedsUpdate(keysTableName, true));
            })
            .catch((r) => {
                log(r);
            });
        this.setState({ open_update_ecommKey: false });
    }

    getEcommKey(id) {
        get(URL.getEcommKey + id)
            .then((r) => {
                const {
                    key,
                    status,
                    cashboxRequired,
                    mcc,
                    password,
                    cashbox,
                    defaultNotificationUrl,
                    defaultSuccessUrl,
                    defaultFailUrl,
                    defaultNotificationEmail,
                    notifyByEmail,
                    notifyByUrl,
                } = r.data.payload;
                this.setState({
                    key,
                    ecommkeyStatus: statusNameMapper(status, 'ru'),
                    cashboxRequired,
                    cashbox,
                    password,
                    defaultNotificationUrl,
                    defaultSuccessUrl,
                    defaultFailUrl,
                    defaultNotificationEmail,
                    notifyByEmail,
                    notifyByUrl,
                    mcc,
                });
            })
            .catch((r) => log(r));
    }

    getEcommKeyCashbox = () => {
        get(URL.getEcommKeyCashboxes)
            .then((r) => {
                this.setState({
                    ecomkeyCashboxes: [
                        ...(!this.state.cashboxRequired
                            ? [
                                  ...r.data.payload,
                                  {
                                      blocked: false,
                                      groupCode: 'без кассы',
                                      id: 0,
                                  },
                              ]
                            : r.data.payload),
                    ],
                    // cashbox: r.data.payload[0].id
                });
            })
            .catch((r) => log(r));
    };

    /*cashbox */

    toggle_add_cashbox = () => {
        this.setState({
            ...this.cashbox,
            open_add_cashbox: !this.state.open_add_cashbox,
            confirm_add_cashbox: false,
        });
    };

    toggle_update_cashbox = () => {
        this.setState({
            open_update_cashbox: !this.state.open_update_cashbox,
            reassignIdCompany: undefined,
        });
    };

    createCashbox = () => {
        const {
            cashboxLogin,
            cashboxPassword,
            cashboxStatus,
            address,
            groupCode,
            ffdVersion,
        } = this.state;
        post(URL.addEcommCashbox, {
            login: cashboxLogin,
            password: cashboxPassword,
            status: statusNameMapper(cashboxStatus, 'en'),
            groupCode,
            address,
            ffdVersion,
        })
            .then((r) => {
                // TODO 637: update new table
                this.props.dispatch(setNeedsUpdate(cashboxesTableName, true));
            })
            .catch((r) => {
                log(r);
            });
        this.setState({ open_add_cashbox: false });
    };

    updateCashbox = (id) => {
        const {
            cashboxLogin,
            cashboxPassword,
            cashboxStatus,
            groupCode,
            address,
            reassignIdCompany,
            ffdVersion,
        } = this.state;
        post(URL.updateEcommCashbox + id, {
            login: cashboxLogin,
            password: cashboxPassword,
            status: statusNameMapper(cashboxStatus, 'en'),
            groupCode,
            address,
            reassignIdCompany,
            ffdVersion,
        })
            .then((r) => {
                this.props.dispatch(setNeedsUpdate(cashboxesTableName, true));
            })
            .catch((r) => {
                log(r);
            });
        this.setState({
            open_update_cashbox: false,
            reassignIdCompany: undefined,
        });
    };

    getCashbox(id) {
        get(URL.getEcommCashbox + id)
            .then((r) => {
                const {
                    groupCode,
                    id,
                    address,
                    login,
                    password,
                    status,
                    ffdVersion,
                } = r.data.payload;
                this.setState({
                    groupCode,
                    cashboxId: id,
                    cashboxLogin: login,
                    cashboxPassword: password,
                    address,
                    cashboxStatus: statusNameMapper(status, 'ru'),
                    ffdVersion,
                });
            })
            .catch((r) => log(r));
    }

    getCashboxAvailableFfd() {
        get(URL.getCashboxAvailableFfd)
            .then((r) => {
                this.setState({
                    ffdVersion: r.data.payload[0].version,
                    cashboxAvailableFfdList: r.data.payload,
                });
            })
            .catch((r) => log(r));
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.send({
            offset: localLoad('cashboxes-offset', 0),
            size: localLoad('cashboxes-range', 10),
        });
    };

    toggle_confirm_add_cashbox = () => {
        this.setState({
            confirm_add_cashbox: !this.state.confirm_add_cashbox,
        });
    };

    render() {
        return (
            <div>
                <Paginator name={cashboxesTableName}>
                    <OverflowContainer
                        title="Кассы"
                        icon={
                            <AddIcon
                                title="Добавить кассу"
                                onClick={() => {
                                    this.toggle_add_cashbox();
                                    this.getCashboxAvailableFfd();
                                }}
                            />
                        }
                    >
                        <AwareTable
                            tableName={cashboxesTableName}
                            url={URL.ecommCashboxes}
                            onItemClick={(e) => {
                                this.toggle_update_cashbox();
                                this.getCashbox(e.ID);
                            }}
                            isStoreTable
                        />
                    </OverflowContainer>
                </Paginator>
                <Paginator name={keysTableName}>
                    <OverflowContainer title="Ключи мерчанта">
                        <AwareTable
                            tableName={keysTableName}
                            url={URL.ecommKeys}
                            onItemClick={(e) => {
                                this.toggle_update_ecommKey();
                                this.getEcommKey(e.ID);
                                this.getEcommKeyCashbox();
                            }}
                            isStoreTable
                        />
                    </OverflowContainer>
                </Paginator>

                {/*cashbox modals*/}

                <Modal
                    isConfirm
                    header="Выйти из редактирования"
                    closeButton="Отмена"
                    actionButton="Выйти"
                    isOpen={this.state.confirm_add_cashbox}
                    onClose={this.toggle_confirm_add_cashbox}
                    onAction={this.toggle_add_cashbox}
                >
                    Все введенные данные будут потеряны.
                </Modal>
                <Modal
                    isConfirmToClose
                    header="Добавить кассу"
                    closeButton="Отмена"
                    actionButton="Сохранить"
                    isOpen={this.state.open_add_cashbox}
                    onClose={this.toggle_confirm_add_cashbox}
                    onAction={this.createCashbox}
                >
                    <ModalField label="Статус">
                        <NewSelect
                            idScrollElement="modal-element-scroll"
                            value={this.state.cashboxStatus}
                            options={getOptionsForSelect(this.state.statuses)}
                            onChange={(value) => {
                                this.setState({
                                    cashboxStatus: value,
                                });
                            }}
                        />
                    </ModalField>
                    <ModalField label="ФФД">
                        <NewSelect
                            idScrollElement="modal-element-scroll"
                            value={this.state.ffdVersion}
                            options={getOptionsForSelect(
                                this.state.cashboxAvailableFfdList,
                                'version',
                                'nameFfd'
                            )}
                            onChange={(value) => {
                                this.setState({
                                    ffdVersion: value,
                                });
                            }}
                        />
                    </ModalField>
                    <ModalField label="Код группы">
                        <Input
                            background
                            placeholder={'Введите код группы'}
                            value={this.state.groupCode}
                            onChange={(e) =>
                                this.setState({
                                    groupCode: e.target.value,
                                })
                            }
                        />
                    </ModalField>
                    <ModalField label="Адрес">
                        <Input
                            background
                            placeholder={'Введите адрес'}
                            value={this.state.address}
                            onChange={(e) =>
                                this.setState({
                                    address: e.target.value,
                                })
                            }
                        />
                    </ModalField>
                    <ModalField label="Логин">
                        <Input
                            background
                            placeholder={'Введите логин кассы'}
                            value={this.state.cashboxLogin}
                            onChange={(e) =>
                                this.setState({
                                    cashboxLogin: e.target.value,
                                })
                            }
                        />
                    </ModalField>
                    <ModalField label="Пароль">
                        <InputPassword
                            background
                            autoComplete="new-password"
                            placeholder={'Введите пароль кассы'}
                            value={this.state.cashboxPassword}
                            onChange={(e) =>
                                this.setState({
                                    cashboxPassword: e.target.value,
                                })
                            }
                        />
                    </ModalField>
                </Modal>

                <Modal
                    header="Редактировать кассу"
                    closeButton="Отмена"
                    actionButton="Сохранить"
                    isOpen={this.state.open_update_cashbox}
                    onClose={this.toggle_update_cashbox}
                    onAction={() => this.updateCashbox(this.state.cashboxId)}
                >
                    <ModalField label="Статус">
                        <NewSelect
                            idScrollElement="modal-element-scroll"
                            value={this.state.cashboxStatus}
                            options={getOptionsForSelect(this.state.statuses)}
                            onChange={(value) => {
                                this.setState({
                                    cashboxStatus: value,
                                });
                            }}
                        />
                    </ModalField>
                    <ModalField label="Адрес">
                        <Input
                            background
                            placeholder="Введите адрес"
                            value={this.state.address}
                            onChange={(e) =>
                                this.setState({
                                    address: e.target.value,
                                })
                            }
                        />
                    </ModalField>
                    <ModalField label="Логин">
                        <Input
                            background
                            placeholder="Введите логин кассы"
                            value={this.state.cashboxLogin}
                            onChange={(e) =>
                                this.setState({
                                    cashboxLogin: e.target.value,
                                })
                            }
                        />
                    </ModalField>
                    <ModalField label="Пароль">
                        <InputPassword
                            background
                            placeholder="Введите пароль кассы"
                            value={this.state.cashboxPassword}
                            onChange={(e) =>
                                this.setState({
                                    cashboxPassword: e.target.value,
                                })
                            }
                        />
                    </ModalField>
                    <ModalField label="Переназначить">
                        <NewSelect
                            idScrollElement="modal-element-scroll"
                            label="Выбрать новую компанию"
                            value={this.state.reassignIdCompany}
                            options={getOptionsForSelect(
                                this.props.assignedCompanies,
                                'id',
                                'name'
                            )}
                            onChange={(value) => {
                                this.setState({
                                    reassignIdCompany: Number(value),
                                });
                            }}
                        />
                    </ModalField>
                </Modal>

                {/*ecommkey modals*/}

                <Modal
                    header="Редактирование ключа мерчанта"
                    closeButton="Отмена"
                    actionButton="Сохранить"
                    isOpen={this.state.open_update_ecommKey}
                    onClose={this.toggle_update_ecommKey}
                    onAction={() => this.updateEcommKey(this.state.key)}
                >
                    <ModalField label="Ключ мерчанта">
                        <b>{this.state.key}</b>
                    </ModalField>
                    {this.state.mcc && IS_PAYMENT_TO_INCLUDE && (
                        <ModalField label="mcc">
                            <b>{this.state.mcc}</b>
                        </ModalField>
                    )}
                    <ModalField label="Пароль мерчанта">
                        <InputPassword
                            background
                            placeholder={'Введите пароль'}
                            value={this.state.password}
                            onChange={(e) =>
                                this.setState({
                                    password: e.target.value,
                                })
                            }
                        />
                    </ModalField>
                    <ModalField label="Статус">
                        <NewSelect
                            idScrollElement="modal-element-scroll"
                            value={this.state.ecommkeyStatus}
                            options={getOptionsForSelect(this.state.statuses)}
                            onChange={(value) => {
                                this.setState({
                                    ecommkeyStatus: value,
                                });
                            }}
                        />
                    </ModalField>
                    <ModalField numberOfColumns={1}>
                        <NewCheckbox
                            label="Касса обязательна"
                            onChange={() =>
                                this.setState(
                                    {
                                        cashboxRequired:
                                            !this.state.cashboxRequired,
                                    },
                                    () => {
                                        if (
                                            this.state.cashboxRequired &&
                                            Boolean(
                                                this.state.ecomkeyCashboxes.find(
                                                    ({ id }) => id === 0
                                                )
                                            )
                                        ) {
                                            this.setState({
                                                ecomkeyCashboxes:
                                                    this.state.ecomkeyCashboxes.filter(
                                                        ({ id }) => id !== 0
                                                    ),
                                                cashbox:
                                                    Number(
                                                        this.state.cashbox
                                                    ) === 0
                                                        ? this.state
                                                              .ecomkeyCashboxes[0]
                                                              .id
                                                        : this.state.cashbox,
                                            });
                                        } else {
                                            !this.state.ecomkeyCashboxes.find(
                                                ({ id }) => id === 0
                                            ) &&
                                                this.setState((prev) => ({
                                                    ecomkeyCashboxes: [
                                                        ...this.state
                                                            .ecomkeyCashboxes,
                                                        {
                                                            blocked: false,
                                                            groupCode:
                                                                'без кассы',
                                                            id: 0,
                                                        },
                                                    ],
                                                    cashbox: this.state.cashbox,
                                                }));
                                        }
                                    }
                                )
                            }
                            checked={this.state.cashboxRequired}
                        />
                    </ModalField>
                    <ModalField label="Касса">
                        <NewSelect
                            idScrollElement="modal-element-scroll"
                            value={String(this.state.cashbox)}
                            options={getOptionsForSelect(
                                this.state.ecomkeyCashboxes,
                                'id',
                                'groupCode'
                            )}
                            onChange={(value) => {
                                this.setState({
                                    cashbox: Number(value),
                                });
                            }}
                        />
                    </ModalField>
                    <ModalField label="URL при успехе">
                        <Input
                            background
                            placeholder="https://"
                            value={this.state.defaultSuccessUrl}
                            onChange={(e) =>
                                this.setState({
                                    defaultSuccessUrl: e.target.value,
                                })
                            }
                        />
                    </ModalField>
                    <ModalField label="URL при ошибке">
                        <Input
                            background
                            placeholder="https://"
                            value={this.state.defaultFailUrl}
                            onChange={(e) =>
                                this.setState({
                                    defaultFailUrl: e.target.value,
                                })
                            }
                        />
                    </ModalField>
                    <ModalField numberOfColumns={1}>
                        <NewCheckbox
                            label="Уведомлять по почте"
                            onChange={() =>
                                this.setState({
                                    notifyByEmail: !this.state.notifyByEmail,
                                })
                            }
                            checked={this.state.notifyByEmail}
                        />
                    </ModalField>
                    {this.state.notifyByEmail && (
                        <ModalField label="Почта для уведомлений">
                            <Input
                                background
                                placeholder="Введите email"
                                value={this.state.defaultNotificationEmail}
                                onChange={(e) =>
                                    this.setState({
                                        defaultNotificationEmail:
                                            e.target.value,
                                    })
                                }
                            />
                        </ModalField>
                    )}
                    <ModalField numberOfColumns={1}>
                        <NewCheckbox
                            label="Уведомлять по URL"
                            checked={this.state.notifyByUrl}
                            onChange={() =>
                                this.setState({
                                    notifyByUrl: !this.state.notifyByUrl,
                                })
                            }
                        />
                    </ModalField>
                    {this.state.notifyByUrl && (
                        <ModalField label="URL для уведомлений">
                            <Input
                                background
                                placeholder="Введите Url"
                                value={this.state.defaultNotificationUrl}
                                onChange={(e) =>
                                    this.setState({
                                        defaultNotificationUrl: e.target.value,
                                    })
                                }
                            />
                        </ModalField>
                    )}
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        companyId: state.auth.companyId,
        roles: state.auth.roles,
        assignedCompanies: state.auth.assignedCompanies,
    };
}

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(EcommPage);
