import React, { FC, useEffect, useState } from 'react';

import { MainTerminal, inventoryFilteringOptions } from '../../config';
import { DropdownMultiselect, SearchField } from 'src/shared/ui';
import { ReworkedTerminalsList } from '../reworked-terminals-list';
import { resetCancelationToken } from 'src/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestCancelationToken } from 'src/redux/selectors';
import { FilterDropdown } from 'src/shared/ui/filter-dropdown';
import { ShowGroupMode, Switcher } from 'src/entities/selector-with-groups';

interface Props {
    selected: number[];
    terminalsList: MainTerminal[];
    onChange: (selected: number[]) => void;
    tableName: string;
}

export const MerchantTerminalsSelector: FC<Props> = ({
    onChange,
    selected,
    terminalsList,
    tableName,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState('');
    const [showGroup, setShowGroup] = useState<ShowGroupMode>('all');
    const [inventoryDisplayFilters, setInventoryDisplayFilters] = useState<
        string[]
    >([]);
    const [pickedTerminals, setPickedTerminals] = useState<number[]>(selected);

    const dispatch = useDispatch();

    const { token } = useSelector(getRequestCancelationToken);
    const terminals = useSelector(
        (state) => state.table?.[tableName]?.filters?.terminalIds
    );

    const handleApplyClick = () => {
        token.cancel();
        dispatch(resetCancelationToken());

        onChange(pickedTerminals);
        setIsOpen(false);
    };

    // Resets terminals if changes aren't applied
    useEffect(() => {
        if (!isOpen) {
            setPickedTerminals(terminals || selected);
        }
    }, [isOpen, selected, terminals]);

    const allTerminals = terminalsList.flatMap((mainTerminal) =>
        mainTerminal.terminalGroups.flatMap((group) =>
            group.terminals.flatMap((terminal) => terminal.id)
        )
    );

    const selectAllTerminals = () => {
        setPickedTerminals(allTerminals);
    };

    const resetTerminals = () => {
        setPickedTerminals([]);
    };

    const hardResetTerminals = () => {
        setPickedTerminals([]);
        onChange([]);
    };

    const handleTerminalClick = (terminalId: number) => {
        const currentlySelected = pickedTerminals.includes(terminalId);
        if (currentlySelected) {
            const newSelected = pickedTerminals.filter(
                (id) => terminalId !== id
            );
            setPickedTerminals(newSelected);
        } else {
            const pickedTerminalsCopy = [...pickedTerminals];
            pickedTerminalsCopy.push(terminalId);
            setPickedTerminals(pickedTerminalsCopy);
        }
    };

    const getTerminalsLength = () => {
        return terminalsList.reduce((total, currentValue) => {
            let groupTerminalsLength = currentValue.terminalGroups.reduce(
                (total, group) => (total += group.terminals.length),
                0
            );
            return (total += groupTerminalsLength);
        }, 0);
    };

    if (!terminalsList.length) {
        return null;
    }

    return (
        <FilterDropdown
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            filterName="Терминалы"
            selectedCount={pickedTerminals.length}
            onReset={hardResetTerminals}
            actions={[
                <button
                    onClick={selectAllTerminals}
                    disabled={pickedTerminals.length === getTerminalsLength()}
                >
                    Выбрать все
                </button>,
                <button onClick={resetTerminals}>Сбросить значения</button>,
            ]}
            firstRowControls={[
                <DropdownMultiselect
                    selected={inventoryDisplayFilters}
                    onChange={setInventoryDisplayFilters}
                    options={inventoryFilteringOptions}
                />,
                <SearchField
                    value={query}
                    onChange={setQuery}
                    placeholder="Уточните адрес по номеру терминала или улице"
                />,
            ]}
            secondRowControls={[
                <Switcher
                    mode={showGroup}
                    count={{
                        all: allTerminals.length,
                        selected: pickedTerminals.length,
                    }}
                    handleChange={setShowGroup}
                />,
            ]}
            onApplyClick={handleApplyClick}
        >
            <ReworkedTerminalsList
                isOpen={isOpen}
                selected={pickedTerminals}
                terminalsList={terminalsList}
                onChange={setPickedTerminals}
                handleTerminalClick={handleTerminalClick}
                query={query}
                inventoryDisplayFilters={inventoryDisplayFilters}
                selectedOnly={showGroup === 'selected'}
            />
        </FilterDropdown>
    );
};
