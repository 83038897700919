import React, {
    FC,
    ReactNode,
    useRef,
    useState,
    useEffect,
    useCallback,
    MutableRefObject,
} from 'react';
import { classNames } from 'src/shared/lib';
import { Portal, Button } from 'src/shared/ui';
import styles from './modal.module.scss';

type TimerId = MutableRefObject<ReturnType<typeof setTimeout>>;

interface ModalProps {
    className?: string;
    header?: string;
    children?: ReactNode;
    isConfirm?: boolean;
    isForm?: boolean;
    isConfirmToClose?: boolean;
    isOpen?: boolean;
    isClosed?: boolean;
    disabledActionButton?: boolean;
    closeButton?: string;
    actionButton?: string;
    onClose?: () => void;
    onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
    onAction?: () => void;
    autoComplete?: string;
}

/*
 * В функцию onAction необходимо передавать setOpenModal(false).
 * Управление окнами осуществляется на уровне выше
 */

const ANIMATION_DELAY = 300;

export const Modal: FC<ModalProps> = (props) => {
    const {
        className = '',
        header,
        children,
        closeButton,
        actionButton,
        isConfirm,
        isForm = false,
        isConfirmToClose = false,
        disabledActionButton = false,
        isOpen,
        isClosed,
        onScroll,
        onClose,
        onAction,
    } = props;
    const [isClosing, setIsClosing] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const TagContent = isForm ? 'form' : 'div';

    const openAnimationTimerRef = useRef() as TimerId;
    const closeAnimationTimerRef = useRef() as TimerId;

    const onContentClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const closeModal = useCallback((func?: () => void) => {
        setIsClosing(true);
        openAnimationTimerRef.current = setTimeout(() => {
            func?.();
            setIsClosing(false);
        }, ANIMATION_DELAY);
    }, []);

    const onCloseHandler = useCallback(() => {
        if (!isConfirmToClose) {
            closeModal(onClose);
        } else {
            onClose?.();
        }
    }, [onClose, isConfirmToClose, closeModal]);

    const onActionHandler = useCallback(
        (e) => {
            if (isForm) {
                e.preventDefault();
                onAction?.();
            } else {
                closeModal(onAction);
            }
        },
        [isForm, onAction, closeModal]
    );

    const onKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                onCloseHandler();
            }
        },
        [onCloseHandler]
    );

    useEffect(() => {
        if (isClosed) onCloseHandler();
    }, [isClosed, onCloseHandler]);

    useEffect(() => {
        if (isOpen && !isMounted) {
            setIsMounted(true);
        }
        if (!isOpen && isMounted) {
            closeAnimationTimerRef.current = setTimeout(() => {
                setIsMounted(false);
            }, ANIMATION_DELAY);
        }
    }, [isOpen, isMounted]);

    useEffect(() => {
        if (isMounted) {
            window.addEventListener('keydown', onKeyDown);
        }

        return () => {
            clearTimeout(openAnimationTimerRef.current);
            clearTimeout(closeAnimationTimerRef.current);
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [isMounted, onKeyDown]);

    const stylesModal = classNames(
        styles.modal,
        {
            [styles.opened]: isMounted,
            [styles.closing]: isClosing,
        },
        []
    );

    const stylesContent = classNames(
        styles.content,
        { [styles.confirm]: isConfirm },
        [className]
    );

    return isOpen ? (
        <Portal>
            <div className={stylesModal}>
                <div className={styles.overlay} onClick={onCloseHandler}>
                    <TagContent
                        className={stylesContent}
                        onClick={onContentClick}
                        onSubmit={isForm ? onActionHandler : undefined}
                    >
                        {header && (
                            <div className={styles.header}>
                                <p className={styles.title}>{header}</p>
                                <span
                                    className={styles.close}
                                    onClick={onCloseHandler}
                                />
                            </div>
                        )}
                        {children && (
                            <div
                                onScroll={onScroll}
                                id="modal-element-scroll"
                                className={styles.body}
                            >
                                {children}
                            </div>
                        )}
                        <div className={styles.footer}>
                            {closeButton && (
                                <Button
                                    type="button"
                                    outline
                                    onClick={onCloseHandler}
                                >
                                    {closeButton}
                                </Button>
                            )}
                            {actionButton && (
                                <Button
                                    type="submit"
                                    onClick={
                                        isForm ? undefined : onActionHandler
                                    }
                                    disabled={disabledActionButton}
                                >
                                    {actionButton}
                                </Button>
                            )}
                        </div>
                    </TagContent>
                </div>
            </div>
        </Portal>
    ) : null;
};
