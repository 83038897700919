import { number, object, string } from 'yup';
import { validationMessages } from 'src/shared/lib';

export const schema = object({
    defaultNotificationUrl: string().optional(),
    defaultNotificationEmail: string().optional(),
    idDefaultETerminal: string().required(validationMessages.required),
    passwordDefaultETerminal: string().required(validationMessages.required),
    merchantId: string().required(validationMessages.required),
    merchantName: string().required(validationMessages.required),
    merchantUrl: string().required(validationMessages.required),
    defaultSuccessUrl: string().required(validationMessages.required),
    defaultFailUrl: string().required(validationMessages.required),
    maxAmount: number()
        .typeError(validationMessages.shouldBeANumber)
        .required(validationMessages.required),
});
