import React from 'react';

const mir = (
    <svg
        width="49"
        height="14"
        viewBox="0 0 49 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M27.2867 1.50862L24.1676 8.29741H23.8504V0H19.4775V14H23.2084C23.6915 13.9932 24.1623 13.8473 24.5642 13.5796C24.9661 13.312 25.2821 12.9341 25.4741 12.4914L28.5706 5.70259H28.8803V14H33.2758V0H29.5676C29.0819 0.00385025 28.6079 0.148464 28.203 0.416274C27.7981 0.684085 27.4798 1.06357 27.2867 1.50862Z"
            fill="#24A23F"
        />
        <path
            d="M10.7848 1.84052L8.9496 8.29741H8.6324L6.79716 1.84052C6.65012 1.31551 6.33653 0.852309 5.90349 0.520444C5.47044 0.188579 4.9413 0.00595981 4.3955 0L0 0V14H4.3955V5.70259H4.7127L7.22765 14H10.3695L12.8844 5.70259H13.2016V14H17.5971V0H13.1941C12.6466 0.00309949 12.1152 0.184506 11.6804 0.51668C11.2456 0.848854 10.9311 1.31365 10.7848 1.84052Z"
            fill="#24A23F"
        />
        <path
            d="M35.1792 6.35876V13.9999H39.5747V9.54195H44.295C45.2746 9.53819 46.2287 9.22959 47.0246 8.65909C47.8205 8.08859 48.4186 7.28454 48.7358 6.35876H35.1792Z"
            fill="#24A23F"
        />
        <path
            d="M44.2948 0H34.5522C34.8351 1.59801 35.6703 3.04626 36.9124 4.09245C38.1545 5.13863 39.7246 5.71644 41.3494 5.72522H48.9018C48.9633 5.41216 48.9937 5.0938 48.9924 4.77478C49.0025 3.51972 48.5135 2.31197 47.6327 1.41673C46.7519 0.521489 45.5514 0.0119512 44.2948 0V0Z"
            fill="#00A3E1"
        />
    </svg>
);

export { mir };
