import React, { FC } from 'react';
import './table-header.scss';

interface Props {
    offset: string;
    size: string;
    sizeLastPage: number;
    isEmpty: boolean;
    actions: React.ReactNode;
}

const TableHeader: FC<Props> = ({
    isEmpty,
    actions,
}) => {
    return (
        <div className="aware-table-header">
            {!isEmpty && (
                <>
                    <div className="aware-table-header__actions">{actions}</div>
                </>
            )}
        </div>
    );
};

export default TableHeader;
