import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './table.scss';
import AwareTable from './state-table';
import StoreAwareTable from './store-table';

/**
 * @onItemClick - make <Table/> clickable and send table row in callback
 * @url - address ti request for table, make it then request is full (offset + size) and this data new
 * @actions - JSX to display on the right side of table header
 * @transformBody - function to change request body properties before sending request
 * @isStoreTable - boolean responsible for choice between two table variants, store table communicates with filter via redux, state table communicates through rxjs
 */

class AwareTableSelector extends Component {
    render() {
        const {
            isStoreTable,
            totalPaginationCountHidden,
            useCommonTableFilters,
            ...tableProps
        } = this.props;

        return isStoreTable ? (
            <StoreAwareTable
                ref={this.props.forwardedRef}
                useCommonTableFilters={useCommonTableFilters}
                totalPaginationCountHidden={totalPaginationCountHidden}
                {...tableProps}
            />
        ) : (
            <AwareTable ref={this.props.forwardedRef} {...tableProps} />
        );
    }
}

AwareTableSelector.propTypes = {
    onGetTable: PropTypes.func,
    updateTableCallback: PropTypes.func,
    makeScroll: PropTypes.func,
    onItemClick: PropTypes.func,
    lastSelected: PropTypes.string,
    transformBody: PropTypes.func,
    isStoreTable: PropTypes.bool,
};

export default React.forwardRef((props, ref) => (
    <AwareTableSelector {...props} forwardedRef={ref} />
));
