import React, { ChangeEvent, Component } from 'react';
import { ReplaySubject } from 'rxjs';

import { createNotification } from 'src/redux/actions/notification';
import store from 'src/store';
import {
    DisplayTheme,
    SelectingItemsFromList,
    SelectOption,
    TypeOptions,
} from 'src/features/selecting-items-from-list';
import { getAllTerminals, Terminal, MainTerminal } from 'src/entities/terminal';
import { URL, Roles } from 'src/app/config';
import {
    get,
    getOptionsForSelect,
    isAdmin,
    log,
    maskPhone,
    post,
} from 'src/shared/lib';
import { Button, Input, Modal, ModalField, NewCheckbox } from 'src/shared';
import { SelectedIcon, ResetFiltersIcon } from 'src/shared';
import styles from './edit-user.module.scss';

interface State {
    email: string;
    open: boolean;
    fullName: string;
    cellPhone: string;
    roles: string[];
    terminals: number[];
    emailIsValid: boolean;
    adminChecked?: boolean;
    bankBlocked?: boolean;
    companyBlocked?: boolean;
    temporaryBlocked?: boolean;
    allTerminals: Terminal[];
}

interface Props {
    roles: string[];
    companyRoles: { value: string }[];
    terminals: MainTerminal[];
    open: boolean;
    id: string;
    toggleUpdateUser: (open: boolean) => void;
    successCallback?: () => void;
}

export default class AddUserModal extends Component<Props, State> {
    filterSubject = new ReplaySubject(1);

    constructor(props) {
        super(props);

        let allTerminals = getAllTerminals(this.props.terminals);

        this.state = {
            open: false,

            fullName: '',
            cellPhone: '',
            email: '',
            roles: [],
            terminals: [],
            bankBlocked: false,
            temporaryBlocked: false,
            emailIsValid: true,

            adminChecked: false,
            companyBlocked: false,
            allTerminals,
        };
    }

    componentDidMount() {
        this.getUserInfo(this.props.id);
    }

    componentWillReceiveProps = (nextProps: Props) => {
        if (nextProps.id !== this.props.id && nextProps.id) {
            this.getUserInfo(nextProps.id);
        }
    };

    getUserInfo(merchantId: string) {
        get(URL.getMerchantUserInfo + merchantId)
            .then((r) => {
                let {
                    email,
                    fullName,
                    cellPhone,
                    bankBlocked,
                    companyBlocked,
                    temporaryBlocked,
                    terminalIds,
                    roles,
                } = r.data.payload;
                this.setState({
                    bankBlocked,
                    companyBlocked,
                    temporaryBlocked,
                    fullName,
                    cellPhone,
                    email,
                    terminals: terminalIds,
                    roles,
                    adminChecked: isAdmin(roles),
                });
            })
            .catch((r) => {
                this.props.toggleUpdateUser(false);
                log(r);
            });
    }

    notify(title: string, message?: string) {
        store.dispatch(
            createNotification(
                {
                    title: title,
                    message: message,
                },
                'success'
            )
        );
    }

    resetMerchantUserPassword(id) {
        get(URL.resetUserPasswordUrl + id)
            .then((r) => {
                this.notify(
                    'Смена пароля',
                    `Пароль для ${this.state.fullName} сброшен`
                );
                this.props.toggleUpdateUser(false);
                this.props.successCallback?.();
                this.filterSubject.next({ forceRequest: true });
            })
            .catch((r) => {
                log(r);
                this.props.toggleUpdateUser(false);
            });
    }

    updateMerchantUser(id) {
        post(URL.merchantUpdateUsersUrl, {
            id,
            fullName: this.state.fullName,
            email: this.state.email,
            cellPhone: this.state.cellPhone,
            block: this.state.companyBlocked,
            roles: this.state.adminChecked
                ? [Roles.ADMIN]
                : isAdmin(this.state.roles)
                ? this.state.roles.filter((role) => role !== 'ADMIN')
                : this.state.roles,
            terminalIds: this.state.adminChecked ? [] : this.state.terminals,
        })
            .then((r) => {
                this.filterSubject.next({ forceRequest: true });
                this.props.successCallback?.();
                this.notify(
                    'Редактирование пользователя',
                    'Пользователь успешно изменен'
                );
            })
            .catch((r) => {
                log(r);
            });
        this.props.toggleUpdateUser(false);
    }

    getModalHeader() {
        return isAdmin(this.state.roles) && this.state.adminChecked
            ? isAdmin(this.props.roles)
                ? 'Редактирование администратора'
                : 'Информация об администраторе'
            : 'Редактирование пользователя';
    }

    getErrorValidateEmail() {
        if (!this.state.emailIsValid && !this.state.email.length) {
            return 'Некорректный email';
        }
        return undefined;
    }

    filterRoles(roles) {
        return roles.filter((role) => role.value !== 'ADMIN');
    }

    getTerminalsOptions() {
        const options = [] as SelectOption[];
        this.state.allTerminals.forEach((terminal) => {
            const option = {} as SelectOption;
            option.value = String(terminal.id) || '';
            option.content = `${terminal.uid} ${terminal.name}` || '';
            options.push(option);
        });
        return options;
    }

    changeAdminChecked = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            this.setState({
                adminChecked: e.target.checked,
            });
        } else {
            this.setState({
                adminChecked: e.target.checked,
                roles: [],
            });
        }
    };

    getUserStatus = () =>
        this.state.bankBlocked ||
        this.state.companyBlocked ||
        this.state.temporaryBlocked;

    render() {
        let { companyRoles } = this.props;
        let { roles, terminals } = this.state;

        return (
            <Modal
                isOpen={this.props.open}
                header={this.getModalHeader()}
                closeButton="Отмена"
                actionButton="Сохранить"
                onClose={() => this.props.toggleUpdateUser(!this.props.open)}
                onAction={() => this.updateMerchantUser(this.props.id)}
                disabledActionButton={
                    isAdmin(this.state.roles) && !isAdmin(this.props.roles)
                }
            >
                <div className={styles.status}>
                    <span
                        className={
                            this.getUserStatus()
                                ? styles.disabled
                                : styles.active
                        }
                    >
                        {this.getUserStatus() ? ResetFiltersIcon : SelectedIcon}
                    </span>
                    <p>
                        {this.getUserStatus()
                            ? 'Пользователь заблокирован'
                            : 'Пользователь активен'}
                    </p>
                    <p>ID {this.props.id}</p>
                </div>

                <ModalField numberOfColumns={1}>
                    <Input
                        background
                        placeholder="ФИО"
                        readonly={
                            !isAdmin(this.props.roles) &&
                            isAdmin(this.state.roles)
                        }
                        value={this.state.fullName}
                        onChange={(fio) =>
                            this.setState({
                                fullName: fio.target.value,
                            })
                        }
                    />
                </ModalField>
                <ModalField
                    numberOfColumns={1}
                    errorMessage={this.getErrorValidateEmail()}
                >
                    <Input
                        background
                        type="email"
                        readonly={
                            !isAdmin(this.props.roles) &&
                            isAdmin(this.state.roles)
                        }
                        value={this.state.email}
                        placeholder="Email"
                        onChange={(email) =>
                            this.setState({ email: email.target.value })
                        }
                    />
                </ModalField>
                <ModalField numberOfColumns={1}>
                    <Input
                        background
                        type="phone"
                        readonly={
                            !isAdmin(this.props.roles) &&
                            isAdmin(this.state.roles)
                        }
                        value={this.state.cellPhone}
                        placeholder="Введите телефон"
                        onChange={(phone) => {
                            this.setState({
                                cellPhone: maskPhone(phone.target.value),
                            });
                        }}
                    />
                </ModalField>
                {isAdmin(this.props.roles) && (
                    <ModalField numberOfColumns={1}>
                        <NewCheckbox
                            alignCheckbox="right"
                            label="Администратор"
                            checked={this.state.adminChecked}
                            onChange={this.changeAdminChecked}
                        />
                    </ModalField>
                )}
                {!this.state.adminChecked && (
                    <ModalField numberOfColumns={1}>
                        <SelectingItemsFromList
                            name="Роли"
                            theme={DisplayTheme.MODAL}
                            typeOptions={TypeOptions.ROLES}
                            initOptions={roles}
                            options={getOptionsForSelect(
                                this.filterRoles(companyRoles),
                                'value',
                                'name'
                            )}
                            onChange={(selectedRoles) =>
                                this.setState({
                                    roles: [...selectedRoles],
                                })
                            }
                        />
                    </ModalField>
                )}
                {!this.state.adminChecked && (
                    <ModalField numberOfColumns={1}>
                        <SelectingItemsFromList
                            name="Терминалы"
                            theme={DisplayTheme.MODAL}
                            typeOptions={TypeOptions.TERMINALS}
                            initOptions={terminals}
                            options={this.getTerminalsOptions()}
                            onChange={(terminals) => {
                                this.setState({
                                    terminals: terminals.map((terminal) =>
                                        Number(terminal)
                                    ),
                                });
                            }}
                        />
                    </ModalField>
                )}
                {isAdmin(this.props.roles) && (
                    <ModalField numberOfColumns={1}>
                        <NewCheckbox
                            alignCheckbox="right"
                            label="Заблокировать"
                            checked={this.state.companyBlocked}
                            onChange={(e) =>
                                this.setState({
                                    companyBlocked: e.target.checked,
                                })
                            }
                        />
                    </ModalField>
                )}
                <ModalField justifyInput="right" label="Смена пароля">
                    <Button
                        size="xs"
                        onClick={() =>
                            this.resetMerchantUserPassword(this.props.id)
                        }
                        disabled={
                            isAdmin(this.state.roles) &&
                            !isAdmin(this.props.roles)
                        }
                    >
                        Сбросить пароль
                    </Button>
                </ModalField>
            </Modal>
        );
    }
}
