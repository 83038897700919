import 'url-search-params-polyfill';
import React from 'react';
import { render } from 'react-dom';

import App from './App';
import moment from 'moment';
import 'moment/locale/ru';

import 'react-datepicker/dist/react-datepicker.css';
import './app/index.scss';
import 'inter-ui/inter.css';

// Форматирует дату перед отправкой запроса на сервер
moment.prototype.toJSON = function () {
    return this.isValid() ? this.format('YYYY-MM-DD') : null;
};

render(<App />, document.getElementById('root'));
