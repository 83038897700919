import React, { FC, useState } from 'react';
import { SdmLogo, CallIcon, vk, ok, telegram } from 'src/shared/ui/media';

import styles from './footer.module.scss';
import { classNames, getAppTitle, useCloseCustomModal } from 'src/shared/lib';
import { ChangelogModal } from 'src/shared/ui/changelog-modal';

interface Props {
    theme?: 'dark';
}

export const Footer: FC<Props> = ({ children, theme }) => {
    const [changelogModalOpen, setChangelogModalOpen] = useState(false);
    const [changelogModalShown, setChangelogModalShown] = useState(false);
    const computedClassName = [
        styles['footer'],
        theme === 'dark' ? styles['footer--dark-theme'] : '',
    ].join(' ');

    const onClose = useCloseCustomModal(setChangelogModalOpen, () =>
        setChangelogModalShown(false)
    );

    const currentYear = new Date().getFullYear();

    const isBank = getAppTitle() === 'bank';

    return (
        <>
            <ChangelogModal
                isOpen={changelogModalOpen}
                isClosed={changelogModalShown}
                onClose={onClose}
            />
            <footer className={computedClassName}>
                <div className={styles['bank-info']}>
                    <div className={styles['logo-wrapper']}>
                        {SdmLogo}
                        <span className={styles['license']}>
                            © «СДМ-Банк» (ПАО), 2013-{currentYear}
                            <br /> Генеральная лицензия Банка России № 1637
                        </span>
                    </div>
                    <a
                        className={styles['link']}
                        href="https://www.sdm.ru/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Перейти на сайт СДМ-Банка
                    </a>
                </div>
                <div className={styles['contacts']}>
                    <h4>Контакты:</h4>
                    <div className={styles['phone-numbers']}>
                        <div className={styles['call-icon']}>{CallIcon}</div>
                        <a href="tel:+74957059090">+7 495 705 90 90</a>
                        <a href="tel:+78002000223">+7 800 200 02 23</a>
                    </div>
                </div>
                <div className={styles['help']}>{children}</div>
                <div className={styles['media-and-version-wrapper']}>
                    <div className={styles['social-media']}>
                        <a
                            href="https://t.me/sdm_official"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {telegram}
                        </a>
                        <a
                            href="https://vk.com/sdmbank"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {vk}
                        </a>
                        <a
                            href="https://ok.ru/group/57040447733936"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {ok}
                        </a>
                    </div>
                    <button
                        onClick={() => {
                            if (isBank) {
                                setChangelogModalOpen(true);
                            }
                        }}
                        className={classNames(styles['version-button'], {
                            [styles['version-button--bank']]: isBank,
                        })}
                    >
                        {`версия ${process.env.REACT_APP_VERSION}`}
                    </button>
                </div>
            </footer>
        </>
    );
};
