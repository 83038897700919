import React from 'react';

export const Logo = (
    <svg
        width="207"
        height="28"
        viewBox="0 0 207 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M43.5548 24.2743C42.4604 24.294 41.374 24.0853 40.3647 23.6617C39.4615 23.2742 38.6525 22.6966 37.9929 21.9681C37.3345 21.2301 36.8289 20.3689 36.505 19.4344C36.1539 18.426 35.9793 17.3646 35.9886 16.2968C35.9798 15.2119 36.1544 14.1333 36.505 13.1066C36.8267 12.1554 37.3322 11.2767 37.9929 10.5204C38.6493 9.78066 39.4585 9.19237 40.3647 8.79623C42.1393 8.06329 44.1139 7.97199 45.9485 8.53804C46.6864 8.76987 47.3769 9.13145 47.9878 9.6058C48.5914 10.0848 49.0929 10.68 49.4625 11.3562C49.865 12.0892 50.115 12.896 50.1977 13.728H46.92C46.8656 13.349 46.733 12.9853 46.5305 12.6603C46.3292 12.3357 46.0748 12.0472 45.7779 11.807C45.4721 11.5483 45.1243 11.3441 44.7495 11.2031C44.3661 11.066 43.9619 10.9964 43.5548 10.9974C42.8804 10.9744 42.2113 11.1251 41.6119 11.435C41.084 11.7172 40.6333 12.1244 40.299 12.6209C39.9597 13.138 39.7152 13.7115 39.577 14.3144C39.4349 14.9653 39.3703 15.6307 39.3845 16.2968C39.3779 16.9391 39.4499 17.5799 39.5989 18.2047C39.7398 18.7932 39.9843 19.3518 40.3209 19.8545C40.6572 20.347 41.1076 20.7509 41.6337 21.0317C42.2332 21.3416 42.9023 21.4923 43.5767 21.4693C44.0253 21.494 44.4739 21.4221 44.8922 21.2582C45.3105 21.0944 45.6886 20.8425 46.001 20.5197C46.6139 19.82 46.988 18.9432 47.0688 18.0166H50.3246C50.2571 18.9243 50.0305 19.813 49.655 20.6422C49.3105 21.3741 48.8275 22.0324 48.2328 22.5808C47.6341 23.133 46.9301 23.5587 46.163 23.8323C45.3262 24.1314 44.4434 24.281 43.5548 24.2743Z"
            fill="white"
        />
        <path
            d="M50.5034 21.0837H51.8162C52.2624 20.4799 52.6286 19.8209 52.9059 19.1232C53.198 18.378 53.415 17.6054 53.5535 16.817C53.712 15.9185 53.8128 15.0108 53.8555 14.0995C53.9051 13.1222 53.9298 12.0661 53.9298 10.9312V8.53754H65.0975V21.0837H66.7779V27.4464H63.7147V23.9456H53.5623V27.4464H50.499L50.5034 21.0837ZM61.693 21.0837V11.3163H57.1419V11.5964C57.1419 12.6466 57.1171 13.6167 57.0675 14.5065C57.0179 15.3963 56.9318 16.2263 56.8093 16.9964C56.6964 17.7285 56.5236 18.45 56.2929 19.1538C56.0696 19.8225 55.7839 20.4686 55.4396 21.0837H61.693Z"
            fill="white"
        />
        <path
            d="M68.1479 8.53754H72.9135L76.515 19.1232H76.5587L79.9633 8.53754H84.7288V23.9325H81.5606V13.0186H81.5168L77.7446 23.9281H75.119L71.36 13.128H71.3293V23.9325H68.1611L68.1479 8.53754Z"
            fill="white"
        />
        <path
            d="M0 21.6788L4.55985 24.742C7.22924 24.2475 18.607 21.8932 18.607 17.8847C18.607 14.8784 15.2724 12.8785 12.3273 11.115C9.9774 9.7059 7.95128 8.48935 7.95128 6.98399C7.95128 5.36048 9.36475 4.02578 12.0385 3.12869C12.6348 2.93098 13.241 2.76446 13.8546 2.62982C11.4127 2.9624 6.8529 3.9864 6.8529 7.04526C6.8529 11.3382 16.2527 12.9617 16.2527 17.4077C16.2483 20.8955 0 21.6788 0 21.6788Z"
            fill="white"
        />
        <path
            d="M9.43047 6.77822C9.43047 4.75649 11.6579 3.57933 13.4214 2.89667C11.1065 3.4393 8.21393 4.64709 8.21393 6.98827C8.21393 8.34047 10.1831 9.52201 12.4674 10.8873C15.4738 12.6903 18.8784 14.7295 18.8784 17.889C18.8784 19.8976 16.3709 21.7224 11.4391 23.3197C9.28513 23.995 7.09332 24.5429 4.875 24.9607L8.55526 27.4419C8.55526 27.4419 21.0051 23.8098 21.0051 18.703C21.0051 10.3535 9.43047 9.63141 9.43047 6.77822Z"
            fill="white"
        />
        <path
            d="M20.9743 4.79652C20.9743 5.37682 20.7437 5.93335 20.3334 6.34368C19.9231 6.75402 19.3665 6.98454 18.7862 6.98454H18.1517V8.03917H18.8169C19.674 8.03917 20.496 7.69869 21.102 7.09262C21.7081 6.48656 22.0486 5.66456 22.0486 4.80746C22.0486 3.95035 21.7081 3.12836 21.102 2.52229C20.496 1.91623 19.674 1.57574 18.8169 1.57574H15.5742V9.33448H16.6288V6.1706V2.626H18.8169C19.3888 2.63399 19.9349 2.86564 20.3382 3.27134C20.7414 3.67704 20.9697 4.22452 20.9743 4.79652Z"
            fill="white"
        />
        <path
            d="M18.8172 0.262695H14.2661V9.33425H15.3164V1.31732H18.8172C19.6808 1.40401 20.4814 1.80841 21.0637 2.45206C21.646 3.09571 21.9684 3.93271 21.9684 4.80066C21.9684 5.66861 21.646 6.50561 21.0637 7.14926C20.4814 7.79291 19.6808 8.19731 18.8172 8.284H18.152V9.33862H18.8172C19.4131 9.33862 20.0032 9.22125 20.5538 8.99319C21.1044 8.76514 21.6046 8.43087 22.026 8.00949C22.4474 7.5881 22.7817 7.08783 23.0097 6.53726C23.2378 5.98669 23.3552 5.39659 23.3552 4.80066C23.3552 4.20473 23.2378 3.61463 23.0097 3.06406C22.7817 2.51348 22.4474 2.01322 22.026 1.59183C21.6046 1.17045 21.1044 0.836181 20.5538 0.608127C20.0032 0.380073 19.4131 0.262695 18.8172 0.262695V0.262695Z"
            fill="white"
        />
        <path
            d="M26.8426 16.3007V10.6819C26.8505 10.1275 26.645 9.59124 26.2688 9.18401C25.8925 8.77679 25.3741 8.5297 24.8208 8.49384C24.539 8.4847 24.2582 8.53228 23.995 8.63376C23.7319 8.73524 23.4919 8.88856 23.2892 9.08461C23.0817 9.28415 22.9163 9.52328 22.8028 9.78785C22.6893 10.0524 22.63 10.3371 22.6284 10.625V16.3138H23.7049V10.625C23.7055 10.4816 23.736 10.3399 23.7943 10.2089C23.8527 10.078 23.9376 9.96059 24.0439 9.86426C24.1501 9.76793 24.2752 9.69478 24.4112 9.64945C24.5472 9.60412 24.6912 9.58762 24.8339 9.60099C25.0923 9.63022 25.3306 9.75424 25.5028 9.94906C25.675 10.1439 25.7688 10.3956 25.766 10.6556V16.3445L26.8426 16.3007Z"
            fill="white"
        />
        <path
            d="M31.3147 16.301V10.6822C31.3157 10.1325 31.1063 9.60326 30.7296 9.20295C30.3529 8.80264 29.8373 8.56158 29.2886 8.52918C29.0054 8.5186 28.723 8.56519 28.4582 8.66616C28.1935 8.76714 27.9518 8.92043 27.7475 9.1169C27.5433 9.31336 27.3808 9.54895 27.2696 9.80962C27.1585 10.0703 27.101 10.3507 27.1006 10.6341V16.3229H28.1815V10.6341C28.1763 10.4866 28.2028 10.3397 28.2592 10.2033C28.3156 10.067 28.4006 9.94426 28.5084 9.8435C28.6162 9.74273 28.7444 9.66624 28.8842 9.61918C29.0241 9.57212 29.1724 9.55558 29.3192 9.57068C29.5776 9.59992 29.8159 9.72394 29.9881 9.91876C30.1603 10.1136 30.2541 10.3653 30.2513 10.6253V16.3142L31.3147 16.301Z"
            fill="white"
        />
        <path
            d="M29.2715 7.22949C28.4284 7.21137 27.6084 7.506 26.9697 8.05656C26.3307 7.50636 25.5109 7.21179 24.6679 7.22949C23.7616 7.26236 22.9034 7.64566 22.2741 8.29867C21.6447 8.95167 21.2934 9.82342 21.2939 10.7303V16.301H22.3705V10.6866C22.3675 10.066 22.6044 9.4682 23.0317 9.01809C23.459 8.56798 24.0436 8.30032 24.6635 8.27099C25.1154 8.25854 25.5615 8.3755 25.9491 8.60809C26.3368 8.84068 26.6499 9.17921 26.8515 9.5838L26.9784 9.83761L27.1053 9.5838C27.3412 9.10805 27.7311 8.72602 28.2115 8.49976C28.6919 8.2735 29.2347 8.21629 29.7517 8.33744C30.2687 8.45858 30.7296 8.75096 31.0595 9.16709C31.3894 9.58322 31.5689 10.0987 31.5689 10.6297V16.3185H32.6454V10.7347C32.6472 9.82704 32.2963 8.95419 31.6668 8.30024C31.0374 7.6463 30.1786 7.26239 29.2715 7.22949Z"
            fill="white"
        />
        <path
            d="M93.1302 24.334C92.2492 24.334 91.4464 24.2062 90.7217 23.9504C89.997 23.6946 89.3647 23.3252 88.8248 22.8421C88.2848 22.359 87.8372 21.7764 87.482 21.0943C87.1268 20.3981 86.8852 19.6166 86.7573 18.7498H88.1854C88.299 19.3892 88.4837 19.9789 88.7395 20.5189C88.9953 21.0446 89.3292 21.5064 89.7413 21.9043C90.1533 22.2879 90.6364 22.5863 91.1906 22.7994C91.759 23.0126 92.3984 23.1191 93.1088 23.1191C93.933 23.1191 94.679 22.9699 95.3468 22.6716C96.0288 22.3732 96.6114 21.954 97.0945 21.414C97.5919 20.8599 97.9826 20.192 98.2668 19.4105C98.551 18.629 98.7073 17.7552 98.7357 16.7889H90.9988V15.574H98.6931C98.622 14.6931 98.4373 13.8974 98.1389 13.1869C97.8405 12.4622 97.4498 11.8441 96.9667 11.3326C96.4835 10.8211 95.9152 10.4303 95.2615 10.1603C94.6221 9.87615 93.9117 9.73406 93.1302 9.73406C91.8797 9.73406 90.8283 10.0609 89.9757 10.7145C89.1232 11.3681 88.5761 12.2704 88.3345 13.4213H86.8852C86.9705 12.6541 87.2049 11.9649 87.5886 11.3539C87.9722 10.7429 88.4482 10.2243 89.0166 9.798C89.585 9.37172 90.2244 9.05201 90.9348 8.83887C91.6453 8.61153 92.3629 8.49785 93.0875 8.49785C94.2385 8.49785 95.2544 8.7181 96.1354 9.15858C97.0164 9.58486 97.7553 10.1603 98.3521 10.885C98.9488 11.6097 99.3964 12.4551 99.6948 13.4213C100.007 14.3876 100.164 15.4035 100.164 16.4692C100.164 17.6628 99.9932 18.7427 99.6522 19.7089C99.3112 20.6752 98.8281 21.5064 98.2029 22.2027C97.5919 22.8847 96.853 23.4104 95.9862 23.7799C95.1337 24.1493 94.1816 24.334 93.1302 24.334Z"
            fill="white"
        />
        <path
            d="M101.79 8.81756H103.24V17.0021L112.085 8.81756H114.046L107.247 15.1051L114.365 24.0356H112.49L106.16 16.1069L103.24 18.8138V24.0356H101.79V8.81756Z"
            fill="white"
        />
        <path
            d="M114.972 8.81756H121.408C122.048 8.81756 122.659 8.8815 123.241 9.00939C123.838 9.12306 124.357 9.32909 124.797 9.62749C125.252 9.91167 125.614 10.2953 125.884 10.7784C126.154 11.2473 126.289 11.837 126.289 12.5475C126.289 12.9453 126.225 13.3361 126.097 13.7197C125.969 14.0892 125.785 14.4302 125.543 14.7428C125.316 15.0412 125.039 15.297 124.712 15.5101C124.399 15.7232 124.044 15.8724 123.646 15.9577V16.0003C124.627 16.1282 125.408 16.5332 125.991 17.2152C126.573 17.883 126.865 18.7143 126.865 19.7089C126.865 20.0784 126.808 20.5118 126.694 21.0091C126.595 21.4922 126.36 21.9611 125.991 22.4158C125.621 22.8705 125.074 23.2541 124.35 23.5667C123.625 23.8793 122.644 24.0356 121.408 24.0356H114.972V8.81756ZM121.408 22.7994C121.948 22.7994 122.46 22.7568 122.943 22.6716C123.426 22.5721 123.852 22.4016 124.222 22.16C124.591 21.9185 124.882 21.5988 125.096 21.2009C125.309 20.803 125.415 20.2915 125.415 19.6663C125.415 18.6859 125.067 17.9541 124.371 17.471C123.689 16.9737 122.701 16.725 121.408 16.725H116.421V22.7994H121.408ZM121.408 15.4888C121.977 15.4888 122.474 15.4249 122.9 15.297C123.341 15.1549 123.703 14.9702 123.987 14.7428C124.271 14.5013 124.485 14.2242 124.627 13.9116C124.769 13.599 124.84 13.265 124.84 12.9098C124.84 11.0058 123.696 10.0538 121.408 10.0538H116.421V15.4888H121.408Z"
            fill="white"
        />
        <path
            d="M133.003 8.81756H134.623L140.591 24.0356H139.035L137.18 19.304H130.253L128.42 24.0356H126.886L133.003 8.81756ZM136.69 18.0678L133.749 10.2669L130.701 18.0678H136.69Z"
            fill="white"
        />
        <path
            d="M141.334 24.0356H143.21L151.948 10.3735V24.0356H153.398V8.81756H151.522L142.783 22.4797V8.81756H141.334V24.0356ZM147.238 7.56004C148.133 7.56004 148.837 7.32559 149.348 6.85669C149.86 6.37357 150.194 5.68443 150.35 4.78925H149.519C149.405 5.34341 149.149 5.77679 148.751 6.08939C148.368 6.38778 147.87 6.53698 147.259 6.53698C146.62 6.53698 146.108 6.38068 145.725 6.06808C145.355 5.75547 145.135 5.3292 145.064 4.78925H144.233C144.346 5.68443 144.652 6.37357 145.149 6.85669C145.647 7.32559 146.343 7.56004 147.238 7.56004Z"
            fill="white"
        />
        <path
            d="M155.827 8.81756H162.583C163.265 8.81756 163.883 8.91703 164.437 9.11595C164.992 9.30067 165.468 9.58486 165.865 9.96851C166.263 10.3379 166.569 10.7926 166.782 11.3326C166.995 11.8725 167.102 12.4835 167.102 13.1656C167.102 13.8476 166.995 14.4586 166.782 14.9986C166.569 15.5385 166.263 16.0003 165.865 16.384C165.468 16.7534 164.992 17.0376 164.437 17.2365C163.883 17.4212 163.265 17.5136 162.583 17.5136H157.276V24.0356H155.827V8.81756ZM162.263 16.2774C163.272 16.2774 164.089 16.0216 164.715 15.5101C165.34 14.9986 165.652 14.2171 165.652 13.1656C165.652 12.1141 165.34 11.3326 164.715 10.8211C164.089 10.3095 163.272 10.0538 162.263 10.0538H157.276V16.2774H162.263Z"
            fill="white"
        />
        <path
            d="M168.612 8.81756H170.062V22.4797L178.8 8.81756H180.676V24.0356H179.227V10.3735L170.488 24.0356H168.612V8.81756Z"
            fill="white"
        />
        <path
            d="M183.105 8.81756H184.554V15.4249H193.591V8.81756H195.041V24.0356H193.591V16.6611H184.554V24.0356H183.105V8.81756Z"
            fill="white"
        />
        <path
            d="M197.601 8.81756H207V10.0538H199.05V24.0356H197.601V8.81756Z"
            fill="white"
        />
    </svg>
);
