export const texts = {
    system: {
        commonDescription: 'Общее описание',
        edge: 'Edge',
        firefox: 'Firefox',
        chrome: 'Chrome',
        ie: 'Ie',
        safari: 'Safari',
        osSafari: 'OS Safari',
        samsungInternet: 'Samsung Internet',
        chromeForAndroid: 'Chrome for Android',
        ucBrowserForAndroid: 'UC Browser for Android',
        activateAccount: 'Активация учетной записи сотрудника',
        supportPartial: 'Поддерживается частично',
        supportedBrowser: 'Поддерживаемые версии браузеров',
        siteLink: 'https://merchant.sdm.ru',
        headerPartOne:
            'Личный кабинет — это веб-приложение для сотрудников ТСП, которое постоянно доступно пользователям ТСП через интернет по адресу ',
        headerPartTwo:
            '. Личный кабинет позволяет отслеживать проведенные операции и возмещения по ним, анализировать основные показатели предприятия и сравнивать данные за разные периоды.',
        footer: 'При создании нового пользователя ТСП, на указанный адрес электронной почты высылается письмо с ссылкой для активации пользователя, а на указанный номер телефона высылается смс-сообщение с кодом подтверждения. При переходе по ссылке активации в браузере, пользователю отображается форма установки постоянного пароля для пользователя ТСП. Далее, необходимо придумать надежный пароль, ввести его в форму, повторить ввод пароля, ввести код подтверждения активации из смс-сообщения и затем нажать на кнопку Отправить. В случае успешной активации, пользователь будет перенаправлен на форму входа, в противном случае, появится всплывающее сообщение об ошибке.',
    },
};
