import * as type from '../constants';

const initState = {
    fetch: false,
    route: ['', ''],
};

export default function fetch(state = initState, action) {
    switch (action.type) {
        case type.FETCH_START:
            return {
                ...state,
                fetch: state.fetch + 1,
            };
        case type.FETCH_STOP:
            return {
                ...state,
                fetch: state.fetch > 0 ? state.fetch - 1 : 0,
            };
        case type.CHANGE_ROUTE:
            return {
                ...state,
                route: [state.route[1], action.path],
            };
        default:
            return state;
    }
}
