import React from 'react';

export const ResetFiltersIcon = (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M9.125 17.6367C13.8535 17.6367 17.7646 13.7256 17.7646 8.99707C17.7646 4.26855 13.8447 0.366211 9.11621 0.366211C4.3877 0.366211 0.485352 4.26855 0.485352 8.99707C0.485352 13.7256 4.39648 17.6367 9.125 17.6367ZM6.41797 12.5303C5.96094 12.5303 5.60059 12.1611 5.60059 11.6953C5.60059 11.4844 5.68848 11.2822 5.84668 11.124L7.95605 9.00586L5.84668 6.89648C5.68848 6.73828 5.60059 6.53613 5.60059 6.31641C5.60059 5.85059 5.96094 5.49902 6.41797 5.49902C6.66406 5.49902 6.84863 5.57812 7.00684 5.73633L9.125 7.8457L11.252 5.72754C11.4277 5.56055 11.6123 5.49023 11.8408 5.49023C12.3066 5.49023 12.667 5.85059 12.667 6.30762C12.667 6.52734 12.5879 6.7207 12.4209 6.8877L10.3027 9.00586L12.4121 11.1152C12.5791 11.2734 12.6582 11.4756 12.6582 11.6953C12.6582 12.1611 12.2979 12.5303 11.832 12.5303C11.5947 12.5303 11.3838 12.4512 11.2256 12.2842L9.125 10.1836L7.0332 12.2842C6.86621 12.4512 6.66406 12.5303 6.41797 12.5303Z" />
    </svg>
);
