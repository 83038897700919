import React, { FC } from 'react';
import styles from './light-table.module.scss';

interface LightTableRowProps {
    firstTD: string;
    lastTD: string | React.ReactNode;
    boldLastTD?: boolean;
}

export const LightTableRow: FC<LightTableRowProps> = ({
    firstTD,
    lastTD,
    boldLastTD,
}) => {
    return (
        <>
            {firstTD && lastTD && (
                <tr>
                    <td>{firstTD}</td>
                    <td className={boldLastTD ? styles['bold'] : ''}>
                        {lastTD}
                    </td>
                </tr>
            )}
        </>
    );
};
