import React from 'react';
import { useSelector } from 'react-redux';
import { ReplaySubject } from 'rxjs';

import AwareTable from 'src/components/AwareTable';

import { LOCAL_STORAGE_TABLE_NAMES, getInitialFilter } from 'src/shared/lib';
import { URL } from 'src/app/config';
import { OverflowContainer, Paginator } from 'src/shared/ui';

import ReversalPageFilter from './ReversalPageFilter';
import { DownloadReportStore } from 'src/features/download-report';

import './reversal-page.scss';

const filterSubject = new ReplaySubject(1);

const pageTitle = LOCAL_STORAGE_TABLE_NAMES.reversal;

export const ReversalPage = () => {
    const { token } = useSelector((state) => state.requestCancelation);

    return (
        <div className="reversal-page">
            <ReversalPageFilter pageTitle={pageTitle} />

            <Paginator name={pageTitle}>
                <OverflowContainer>
                    <AwareTable
                        token={token}
                        newStatuses
                        tableName={pageTitle}
                        url={URL.reversal}
                        isStoreTable
                        actions={[
                            <DownloadReportStore
                                pageTitle={pageTitle}
                                defaultFileName="Возвраты"
                                getFilterCallback={() =>
                                    getInitialFilter(['terminalIds'], pageTitle)
                                }
                                filterSubject={filterSubject}
                                url={URL.reversalExport}
                            />,
                        ]}
                    />
                </OverflowContainer>
            </Paginator>
        </div>
    );
};
