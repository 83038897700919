import React, { FC } from 'react';
import { SDMFullLogo } from 'src/shared/ui';
import styles from './public-form.module.scss';

interface Props {
    header: string | React.ReactNode;
}

export const UI: FC<Props> = ({ header, children }) => {
    return (
        <div className={styles['public-form']}>
            {SDMFullLogo}
            <div className={styles['header']}>{header}</div>
            <div className={styles['content']}>{children}</div>
        </div>
    );
};
