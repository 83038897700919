import React, { useRef, useState } from 'react';
import styles from './styles.module.scss';
import { classNames } from 'src/shared/lib';
import { SelectOption } from '..';
import { useClickOutside } from 'src/shared/lib';

interface Props {
    options: SelectOption[];
    onChange: React.Dispatch<React.SetStateAction<string[]>>;
    selected: string[];
}

const Checked = ({ active }: { active?: boolean }) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="8" cy="8" r="7.5" stroke="#BDBDBD" />

        <path
            className={classNames(styles['checkmark'], {
                [styles['active']]: active,
            })}
            d="M8.00406 16C12.384 16 16 12.384 16 7.99594C16 3.61605 12.3758 0 7.99594 0C3.61605 0 0 3.61605 0 7.99594C0 12.384 3.61605 16 8.00406 16ZM7.14271 11.8151C6.83393 11.8151 6.59015 11.677 6.36262 11.3926L4.50178 9.14982C4.35551 8.96292 4.28238 8.77603 4.28238 8.57288C4.28238 8.15033 4.61554 7.81717 5.03809 7.81717C5.29 7.81717 5.48502 7.90655 5.68004 8.15846L7.11833 9.96242L10.2387 4.98121C10.4175 4.6968 10.645 4.55053 10.905 4.55053C11.3113 4.55053 11.6851 4.84307 11.6851 5.26562C11.6851 5.45251 11.6039 5.64754 11.482 5.82631L7.8903 11.3845C7.71153 11.6607 7.44337 11.8151 7.14271 11.8151Z"
            fill="#002E5A"
        />
    </svg>
);

export const DropdownMultiselect = ({ options, onChange, selected }: Props) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);

    const toggleDropdown = () => {
        setOpen((prev) => !prev);
    };

    const ref = useRef<HTMLDivElement>(null);

    useClickOutside(() => setOpen(false), ref);

    return (
        <div ref={ref} className={styles['wrapper']}>
            <div onClick={toggleDropdown} className={styles['header']}>
                Отображение инвентаря
            </div>

            <div
                ref={contentRef}
                style={{
                    maxHeight: open ? contentRef.current?.scrollHeight : 0,
                }}
                className={classNames(styles['content'], {
                    [styles['open']]: open,
                })}
                onClick={(e) => e.stopPropagation()}
            >
                {options.map((option, i) => (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            onChange((prev) => {
                                let copy = [...prev];
                                copy.includes(option.value)
                                    ? (copy = copy.filter(
                                          (value) => value !== option.value
                                      ))
                                    : copy.push(option.value);
                                return copy;
                            });
                        }}
                        className={styles['option']}
                        key={option.value}
                    >
                        <Checked active={selected.includes(option.value)} />
                        {option.content}
                    </div>
                ))}
            </div>
        </div>
    );
};
