import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    getClassName,
    isPermit,
    PaymentTypeFilterOptions,
    QRCodeTypeOptions,
    localLoad,
    localLoadMoment,
    localSave,
    getInitialFilter,
    formatDateForInput,
} from 'src/shared/lib';
import { FiltersSelector, DateRangePicker, SearchField } from 'src/shared/ui';
import { Roles } from 'src/app/config';
import { setEndDate, setStartDate, setFilters } from 'src/redux/actions/table';

import './einvoice-filter.scss';

class EinvoiceFilter extends Component {
    constructor(props) {
        super(props);
        this.startDate = getClassName(this.props.pageTitle, 'startDate');
        this.endDate = getClassName(this.props.pageTitle, 'endDate');
        this.paymentType = getClassName(this.props.pageTitle, 'paymentType');
        this.qrcType = getClassName(this.props.pageTitle, 'qrcType');
        this.searchQuery = getClassName(this.props.pageTitle, 'searchQuery');

        const { startDate, endDate, ...initialFilter } = getInitialFilter(
            ['searchQuery'],
            this.props.pageTitle
        );

        const localQrcTypeValue = localLoad(this.qrcType, '');
        const localPaymentTypeValue = localLoad(this.paymentType, '')
            .split(',')
            .filter((type) => type !== '');

        this.defaultFilter = {
            startDate: localLoadMoment(
                this.startDate,
                moment(new Date()).add(-7, 'days')
            ),
            endDate: localLoadMoment(this.endDate, moment(new Date())),
            searchQuery: localLoad(this.searchQuery, ''),
            paymentType: [],
            qrcType: undefined,
        };
        this.state = {
            ...this.defaultFilter,
        };
        this.setStartDate(this.defaultFilter.startDate);
        this.setEndDate(this.defaultFilter.endDate);
        this.props.dispatch(
            setFilters(this.props.pageTitle, {
                paymentType: localPaymentTypeValue,
                qrcType: localQrcTypeValue,
                ...initialFilter,
                ...this.props.additionalFilter,
            })
        );
    }

    saveFilter() {
        localSave(this.startDate, this.getStartDate());
        localSave(this.endDate, this.getEndDate());
        localSave(this.qrcType, this.props.table?.filters?.qrcType || '');
        localSave(
            this.paymentType,
            this.props.table?.filters?.paymentType || []
        );
    }

    componentDidUpdate() {
        this.saveFilter();
    }

    getStartDate() {
        return this.props.table?.startDate ?? this.state.startDate;
    }

    setStartDate(date) {
        this.props.dispatch(setStartDate(this.props.pageTitle, date));
    }

    getEndDate() {
        return this.props.table?.endDate ?? this.state.endDate;
    }

    setEndDate(date) {
        this.props.dispatch(setEndDate(this.props.pageTitle, date));
    }

    render() {
        const companyRolesHasSBP = isPermit(Roles.OPERATION_SBP);
        return (
            <div className="einvoice-filter">
                {companyRolesHasSBP && (
                    <FiltersSelector
                        columns={[
                            {
                                title: 'Способ оплаты',
                                options: PaymentTypeFilterOptions,
                                onChange: (value) =>
                                    this.props.dispatch(
                                        setFilters(this.props.pageTitle, {
                                            paymentType: value,
                                        })
                                    ),
                                selected:
                                    this.props.table?.filters?.paymentType ||
                                    [],
                            },
                            {
                                title: 'Тип QR',
                                options: QRCodeTypeOptions,
                                onChange: (value) =>
                                    this.props.dispatch(
                                        setFilters(this.props.pageTitle, {
                                            qrcType: value,
                                        })
                                    ),
                                selected:
                                    this.props.table?.filters?.qrcType || '',
                            },
                        ].filter(Boolean)}
                        reset={() => {
                            this.props.dispatch(
                                setFilters(this.props.pageTitle, {
                                    paymentType: [],
                                    qrcType: undefined,
                                })
                            );
                        }}
                    />
                )}
                <DateRangePicker
                    datepickers={[
                        {
                            selected: this.getStartDate(),
                            start: true,
                            onChange: (startDate) =>
                                this.setStartDate(startDate),
                        },
                        {
                            selected: this.getEndDate(),
                            end: true,
                            onChange: (endDate) => this.setEndDate(endDate),
                        },
                    ]}
                    formatter={formatDateForInput}
                    startDate={this.getStartDate()}
                    endDate={this.getEndDate()}
                    onReset={() => {
                        this.setStartDate(moment(new Date()).add(-7, 'days'));
                        this.setEndDate(moment(new Date()));
                    }}
                />
                <SearchField
                    fullsize
                    placeholder="Введите E-Mail"
                    onChange={(value) =>
                        this.props.dispatch(
                            setFilters(this.props.pageTitle, {
                                searchQuery: value,
                            })
                        )
                    }
                    value={this.props.table?.filters?.searchQuery}
                />
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        roles: state.auth.companyRoles,
        table: state.table?.[props.pageTitle],
    };
}

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(EinvoiceFilter);
