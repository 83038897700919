import React from 'react';

export const EmailIcon = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.2695 19.959C15.5332 19.959 19.8789 15.6133 19.8789 10.3398C19.8789 5.07617 15.5234 0.730469 10.2598 0.730469C4.99609 0.730469 0.650391 5.07617 0.650391 10.3398C0.650391 15.6133 4.99609 19.959 10.2695 19.959ZM10.2695 10.75C10.1035 10.75 9.92773 10.6816 9.72266 10.4961L5.74805 6.51172C5.88477 6.4043 6.1582 6.32617 6.54883 6.32617H13.9707C14.3613 6.32617 14.6445 6.4043 14.7812 6.51172L10.7969 10.4961C10.6113 10.6816 10.4355 10.75 10.2695 10.75ZM8.43359 10.3203L5.23047 13.5332C5.16211 13.4062 5.12305 13.1719 5.12305 12.8398V7.84961C5.12305 7.49805 5.16211 7.25391 5.23047 7.13672L8.43359 10.3203ZM12.0762 10.3203L15.2695 7.13672C15.3379 7.25391 15.3867 7.49805 15.3867 7.84961V12.8398C15.3867 13.1719 15.3477 13.4062 15.2793 13.5332L12.0762 10.3203ZM10.2598 11.5605C10.6016 11.5605 10.8848 11.4336 11.2168 11.1211L11.4902 10.8672L14.791 14.168C14.6543 14.2852 14.3809 14.3535 13.9707 14.3535H6.54883C6.13867 14.3535 5.86523 14.2852 5.72852 14.168L9.0293 10.8672L9.29297 11.1211C9.63477 11.4336 9.91797 11.5605 10.2598 11.5605Z"
            fill="#CABFAD"
        />
    </svg>
);
