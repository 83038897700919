import * as yup from 'yup';

export const validationScheme = yup
    .object()
    .shape({
        name: yup
            .string()
            .trim()
            .max(16, 'Максимум 16 символов')
            .required('Обязательное поле'),
    })
    .required();
