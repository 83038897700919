import React from 'react';
import moment from 'moment';

import store from '../../store';
import { Roles } from 'src/app/config';
import { getAppTitle } from 'src/shared/lib';

export const formatDate = (date) => {
    return date ? moment(date).format(' YYYY-MM-DD HH:mm') : date;
};

export const formatDateOnly = (date) => {
    return date ? moment(date).format('YYYY-MM-DD') : date;
};

export const formatDateForInput = (date) => {
    return date ? moment(date).format('DD.MM.YYYY') : date;
};

export function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export const timeZoneOptions = [
    { value: '-12', text: '(GMT-12:00) Международная Дата Линии Запад' },
    { value: '-11', text: '(GMT-11:00) Мидуэй, Самоа' },
    { value: '-10', text: '(GMT-10:00) Гавайи' },
    { value: '-9', text: '(GMT-09:00) Аляска' },
    { value: '-8', text: '(GMT-08:00) Тихоокеанское время (США и Канада)' },
    { value: '-8', text: '(GMT-08:00) Тихуана, Нижняя Калифорния' },
    { value: '-7', text: '(GMT-07:00) Аризона' },
    { value: '-7', text: '(GMT-07:00) Чихуахуа, Ла Пас, Мазатлан' },
    { value: '-7', text: '(GMT-07:00) Горное время (США и Канада)' },
    { value: '-6', text: '(GMT-06:00) Центральная Америка' },
    { value: '-6', text: '(GMT-06:00) Центральное время (США и Канада)' },
    { value: '-6', text: '(GMT-06:00) Гвадалахара, Мехико, Монтеррей' },
    { value: '-6', text: '(GMT-06:00) Саскачеван' },
    { value: '-5', text: '(GMT-05:00) Богота, Лима, Кито, Риу-Бранку' },
    { value: '-5', text: '(GMT-05:00) Восточное время (США и Канада)' },
    { value: '-5', text: '(GMT-05:00) Индиана (Восток)' },
    { value: '-4', text: '(GMT-04:00) Атлантическое время (Канада)' },
    { value: '-4', text: '(GMT-04:00) Каракас, Ла-Пас' },
    { value: '-4', text: '(GMT-04:00) Манаус' },
    { value: '-4', text: '(GMT-04:00) Сантьяго' },
    { value: '-3.5', text: '(GMT-03:30) Ньюфаундленд' },
    { value: '-3', text: '(GMT-03:00) Бразилиа' },
    { value: '-3', text: '(GMT-03:00) Буэнос-Айрес, Джорджтаун' },
    { value: '-3', text: '(GMT-03:00) Гренландия' },
    { value: '-3', text: '(GMT-03:00) Монтевидео' },
    { value: '-2', text: '(GMT-02:00) Среднеатлантическое время' },
    { value: '-1', text: '(GMT-01:00) Кабо-Верде' },
    { value: '-1', text: '(GMT-01:00) Азорские острова' },
    { value: '0', text: '(GMT+00:00) Касабланка, Монровия, Рейкьявик' },
    {
        value: '0',
        text: '(GMT+00:00) Среднее время по Гринвичу: Дублин, Эдинбург, Лиссабон, Лондон',
    },
    {
        value: '+1',
        text: '(GMT+01:00) Амстердам, Берлин, Берн, Рим, Стокгольм, Вена ',
    },
    {
        value: '+1',
        text: '(GMT+01:00) Белград, Братислава, Будапешт, Любляна, Прага',
    },
    { value: '+1', text: '(GMT+01:00) Брюссель, Копенгаген, Мадрид, Париж' },
    { value: '+1', text: '(GMT+01:00) Сараево, Скопье, Варшава, Загреб' },
    { value: '+1', text: '(GMT+01:00) Западная Центральная Африка' },
    { value: '+2', text: '(GMT+02:00) Амман' },
    { value: '+2', text: '(GMT+02:00) Афины, Бухарест, Стамбул' },
    { value: '+2', text: '(GMT+02:00) Бейрут' },
    { value: '+2', text: '(GMT+02:00) Каир' },
    { value: '+2', text: '(GMT+02:00) Хараре, Претория' },
    {
        value: '+2',
        text: '(GMT+02:00) Хельсинки, Киев, Рига, София, Таллин, Вильнюс',
    },
    { value: '+2', text: '(GMT+02:00) Иерусалим' },
    { value: '+2', text: '(GMT+02:00) Минск' },
    { value: '+2', text: '(GMT+02:00) Виндхук' },
    { value: '+3', text: '(GMT+03:00) Москва, Санкт-Петербург' },
    { value: '+3', text: '(GMT+03:00) Кувейт, Эр-Рияд, Багдад' },
    { value: '+3', text: '(GMT+03:00) Найроби' },
    { value: '+3', text: '(GMT+03:00) Тбилиси' },
    { value: '+3.5', text: '(GMT+03:30) Тегеран' },
    { value: '+4', text: '(GMT+04:00) Абу-Даби,Маскат' },
    { value: '+4', text: '(GMT+04:00) Баку' },
    { value: '+4', text: '(GMT+04:00) Ереван' },
    { value: '+4.5', text: '(GMT+04:30) Кабул' },
    { value: '+5', text: '(GMT+05:00) Екатеринбург' },
    { value: '+5', text: '(GMT+05:00) Исламабад, Карачи, Ташкент' },
    { value: '+5.5', text: '(GMT+05:30) Шри Джаяварденапура' },
    { value: '+5.5', text: '(GMT+05:30) Ченнаи, Калькутта, Мумбаи, Нью-Дели' },
    { value: '+5.7', text: '(GMT+05:45) Катманду' },
    { value: '+6', text: '(GMT+06:00) Алматы, Новосибирск' },
    { value: '+6', text: '(GMT+06:00) Астана, Дакка' },
    { value: '+6.5', text: '(GMT+06:30) Янгон (Рангун)' },
    { value: '+7', text: '(GMT+07:00) Бангкок, Ханой, Джакарта' },
    { value: '+7', text: '(GMT+07:00) Красноярск' },
    { value: '+8', text: '(GMT+08:00) Пекин, Чунцин, Гонконг, Урумчи' },
    { value: '+8', text: '(GMT+08:00) Куала Лумпур, Сингапур' },
    { value: '+8', text: '(GMT+08:00) Иркутск, Улан-Батаар' },
    { value: '+8', text: '(GMT+08:00) Перт' },
    { value: '+8', text: '(GMT+08:00) Таипеи' },
    { value: '+9', text: '(GMT+09:00) Осака, Саппоро, Токио' },
    { value: '+9', text: '(GMT+09:00) Сеул' },
    { value: '+9', text: '(GMT+09:00) Якутск' },
    { value: '+9.5', text: '(GMT+09:30) Аделаида' },
    { value: '+9.5', text: '(GMT+09:30) Дарвин' },
    { value: '+10', text: '(GMT+10:00) Брисбейн' },
    { value: '+10', text: '(GMT+10:00) Канберра, Мельбурн, Сидней' },
    { value: '+10', text: '(GMT+10:00) Хобарт' },
    { value: '+10', text: '(GMT+10:00) Гуам, Порт-Морсби' },
    { value: '+10', text: '(GMT+10:00) Владивосток' },
    {
        value: '+11',
        text: '(GMT+11:00) Магадан, Соломоновы Острова, Новые Каледони',
    },
    { value: '+12', text: '(GMT+12:00) Окленд, Веллингтон' },
    { value: '+12', text: '(GMT+12:00) Фиджи, Камчатка, Маршалловы Острова' },
    { value: '+13', text: "(GMT+13:00) Нуку'алофа" },
];

export function isPermit(role = '') {
    if (getAppTitle() === 'bank') {
        return (
            store.getState().auth.roles.includes(Roles.ADMIN) ||
            store.getState().auth.roles.includes(role)
        );
    }
    return (
        isAdmin(store.getState().auth.roles) ||
        (store.getState().auth.companyRoles.includes(role) &&
            store.getState().auth.roles.includes(role))
    );
}

export function isAdmin(roles) {
    return roles.includes(Roles.ADMIN);
}

export function getClassName(name, postFix) {
    return postFix ? `${name}-${postFix}` : name;
}

export function sendToSubject(message) {
    if (this.props.subject) this.props.subject.next(message);
}

export function log(message) {
    if (process.env.NODE_ENV === 'development') console.log(message);
}

export function isEmptyObj(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}

export function ticksFormatter(value) {
    if (typeof value === 'string')
        return !this.props.toggle
            ? parseFloat(value)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' ₽'
            : checkFloor(value);
    return !this.props.toggle
        ? value.toFixed(0).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' ₽'
        : checkFloor(value);
}

export function ticksMpsFormatter(value) {
    if (typeof value === 'string')
        return !this.props.toggle
            ? parseFloat(value)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' ₽'
            : value;
    return !this.props.toggle
        ? value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' ₽'
        : value;
}

export function roleNameMapper(role) {
    return {
        ADMIN: 'Администратор',
        COMPANY_INFO: 'Управление ТСП',
        USER_MANAGEMENT: 'Управление пользователями',
        OPERATIONS: 'Операции',
        CASHBOX: 'Реестры ПЛАТ-ФОРМЫ',
        SETTLEMENT: 'Возмещение',
        REFUND: 'Возврат',
        SETTING_TERMINAL: 'Управление терминалами',
        SETTING_USER: 'Управление пользователями',
        STATISTIC: 'Статистика',
        DYNAMIC: 'Динамика',
        OPERATIONS_SBP: 'Операции СБП',
        ECOMM: 'Электронная коммерция',
        EINVOICE: 'Электронные счета',
        SCHEDULE: 'Подписки',
    }[role];
}

export function operationStatusMapper(status) {
    return {
        IN_PROCESS: 'Авторизована',
        CLOSED: 'Подтверждена',
        SETTLED: 'Взаиморасчет',
    }[status];
}

export function operationTypeMapper(type) {
    return {
        PAY: 'Покупка',
        PRE_PAY: 'Предавторизация',
        REVERSAL: 'Отмена',
        PRE_REVERSAL: 'Отмена предавторизации',
        REFUND: 'Возврат',
    }[type];
}

export function isMobileWidth() {
    return document.body.offsetWidth < 840;
}

function checkFloor(value) {
    if (Math.floor(value) === value) {
        return value;
    }
}

/* validations */

export const validateEmail = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export function statusNameMapper(status, lang) {
    return {
        en: {
            Активно: 'Active',
            Выключено: 'Disabled',
        },
        ru: {
            Active: 'Активно',
            Disabled: 'Выключено',
        },
    }[lang][status];
}

export const einvoiceDelCases = ['Отправлен', 'Создан', 'Не удалось отправить'];

export const einvoiceSendCases = ['Оплачен', 'Истек срок'];

export const einvoiceQrcEmptyCase = (qrcType) => qrcType !== 'Статический';

export const einvoiceStaticDownloadCase = (qrcType, status) =>
    status !== 'Истек срок' && qrcType === 'Статический';

export const unavailablePageMsg = (
    <React.Fragment>
        <p>
            Новый сервис электронной торговли от СДМ-Банка – это простой и
            быстрый способ выхода вашего бизнеса в онлайн.
        </p>
        <p>
            Теперь нет необходимости создавать или дорабатывать свой сайт,
            нанимать программистов и получать лицензии.
        </p>
        <p>
            Способ оплаты, который выведет ваш бизнес в интернет быстро и просто
            – платёжная ссылка.
        </p>
        <p>
            Для подключения этого инструмента нужно отправить заявку через ваш
            Личный кабинет.
        </p>
        <p>Всё остальное сделает сервис электронной торговли от СДМ-Банка.</p>
        <p>
            Подробную информацию вы можете получить по телефону{' '}
            <a href="tel:8 800 200 02 23">8 800 200 02 23</a> или по электронной
            почте <a href="mailto:sdm@sdm.ru">sdm@sdm.ru</a>.
        </p>
    </React.Fragment>
);

export const PaymentTypeOptions = (
    hasSbpAndCardPaymentOptions = true,
    ecomKeys
) => {
    let resultOption = [
        {
            id: 0,
            value: ['CARD'],
            text: 'Оплата Картой',
        },
        {
            id: 1,
            value: ['SBP'],
            text: 'Оплата через СБП',
        },
        ...(hasSbpAndCardPaymentOptions
            ? [
                  {
                      id: 2,
                      value: 'CARD,SBP',
                      text: 'Оплата Картой и СБП',
                  },
              ]
            : []),
    ];
    resultOption = resultOption.filter((option) => {
        const valueArr = Array.isArray(option.value)
            ? option.value
            : option.value.split(',');
        return valueArr.every(
            (value) =>
                ecomKeys?.findIndex((key) => key.paymentType.includes(value)) >=
                0
        );
    });
    if (resultOption?.length) {
        return resultOption;
    }

    return [
        {
            id: 0,
            value: ['CARD'],
            text: 'Оплата Картой',
        },
        {
            id: 1,
            value: ['SBP'],
            text: 'Оплата через СБП',
        },
        ...(hasSbpAndCardPaymentOptions
            ? [
                  {
                      id: 2,
                      value: 'CARD,SBP',
                      text: 'Оплата Картой и СБП',
                  },
              ]
            : []),
    ];
};
export const PaymentCardTypeOptions = (ecomKeys) => {
    const cardExist =
        ecomKeys.findIndex((key) => key.paymentType.includes('CARD')) >= 0;
    return cardExist
        ? [
              {
                  id: 0,
                  value: ['CARD'],
                  text: 'Оплата Картой',
              },
          ]
        : [];
};

export const getBankPaymentTypeOptions = () => {
    return [
        {
            id: 0,
            value: ['SBP'],
            text: 'Оплата через СБП',
        },
    ];
};

export const PaymentTypeFilterOptions = [
    {
        id: 0,
        value: 'CARD',
        text: 'Картой',
    },
    {
        id: 1,
        value: 'SBP',
        text: 'Через СБП',
    },
    {
        id: 2,
        value: 'MIR_PAY',
        text: 'Через Mir Pay',
    },
];

export const QRCodeTypeOptions = [
    {
        id: 0,
        value: '01',
        text: 'Статический',
    },
    {
        id: 1,
        value: '02',
        text: 'Динамический',
    },
];

export const BankQRCodeTypeOptions = [
    {
        id: 0,
        value: '01',
        text: 'Статический',
    },
];

export const OperationSBPTypeOptions = [
    {
        id: 1,
        value: 'PAYMENT',
        text: 'Оплата',
    },
    {
        id: 2,
        value: 'REFUND',
        text: 'Возврат',
    },
];
