import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle } from 'reactstrap';
import moment from 'moment';
import range from 'lodash/range';

import { getDynamicsChart } from 'src/redux/actions/charts';
import {
    getClassName,
    localLoad,
    localLoadSerialize,
    localSave,
    localSaveSerialize,
    rusDate,
} from 'src/shared/lib';
import { Button } from 'src/shared/ui';
import { BankTerminalsSelector } from 'src/features/select-terminals';

import './dynamic-filter.scss';

class DynamicFilter extends Component {
    constructor(props) {
        super(props);
        this.getYearsFrom2017 = range(2017, moment().year() + 1);
        this.clsName = 'DynamicFilter';
        this.firstGroup = {
            year: getClassName(this.clsName, 'firstGroup.year'),
            month: getClassName(this.clsName, 'firstGroup.month'),
            terminals: getClassName(this.clsName, 'firstGroup.terminals'),
        };
        this.secondGroup = {
            year: getClassName(this.clsName, 'secondGroup.year'),
            month: getClassName(this.clsName, 'secondGroup.month'),
            terminals: getClassName(this.clsName, 'secondGroup.terminals'),
        };
        this.state = {
            focusedInput: null,
            firstGroup: {
                year: localLoad(this.firstGroup.year, moment().format('YYYY')),
                month: localLoad(this.firstGroup.month, moment().format('M')),
                terminals: localLoadSerialize(this.firstGroup.terminals, []),
            },
            secondGroup: {
                year: localLoad(this.secondGroup.year, moment().format('YYYY')),
                month: localLoad(this.secondGroup.month, moment().format('M')),
                terminals: localLoadSerialize(this.secondGroup.terminals, []),
            },
        };
    }

    getData() {
        this.saveFilter();
        this.props.dispatch(
            getDynamicsChart({
                groups: [
                    {
                        name: 'Группа 1',
                        terminals: this.state.firstGroup.terminals,
                        year: this.state.firstGroup.year,
                        month: this.state.firstGroup.month,
                    },
                    {
                        name: 'Группа 2',
                        terminals: this.state.secondGroup.terminals,
                        year: this.state.secondGroup.year,
                        month: this.state.secondGroup.month,
                    },
                ],
            })
        );
    }

    saveFilter() {
        localSave(this.firstGroup.year, this.state.firstGroup.year);
        localSave(this.firstGroup.month, this.state.firstGroup.month);
        localSaveSerialize(
            this.firstGroup.terminals,
            this.state.firstGroup.terminals
        );
        localSave(this.secondGroup.year, this.state.secondGroup.year);
        localSave(this.secondGroup.month, this.state.secondGroup.month);
        localSaveSerialize(
            this.secondGroup.terminals,
            this.state.secondGroup.terminals
        );
    }

    render() {
        return (
            <div className="dynamic-filter">
                <div className="dynamic-filter__menu">
                    <div className="dynamic-filter__menu__block">
                        <Card>
                            <CardBody>
                                <CardTitle>Группа 1</CardTitle>
                                <BankTerminalsSelector
                                    selected={this.state.firstGroup.terminals}
                                    terminalsList={this.props.terminals}
                                    onChange={(terminals) =>
                                        this.setState({
                                            firstGroup: {
                                                ...this.state.firstGroup,
                                                terminals,
                                            },
                                        })
                                    }
                                />
                                <div className="dynamic-filter__menu__block__row">
                                    <span>Год:</span>
                                    <select
                                        value={this.state.firstGroup.year}
                                        className="custom-select"
                                        onChange={(e) =>
                                            this.setState({
                                                firstGroup: {
                                                    ...this.state.firstGroup,
                                                    year: e.target.value,
                                                },
                                            })
                                        }
                                    >
                                        {this.getYearsFrom2017.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="dynamic-filter__menu__block__row">
                                    <span>Месяц:</span>
                                    <select
                                        className="custom-select"
                                        value={this.state.firstGroup.month}
                                        onChange={(e) =>
                                            this.setState({
                                                firstGroup: {
                                                    ...this.state.firstGroup,
                                                    month: e.target.value,
                                                },
                                            })
                                        }
                                    >
                                        {[
                                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
                                            12,
                                        ].map((e) => (
                                            <option key={e} value={e}>
                                                {rusDate.month[e - 1]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="dynamic-filter__menu__block">
                        <Card>
                            <CardBody>
                                <CardTitle>Группа 2</CardTitle>
                                {/* TODO 637: think of better naming*/}
                                <BankTerminalsSelector
                                    selected={this.state.secondGroup.terminals}
                                    terminalsList={this.props.terminals}
                                    onChange={(terminals) =>
                                        this.setState({
                                            secondGroup: {
                                                ...this.state.secondGroup,
                                                terminals,
                                            },
                                        })
                                    }
                                />
                                <div className="dynamic-filter__menu__block__row">
                                    <span>Год:</span>
                                    <select
                                        value={this.state.secondGroup.year}
                                        className="custom-select"
                                        onChange={(e) =>
                                            this.setState({
                                                secondGroup: {
                                                    ...this.state.secondGroup,
                                                    year: e.target.value,
                                                },
                                            })
                                        }
                                    >
                                        {this.getYearsFrom2017.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="dynamic-filter__menu__block__row">
                                    <span>Месяц:</span>
                                    <select
                                        className="custom-select"
                                        value={this.state.secondGroup.month}
                                        onChange={(e) =>
                                            this.setState({
                                                secondGroup: {
                                                    ...this.state.secondGroup,
                                                    month: e.target.value,
                                                },
                                            })
                                        }
                                    >
                                        {[
                                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
                                            12,
                                        ].map((e) => (
                                            <option key={e} value={e}>
                                                {rusDate.month[e - 1]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className="dynamic-filter__submit">
                    <Button onClick={() => this.getData()}>Применить</Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ terminals: state.terminals.terminals });

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(DynamicFilter);
