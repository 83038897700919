import * as type from '../constants';
import { localSaveSerialize } from 'src/shared/lib/localStorage';

const initState = {
    terminals: [],
    rcMsg: [],
    idnSbp: [],
    inactive: false,
};

export default function terminals(state = initState, action) {
    switch (action.type) {
        case type.GET_TERMINALS:
            return {
                ...state,
                terminals: action.payload,
            };

        case type.GET_RCMSG:
            localSaveSerialize('rcMsg', action.payload);
            return {
                ...state,
                rcMsg: action.payload,
            };

        case type.SET_TERMINAL_INACTIVE:
            return {
                ...state,
                inactive: action.payload,
            };

        case type.SET_TERMINALS_INACTIVE_PERIOD_PER_DAYS:
            return {
                ...state,
                inactivePeriodPerDays: action.payload,
            };

        case type.SET_IDN_SBP:
            return {
                ...state,
                idnSbp: action.payload
            }

        default:
            return state;
    }
}
