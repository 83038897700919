import React, { PureComponent } from 'react';
import { Chart } from 'chart.js';
import { ticksFormatter, renderCustomTooltip } from 'src/shared/lib';
import Container from 'src/components/Container';
import connect from 'react-redux/es/connect/connect';

import './decline-chart.scss';

const hoverSectionBackgroundColor = {
    id: 'hoverSectionBackgroundColor',
    beforeDatasetsDraw(chart) {
        const {
            ctx,
            tooltip,
            chartArea: { top, height },
            scales: { x },
        } = chart;
        if (tooltip._active && tooltip._active.length > 0) {
            const index = tooltip._active[0].index;
            if (x._gridLineItems.length > index + 1) {
                const currentX = x._gridLineItems[index].x1;
                const nextX = x._gridLineItems[index + 1]
                    ? x._gridLineItems[index + 1].x1
                    : currentX;
                const newWidth = currentX - nextX;
                ctx.fillStyle = 'rgba(239, 239, 243, 0.5)';
                ctx.fillRect(nextX, top, newWidth, height);
            }
        }
    },
};

class DeclineChart extends PureComponent {
    constructor(props) {
        super(props);
        this.ticksFormatter = ticksFormatter.bind(this);
        this.chartProps = this.props;
        this.chart = null;
        this.state = {
            chartConfig: {
                chartType: 'bar',
                data: {
                    datasets: [
                        {
                            data: [],
                            backgroundColor: '#EAEDF3',
                            hoverBackgroundColor: '#002E5A',
                            lineTension: 0,
                            maxBarThickness: 130,
                            borderRadius: 6,
                        },
                    ],
                },
                options: {
                    layout: {
                        padding: {
                            top: 30,
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: false,
                            external: (context) =>
                                renderCustomTooltip(
                                    context,
                                    this.renderTooltipContent
                                ),
                        },
                    },
                    scales: {
                        x: {
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: '',
                            },
                            grid: {
                                borderColor: '#F0F2F2',
                                display: false,
                            },
                            ticks: {
                                color: '#000',
                            },
                        },
                        y: {
                            ticks: {
                                min: 0, // it is for ignoring negative step.
                                beginAtZero: true,
                                callback: this.ticksFormatter,
                                color: '#000',
                            },
                            grid: {
                                borderColor: '#F0F2F2',
                                display: false,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: '',
                            },
                        },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                },
            },
        };
    }

    renderTooltipContent(context) {
        let innerHtml = '';
        const dataSets = context.tooltip.chart.config.data.datasets;
        const currentDataIndex = context.tooltip.dataPoints[0].dataIndex;
        dataSets.forEach((dataset) => {
            innerHtml += `<div style="
                display: flex;
                align-items: center;
                justify-content: space-between;
            ">
                <span style="font-weight: 400; font-size: 12px; margin-right: 12px;">
                    ${context.tooltip.title}:
                </span>
                <span style="
                    font-weight: 600;
                    text-align: right;
                    font-size: 12px;
                ">
                    ${dataset.data[currentDataIndex]}
                </span>`;
            innerHtml += '</div>';
        });
        return innerHtml;
    }

    clearChart() {
        if (this.chart) this.chart.destroy();
    }

    configureCharts(data) {
        this.prepareData(data);
        let ctx = this.refs.canvas.getContext('2d');
        this.clearChart();
        this.chart = new Chart(ctx, {
            type: this.state.chartConfig.chartType,
            data: this.state.chartConfig.data,
            options: this.state.chartConfig.options,
            plugins: [hoverSectionBackgroundColor],
        });
    }

    componentWillUnmount() {
        this.clearChart();
    }

    prepareData(data) {
        let newChart = this.state.chartConfig;
        newChart.options.scales.y.scaleLabel.labelString = data.groupName;
        newChart.options.scales.x.scaleLabel.labelString = data.name;
        newChart.data.labels = [];
        newChart.data.datasets[0].data = [];
        data.tuples.forEach((item) => {
            newChart.data.labels.push(item.name);
            newChart.data.datasets[0].data.push(item.values[0]);
        });
        this.setState({ chartConfig: newChart });
    }

    componentDidUpdate() {
        if (this.chartRenderCheck)
            this.configureCharts(this.props.data[this.props.toggle]);
    }

    get chartRenderCheck() {
        return !!(
            this.props.data && this.props.data[this.props.toggle].tuples.length
        );
    }

    render() {
        if (!this.chartRenderCheck) return null;

        return (
            <Container header={true} headerName="Причины отказа" background>
                <div className="decline-chart chart">
                    <canvas ref="canvas" />
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({ data: state.charts.failureCodes });

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(DeclineChart);
