import moment from 'moment';
import {
    localLoad,
    localLoadIntInArray,
    localLoadMoment,
    localLoadSerialize,
} from './localStorage';
import { getClassName } from './util';

const getInitialValue = (
    key: 'startDate' | 'endDate' | 'searchQuery' | 'companyIds' | string
) => {
    switch (key) {
        case 'startDate':
            return moment(new Date()).add(-7, 'days');
        case 'endDate':
            return moment(new Date());
        case 'searchQuery':
            return '';
        default:
            return [];
    }
};

const getInitialFilter = (fields: string[], pageTitle: string) => {
    let initialFilter = {};
    ['startDate', 'endDate', ...fields].forEach((field) => {
        let key = getClassName(pageTitle, field);
        switch (field) {
            case 'startDate':
            case 'endDate':
                initialFilter[field] = localLoadMoment(
                    key,
                    getInitialValue(field)
                );
                break;
            case 'searchQuery':
                initialFilter[field] = localLoad(key, getInitialValue(field));
                break;
            case 'companyIds':
                initialFilter[field] = localLoadIntInArray(
                    getClassName(pageTitle, 'companySelectId'),
                    getInitialValue(field)
                );
                break;
            case 'qrcTypes': {
                const localValue = localLoad(
                    getClassName(pageTitle, 'qrcTypes'),
                    getInitialValue(field)
                );

                initialFilter[field] = localValue;
                break;
            }
            case 'operationTypes': {
                const localValue = localLoad(
                    getClassName(pageTitle, 'operationTypes'),
                    getInitialValue(field)
                );

                initialFilter[field] =
                    typeof localValue === 'string' && localValue !== ''
                        ? localValue.split(',')
                        : [];

                break;
            }
            case 'companyTerminalIds': {
                const localValue = localLoad(
                    getClassName(pageTitle, 'companyTerminalIds'),
                    getInitialValue(field)
                );

                initialFilter[field] =
                    typeof localValue === 'string'
                        ? localValue.split(',')
                        : localValue;

                break;
            }

            case 'operationStatuses': {
                const localValue = localLoad(
                    getClassName(pageTitle, 'operationStatuses'),
                    getInitialValue(field)
                );

                initialFilter[field] =
                    typeof localValue === 'string' && localValue !== ''
                        ? localValue.split(',')
                        : [];

                break;
            }
            case 'responseMessages': {
                const localValue = localLoad(
                    getClassName(pageTitle, 'responseMessages'),
                    getInitialValue(field)
                );

                initialFilter[field] =
                    typeof localValue === 'string' && localValue !== ''
                        ? localValue.split(',')
                        : [];

                break;
            }

            case 'merchantIds': {
                const localValue = localLoad(
                    getClassName(pageTitle, 'merchantIds'),
                    getInitialValue(field)
                );

                initialFilter[field] =
                    typeof localValue === 'string' && localValue !== ''
                        ? localValue.split(',')
                        : [];

                break;
            }

            case 'terminalIds': {
                const localValue = localLoad(
                    getClassName(pageTitle, 'terminalIds'),
                    getInitialValue(field)
                );

                initialFilter[field] =
                    typeof localValue === 'string'
                        ? localValue.split(',').map((value) => Number(value))
                        : localValue;

                break;
            }

            default:
                initialFilter[field] = localLoadSerialize(
                    key,
                    getInitialValue(field)
                );
                break;
        }
    });

    return initialFilter;
};

export { getInitialFilter };
