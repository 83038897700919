import * as type from '../constants';
import { GET_DECLINE_CHARTS_FAIL, GET_MPS_CHARTS_FAILED } from '../constants';

const initState = {
    conversion: null,
    failureCodes: null,
    mps: null,
    dynamic: null,
    toggle: 'count',
};

export default function charts(state = initState, action) {
    switch (action.type) {
        case type.GET_CONVERSION_CHARTS_SUCCESS:
            return {
                ...state,
                conversion: action.payload,
            };
        case type.GET_MPS_CHARTS_SUCCESS:
            return {
                ...state,
                mps: action.payload,
            };
        case type.GET_DECLINE_CHARTS_SUCCESS:
            return {
                ...state,
                failureCodes: action.payload,
            };
        case type.GET_DINAMIC_CHARTS_SUCCESS:
            return {
                ...state,
                dynamic: action.payload,
            };
        case type.TOGGLE_CHART:
            return {
                ...state,
                toggle: action.toggle,
            };
        case type.GET_CONVERSION_CHARTS_FAIL ||
            type.GET_DINAMIC_CHARTS_FAIL ||
            GET_DECLINE_CHARTS_FAIL ||
            GET_MPS_CHARTS_FAILED:
            return {
                ...state,
            };
        default:
            return state;
    }
}
