import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { ChangePasswordForm } from 'src/features';
import { DocumentLinks } from 'src/features/document-links';
import { UserProfile } from 'src/entities/user';
import { getAppTitle } from 'src/shared/lib';
import { Button, ArticleLayout } from 'src/shared/ui';
import './user-config-page.scss';

class UserConfigPage extends Component {
    constructor(props) {
        super(props);

        this.isMerchant = getAppTitle() === 'merchant';

        this.state = {
            modal: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    render() {
        return (
            <div className="user-settings-page">
                <ArticleLayout
                    className="user-settings-page__body"
                    bigColumn={
                        <>
                            <UserProfile />
                            <Button
                                color="gray"
                                size="medium"
                                onClick={this.toggle}
                                outline
                                className="button"
                            >
                                Изменить пароль
                            </Button>
                        </>
                    }
                    smallColumn={
                        this.isMerchant && (
                            <DocumentLinks className="documents" />
                        )
                    }
                />
                <Modal
                    centered
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className="user-settings-page__modal"
                >
                    <ModalHeader toggle={this.toggle}>
                        Изменить пароль
                    </ModalHeader>
                    <ModalBody>
                        <ChangePasswordForm inline />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    fetch: state.fetch.fetch,
    terminals: state.terminals.terminals,
    form: state['change-password-form'],
});

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(UserConfigPage);
