import { StateType } from '../model/types';
import { statusNameMapper } from 'src/shared/lib';
export const createBody = (
    values: StateType,
    paymentType: string[],
    companyId: string
) => {
    const {
        accAlias,
        account,
        allowWidgets,
        cashbox,
        cashboxRequired,
        defaultFailUrl,
        defaultNotificationEmail,
        defaultNotificationUrl,
        defaultSuccessUrl,
        eCommKeyBlocked,
        ecommkeyStatus,
        extEntityId,
        idDefaultETerminal,
        maxAmount,
        merchantId,
        merchantIdSbp,
        merchantName,
        merchantUrl,
        notifyByEmail,
        notifyByUrl,
        passwordDefaultETerminal,
        timeZone,
        mirPayKid,
        mirPayMerchantId,
        mirPayPrivateKey,
        mirPayTerminalId,
    } = values;
    return {
        status: statusNameMapper(ecommkeyStatus, 'en'),
        blocked: eCommKeyBlocked,
        ...(paymentType &&
            paymentType.includes('CARD') && {
                idDefaultETerminal,
                passwordDefaultETerminal,
            }),
        company: companyId,
        cashboxRequired,
        cashbox: parseInt(cashbox, 10),
        merchantId,
        merchantName,
        merchantUrl,
        defaultNotificationUrl,
        defaultSuccessUrl,
        defaultFailUrl,
        timeZone,
        allowWidgets,
        maxAmount: parseInt(maxAmount, 10),
        defaultNotificationEmail,
        notifyByEmail,
        notifyByUrl,
        paymentType,
        mirPayKid,
        mirPayMerchantId,
        mirPayTerminalId,
        mirPayPrivateKey: mirPayPrivateKey,
        ...(paymentType.includes('SBP') && {
            extEntityId,
            merchantIdSbp,
            accAlias,
            account,
        }),
    };
};
