import React, { useEffect, useState } from 'react';
import { Modal } from 'src/shared';
import { ChangelogEntry } from '../changelog-entry';
import styles from './styles.module.scss';
import { BANK_CHANGELOG } from 'src/shared/lib/user-changelog/bank-changelog';

interface Props {
    isOpen: boolean;
    isClosed: boolean;
    onClose: () => void;
}

const BOTTOM_OFFSET = 50;

const CHANGELOG = BANK_CHANGELOG;
const entriesCount = Object.keys(CHANGELOG).length;

export const ChangelogModal = ({ isClosed, isOpen, onClose }: Props) => {
    const [entriesDisplayedCount, setEntriesDisplayedCount] = useState(3);
    const [hasScroll, setHasScroll] = useState(false);

    useEffect(() => {
        const entries = document.getElementById('modal-element-scroll');
        if (entries)
            setHasScroll(
                entries?.scrollHeight > entries?.clientHeight + BOTTOM_OFFSET
            );
    }, [entriesDisplayedCount]);

    useEffect(() => {
        return () => {
            setEntriesDisplayedCount(3);
        };
    }, []);

    return (
        <div>
            <Modal
                isOpen={isOpen}
                isClosed={isClosed}
                closeButton="Закрыть"
                onClose={onClose}
                onScroll={(e) => {
                    const bottom =
                        e.currentTarget.scrollHeight -
                            e.currentTarget.scrollTop -
                            BOTTOM_OFFSET <
                        e.currentTarget.clientHeight;

                    if (bottom) {
                        setEntriesDisplayedCount((prev) => prev + 5);
                    }
                }}
            >
                <p className={styles['title']}>История изменений</p>
                <div className={styles['body']}>
                    {Object.keys(CHANGELOG)
                        .slice(0, entriesDisplayedCount)
                        .map((key) => (
                            <ChangelogEntry
                                key={key}
                                value={CHANGELOG[key]}
                                dateAndVersion={key}
                            />
                        ))}
                </div>
                {!hasScroll && entriesCount > entriesDisplayedCount && (
                    <button
                        onClick={() =>
                            setEntriesDisplayedCount((prev) => prev + 5)
                        }
                        className={styles['show-more']}
                    >
                        показать еще
                    </button>
                )}
            </Modal>
        </div>
    );
};
