import { StateType } from '../types';

export let defaultValues: StateType = {
    ecommkeyStatus: 'Активно',
    cashbox: '0',
    extEntityId: '',
    merchantIdSbp: '',
    account: '',
    merchantId: '',
    accAlias: '',
    defaultNotificationEmail: '',
    defaultNotificationUrl: '',
    maxAmount: '',
    notifyByEmail: false,
    allowWidgets: false,
    notifyByUrl: false,
    eCommKeyBlocked: false,
    cashboxRequired: false,
    merchantName: '',
    merchantUrl: '',
    defaultFailUrl: '',
    defaultSuccessUrl: '',
    idDefaultETerminal: '',
    timeZone: '+3',
    passwordDefaultETerminal: ''
};
