import { object, string } from 'yup';

interface ForgotPasswordFormFields {
    email: string;
}

const schema = object({
    email: string().required('Введите E-Mail').email(''),
});

export type { ForgotPasswordFormFields };

export { schema };
