import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
    BankUserListPage,
    CashBoxes,
    CashBoxReceiptPage,
    CashBoxRegistriesPage,
    CompanyDetailsPage,
    CompanyListPage,
    DynamicPage,
    EcommPage,
    EinvoiceDetailsPage,
    MerchantEinvoicePage,
    BankEinvoicePage,
    EpaymentDetailsPage,
    EpaymentsPage,
    GroupListPage,
    HelpPage,
    OperationDetailPage,
    OperationSBPDetailPage,
    OperationsPage,
    OperationsSBPPage,
    PlatformCashBoxErrorsPage,
    PlatformCashBoxPage,
    ReversalPage,
    SchedulePage,
    SettingsPage,
    SettlementDetailsPage,
    SettlementPage,
    StatisticPage,
    SupportPage,
    SystemPage,
    InventoryPage,
} from 'src/pages';
import { getAppTitle, isPermit } from 'src/shared/lib';
import { navRoutes, Roles } from '.';

const protectedRoutesConfig = {
    bank: () => (
        <>
            <Switch>
                {isPermit(Roles.COMPANY_INFO) ? (
                    <Route
                        path={navRoutes.companyId}
                        component={() => <CompanyDetailsPage />}
                    />
                ) : null}
                {isPermit(Roles.COMPANY_INFO) ? (
                    <Route
                        path={navRoutes.company}
                        component={CompanyListPage}
                    />
                ) : null}
                {isPermit(Roles.COMPANY_INFO) ? (
                    <Route path={navRoutes.groups} component={GroupListPage} />
                ) : null}
                {isPermit(Roles.USER_MANAGEMENT) ? (
                    <Route
                        path={navRoutes.users}
                        component={BankUserListPage}
                    />
                ) : null}
                {isPermit(Roles.OPERATIONS) ? (
                    <Route
                        path={navRoutes.operationsId}
                        component={OperationDetailPage}
                    />
                ) : null}
                {isPermit(Roles.OPERATIONS) ? (
                    <Route
                        path={navRoutes.operations}
                        component={OperationsPage}
                    />
                ) : null}
                {isPermit(Roles.OPERATIONS) ? (
                    <Route
                        exact
                        path={navRoutes.operationSBPLastUrl}
                        component={OperationsSBPPage}
                    />
                ) : null}
                {isPermit(Roles.OPERATIONS) ? (
                    <Route
                        path={navRoutes.operationsSBPId}
                        component={OperationSBPDetailPage}
                    />
                ) : null}
                {isPermit(Roles.SETTLEMENT) ? (
                    <Route
                        exact
                        path={navRoutes.settlement}
                        component={SettlementPage}
                    />
                ) : null}
                {isPermit(Roles.SETTLEMENT) ? (
                    <Route
                        path={navRoutes.settlementDateId}
                        component={SettlementDetailsPage}
                    />
                ) : null}
                {isPermit(Roles.SETTLEMENT) ? (
                    <Route
                        path={navRoutes.settlementDate}
                        component={SettlementDetailsPage}
                    />
                ) : null}
                {isPermit(Roles.CASHBOX) ? (
                    <Route
                        path={navRoutes.platformCashBox}
                        component={PlatformCashBoxPage}
                    />
                ) : null}
                {isPermit(Roles.CASHBOX) ? (
                    <Route
                        path={navRoutes.platformErrorCashBox}
                        component={PlatformCashBoxErrorsPage}
                    />
                ) : null}
                {isPermit(Roles.CASHBOX) ? (
                    <Route
                        path={navRoutes.registresOfCashbox}
                        component={CashBoxRegistriesPage}
                    />
                ) : null}
                {isPermit(Roles.CASHBOX) ? (
                    <Route
                        path={navRoutes.receiptsOfCashbox}
                        component={CashBoxReceiptPage}
                    />
                ) : null}
                {isPermit(Roles.ADMIN) ? (
                    <Route path={navRoutes.reversal} component={ReversalPage} />
                ) : null}
                {isPermit(Roles.EINVOICE) ? (
                    <Route
                        exact
                        path={navRoutes.einvoice}
                        component={BankEinvoicePage}
                    />
                ) : null}
                {isPermit(Roles.EINVOICE) ? (
                    <Route
                        path={navRoutes.einvoiceId}
                        component={EinvoiceDetailsPage}
                    />
                ) : null}
                {isPermit(Roles.EINVOICE) ? (
                    <Route
                        path={navRoutes.paymentId}
                        component={EpaymentDetailsPage}
                    />
                ) : null}
                {isPermit(Roles.ADMIN) ? (
                    <Route path={navRoutes.cashBoxes} component={CashBoxes} />
                ) : null}
                <Route path={navRoutes.settings} component={SettingsPage} />
            </Switch>
        </>
    ),
    merchant: () => (
        <>
            <Switch>
                {isPermit(Roles.STATISTIC) ? (
                    <Route
                        path={navRoutes.statistic}
                        component={StatisticPage}
                    />
                ) : null}
                {isPermit(Roles.DYNAMIC) ? (
                    <Route path={navRoutes.dynamics} component={DynamicPage} />
                ) : null}
                {isPermit(Roles.INVENTORY) ? (
                    <Route
                        path={navRoutes.inventory}
                        component={InventoryPage}
                    />
                ) : null}
                {isPermit(Roles.OPERATIONS) ? (
                    <Route
                        exact
                        path={navRoutes.operations}
                        component={OperationsPage}
                    />
                ) : null}
                {isPermit(Roles.OPERATION_SBP) ? (
                    <Route
                        exact
                        path={navRoutes.operationSBPLastUrl}
                        component={OperationsSBPPage}
                    />
                ) : null}
                {isPermit(Roles.SETTLEMENT) ? (
                    <Route
                        exact
                        path={navRoutes.settlementLastUrl}
                        component={SettlementPage}
                    />
                ) : null}
                {isPermit(Roles.OPERATIONS) ? (
                    <Route
                        path={navRoutes.operationsId}
                        component={OperationDetailPage}
                    />
                ) : null}
                {isPermit(Roles.OPERATION_SBP) ? (
                    <Route
                        path={navRoutes.operationsSBPId}
                        component={OperationSBPDetailPage}
                    />
                ) : null}
                {isPermit(Roles.SETTLEMENT) ? (
                    <Route
                        path={navRoutes.settlementDate}
                        component={SettlementDetailsPage}
                    />
                ) : null}
                {isPermit(Roles.ECOMM) ? (
                    <Route
                        path={navRoutes.paymentId}
                        component={EpaymentDetailsPage}
                    />
                ) : null}
                {isPermit(Roles.ECOMM) ? (
                    <Route path={navRoutes.ecomm} component={EcommPage} />
                ) : null}
                {isPermit(Roles.ECOMM) ? (
                    <Route
                        path={navRoutes.payments}
                        component={EpaymentsPage}
                    />
                ) : null}
                {isPermit(Roles.EINVOICE) ? (
                    <Route
                        exact
                        path={navRoutes.einvoice}
                        component={MerchantEinvoicePage}
                    />
                ) : null}
                {isPermit(Roles.EINVOICE) ? (
                    <Route
                        path={navRoutes.einvoiceId}
                        component={EinvoiceDetailsPage}
                    />
                ) : null}
                {isPermit(Roles.SCHEDULE) ? (
                    <Route path={navRoutes.schedule} component={SchedulePage} />
                ) : null}
                <Route path={navRoutes.support.main} component={HelpPage} />
                {/* TODO: временно изменена странца для адреса -> navRoutes.support.main */}
                {/*<Route path={navRoutes.support.main} component={SystemPage} />*/}
                <Route
                    path={navRoutes.support.faq}
                    exact
                    component={SupportPage}
                />
                <Route
                    path={navRoutes.support.system}
                    exact
                    component={SystemPage}
                />
                <Route path={navRoutes.settings} component={SettingsPage} />
            </Switch>
        </>
    ),
};

export const ProtectedRoutes = () => {
    return protectedRoutesConfig[getAppTitle()]();
};
