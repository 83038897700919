import React, { Component } from 'react';
import './multiple-select.scss';

/**
 *
 * @types
 * @Component MultipleSelect
 *
 */

type IProps = {
    disabled?: boolean;
    className?: string;
    defaultValue?: string | string[];
    value?: any;
    initialState?: any;
    onChange?(
        value: string[],
        event: React.SyntheticEvent<any> | undefined | any
    ): void;
};

class MultipleSelect extends Component<IProps> {
    state = {
        selected:
            (this.props.initialState &&
                this.props.initialState.reduce((obj, next) => {
                    obj[next] = true;
                    return obj;
                }, {})) ||
            {},
    };
    render() {
        const { selected } = this.state;
        return (
            <div className="custom-selected">
                {this.props.value &&
                    this.props.value.map(({ value, name }) => {
                        return (
                            <span
                                className={selected[value] ? 'selected' : ''}
                                onClick={() => {
                                    this.setState(
                                        {
                                            selected: {
                                                ...selected,
                                                [value]: !selected[value],
                                            },
                                        },
                                        () => {
                                            //@ts-ignore
                                            this.props.onChange(
                                                Object.keys(this.state.selected)
                                                    .filter(
                                                        (i) =>
                                                            this.state.selected[
                                                                i
                                                            ]
                                                    )
                                                    .map((i) => i)
                                            );
                                        }
                                    );
                                }}
                                key={value}
                            >
                                {name || value}
                                <br />
                            </span>
                        );
                    })}
            </div>
        );
    }
}

export { MultipleSelect };
