import { applyMiddleware, createStore } from 'redux';
// eslint-disable-next-line no-unused-vars
import createLogger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from '../redux/reducers/reducers';
import thunkMiddleware from 'redux-thunk';
import { LOG_OUT_AND_CLEAR_LOCAL } from '../redux/constants';

const middleware = [];

// if (process.env.NODE_ENV === 'development') {
//     middleware.push(thunkMiddleware, createLogger);
// } else {
middleware.push(thunkMiddleware);
// }

const rootReducer = (state, action) => {
    if (action.type === LOG_OUT_AND_CLEAR_LOCAL) {
        state = undefined;
    }
    return reducers(state, action);
};

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
