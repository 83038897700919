import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { logOut, redirectUrlByPermission } from 'src/redux/actions/auth';
import {
    getInactivePeriodPerDays,
    getTerminals,
} from 'src/redux/actions/terminals';
import { CHANGE_ROUTE } from 'src/redux/constants';

import { navRoutes, ProtectedRoutes, URL } from 'src/app/config';
import { PageLayout } from 'src/shared/ui';
import { getAppTitle } from 'src/shared/lib';

import {
    Navigation,
    UserDropdownNavigation,
    FooterNavigation,
} from 'src/app/config';

class MainPage extends Component {
    constructor(props) {
        super(props);
        this.unlisten = null;
        this.homePageUrl =
            getAppTitle() === 'merchant'
                ? navRoutes.statistic
                : navRoutes.company;
    }

    componentDidMount() {
        if (!this.props.auth.logged) {
            this.props.history.push(URL.loginUrl);
        } else {
            const { pathname } = this.props.history.location;
            if (pathname === '/') {
                this.props.history.push(
                    redirectUrlByPermission(
                        this.props.auth.roles,
                        this.props.auth.companyRoles
                    )
                );
            }
            if (getAppTitle() === 'merchant') {
                this.props.getTerminals();
                this.props.getInactivePeriodPerDays();
            }
        }
        this.unlisten = this.props.history.listen((route) =>
            this.props.changeRoute(route.pathname)
        );
    }

    componentWillUnmount() {
        if (this.unlisten) this.unlisten();
    }

    componentDidUpdate() {
        if (!this.props.auth.logged) this.props.history.push(URL.loginUrl);
    }

    setHead(path) {
        if (path === navRoutes.statistic) return 'Статистика';
        if (path === navRoutes.company || path === navRoutes.company + '/')
            return 'Управление ТСП';
        if (path === navRoutes.groups || path === navRoutes.groups + '/')
            return 'Группы ТСП';
        if (path === navRoutes.reversal || path === navRoutes.reversal + '/')
            return 'Возвраты';
        if (path === navRoutes.dynamics) return 'Динамика';
        if (
            path === navRoutes.operationLastUrl ||
            path === navRoutes.operationLastUrl + '/'
        )
            return 'Операции';
        if (path.includes(navRoutes.operations)) return 'Детали операции';
        if (path === navRoutes.operationSBPLastUrl) return 'Операции СБП';
        if (path === navRoutes.schedule) return 'Подписки';
        if (path === navRoutes.platformCashBox) return 'Реестры ПЛАТ-ФОРМЫ';
        if (
            path === navRoutes.settlementLastUrl ||
            path === navRoutes.settlementLastUrl + '/'
        )
            return 'Возмещения';
        if (path.includes(navRoutes.settlement))
            return `Возмещения за ${moment(path.split('/')[2]).format(
                'DD.MM.YYYY'
            )}`;
        if (path.includes(navRoutes.support.root)) return 'Помощь и справка';
        if (path.includes(navRoutes.einvoice)) return 'Счета';
        if (
            path.includes(navRoutes.settingsUsers) ||
            path.includes(navRoutes.users)
        )
            return 'Управление пользователями';
        if (path.includes(navRoutes.inventory)) return 'Инвентарь';
        if (path.includes(navRoutes.settingsTerminals))
            return 'Управление терминалами';
        if (path.includes(navRoutes.settingsUser))
            return 'Профиль пользователя';
        if (path === navRoutes.payments || path === navRoutes.payments + '/')
            return 'Онлайн заказы';
        if (
            path === navRoutes.platformErrorCashBox ||
            path === navRoutes.platformErrorCashBox + '/'
        )
            return 'Кассы';
        if (path.includes(navRoutes.receiptsOfCashbox.replace(/:id/gi, '')))
            return 'Чеки реестра';

        if (path === navRoutes.cashBoxes || path === navRoutes.cashBoxes + '/')
            return 'Ключи';
        if (path.includes(navRoutes.ecomm)) return 'Электронная коммерция';
        return null;
    }
    render() {
        return (
            <PageLayout
                navigation={<Navigation />}
                pageTitle={this.setHead(this.props.location.pathname)}
                userDropdownNavigation={<UserDropdownNavigation />}
                userName={
                    this.props.auth.companyName || this.props.auth.fullName
                }
                footerNavigation={<FooterNavigation />}
                homePageUrl={this.homePageUrl}
            >
                <div className="main-page">
                    <ProtectedRoutes />
                </div>
            </PageLayout>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        logOut: () => dispatch(logOut()),
        getTerminals: () => dispatch(getTerminals()),
        getInactivePeriodPerDays: () => dispatch(getInactivePeriodPerDays()),
        changeRoute: (path) => dispatch({ type: CHANGE_ROUTE, path }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(MainPage);
