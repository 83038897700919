import React from 'react';

export const LinkIcon = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M10.2695 19.959C15.5332 19.959 19.8789 15.6133 19.8789 10.3398C19.8789 5.07617 15.5234 0.730469 10.2598 0.730469C4.99609 0.730469 0.650391 5.07617 0.650391 10.3398C0.650391 15.6133 4.99609 19.959 10.2695 19.959ZM14.5371 5.87695C15.7578 7.09766 15.6504 8.62109 14.2441 10.0273L12.6621 11.5996C12.8086 11.1016 12.7695 10.457 12.6035 10.1055L13.4434 9.28516C14.3906 8.35742 14.4883 7.38086 13.7559 6.66797C13.0234 5.93555 12.0664 6.05273 11.1289 6.98047L9.88867 8.2207C8.93164 9.1582 8.81445 10.1348 9.54688 10.8672C9.76172 11.0723 10.1035 11.2578 10.4648 11.3262C10.3379 11.6484 9.98633 12.0488 9.70312 12.2637C9.43945 12.1855 9.06836 11.9512 8.77539 11.6387C7.53516 10.4277 7.65234 8.88477 9.08789 7.45898L10.3867 6.17969C11.8027 4.77344 13.3164 4.66602 14.5371 5.87695ZM5.66016 14.793C4.42969 13.5723 4.54688 12.0488 5.94336 10.6328L7.52539 9.07031C7.37891 9.55859 7.41797 10.2129 7.58398 10.5645L6.74414 11.3848C5.80664 12.3125 5.69922 13.2793 6.44141 14.002C7.16406 14.7344 8.12109 14.6172 9.05859 13.6895L10.2988 12.4492C11.2559 11.502 11.373 10.5352 10.6504 9.80273C10.4258 9.58789 10.084 9.41211 9.72266 9.34375C9.84961 9.02148 10.2012 8.62109 10.4941 8.40625C10.748 8.48438 11.1191 8.71875 11.4121 9.03125C12.6523 10.2422 12.5352 11.7852 11.1094 13.2109L9.80078 14.4902C8.39453 15.8965 6.87109 16.0039 5.66016 14.793Z" />
    </svg>
);
