import React, { FC } from 'react';
import {
    LightTable,
    LightTableRow,
    Title,
    LinkIcon,
    InfoIcon,
} from 'src/shared/ui';
import { formatCurrency } from '../../lib';
import { OperationSbp } from '../../model';

import styles from './operation-description.module.scss';

interface OperationDescriptionSbpProps {
    data: OperationSbp;
}

export const OperationDescriptionSbp: FC<OperationDescriptionSbpProps> = ({
    data,
    children,
}) => {
    const { merchantName, trxId, commission, description, settlement } = data;

    return (
        <div className={styles['wrapper']}>
            <LightTable borderTop>
                <LightTableRow
                    firstTD="Наименование ТСП"
                    lastTD={merchantName}
                />
                <LightTableRow firstTD="ID операции НСПК" lastTD={trxId} />
                <LightTableRow
                    firstTD="Возмещение"
                    lastTD={settlement && formatCurrency(settlement)}
                    boldLastTD
                />
                <LightTableRow
                    firstTD="Комиссия"
                    lastTD={commission && formatCurrency(commission)}
                    boldLastTD
                />
            </LightTable>
            <div className={styles['add-info']}>
                {description && (
                    <Title
                        icon={InfoIcon}
                        className={styles['add-info__item']}
                        subtitle={description}
                    >
                        Дополнительные данные
                    </Title>
                )}
                {children && (
                    <>
                        <Title
                            icon={LinkIcon}
                            className={`${styles['add-info__item']} ${styles['links']}`}
                        >
                            Связанные операции
                        </Title>
                        {children}
                    </>
                )}
            </div>
        </div>
    );
};
