import React, { Component } from 'react';
import { filter } from 'rxjs/operators';

import { localLoadInt, localSave } from 'src/shared/lib/localStorage';
import { sendToSubject } from 'src/shared/lib/util';

import './container.scss';

export default class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bounce: false,
        };
        this.send = sendToSubject.bind(this);
    }

    bounce() {
        this.setState({ bounce: true });
        setTimeout(() => this.setState({ bounce: false }), 1000);
    }

    render() {
        let bounce = this.state.bounce ? 'bounce' : '';
        let header = this.props.header ? (
            <div className="container-header">
                <h2 className="container-header__title">
                    {this.props.headerName}
                </h2>
                <span className={bounce} onClick={() => this.bounce()}>
                    {this.props.icon}
                </span>
            </div>
        ) : null;
        let componentCls = this.props.background
            ? 'component-container component-container--background'
            : 'component-container';

        let containerCls = this.props.table
            ? 'container-content container-table'
            : 'container-content';
        return (
            <div className={componentCls}>
                {header}
                <div className={containerCls}>
                    {this.props.subject
                        ? React.cloneElement(this.props.children, {
                              subject: this.props.subject,
                          })
                        : this.props.children}
                </div>
                {this.props.footer ? (
                    <PageSelector
                        name={this.props.name}
                        subject={
                            this.props.subject ? this.props.subject : undefined
                        }
                    />
                ) : null}
            </div>
        );
    }
}

class PageSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            size: this.getRange(),
            offset: this.getOffset(),
            end: undefined,
            fetch: false,
        };
        this.send = sendToSubject.bind(this);
    }

    // TODO 637: look into pagintation saves for new paginator
    componentDidMount() {
        if (this.props.subject) {
            this.subscription = this.props.subject
                .pipe(
                    filter(
                        (s) => s.dropOffset !== undefined || s.end !== undefined
                    )
                )
                .subscribe((s) => {
                    if (s.dropOffset !== undefined) this.dropOffset();
                    if (s.end !== undefined) {
                        this.setState({ end: s.end, fetch: false }, () =>
                            localSave(
                                `${this.props.name}-offset`,
                                this.state.offset
                            )
                        );
                    }
                    this.setState({ isEmpty: s.isEmpty });
                });
            this.send({ size: this.state.size, offset: this.state.offset });
        }
    }

    changePage(e, sendMessage = true) {
        if (!this.state.fetch && e !== this.state.offset) {
            this.setState({ offset: e, fetch: sendMessage });
            localSave(`${this.props.name}-offset`, e);
            if (sendMessage) this.send({ size: this.state.size, offset: e });
        }
    }

    dropOffset() {
        this.changePage(1, false);
    }

    getRange() {
        let range = undefined;
        if (this.props.name) {
            range = localLoadInt(`${this.props.name}-range`);
        }
        return range ? range : 10;
    }

    getOffset() {
        let offset = undefined;
        if (this.props.name) {
            offset = localLoadInt(`${this.props.name}-offset`);
        }
        return offset ? offset : 1;
    }

    setRange(e) {
        let updateOffset =
            Math.trunc(((this.state.offset - 1) * this.state.size) / e) + 1;
        this.setState({ size: e, offset: updateOffset });
        this.send({ size: e, offset: updateOffset });
        if (this.props.name) {
            localSave(`${this.props.name}-range`, e);
        }
    }

    componentWillUnmount() {
        if (this.subscription) this.subscription.unsubscribe();
    }

    pagination = () => {
        let defaultClassName = ' pagination-item ';
        let header = <span className="pagination__header">Страница: </span>;

        let offset = (r) => {
            if (r === this.state.offset) return 'pagination_active';
            if (this.state.end >= 2) return defaultClassName;
            if (this.state.end === 1)
                return r > this.state.offset + 1 ? 'hide' : defaultClassName;
            if (this.state.end <= 0)
                return r >= this.state.offset ? 'hide' : defaultClassName;
            return defaultClassName;
        };

        if (this.state.offset === 1) {
            return (
                <span className="pagination">
                    {header}
                    <span
                        className={offset(1)}
                        onClick={() => this.changePage(1)}
                    >
                        1
                    </span>
                    <span
                        className={offset(2)}
                        onClick={() => this.changePage(2)}
                    >
                        2
                    </span>
                    <span
                        className={offset(3)}
                        onClick={() => this.changePage(3)}
                    >
                        3
                    </span>
                </span>
            );
        }
        if (this.state.offset === 2) {
            return (
                <span className="pagination">
                    {header}
                    <span
                        className={offset(1)}
                        onClick={() => this.changePage(1)}
                    >
                        1
                    </span>
                    <span
                        className={offset(2)}
                        onClick={() => this.changePage(2)}
                    >
                        2
                    </span>
                    <span
                        className={offset(3)}
                        onClick={() => this.changePage(3)}
                    >
                        3
                    </span>
                    <span
                        className={offset(4)}
                        onClick={() => this.changePage(4)}
                    >
                        4
                    </span>
                </span>
            );
        }
        if (this.state.offset === 3) {
            return (
                <span className="pagination">
                    {header}
                    <span
                        className={offset(1)}
                        onClick={() => this.changePage(1)}
                    >
                        1
                    </span>
                    <span
                        className={offset(2)}
                        onClick={() => this.changePage(2)}
                    >
                        2
                    </span>
                    <span
                        className={offset(3)}
                        onClick={() => this.changePage(3)}
                    >
                        3
                    </span>
                    <span
                        className={offset(4)}
                        onClick={() => this.changePage(4)}
                    >
                        4
                    </span>
                    <span
                        className={offset(5)}
                        onClick={() => this.changePage(5)}
                    >
                        5
                    </span>
                </span>
            );
        }
        if (this.state.offset >= 4) {
            return (
                <span className="pagination">
                    {header}
                    {this.state.offset > 4 ? (
                        <div>
                            <span
                                className={defaultClassName}
                                onClick={() => this.changePage(1)}
                            >
                                1
                            </span>
                            <span className="no-pointer">...</span>
                        </div>
                    ) : null}

                    <span
                        className={offset(this.state.offset - 2)}
                        onClick={() => this.changePage(this.state.offset - 2)}
                    >
                        {this.state.offset - 2}
                    </span>
                    <span
                        className={offset(this.state.offset - 1)}
                        onClick={() => this.changePage(this.state.offset - 1)}
                    >
                        {this.state.offset - 1}
                    </span>
                    <span
                        className={offset(this.state.offset)}
                        onClick={() => this.changePage(this.state.offset)}
                    >
                        {this.state.offset}
                    </span>
                    <span
                        className={offset(this.state.offset + 1)}
                        onClick={() => this.changePage(this.state.offset + 1)}
                    >
                        {this.state.offset + 1}
                    </span>
                    <span
                        className={offset(this.state.offset + 2)}
                        onClick={() => this.changePage(this.state.offset + 2)}
                    >
                        {this.state.offset + 2}
                    </span>
                </span>
            );
        }
    };

    render() {
        return (
            <div className="container-footer">
                {this.state.end >= -0 ? <span>{this.pagination()}</span> : null}
            </div>
        );
    }
}
