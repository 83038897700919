import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { createNotification } from 'src/redux/actions/notification';
import { URL } from 'src/app/config';
import { log, post, useCloseCustomModal } from 'src/shared/lib';
import { Input, Modal, ModalField, NewSelect } from 'src/shared/ui';
import { validationScheme } from '../lib/validationScheme';
import { revertOptions, submitMessage } from '../model/consts';
import { SendRefundSchema } from '../model/sendRefundSchema';

interface Props {
    operationId: number;
    fullAmount: number;
    closeModal: () => void;
    successCallback: () => void;
}

export const SendRefundRequest: FC<Props> = (props) => {
    const { operationId, fullAmount, closeModal, successCallback } = props;
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(true);
    const [isClosed, setClosed] = useState(false);
    const [isPartial, setPartial] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm<SendRefundSchema>({
        mode: 'onSubmit',
        defaultValues: {
            revert: 'full',
            amount: String(fullAmount),
            fullAmount: String(fullAmount + 0.01),
        },
        resolver: yupResolver(validationScheme),
    });

    const changeRevert = (value: string) => {
        const isPartial = value === 'partial';
        setValue('revert', value);
        setValue('amount', isPartial ? '' : String(fullAmount));
        setPartial(isPartial);
    };

    const onSubmit = ({ amount, revert }: SendRefundSchema) => {
        const body =
            revert === 'full'
                ? {}
                : {
                      amount: parseFloat(amount).toFixed(2),
                  };
        post(URL.operationRevertUrl + operationId, body)
            .then((r) => {
                successCallback();
                dispatch(createNotification(submitMessage, 'success'));
            })
            .catch((r) => log(r));

        setClosed(true);
    };

    const onClose = useCloseCustomModal(setOpen, closeModal);

    return (
        <Modal
            isForm
            isOpen={isOpen}
            isClosed={isClosed}
            header="Заявка на возврат"
            closeButton="Отмена"
            actionButton="Отправить"
            onClose={onClose}
            onAction={handleSubmit(onSubmit)}
        >
            <ModalField numberOfColumns={1}>
                <NewSelect
                    idScrollElement="modal-element-scroll"
                    options={revertOptions}
                    value={getValues('revert')}
                    onChange={changeRevert}
                />
            </ModalField>
            {isPartial && (
                <ModalField
                    numberOfColumns={1}
                    errorMessage={errors.amount?.message}
                >
                    <Input
                        background
                        placeholder="Введите сумму возврата"
                        {...register('amount')}
                    />
                </ModalField>
            )}
        </Modal>
    );
};
