import React from 'react';
import TerminalsPage from '../TerminalsPage';

export const InventoryPage = () => {
    return (
        <div>
            <TerminalsPage showTerminalHeader />
        </div>
    );
};
