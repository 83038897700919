import React, {
    FC,
    memo,
    MutableRefObject,
    useEffect,
    useRef,
    useState,
} from 'react';
import { URL } from 'src/app/config';
import { get, log } from 'src/shared/lib';
import styles from './shows-message-from-bank.module.scss';
import { firePageContentChangedEvent } from 'src/shared/ui';

export const ShowsMessageFromBank: FC = memo(() => {
    const [isShow, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [colorText, setColorText] = useState('');
    const timerRef = useRef() as MutableRefObject<
        ReturnType<typeof setTimeout>
    >;

    const getBankInfo = () => {
        get(URL.bankSupportMsg)
            .then((r) => {
                setMessage(r.data.payload.text);
                setColorText(r.data.payload.color);
                setShow(true);

                timerRef.current = setTimeout(() => {
                    setShow(false);
                    firePageContentChangedEvent();
                }, 180000);
            })
            .catch((r) => log(r));
    };

    useEffect(() => {
        if (!message) {
            getBankInfo();
        }

        return () => {
            clearTimeout(timerRef.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isShow) {
        return null;
    }

    return (
        <div style={{ color: colorText }} className={styles.wrapper}>
            {message}
        </div>
    );
});
