import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { classNames } from 'src/shared/lib';
import { ChangelogRecord } from 'src/shared/lib/user-changelog/changelog-types';
import { ChangeRow } from '../change-row';

interface Props {
    dateAndVersion: string;
    value: ChangelogRecord;
}

const MAX_ENTRY_HEIGHT = 200;

export const ChangelogEntry = ({
    dateAndVersion,
    value: { title, description },
}: Props) => {
    const [version, date] = dateAndVersion.split(' ');
    const [displayFull, setDisplayFull] = useState(false);
    const [descriptionOverflown, setDescriptionOverflown] = useState(false);
    const entryRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const detectEntryOverflow = () => {
            if (entryRef.current) {
                setDescriptionOverflown(
                    entryRef.current?.scrollHeight > MAX_ENTRY_HEIGHT
                );
            }
        };

        detectEntryOverflow();

        window.addEventListener('resize', detectEntryOverflow);
        return () => {
            window.removeEventListener('resize', detectEntryOverflow);
        };
    }, []);

    return (
        <div className={styles['wrapper']}>
            <div className={styles['version-date-wrapper']}>
                <span>{date}</span>
                <span>Версия {version}</span>
            </div>
            <div
                ref={entryRef}
                style={{
                    maxHeight: displayFull
                        ? entryRef.current?.scrollHeight
                        : MAX_ENTRY_HEIGHT,
                }}
                className={styles['title-description-wrapper']}
            >
                <p className={styles['title']}>{title}</p>
                <span
                    className={classNames(styles['description'], {
                        [styles['open']]: displayFull,
                    })}
                >
                    {Array.isArray(description) ? (
                        <div className={styles['changes-list']}>
                            {description.map((change) => (
                                <ChangeRow changeDescription={change} />
                            ))}
                        </div>
                    ) : (
                        description
                    )}
                </span>
            </div>
            {!displayFull && descriptionOverflown && (
                <span
                    className={styles['show-all']}
                    onClick={() => setDisplayFull(true)}
                >
                    подробнее
                </span>
            )}
        </div>
    );
};
