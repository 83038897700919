import React from 'react';

const CirrusIcon = (
    <svg
        width="28px"
        height="17px"
        viewBox="0 0 28 17"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>MasterCard</title>
        <rect
            fill="#2271ce"
            x="10.2044736"
            y="1.81521127"
            width="7.56331392"
            height="13.3429638"
        ></rect>
        <path
            d="M10.6846816,8.48669104 C10.6846816,5.77566473 11.9812497,3.37110227 13.9741229,1.81520892 C12.5094811,0.683650111 10.660671,0 8.64378733,0 C3.86569378,0 0,3.79543682 0,8.48669104 C0,13.1779452 3.86569378,16.9733821 8.64378733,16.9733821 C10.660671,16.9733821 12.5094811,16.289732 13.9741229,15.1581732 C11.9812497,13.6258539 10.6846816,11.1977173 10.6846816,8.48669104 Z"
            fill="#005eb6"
        ></path>
        <path
            d="M27.9722562,8.48669104 C27.9722562,13.1779452 24.1065625,16.9733821 19.3284689,16.9733821 C17.3115852,16.9733821 15.4627751,16.289732 13.9981334,15.1581732 C16.0150171,13.6022798 17.2875747,11.1977173 17.2875747,8.48669104 C17.2875747,5.77566473 15.9910066,3.37110227 13.9981334,1.81520892 C15.4627751,0.683650111 17.3115852,0 19.3284689,0 C24.1065625,0 27.9722562,3.81901097 27.9722562,8.48669104 Z"
            fill="#0099df"
        ></path>
    </svg>
);

export { CirrusIcon };
