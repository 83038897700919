import { navigation } from '../../config';
import { EmptyContent } from './empty-content';
import styles from './content.module.scss';
import { SectionCard } from '../section-card';
import React from 'react';

export const Navigation = ({ searchQuery }: { searchQuery: string }) => {
    const filteredItems = navigation.filter((el) => {
        return (
            el.content?.some((el) =>
                el.toLowerCase().includes(searchQuery.toLowerCase())
            ) ||
            el.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            el.description.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    if (filteredItems.length === 0) {
        return <EmptyContent />;
    }

    return (
        <div className={styles['navigation']}>
            {filteredItems.map((props, idx) => (
                <SectionCard key={idx + (props?.title || '')} {...props} />
            ))}
        </div>
    );
};
