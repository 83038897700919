import React from 'react';

export const SecurityIcon = (
    <svg
        width="32"
        height="46"
        viewBox="0 0 32 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.4043 45.3711H26.791C30.1113 45.3711 31.7715 43.7354 31.7715 40.0977V23.8379C31.7715 20.5664 30.4287 18.9062 27.7188 18.6133V13.1445C27.7188 4.77051 22.2744 0.449219 16.0977 0.449219C9.9209 0.449219 4.47656 4.77051 4.47656 13.1445V18.6133C1.7666 18.9062 0.423828 20.5664 0.423828 23.8379V40.0977C0.423828 43.7354 2.05957 45.3711 5.4043 45.3711ZM7.6748 12.7783C7.6748 6.8457 11.4346 3.47656 16.0977 3.47656C20.7363 3.47656 24.5205 6.8457 24.5205 12.7783V18.5645H7.6748V12.7783ZM5.50195 42.3926C4.20801 42.3926 3.59766 41.8555 3.59766 40.293V23.6182C3.59766 22.0557 4.20801 21.543 5.50195 21.543H26.7178C27.9873 21.543 28.5732 22.0557 28.5732 23.6182V40.293C28.5732 41.8555 27.9873 42.3926 26.7178 42.3926H5.50195Z"
            fill="#002E5A"
        />
    </svg>
);
