import React, { FC, memo, useCallback } from 'react';
import { Modal } from 'src/shared';
import styles from './advertising-of-ecommerce-service.module.scss';

interface Props {
    isOpen: boolean;
    setOpen: (arg: boolean) => void;
}

export const AdvertisingOfEcommerceService: FC<Props> = memo((props) => {
    const { isOpen, setOpen } = props;

    const onClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return (
        <Modal
            isOpen={isOpen}
            header="Уважаемый клиент"
            closeButton="Закрыть"
            onClose={onClose}
            className={styles.wrapper}
        >
            <p>
                Новый сервис электронной торговли от СДМ-Банка – это простой и
                быстрый способ выхода вашего бизнеса в онлайн.
            </p>
            <p>
                Теперь нет необходимости создавать или дорабатывать свой сайт,
                нанимать программистов и получать лицензии.
            </p>
            <p>
                Способ оплаты, который выведет ваш бизнес в интернет быстро и
                просто – платёжная ссылка.
            </p>
            <p>
                Для подключения этого инструмента нужно отправить заявку через
                ваш Личный кабинет.
            </p>
            <p>
                Всё остальное сделает сервис электронной торговли от <br />
                СДМ-Банка.
            </p>
            <p>
                Подробную информацию вы можете получить по телефону <br />
                <a href="tel:8 800 200 02 23">8 800 200 02 23</a> или по
                электронной почте <a href="mailto:sdm@sdm.ru">sdm@sdm.ru</a>.
            </p>
        </Modal>
    );
});
