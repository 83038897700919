import React from 'react';
import { navRoutes } from 'src/app/config';
import { AccessIcon, SecurityIcon, SystemIcon, UserIcon } from 'src/shared/ui';
import { texts } from './texts';

interface NavigationItem {
    bgColor: string;
    title: string;
    description: string;
    icon: React.ReactNode;
    url: string;
    content: string[];
}

export const navigation: NavigationItem[] = [
    {
        bgColor: '#F9F9FA',
        title: 'Управление аккаунтом',
        description:
            'Как подключиться, роли пользователей, вход, настройки терминалов, статистика',
        icon: UserIcon,
        url: '',
        content: [],
    },
    {
        bgColor: '#fbf9f7',
        title: 'Настройка доступа',
        description:
            'Вход по паролю, восстановление пароля, настройка рабочей среды',
        icon: AccessIcon,
        url: '',
        content: [],
    },
    {
        bgColor: '#fbf6f7',
        title: 'Безопасность',
        description:
            'Как защитить учетную запись, безопастность данных, статистика авторизаций',
        icon: SecurityIcon,
        url: '',
        content: [],
    },
    {
        bgColor: '#f0f3f6',
        title: 'Работа с системой',
        description: 'Статистика, возвраты, счета, выгрузка данных',
        icon: SystemIcon,
        url: navRoutes.support.system,
        content: [...Object.values(texts.system)],
    },
];
