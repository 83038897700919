import React, { FC, memo, TextareaHTMLAttributes } from 'react';
import { classNames } from 'src/shared/lib';
import styles from './textarea.module.scss';

type TextareaTheme = 'modal-input';

type HTMLTextareaProps = Omit<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    'readOnly'
>;

interface TextareaProps extends HTMLTextareaProps {
    className?: string;
    theme?: TextareaTheme;
    readonly?: boolean;
}

export const Textarea: FC<TextareaProps> = memo((props) => {
    const { className, theme = 'modal-input', readonly, ...otherProps } = props;

    return (
        <textarea
            readOnly={readonly}
            className={classNames(
                styles.textarea,
                { [styles.readonly]: readonly },
                [className, styles[theme]]
            )}
            {...otherProps}
        />
    );
});
