import React, { Component } from 'react';
import './settings-page.scss';
import MerchantUserListPage from '../MerchantUserListPage';
import TerminalsPage from '../TerminalsPage';
import UserConfigPage from '../UserConfigPage';
import { Route, Switch } from 'react-router-dom';
import { navRoutes } from 'src/app/config';

class SettingsPage extends Component {
    render() {
        return (
            <div className="settings-page">
                <Switch>
                    <Route
                        path={navRoutes.settingsUsers}
                        component={() => <MerchantUserListPage />}
                    />
                    <Route
                        path={navRoutes.settingsTerminals}
                        component={() => <TerminalsPage />}
                    />
                    <Route
                        path={navRoutes.settingsUser}
                        component={() => <UserConfigPage />}
                    />
                </Switch>
            </div>
        );
    }
}

export default SettingsPage;
