import * as type from '../constants';

const initState = {
    companyRoles: [],
};

export default function settings(state = initState, action) {
    switch (action.type) {
        case type.GET_COMPANY_ROLES:
            return {
                ...state,
            };
        case type.GET_COMPANY_ROLES_SUCCESS:
            return {
                ...state,
                companyRoles: action.payload,
            };
        case type.GET_COMPANY_ROLES_FAILED:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}
