import React from 'react';

type Props = {
    color?: string
}

export const ArrowRight: React.FC<Props> = (props) => {
    const { color = '#ffffff' } = props;

    return (
        <svg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M11.5098 5.85254C11.5098 5.59733 11.4163 5.38086 11.2295 5.20312L7.01855 0.985352C6.91829 0.894206 6.81576 0.825846 6.71094 0.780273C6.60612 0.730143 6.49902 0.705078 6.38965 0.705078C6.12988 0.705078 5.91797 0.784831 5.75391 0.944336C5.5944 1.10384 5.51465 1.30436 5.51465 1.5459C5.51465 1.67806 5.53971 1.79655 5.58984 1.90137C5.64453 2.00618 5.71061 2.09733 5.78809 2.1748L7.2373 3.6377L9.43848 5.6543L9.80078 5.10742L7.35352 4.97754H0.916016C0.642578 4.97754 0.421549 5.05957 0.25293 5.22363C0.0843099 5.38314 0 5.59277 0 5.85254C0 6.11686 0.0843099 6.33105 0.25293 6.49512C0.421549 6.65918 0.642578 6.74121 0.916016 6.74121H7.35352L9.80078 6.60449L9.43848 6.05762L7.2373 8.07422L5.78809 9.53027C5.71061 9.60775 5.64453 9.70117 5.58984 9.81055C5.53971 9.91536 5.51465 10.0339 5.51465 10.166C5.51465 10.403 5.5944 10.6012 5.75391 10.7607C5.91797 10.9202 6.12988 11 6.38965 11C6.61296 11 6.81803 10.9089 7.00488 10.7266L11.2295 6.50879C11.4163 6.33105 11.5098 6.1123 11.5098 5.85254Z'
                fill={color} />
        </svg>
    );
};