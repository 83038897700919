import React, { FC } from 'react';
import { BellIcon } from 'src/shared/ui/media/navigation-icons/bell-icon';
import { Badge } from 'src/shared/ui';
import styles from './feedback-menu.module.scss';

export const FeedbackMenu: FC = () => {
    const value = 0;

    return (
        <div className={styles['wrapper']}>
            {BellIcon}
            {!!value && <Badge value={value} warning />}
        </div>
    );
};
