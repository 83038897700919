import React from 'react';
import { Footer } from '../page-layout/footer';

import styles from './public-page-layout.module.scss';

interface Props {
    footerNavigation: React.ReactNode;
}

export const PublicPageLayout: React.FC<Props> = ({
    children,
    footerNavigation,
}) => {
    return (
        <article id="modal-element-scroll" className={styles['wrapper']}>
            <div className={styles['page']}>
                <main className={styles['main']}>
                    <div className={styles['content']}>{children}</div>
                </main>
                <div className={styles['footer']}>
                    <Footer theme="dark">{footerNavigation}</Footer>
                </div>
            </div>
        </article>
    );
};
