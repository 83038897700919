import * as yup from 'yup';

export const validationScheme = yup
    .object()
    .shape({
        email: yup
            .string()
            .trim()
            .lowercase()
            .email('Некорректный email')
            .required('Обязательное поле'),
    })
    .required();
