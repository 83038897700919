import styles from './content.module.scss';
import { SearchField } from '../../../../shared';
import React from 'react';

export const Search = ({ value, onChange }) => {
    return (
        <>
            <h2 className={styles['search-header']}>
                Чем мы можем Вам помочь?
            </h2>
            <SearchField
                value={value}
                onChange={onChange}
                placeholder="Для быстрого поиска введите свой запрос"
            />
        </>
    );
};
