import React from 'react';
import { uniquePasswordValidator } from 'src/shared/lib';

const symbolsValidator =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=*]).*$/;
const lettersValidator = /([a-z].*[A-Z])|([A-Z].*[a-z])/;
const lengthValidator = /^(?=\S+$).{8,}$/;

const securityLevels = [
    {
        descriptionLabel: <>Длина должна быть минимум 8 символов</>,
        validator: lengthValidator,
    },
    {
        descriptionLabel: (
            <>
                Должны быть строчные и заглавные латинские буквы, цифры и
                спецсимволы
            </>
        ),
        validator: lettersValidator,
    },
    {
        descriptionLabel: <>Допустимые спецсимволы @ # $ % ^ & * +</>,
        validator: symbolsValidator,
    },
];

const oldPasswordSecurityLevel = (currentPassword: string) => ({
    descriptionLabel: (
        <React.Fragment>
            Должен отличаться от предыдущего не менее чем в 4-х позициях
        </React.Fragment>
    ),
    validator: (value) => uniquePasswordValidator(value, currentPassword),
});

export {
    securityLevels,
    lengthValidator,
    symbolsValidator,
    lettersValidator,
    oldPasswordSecurityLevel,
};
