import React from 'react';

export const AccessIcon = (
    <svg
        width="58"
        height="32"
        viewBox="0 0 58 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M57.0596 14.6289C57.7676 15.4346 57.8652 16.5576 57.084 17.3389L49.9551 24.4678C49.1738 25.249 48.002 25.2002 47.2207 24.4434L43.4365 20.6348L38.0166 26.0791C37.2598 26.8359 36.0635 26.8115 35.2822 26.0547L30.082 20.8789C27.1279 27.3975 21.9766 31.0596 15.8486 31.0596C7.49902 31.0596 0.785155 24.3213 0.785155 15.9717C0.785156 7.59766 7.47461 0.883789 15.8486 0.883789C21.9521 0.883789 27.3965 4.44824 29.7646 10.0391L51.6641 10.0391C52.3477 10.0391 53.1289 10.2588 53.666 10.8691L57.0596 14.6289ZM53.9346 15.9717L51.2002 13.2373L27.4697 13.2373C26.1514 7.81738 21.4395 4.05762 15.8486 4.05762C9.28125 4.05762 3.9834 9.37988 3.9834 15.9717C3.9834 22.5635 9.28125 27.8613 15.8486 27.8613C21.3906 27.8613 26.1514 24.0771 27.7139 17.9736L31.7422 17.9736L36.6738 22.8809L41.7764 17.7295L45.0479 17.7295L48.5635 21.2695L53.9346 15.9717ZM15.9463 15.9717C15.9463 18.2422 14.0908 20.1221 11.8203 20.1221C9.52539 20.1221 7.66992 18.2422 7.66992 15.9717C7.66992 13.6768 9.50098 11.8457 11.8203 11.8457C14.0908 11.8457 15.9463 13.7012 15.9463 15.9717Z"
            fill="#002E5A"
        />
    </svg>
);
