import React, { CSSProperties, FC, MouseEventHandler } from 'react';
import styles from './check-box.module.scss';

/**
 *
 * @types
 * @Component CheckBox
 *
 */

type Props = {
    color?: string;
    onChange?: any;
    disabled?: boolean;
    checked?: boolean;
    onClick?: MouseEventHandler<any>;
    className?: string;
    style?: CSSProperties;
    state?: string;
};

const CheckBox: FC<Props> = ({ onChange, checked, state = undefined }) => {
    switch (state) {
        case 'none':
            return <span className="mdi mdi-check-box-outline-blank" />;
        case 'half':
            return <span className="mdi mdi-indeterminate-check-box" />;
        case 'full':
            return <span className="mdi mdi-check-box" />;
        default:
            return (
                <div
                    className={
                        checked
                            ? styles['checkbox_checked']
                            : styles['checkbox']
                    }
                    onClick={() =>
                        onChange !== undefined ? onChange(checked) : null
                    }
                />
            );
    }
};

export { CheckBox };
