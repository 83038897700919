import React, { FC } from 'react';

import styles from './layout.module.scss';

interface Props {
    topContent?: React.ReactNode;
    links: React.ReactNode;
    title?: string;
}

export const Layout: FC<Props> = ({ links, title, topContent, children }) => {
    return (
        <div className={styles['layout']}>
            {topContent && (
                <div className={styles['top-content']}>{topContent}</div>
            )}
            <div className={styles['content-wrapper']}>
                <div className={styles['content']}>
                    {title && <h2 className={styles['title']}>{title}</h2>}
                    {children}
                </div>
                <div className={styles['links']}>{links}</div>
            </div>
        </div>
    );
};
