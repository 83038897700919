import React from 'react';
import { Portal } from 'src/shared/ui';
import styles from './styles.module.scss';

interface Props {
    children: React.ReactNode;
    top: number;
    left: number;
    isDisplayed: boolean;
    transformX?: string;
    transformY?: string;
}

export const FixedPositionTooltip = ({
    children,
    top,
    left,
    transformX,
    transformY,
    isDisplayed,
}: Props) => {
    return isDisplayed ? (
        <Portal>
            <div
                style={{
                    top,
                    left,
                    transform: `translate(${transformX}, ${transformY})`,
                }}
                className={styles['wrapper']}
            >
                {children}
            </div>
        </Portal>
    ) : (
        <></>
    );
};
