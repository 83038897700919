import React, { useEffect, useState } from 'react';
import {
    Modal,
    CheckBox,
    ModalField,
    Input,
    NewSelect,
    SelectOption,
    InputPassword,
} from 'src/shared';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { URL } from 'src/app/config';
import { get, log, post, statusNameMapper } from 'src/shared/lib';
import { schema } from '../lib/validateScheme';
import { defaultValues } from '../model/consts';
import { FfdType, StateType } from '../model/types';
import { createNotification } from '../../../redux/actions/notification';
import { useDispatch } from 'react-redux';
import CashBoxWithRoles from '../../../pages/PlatformCashBoxPage/CashBoxWithRoles';
interface AddMerchantCashboxProps {
    company: {
        id: string;
        inn: string;
    };
    onClose: () => void;
    onUpdate: () => void;
    isOpen: boolean;
}

const statuses: SelectOption[] = [
    { value: 'Активно', content: 'Активно' },
    { value: 'Выключено', content: 'Выключено' },
];

/**
 * @type {boolean}
 * В соответствии с задачей DPS-359 установить возможность
 * включения/выключения Мир/Обновленного вида создания кассы
 */
const IS_CASH_BOX = true;

export const AddMerchantCashbox = (props: AddMerchantCashboxProps) => {
    const { isOpen, onUpdate, company, onClose } = props;

    const dispatch = useDispatch();

    const { register, handleSubmit, control, formState, setValue, reset } =
        useForm<StateType>({
            defaultValues,
            resolver: yupResolver(schema),
        });

    const [cashboxAvailableFfdList, setCashboxAvailableFfdList] = useState<
        FfdType[]
    >([]);
    const [ffdVersion, setFfdVersion] = useState('');
    const [isClosed, setIsClosed] = useState(false);
    const [role, setRole] = useState<Array<'E_COMMERCE' | 'PLAT_FORM'>>([
        'E_COMMERCE',
    ]);

    useEffect(() => {
        reset();
        if (!isOpen) setIsClosed(false);
    }, [isOpen, reset]);

    useEffect(() => {
        get(URL.getCashboxAvailableFfd)
            .then((r) => {
                if (r.data.payload) {
                    setCashboxAvailableFfdList(r.data.payload);
                    setFfdVersion(r.data.payload[0].version);
                }
            })
            .catch((r) => log(r));
    }, [setValue]);

    const submit = (values: StateType) => {
        const {
            cashboxStatus,
            cashboxBlocked,
            groupCode,
            address,
            cashboxLogin,
            cashboxPassword,
        } = values;
        const submitParams = {
            login: cashboxLogin,
            ffdVersion,
            password: cashboxPassword,
            status: statusNameMapper(cashboxStatus, 'en'),
            blocked: cashboxBlocked,
            innCompany: company.inn,
            groupCode,
            address,
        };
        if (IS_CASH_BOX) {
            submitParams['role'] = role;
        }
        post(URL.addCashbox + company.id, submitParams)
            .then(() => {
                onClose();
                onUpdate();
                dispatch(
                    createNotification(
                        {
                            title: 'Создание кассы',
                            message: 'Касса успешно создана',
                        },
                        'success'
                    )
                );
            })
            .catch((r) => {
                log(r);
                onClose();
            });
        setIsClosed(true);
    };

    return (
        <Modal
            isForm
            header="Добавить кассу"
            isOpen={isOpen}
            isClosed={isClosed}
            actionButton="Добавить"
            onAction={handleSubmit(submit)}
            onClose={onClose}
            autoComplete="off"
        >
            <ModalField label="ФФД кассы:">
                <NewSelect
                    value={ffdVersion}
                    onChange={(value) => setFfdVersion(value)}
                    idScrollElement="modal-element-scroll"
                    options={cashboxAvailableFfdList.map((ffd) => ({
                        value: ffd.version,
                        content: ffd.nameFfd,
                    }))}
                />
            </ModalField>
            <ModalField label="Статус:">
                <Controller
                    name="cashboxStatus"
                    control={control}
                    render={({ field }) => (
                        <NewSelect
                            {...field}
                            onChange={(value) => {
                                field.onChange(value);
                            }}
                            idScrollElement="modal-element-scroll"
                            options={statuses}
                        />
                    )}
                />
            </ModalField>
            <ModalField label="Блокировка:">
                <Controller
                    name="cashboxBlocked"
                    control={control}
                    render={({ field }) => (
                        <CheckBox
                            onChange={(value) => {
                                field.onChange(!value);
                            }}
                            checked={field.value}
                        />
                    )}
                />
            </ModalField>
            <ModalField
                label="Код группы:"
                errorMessage={formState.errors.groupCode?.message}
            >
                <Input
                    {...register('groupCode')}
                    background
                    placeholder="Введите Код группы"
                />
            </ModalField>
            <ModalField
                label="Адрес:"
                errorMessage={formState.errors.address?.message}
            >
                <Input
                    {...register('address')}
                    background
                    placeholder="Введите Адрес"
                />
            </ModalField>
            <ModalField
                label="Логин кассы:"
                errorMessage={formState.errors.cashboxLogin?.message}
            >
                <Input
                    {...register('cashboxLogin')}
                    background
                    placeholder="Введите Логин кассы"
                    autoComplete="new-login"
                />
            </ModalField>
            <ModalField
                label="Пароль кассы:"
                errorMessage={formState.errors.cashboxPassword?.message}
            >
                <InputPassword
                    {...register('cashboxPassword')}
                    background
                    placeholder="Введите Пароль кассы"
                    autoComplete="new-password"
                />
            </ModalField>
            {IS_CASH_BOX && (
                <CashBoxWithRoles
                    isPlatform={false}
                    onChange={(fields) => {
                        setRole(fields.role);
                    }}
                />
            )}
        </Modal>
    );
};
