import React, { useState, useLayoutEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Dropdown } from 'src/shared/ui';
import { UserDropdownIcon } from 'src/shared/ui/media';
import { FeedbackMenu } from 'src/shared/ui';
import styles from './header.module.scss';
import { getAppTitle } from 'src/shared/lib';
import { navRoutes } from 'src/app/config';
import moment from 'moment';

interface Props {
    pageTitle: string | React.ReactNode;
    userName: string;
    userDropdownNavigation: React.ReactNode;
}

export const Header: React.FC<Props> = ({
    pageTitle,
    userDropdownNavigation: UserDropdownNavigation,
    userName,
}) => {
    const BACKWARDS_BUTTON_MAP = React.useMemo(
        () => ({
            'operations-sbp': {
                buttonText: 'Список операций СБП',
                getTitleText: (id: number) => `Операция ${id}`,
                backUrl: navRoutes.operationsSBP,
            },
            operations: {
                buttonText: 'Список операций',
                getTitleText: (id: number) => `Операция ${id}`,
                backUrl: navRoutes.operations,
            },
            payment: {
                buttonText: 'Онлайн-заказы',
                backUrl: navRoutes.payments,
            },
            settlement: {
                buttonText: 'Список возмещений',
                getTitleText: (date: string) =>
                    'Возмещения за ' +
                    moment(date, 'YYYYMMDD').format('DD.MM.YYYY'),
                backUrl: navRoutes.settlement,
            },
        }),
        []
    );

    const [operationId, setOperationId] = useState<number>(0);
    const location = useLocation();
    const history = useHistory();

    // Для страниц Операции, Операции СБП, Онлайн заказы, Возмещения возможен переход обратно к таблицам.
    const currentDetailsPage = [
        /operations\/\d+/,
        /operations-sbp\/\d+/,
        /payment\/\d+/,
        /settlement\/\d+/,
    ]
        .filter((item) => location.pathname.search(item) + 1)[0]
        ?.toString()
        .match(/[a-zA-Z|-]+/)?.[0];

    useLayoutEffect(() => {
        setOperationId(+location.pathname.replace(/\D/g, ''));
    }, [location.pathname]);

    const isBankApp = getAppTitle() === 'bank';

    const goBack = () => {
        if (location.key) {
            history.goBack();
        } else {
            currentDetailsPage &&
                history.push(BACKWARDS_BUTTON_MAP[currentDetailsPage].backUrl);
        }
    };

    return (
        <header className={styles['header']}>
            {currentDetailsPage ? (
                <div className={styles['page-title']}>
                    {isBankApp ? (
                        <button
                            onClick={goBack}
                            className={styles['title-button']}
                        >
                            Вернуться
                        </button>
                    ) : (
                        <Link
                            to={{
                                pathname:
                                    '/' +
                                    location.pathname.replace(/[/\d]/g, ''),
                            }}
                            className={styles['title-button']}
                        >
                            {
                                BACKWARDS_BUTTON_MAP[currentDetailsPage]
                                    .buttonText
                            }
                        </Link>
                    )}
                    {BACKWARDS_BUTTON_MAP[currentDetailsPage]?.getTitleText?.(
                        operationId
                    ) ?? ''}
                </div>
            ) : (
                <div className={styles['page-title']}>{pageTitle}</div>
            )}

            <div className={styles['menu']}>
                <FeedbackMenu />
                <Dropdown
                    circular
                    header={<UserDropdownHeader userName={userName} />}
                >
                    {UserDropdownNavigation}
                </Dropdown>
            </div>
        </header>
    );
};

interface UserDropdownHeaderProps {
    userName: string;
}

const UserDropdownHeader: React.FC<UserDropdownHeaderProps> = ({
    userName,
}) => {
    return (
        <div className={styles['user-dropdown-header']}>
            {UserDropdownIcon}
            <span className={styles['user-dropdown-header__user-name']}>
                {userName}
            </span>
        </div>
    );
};
