import { Moment } from 'moment';
import React, { FC } from 'react';

import styles from './date-text-input.module.scss';
import { isIphoneSafari } from './utils';
import { useDateTextInput } from './use-date-text-input';

export interface DateTextInputProps {
    setDate: (value: Moment | null) => void;
    startDate: Moment | null;
    placeholder: string;
}

export const DateTextInput: FC<DateTextInputProps> = (props) => {
    const { placeholder } = props;
    const { handleBlur, onChange, onKeyDown, value } = useDateTextInput(props);

    const inputComponent = (
        <input
            disabled={isIphoneSafari()}
            onClick={(event) => event.preventDefault()}
            onChange={onChange}
            value={value}
            type={'date'}
            min="0001-01-01"
            max="9999-01-01"
            placeholder={placeholder}
            className={[
                styles['date-text-input'],
                // safari fix
                isIphoneSafari() && styles['date-text-input__safari-fix'],
            ].join(' ')}
            onBlur={handleBlur}
            onKeyDown={onKeyDown}
        />
    );

    if (isIphoneSafari()) {
        return (
            <div className={styles['container']}>
                <div className={styles['container__hider']} />
                {inputComponent}
            </div>
        );
    }

    return inputComponent;
};
