import { SelectOption } from 'src/shared/ui';

export const submitMessage = {
    title: 'Возврат',
    message: 'Заявка на возврат принята',
};

export const revertOptions: SelectOption[] = [
    { value: 'full', content: 'Полный возврат' },
    { value: 'partial', content: 'Частичный возврат' },
];
