import React, { FC } from 'react';
import {
    LightTable,
    LightTableRow,
    Title,
    LinkIcon,
    InfoIcon,
} from 'src/shared/ui';
import { formatCurrency } from '../../lib';
import { Operation } from '../../model';
import styles from './operation-description.module.scss';

interface OperationDescriptionProps {
    data: Operation;
    isSBP: boolean;
}

export const OperationDescription: FC<OperationDescriptionProps> = ({
    data,
    children,
}) => {
    const {
        orderId,
        id,
        terminalId,
        mcc,
        mccDescription,
        fee,
        settlement,
        orderDescription,
        additionalData,
        tips,
    } = data;

    return (
        <div className={styles['wrapper']}>
            <LightTable borderTop>
                <LightTableRow firstTD="ID операции" lastTD={id} />
                <LightTableRow firstTD="Номер заказа" lastTD={orderId} />
                <LightTableRow firstTD="ID терминала" lastTD={terminalId} />
                <LightTableRow
                    firstTD="Торговая категория"
                    lastTD={`${mcc} ${mccDescription || ''}`}
                />
                <LightTableRow
                    firstTD="Комиссия"
                    lastTD={fee && formatCurrency(fee)}
                    boldLastTD
                />
                <LightTableRow
                    firstTD="Возмещение"
                    lastTD={settlement && formatCurrency(settlement)}
                    boldLastTD
                />
                <LightTableRow
                    firstTD="Чаевые"
                    lastTD={tips && formatCurrency(tips)}
                    boldLastTD
                />
            </LightTable>
            <div className={styles['add-info']}>
                {orderDescription && (
                    <Title
                        icon={InfoIcon}
                        className={styles['add-info__item']}
                        subtitle={orderDescription}
                    >
                        Описание заказа
                    </Title>
                )}
                {additionalData && (
                    <Title
                        icon={InfoIcon}
                        className={styles['add-info__item']}
                        subtitle={additionalData}
                    >
                        Дополнительные данные
                    </Title>
                )}
                {children && (
                    <>
                        <Title
                            icon={LinkIcon}
                            className={`${styles['add-info__item']} ${styles['links']}`}
                        >
                            Связанные операции
                        </Title>
                        {children}
                    </>
                )}
            </div>
        </div>
    );
};
