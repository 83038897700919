import React from 'react';
import styles from './styles.module.scss';
import { classNames } from 'src/shared/lib';

enum Variant {
    BACKWARD = 'backward',
    FORWARD = 'forward',
}

const ARROW_VARIANT_TO_STYLE_MAP: Record<Variant, string> = {
    [Variant.BACKWARD]: styles['backward'],
    [Variant.FORWARD]: styles['forward'],
};

const ACTIVE_COLOR = '#002E5A';
const DISABLED_COLOR = '#E0E0E0';

interface Props {
    variant: Variant;
    onClick: () => void;
    active: boolean;
}

export const PaginatorArrow = ({ variant, active, onClick }: Props) => {
    return (
        <svg
            onClick={() => active && onClick()}
            width="17"
            height="15"
            viewBox="0 0 17 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classNames(ARROW_VARIANT_TO_STYLE_MAP[variant], {
                [styles['wrapper']]: true,
                [styles['disabled']]: !active,
            })}
        >
            <path
                d="M16.168 7.5C16.168 7.17188 16.0479 6.89355 15.8076 6.66504L10.3936 1.24219C10.2646 1.125 10.1328 1.03711 9.99805 0.978516C9.86328 0.914062 9.72559 0.881836 9.58496 0.881836C9.25098 0.881836 8.97852 0.984375 8.76758 1.18945C8.5625 1.39453 8.45996 1.65234 8.45996 1.96289C8.45996 2.13281 8.49219 2.28516 8.55664 2.41992C8.62695 2.55469 8.71191 2.67188 8.81152 2.77148L10.6748 4.65234L13.5049 7.24512L13.9707 6.54199L10.8242 6.375H1.26172C0.910156 6.375 0.625977 6.48047 0.40918 6.69141C0.192383 6.89648 0.0839844 7.16602 0.0839844 7.5C0.0839844 7.83984 0.192383 8.11523 0.40918 8.32617C0.625977 8.53711 0.910156 8.64258 1.26172 8.64258H10.8242L13.9707 8.4668L13.5049 7.76367L10.6748 10.3564L8.81152 12.2285C8.71191 12.3281 8.62695 12.4482 8.55664 12.5889C8.49219 12.7236 8.45996 12.876 8.45996 13.0459C8.45996 13.3506 8.5625 13.6055 8.76758 13.8105C8.97852 14.0156 9.25098 14.1182 9.58496 14.1182C9.87207 14.1182 10.1357 14.001 10.376 13.7666L15.8076 8.34375C16.0479 8.11523 16.168 7.83398 16.168 7.5Z"
                fill={active ? ACTIVE_COLOR : DISABLED_COLOR}
            />
        </svg>
    );
};

PaginatorArrow.variant = Variant;
