import moment, { Moment } from 'moment';
import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import { Input } from '../input';
import styles from './date-picker.module.scss';
import { isMobile } from 'react-device-detect';

interface DatePickerProps {
    selected: Moment | null;
    onChange: (data: Moment | null) => void;
}

export const Datepicker: FC<DatePickerProps> = (props) => {
    const { selected, onChange, ...otherProps } = props;

    const changeDate = (data: Moment | null) => {
        onChange(data);
    };

    return (
        <DatePicker
            {...otherProps}
            readOnly
            selected={selected}
            onChange={changeDate}
            startDate={moment(new Date())}
            customInput={<Input background readonly={isMobile} />}
            calendarClassName={styles.modal}
        />
    );
};
