import React, { FC } from 'react';
import styles from './details-item.module.scss';

interface DetailsItemProps {
    icon?: React.ReactNode;
    title: string;
    subtitle: string;
}

export const DetailsItem: FC<DetailsItemProps> = ({
    icon,
    title,
    subtitle,
}) => {
    return (
        <>
            {title && subtitle && (
                <div className={styles.wrapper}>
                    {icon && <div className={styles.icon}>{icon}</div>}
                    <div className={styles.title}>{title}</div>
                    <div>{subtitle}</div>
                </div>
            )}
        </>
    );
};
