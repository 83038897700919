import * as type from '../constants';
import {
    localLoad,
    localLoadSerialize,
    localSave,
    localSaveSerialize,
} from '../../shared/lib/localStorage';

const initState = {
    logged: !!localLoad('logged'),
    roles: localLoadSerialize('roles', []),
    assignedCompanies: localLoadSerialize('assignedCompanies', []),
    companyRoles: localLoadSerialize('companyRoles', []),
    fullName: localLoad('fullName', 'Пользователь'),
    id: localLoad('id'),
    cellPhone: localLoad('cellPhone'),
    email: localLoad('email'),
    companyId: localLoad('companyId'),
    companyName: localLoad('companyName'),
    submitted: false,
};

export default function auth(state = initState, action) {
    switch (action.type) {
        case type.LOG_IN:
            return {
                ...state,
                submitted: true,
            };
        case type.LOG_IN_SUCCESS:
            updateStorage(true);
            return {
                ...state,
            };
        case type.LOG_IN_FAILED:
            return {
                ...state,
                logged: false,
                submitted: false,
            };
        case type.ACTIVATE_USER_SUCCESS:
            return {
                ...state,
                submitted: false,
            };
        case type.ACTIVATE_USER_FAILED:
            return {
                ...state,
                submitted: false,
            };
        case type.FORGOT_PASSWORD_USER_SUCCESS:
            return {
                ...state,
                submitted: false,
            };
        case type.FORGOT_PASSWORD_USER_FAILED:
            return {
                ...state,
                submitted: false,
            };
        case type.CHANGE_PASSWORD_USER_EXPIRED:
            localSave('email', action.payload);
            return {
                ...state,
                email: action.payload,
            };
        case type.GET_USER_AFTER_LOGIN_ROLES_SUCCESS:
            localSaveSerialize('roles', action.payload.roles);
            localSaveSerialize(
                'assignedCompanies',
                action.payload.assignedCompanies
            );
            localSaveSerialize('companyRoles', action.payload.companyRoles);
            localSave('fullName', action.payload.fullName);
            localSave('id', action.payload.id);
            localSave('cellPhone', action.payload.cellPhone);
            localSave('email', action.payload.email);
            localSave('companyId', action.payload.companyId);
            localSave('companyName', action.payload.companyName);
            return {
                ...state,
                logged: true,
                submitted: false,
                id: action.payload.id,
                cellPhone: action.payload.cellPhone,
                email: action.payload.email,
                roles: action.payload.roles,
                assignedCompanies: action.payload.assignedCompanies,
                fullName: action.payload.fullName,
                companyId: action.payload.companyId,
                companyName: action.payload.companyName,
                companyRoles: action.payload.companyRoles,
            };
        case type.LOG_OUT_AND_CLEAR_LOCAL:
            cleanStorage();
            return {
                ...state,
                logged: false,
            };
        case type.CHANGE_PASSWORD:
            return {
                ...state,
                submitted: true,
            };
        case type.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                submitted: false,
            };
        case type.CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                error: action.error,
                submitted: false,
            };
        default:
            return state;
    }
}

function updateStorage(logged) {
    localSave('logged', logged);
}

function cleanStorage() {
    localStorage.clear();
}
