import React, { FC, useState } from 'react';
import { Redirect } from 'react-router-dom';

import styles from './section-card.module.scss';

interface Props {
    icon?: React.ReactNode;
    bgColor: string;
    title: string;
    description?: string;
    url: string;
}

export const SectionCard: FC<Props> = ({
    bgColor,
    title,
    description,
    icon,
    url,
}) => {
    const [redirect, setRedirect] = useState<string>();
    return (
        <button
            onClick={() => setRedirect(url)}
            className={styles['section-card']}
            style={{ backgroundColor: bgColor }}
        >
            {redirect && <Redirect to={redirect} />}
            <h2 className={styles['title']}>{title}</h2>
            <p className={styles['description']}>{description}</p>
            <div className={styles['icon']}>{icon}</div>
        </button>
    );
};
