import Notifications from 'react-notification-system-redux';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { debounceTime } from 'rxjs/operators';

const subject = new ReplaySubject(1);

subject.pipe(debounceTime(300)).subscribe((event) => {
    if (event.template.autoDismiss === undefined) {
        event.template.autoDismiss = 5;
    }
    event.dispatch(Notifications.removeAll());
    event.dispatch(Notifications.show(event.template, event.type));
});

export function createNotification(template, type) {
    return (dispatch) => subject.next({ dispatch, template, type });
}
