import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

import styles from './button.module.scss';

interface Props {
    fluid?: boolean;
    disabled?: boolean;
    loading?: boolean;
    outline?: boolean;
    clear?: boolean;
    // tiny?: boolean;
    size?: 'xs' | 'tiny' | 'smedium' | 'medium';
    color?: 'gray';
}

type ReactButtonProps = DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>;

export const Button: React.FC<Props & ReactButtonProps> = ({
    children,
    fluid,
    disabled,
    loading,
    clear,
    size,
    outline,
    color,
    ...rest
}) => {
    const computedClassName = [
        styles['button'],
        fluid ? styles['button--fluid'] : '',
        disabled ? styles['button--disabled'] : '',
        loading ? styles['button--loading'] : '',
        clear ? styles['button--clear'] : '',
        size === 'xs' ? styles['button--xs'] : '',
        size === 'tiny' ? styles['button--tiny'] : '',
        size === 'smedium' ? styles['button--smedium'] : '',
        size === 'medium' ? styles['button--medium'] : '',
        color === 'gray' ? styles['button--gray'] : '',
        outline ? styles['button--outline'] : '',
        rest.className,
    ].join(' ');

    return (
        <button disabled={disabled} {...rest} className={computedClassName}>
            {loading ? (
                <div className={styles['loading-icon']}></div>
            ) : (
                children
            )}
        </button>
    );
};
