import React, { Component } from 'react';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { ReplaySubject } from 'rxjs';
import { URL, BankRoles } from 'src/app/config';

import AwareTable from 'src/components/AwareTable';

import {
    isAdmin,
    isPermit,
    log,
    roleNameMapper,
    validateEmail,
    get,
    post,
} from 'src/shared/lib';
import {
    Button,
    MultipleSelect,
    CheckBox,
    OverflowContainer,
    Paginator,
    AddIcon,
} from 'src/shared/ui';
import { BankUserFilter } from './bank-user-filter';

import './bank-user-list-page.scss';
import { setNeedsUpdate } from 'src/redux/actions/table';
import { createNotification } from 'src/redux/actions/notification';

const bankUserListTableName = 'BankUserList-Table';

class BankUserListPage extends Component {
    filterSubject = new ReplaySubject(1);

    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            bankBlocked: undefined,
            adminChecked: undefined,
            open_add_user: false,
            open_update_user: false,
            temporaryBlocked: false,
            fullName: '',
            email: '',
            emailIsValid: true,
            roles: [],
        };
        this.toggle_add_user = this.toggle_add_user.bind(this);
        this.toggle_update_user = this.toggle_update_user.bind(this);
        this.createBankUser = this.createBankUser.bind(this);
        this.permit = isPermit.bind(this);
        this.changeAdminChecked = this.changeAdminChecked.bind(this);
    }

    toggle_add_user() {
        this.setState({ fullName: '', email: '', roles: [] });
        this.setState({ open_add_user: !this.state.open_add_user });
    }

    toggle_update_user() {
        this.setState({ open_update_user: !this.state.open_update_user });
    }

    createBankUser() {
        post(URL.bankCreateUsersUrl, {
            fullName: this.state.fullName,
            email: this.state.email,
            roles: this.state.roles,
        })
            .then((r) => {
                this.setState({ open_add_user: false });
                this.props.dispatch(
                    setNeedsUpdate(bankUserListTableName, true)
                );
                this.props.dispatch(
                    createNotification(
                        {
                            title: 'Создание пользователя',
                            message: 'Пользователь успешно создан',
                        },
                        'success'
                    )
                );
            })
            .catch((r) => {
                log(r);
                this.setState({ open_add_user: false });
            });
    }

    updateBankUser(id) {
        post(URL.bankUpdateUsersUrl + id, {
            fullName: this.state.fullName,
            email: this.state.email,
            bankBlocked: this.state.bankBlocked,
            roles: this.state.adminChecked
                ? [BankRoles.ADMIN]
                : isAdmin(this.state.roles)
                ? this.state.roles.filter((role) => role !== 'ADMIN')
                : this.state.roles,
        })
            .then((r) => {
                this.setState({ open_update_user: false });
                this.props.dispatch(
                    setNeedsUpdate(bankUserListTableName, true)
                );
                this.props.dispatch(
                    createNotification(
                        {
                            title: 'Редактирование пользователя',
                            message: 'Пользователь успешно изменен',
                        },
                        'success'
                    )
                );
            })
            .catch((r) => {
                log(r);
                this.setState({ open_update_user: false });
            });
    }

    getUserInfo(id) {
        get(URL.getBankUserInfo + id)
            .then((r) => {
                let {
                    id,
                    email,
                    fullName,
                    bankBlocked,
                    temporaryBlocked,
                    roles,
                } = r.data.payload;

                this.setState(
                    {
                        id,
                        bankBlocked,
                        temporaryBlocked,
                        fullName,
                        email,
                        roles,
                    },
                    () => {
                        this.setState({
                            adminChecked: !!isAdmin(this.state.roles),
                        });
                    }
                );
                this.setState({ open_update_user: true });
            })
            .catch((r) => {
                this.setState({ open_update_user: false });
                log(r);
            });
    }
    returnRoleAsObject(role) {
        return role.map((role) => {
            return {
                value: role,
                name: roleNameMapper(role),
            };
        });
    }

    changeAdminChecked() {
        this.setState({
            roles: !this.state.adminChecked ? [BankRoles.ADMIN] : [],
            adminChecked: !this.state.adminChecked,
        });
    }

    render() {
        let bankStandartRoles = this.returnRoleAsObject(
            Object.values(BankRoles)
        );

        return (
            <div className="bank-user-list-page">
                <BankUserFilter pageTitle={bankUserListTableName} />
                <Paginator name={bankUserListTableName}>
                    <OverflowContainer
                        icon={
                            <AddIcon
                                customIcon="mdi-group-add"
                                onClick={this.toggle_add_user}
                            />
                        }
                    >
                        <AwareTable
                            isStoreTable
                            tableName={bankUserListTableName}
                            url={URL.bankUserUrl}
                            onItemClick={(e) => this.getUserInfo(e.ID)}
                        />
                    </OverflowContainer>
                </Paginator>
                <Modal
                    isOpen={this.state.open_add_user}
                    toggle={this.toggle_add_user}
                >
                    <ModalHeader toggle={this.toggle_add_user}>
                        Добавить пользователя
                    </ModalHeader>
                    <ModalBody className={'add-bank-user-modal'}>
                        <div className="user-add-form">
                            <Input
                                onChange={(fio) =>
                                    this.setState({
                                        fullName: fio.target.value,
                                    })
                                }
                                value={this.state.fullName}
                                placeholder={'Введите ФИО'}
                                className="modal-input"
                            />
                            {this.state.emailIsValid ||
                            !this.state.email.length ? null : (
                                <span className="error-msg">
                                    Неправильный Email
                                </span>
                            )}
                            <Input
                                onChange={(email) => {
                                    this.setState(
                                        { email: email.target.value },
                                        () => {
                                            this.setState({
                                                emailIsValid: validateEmail(
                                                    this.state.email
                                                ),
                                            });
                                        }
                                    );
                                }}
                                value={this.state.email}
                                placeholder={'Введите email'}
                                className="modal-input"
                                type="email"
                            />
                            {isAdmin(this.props.roles) ? (
                                <div className="user-add-form__info">
                                    <span>Администратор:</span>
                                    <span>
                                        <CheckBox
                                            onChange={this.changeAdminChecked}
                                            checked={this.state.adminChecked}
                                        />
                                    </span>
                                </div>
                            ) : null}
                            {this.state.adminChecked ? null : (
                                <div
                                    className="user-add-form__info column"
                                    key={1}
                                >
                                    <span>Роли</span>
                                    <MultipleSelect
                                        value={bankStandartRoles.filter(
                                            (role) => role.value !== 'ADMIN'
                                        )}
                                        initialState={this.state.roles}
                                        onChange={(roles) =>
                                            this.setState({ roles })
                                        }
                                    />
                                </div>
                            )}
                            <Button
                                className="mt-4"
                                fluid
                                size="tiny"
                                onClick={
                                    !this.state.emailIsValid
                                        ? null
                                        : this.createBankUser
                                }
                            >
                                Создать
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={this.state.open_update_user}
                    toggle={this.toggle_update_user}
                >
                    <ModalHeader toggle={this.toggle_update_user}>
                        {isAdmin(this.state.roles) && this.state.adminChecked
                            ? isAdmin(this.props.roles)
                                ? 'Редактирование Администратора'
                                : 'Информация об Администраторе'
                            : 'Редактирование Пользователя'}
                    </ModalHeader>
                    <ModalBody className={'add-bank-user-modal'}>
                        <div className="user-add-form">
                            <div className="user-add-form__info">
                                <span>ID пользователя:</span>
                                <span>{this.state.id}</span>
                            </div>
                            <div className="user-add-form__info">
                                <span>Блокировка:</span>
                                <span>
                                    <CheckBox
                                        onChange={() =>
                                            this.setState({
                                                bankBlocked:
                                                    !this.state.bankBlocked,
                                            })
                                        }
                                        checked={this.state.bankBlocked}
                                    />
                                </span>
                            </div>
                            {this.state.temporaryBlocked ? (
                                <div className="user-add-form__info">
                                    <span>Временно заблокирован</span>
                                </div>
                            ) : null}
                            <Input
                                onChange={(fio) =>
                                    this.setState({
                                        fullName: fio.target.value,
                                    })
                                }
                                value={this.state.fullName}
                                placeholder={'Введите ФИО'}
                                className="modal-input"
                            />
                            <Input
                                onChange={(email) =>
                                    this.setState({ email: email.target.value })
                                }
                                value={this.state.email}
                                placeholder={'Введите email'}
                                className="modal-input"
                                type="email"
                            />

                            {isAdmin(this.props.roles) ? (
                                <div className="user-add-form__info">
                                    <span>Администратор:</span>
                                    <span>
                                        <CheckBox
                                            onChange={() =>
                                                this.setState({
                                                    adminChecked:
                                                        !this.state
                                                            .adminChecked,
                                                })
                                            }
                                            checked={this.state.adminChecked}
                                        />
                                    </span>
                                </div>
                            ) : null}
                            {this.state.adminChecked ? null : (
                                <div
                                    className="user-add-form__info column"
                                    key={1}
                                >
                                    <span>Роли</span>
                                    <MultipleSelect
                                        value={bankStandartRoles.filter(
                                            (option) => option.value !== 'ADMIN'
                                        )}
                                        initialState={this.state.roles}
                                        onChange={(roles) =>
                                            this.setState({ roles })
                                        }
                                    />
                                </div>
                            )}

                            <Button
                                className="mt-4"
                                fluid
                                size="tiny"
                                disabled={
                                    isAdmin(this.state.roles) &&
                                    !isAdmin(this.props.auth.roles)
                                }
                                onClick={() =>
                                    this.updateBankUser(this.state.id)
                                }
                            >
                                Сохранить
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        fetch: state.fetch.fetch,
        roles: state.auth.roles,
    };
}

export default connect(mapStateToProps)(BankUserListPage);
