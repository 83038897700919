import { isPasswordStrong } from 'src/features/new-password-input';
import { object, ref, string } from 'yup';

interface ActivateFormFields {
    password: string;
    repeatPassword: string;
    code?: string;
}

interface Params {
    uid: string;
}

const schemaBank = object({
    password: string()
        .required('Введите пароль')
        .test(
            'securePassword',
            'Пароль не прошел проверку сложности',
            (value) => (value ? isPasswordStrong(value) : false)
        ),
    repeatPassword: string().oneOf(
        [ref('password'), null],
        'Пароли должны совпадать'
    ),
});

const schemaMerchant = object({
    password: string()
        .required('Введите пароль')
        .test(
            'securePassword',
            'Пароль не прошел проверку сложности',
            (value) => (value ? isPasswordStrong(value) : false)
        ),
    repeatPassword: string().oneOf(
        [ref('password'), null],
        'Пароли должны совпадать'
    ),
    code: string().min(1, 'Поле не должно быть пустым'),
});

export type { ActivateFormFields, Params };

export { schemaBank, schemaMerchant };
