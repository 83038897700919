import React, { FC, memo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { URL } from 'src/app/config';
import { createNotification } from 'src/redux/actions/notification';
import { Input, Modal, ModalField } from 'src/shared';
import { log, post } from 'src/shared/lib';
import { validationScheme } from '../../lib/validationScheme';

interface Inputs {
    email: string;
}

interface Props {
    isOpen: boolean;
    setOpen: (arg: boolean) => void;
}

export const SendReceiptAboutOperation: FC<Props> = memo((props) => {
    const { isOpen, setOpen } = props;
    const [isClosed, setClosed] = useState(false);
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>({
        mode: 'onSubmit',
        defaultValues: { email: '' },
        resolver: yupResolver(validationScheme),
    });

    const onSubmit = useCallback(
        ({ email }: Inputs) => {
            post(URL.receiptUrl, {
                operationId: id,
                email,
            })
                .then((r) => {
                    dispatch(
                        createNotification(
                            {
                                title: 'Отправка квитанции',
                                message: 'Отправка квитанции прошла успешно',
                            },
                            'success'
                        )
                    );
                })
                .catch((r) => {
                    log(r);
                });
            setClosed(true);
        },
        [dispatch, id]
    );

    const onClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return (
        <Modal
            isForm
            isOpen={isOpen}
            isClosed={isClosed}
            header="Отправка квитанции"
            closeButton="Отмена"
            actionButton="Отправить"
            onClose={onClose}
            onAction={handleSubmit(onSubmit)}
        >
            <ModalField
                numberOfColumns={1}
                errorMessage={errors.email?.message}
            >
                <Input
                    background
                    placeholder="Введите email"
                    {...register('email')}
                />
            </ModalField>
        </Modal>
    );
});
