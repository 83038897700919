import React from 'react';
import { SelectorWithGroups } from 'src/entities/selector-with-groups';
import { Organisation, identificationSBP } from './type';
import { filterSBPIdentificators } from '../lib';

interface Props {
    selected: string[];
    identificatorsList: Organisation[];
    onChange: (selected: string[]) => void;
    handleIdentificationClick: (sbpIdentificatorId: string) => void;
    query: string;
    selectedOnly: boolean;
}

export const SbpIdentificatorSelector = ({
    selected,
    identificatorsList,
    onChange,
    handleIdentificationClick,
    query,
    selectedOnly,
}: Props) => {
    const handleOrganisationTitleClick = (organisation: Organisation) => {
        const allIdentifications = organisation.identificationsSbp.flatMap(
            (identification) => identification.merchantId
        );

        allIdentifications.every((id) => selected.includes(id))
            ? onChange(
                  selected.filter(
                      (selected) => !allIdentifications.includes(selected)
                  )
              )
            : onChange([
                  ...selected,
                  ...allIdentifications.filter((id) => !selected.includes(id)),
              ]);
    };

    const handleGroupClick = (organisation: Organisation) =>
        handleOrganisationTitleClick(organisation);

    const get = (organisation: Organisation) => {
        return organisation.identificationsSbp.flatMap(
            (identificationSbp) => identificationSbp
        );
    };

    const nameGetter = (row: identificationSBP) => {
        return `${row.merchantId} ${row.merchantName}`;
    };

    const isSelected = (data: Organisation) =>
        data.identificationsSbp.some((identification) =>
            selected.includes(identification.merchantId)
        );

    const isFullySelected = (data: Organisation) =>
        data.identificationsSbp.every((identification) =>
            selected.includes(identification.merchantId)
        );

    const filterData = (data: Organisation[]) => {
        return filterSBPIdentificators({
            query,
            identificatorsList: data,
            selected,
            selectedOnly,
        });
    };

    return (
        <SelectorWithGroups<
            Organisation,
            identificationSBP,
            identificationSBP,
            string
        >
            getRowId={(row: identificationSBP) => row.merchantId}
            data={identificatorsList}
            rowsGetter={get}
            rowNameGetter={nameGetter}
            selected={selected}
            handleRowClick={(identificationId: string) =>
                handleIdentificationClick(identificationId)
            }
            isCurrentlySelected={(identificationId: string) =>
                selected.includes(identificationId)
            }
            query={query}
            selectedOnly={selectedOnly}
            isSelected={(id: string) => selected.includes(id)}
            groupNameGetter={function (data: Organisation): string {
                return data.name;
            }}
            handleGroupClick={handleGroupClick}
            isGroupSelected={isSelected}
            isGroupFullySelected={isFullySelected}
            filterData={filterData}
            groupsGetter={function (data: Organisation): identificationSBP[] {
                return data.identificationsSbp;
            }}
            inventoryDisplayFilters={[]}
            emptyPlaceholderText='Идентификаторы подходящие под заданные фильтры не найдены.'
        />
    );
};
