import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    localLoadInt,
    localLoadSerialize,
    localSave,
    localSaveSerialize,
} from '../../shared/lib/localStorage';
import { getClassName, log } from '../../shared/lib/util';
import { post } from '../../shared/lib/request-wrapper';
import Select from 'react-select';
import { URL } from 'src/app/config';

import './company-select.scss';

class CompanySelect extends Component {
    static companyIds = 'companySelectId';
    static companySelect = 'companySelect';
    static companySelectList = 'companySelectList';

    constructor(props) {
        super(props);
        this.id = getClassName(this.props.uid, CompanySelect.companyIds);
        this.value = getClassName(this.props.uid, CompanySelect.companySelect);
        this.list = getClassName(
            this.props.uid,
            CompanySelect.companySelectList
        );
        this.state = {
            fetch: false,
            id: localLoadInt(this.id, undefined),
            value: localLoadSerialize(this.value, undefined),
            list: localLoadSerialize(this.list, undefined),
        };
        this.companySelectInputChangeHandler =
            this.companySelectInputChangeHandler.bind(this);
    }

    companySelectInputChangeHandler(input, { action }) {
        if (action === 'input-change' && input.length >= 3) {
            this.getCompanies(input);
        }
    }

    componentDidMount() {
        this.props.onChange(this.state.value ? this.state.value : '');
    }

    getCompanies(searchQuery) {
        this.setState({ fetch: true });
        return post(
            this.props.url ? this.props.url : URL.companyFindSelectUrl,
            { searchQuery, offset: 0, size: 10 },
            {},
            false
        )
            .then((response) => {
                const list = response.data.payload.map((e) => {
                    return { value: e.id, label: e.name };
                });
                this.setState({ fetch: false, list });
                localSaveSerialize(this.list, list);
            })
            .catch((response) => log(response))
            .finally(() => this.setState({ fetch: false }));
    }

    onChangeSelect(value) {
        localSaveSerialize(this.value, value ? value : '');
        localSave(this.id, value ? value.value : '');
        this.setState({ value: value ? value : '' });
        this.props.onChange(value ? value : '');
    }

    render() {
        return (
            <Select
                classNamePrefix="react-select"
                className={`company-select ${this.props.classSelect || ''}`}
                value={
                    this.state.value
                        ? this.state.value
                        : this.props.def
                        ? this.props.def
                        : undefined
                }
                isSearchable
                isLoading={this.state.fetch}
                placeholder="Введите название"
                noOptionsMessage={() => 'Нет данных'}
                loadingMessage={() => 'Обновление'}
                onChange={(value) => this.onChangeSelect(value)}
                onInputChange={this.companySelectInputChangeHandler}
                options={this.state.list}
                isClearable={true}
                components={{ ClearIndicator: () => <></> }}
            />
        );
    }
}

CompanySelect.propTypes = {
    classSelect: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    uid: PropTypes.string.isRequired,
};

export default CompanySelect;
