import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
    getClassName,
    sendToSubject,
    localSave,
    formatDateForInput,
    getInitialFilter,
} from 'src/shared/lib';
import { DateRangePicker, SearchField } from 'src/shared/ui';
import { FiltersContainer } from 'src/widgets';
import { setEndDate, setFilters, setStartDate } from 'src/redux/actions/table';
import styles from './styles.module.scss';

class EpaymentsFilter extends Component {
    constructor(props) {
        super(props);
        this.clsName = 'EpaymentsFilter';
        this.startDate = getClassName(this.clsName, 'startDate');
        this.endDate = getClassName(this.clsName, 'endDate');
        this.defsIds = getClassName(this.clsName, 'defsIds');
        this.searchQuery = getClassName(this.clsName, 'searchQuery');
        this.defaultFilter = {
            ...getInitialFilter(['searchQuery'], this.clsName),
        };
        this.state = {
            ...this.defaultFilter,
        };
        this.setStartDate(this.defaultFilter.startDate);
        this.setEndDate(this.defaultFilter.endDate);
        this.send = sendToSubject.bind(this);

        if (this.props.table?.filters?.searchQuery) {
            this.props.dispatch(
                setFilters(this.props.pageTitle, {
                    searchQuery: this.props.table?.filters?.searchQuery,
                })
            );
        }
    }

    componentDidMount() {
        this.send({
            filter: {
                ...this.state,
            },
        });
    }

    componentWillUnmount() {
        if (this.subscription) this.subscription.unsubscribe();
    }

    saveAndSend() {
        this.saveFilter();
        this.send({
            offset: 1,
            dropOffset: true,
            filter: {
                ...this.state,
                startDate: this.getStartDate(),
                endDate: this.getEndDate(),
            },
        });
    }

    componentDidUpdate() {
        this.saveAndSend();
    }

    saveFilter() {
        localSave(this.startDate, this.getStartDate());
        localSave(this.endDate, this.getEndDate());
    }

    getStartDate() {
        return this.props.table?.startDate ?? this.state.startDate;
    }

    setStartDate(date) {
        this.props.dispatch(setStartDate(this.props.pageTitle, date));
    }

    getEndDate() {
        return this.props.table?.endDate ?? this.state.endDate;
    }

    setEndDate(date) {
        this.props.dispatch(setEndDate(this.props.pageTitle, date));
    }

    render() {
        return (
            <div className={styles['wrapper']}>
                <FiltersContainer>
                    <DateRangePicker
                        datepickers={[
                            {
                                selected: this.getStartDate(),
                                start: true,
                                onChange: (startDate) =>
                                    this.setStartDate(startDate),
                            },
                            {
                                selected: this.getEndDate(),
                                end: true,
                                onChange: (endDate) => this.setEndDate(endDate),
                            },
                        ]}
                        formatter={formatDateForInput}
                        position="left"
                        startDate={this.getStartDate()}
                        endDate={this.getEndDate()}
                        onReset={() => {
                            this.setStartDate(
                                moment(new Date()).add(-7, 'days')
                            );
                            this.setEndDate(moment(new Date()));
                        }}
                    />
                    <SearchField
                        fullsize
                        placeholder="Введите номер заказа"
                        value={this.props.table?.filters?.searchQuery}
                        onChange={(value) =>
                            this.props.dispatch(
                                setFilters(this.props.pageTitle, {
                                    searchQuery: value,
                                })
                            )
                        }
                    />
                </FiltersContainer>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        terminals: state.terminals.terminals,
        table: state.table?.[props.pageTitle],
    };
}

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(EpaymentsFilter);
