import React from 'react';

export const DocumentsIcon = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.2695 19.9492C15.5332 19.9492 19.8789 15.6035 19.8789 10.3301C19.8789 5.06641 15.5234 0.720703 10.2598 0.720703C4.99609 0.720703 0.650391 5.06641 0.650391 10.3301C0.650391 15.6035 4.99609 19.9492 10.2695 19.9492ZM7.79883 15.4082C6.71484 15.4082 6.1582 14.8516 6.1582 13.7578V6.6582C6.1582 5.58398 6.72461 5.00781 7.79883 5.00781H9.63477V8.85547C9.63477 9.48047 9.9375 9.7832 10.5527 9.7832H14.3613V13.7578C14.3613 14.832 13.7949 15.4082 12.7207 15.4082H7.79883ZM10.7285 8.97266C10.543 8.97266 10.4355 8.875 10.4355 8.67969V5.05664C10.6699 5.08594 10.8945 5.26172 11.1484 5.51562L13.8438 8.24023C14.1074 8.51367 14.2832 8.73828 14.3027 8.97266H10.7285Z"
            fill="#CABFAD"
        />
    </svg>
);
