import { EditMerchantKeysState } from '../model/types/EditMerchantKeysState';
import { InitialData } from '../model/types/InitialData';

export let getDefaultValues = (
    initialData: InitialData
): EditMerchantKeysState => {
    const {
        accAlias = '',
        account = '',
        extEntityId = '',
        merchantIdSbp = '',
        cashbox,
        maxAmount,
        mirPayKid = '',
        mirPayMerchantId = '',
        mirPayPrivateKey = '',
        mirPayTerminalId = '',
        ...rest
    } = initialData;

    return {
        accAlias,
        account,
        extEntityId,
        merchantIdSbp,
        cashbox: String(cashbox),
        maxAmount: String(maxAmount),
        mirPayKid,
        mirPayMerchantId,
        mirPayPrivateKey,
        mirPayTerminalId,
        ...rest,
    };
};
