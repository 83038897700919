import React, { Component } from 'react';

import { localLoad, localSave } from 'src/shared/lib/localStorage';
import { getClassName } from 'src/shared/lib/util';
import { FiltersContainer } from 'src/widgets';
import { SearchField } from 'src/shared/ui';
import { connect } from 'react-redux';
import { setFilters } from 'src/redux/actions/table';
import styles from './styles.module.scss'

class TerminalsFilter extends Component {
    constructor(props) {
        super(props);
        this.clsName = 'TerminalsFilter';
        this.queryName = getClassName(this.clsName, 'searchQuery');
        this.props.dispatch(
            setFilters(this.props.pageTitle, {
                searchQuery: localLoad(this.queryName, ''),
            })
        );
    }

    render() {
        return (
            <div className={styles['wrapper']}>
                <FiltersContainer>
                    <SearchField
                        fullsize
                        placeholder="Введите Id терминала"
                        value={this.props.table?.fitlers?.searchQuery}
                        onChange={(value) => {
                            localSave(
                                this.queryName,
                                this.props.table?.fitlers?.searchQuery
                            );
                            this.props.dispatch(
                                setFilters(this.props.pageTitle, {
                                    searchQuery: value,
                                })
                            );
                        }}
                    />
                </FiltersContainer>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    table: state.table?.[props.pageTitle],
});

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(TerminalsFilter);
