import React from 'react';

export const ok = (
    <svg
        width="14"
        height="24"
        viewBox="0 0 14 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.86404 16.9131C4.05602 16.712 2.41902 16.2597 1.02635 15.1288C0.855318 14.978 0.684288 14.8523 0.513259 14.7016C-0.0975615 14.0984 -0.17086 13.3948 0.317796 12.6911C0.733154 12.0879 1.41727 11.912 2.15026 12.2639C2.29685 12.3393 2.41902 12.4146 2.54118 12.5152C5.13106 14.3497 8.72268 14.4 11.337 12.5906C11.6058 12.3895 11.8745 12.2136 12.1921 12.1382C12.8274 11.9623 13.3893 12.2136 13.7314 12.7665C14.1223 13.3948 14.0979 14.023 13.6337 14.5256C12.9007 15.2796 12.0455 15.8325 11.0682 16.2345C10.1642 16.5864 9.16247 16.7874 8.16073 16.9131C8.30733 17.089 8.38062 17.1644 8.47835 17.2649C9.82216 18.6471 11.166 20.0293 12.5098 21.4366C12.974 21.9141 13.0717 22.4921 12.803 23.045C12.5342 23.6482 11.9234 24.0503 11.3126 24C10.9216 23.9749 10.6284 23.7738 10.3597 23.4974C9.35794 22.4419 8.30733 21.4115 7.33001 20.356C7.03682 20.0544 6.89022 20.1047 6.64589 20.3811C5.61972 21.4618 4.59354 22.5173 3.54293 23.5728C3.0787 24.0503 2.51675 24.1256 1.97923 23.8743C1.39284 23.5979 1.02635 22.9696 1.07521 22.3665C1.09965 21.9644 1.29511 21.6377 1.56387 21.3612C2.88324 20.0042 4.22705 18.622 5.54642 17.2649C5.64415 17.1393 5.71745 17.0387 5.86404 16.9131Z"
            fill="white"
        />
        <path
            d="M6.96348 12.1382C3.73835 12.1382 1.09961 9.37382 1.09961 6.05654C1.12404 2.689 3.76279 0 7.03678 0C10.3108 0 12.9251 2.7644 12.9007 6.15707C12.8762 9.47434 10.213 12.1633 6.96348 12.1382ZM9.89542 6.05654C9.89542 4.39791 8.60048 3.0911 7.01235 3.0911C5.39978 3.0911 4.10484 4.42304 4.12928 6.08167C4.12928 7.71518 5.42422 9.02199 7.03678 9.02199C8.62491 9.04712 9.89542 7.71518 9.89542 6.05654Z"
            fill="white"
        />
    </svg>
);
