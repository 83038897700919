import React, { Component } from 'react';
import { connect } from 'react-redux';

import Container from 'src/components/Container';
import DynamicChart from './DynamicChart';
import DynamicFilter from './DynamicFilter';

class DynamicPage extends Component {
    render() {
        return (
            <div className="dynamic-page">
                <Container header={true} headerName={'Параметры сравнения'}>
                    <DynamicFilter />
                </Container>
                <Container header={true} headerName={'Показатели'}>
                    <DynamicChart data={this.props.dynamic} />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dynamic: state.charts.dynamic,
    fetch: state.fetch.fetch,
});

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(DynamicPage);
