import React from 'react';

export const chevron = (
    <svg
        width="9"
        height="6"
        viewBox="0 0 9 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.49023 0.155274C4.20703 0.160157 3.98242 0.262696 3.74805 0.492188L0.413086 3.91016C0.24707 4.08106 0.168945 4.27637 0.168945 4.51563C0.168945 5.00391 0.564453 5.4043 1.04297 5.4043C1.28223 5.4043 1.50684 5.30664 1.69238 5.11621L4.48535 2.21094L7.28809 5.11621C7.46875 5.30176 7.69336 5.4043 7.9375 5.4043C8.4209 5.4043 8.81641 5.00391 8.81641 4.51562C8.81641 4.28125 8.7334 4.08594 8.56738 3.91016L5.22754 0.492188C4.99316 0.257813 4.76855 0.155274 4.49023 0.155274Z"
            fill="#002E5A"
        />
    </svg>
);
