import React, { FC, useMemo, useState } from 'react';
import download from 'downloadjs';
import { useLocation } from 'react-router-dom';
import { createNotification } from 'src/redux/actions/notification';
import { Input, Modal, ModalField, NewCheckbox, NewSelect } from 'src/shared';
import {
    getAppTitle,
    getOptionsForSelect,
    localLoadIntInArray,
    log,
    post,
    useCloseCustomModal,
} from 'src/shared/lib';
import { getDay, getUrl } from '../../lib/getUrl';
import { FileFormat } from '../../model/types';
import { getDownloadedFileName } from '../../lib/getDownloadedFileName';
import { errorMessage, infoMessage } from '../../model/consts';
import { useDispatch } from 'react-redux';
import { URL } from 'src/app/config';
import moment from 'moment';

type Event = React.ChangeEvent<HTMLInputElement>;

interface Props {
    url: string;
    defaultFileName: string;
    filters: Record<string, unknown>;
    onToggle: () => void;
    isDetailed?: boolean;
    availableFormats?: FileFormat[];
}

export const ModalBody: FC<Props> = (props) => {
    const {
        url,
        filters,
        defaultFileName,
        isDetailed = false,
        availableFormats = ['XLSX', 'CSV'],
        onToggle,
    } = props;

    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [isOpen, setOpen] = useState(true);
    const [fileName, setFileName] = useState(defaultFileName);
    const [fileFormat, setFileFormat] = useState(availableFormats[0]);
    const [detailedReport, setDetailedReport] = useState(false);

    const optionsFileFormat = useMemo(() => {
        return getOptionsForSelect<FileFormat>(availableFormats);
    }, [availableFormats]);

    const onChangeFileName = (e: Event) => {
        setFileName(e.currentTarget.value);
    };

    const onChangeFileFormat = (value: string) => {
        setFileFormat(value as FileFormat);
    };

    const onChangeDetailedReport = (e: Event) => {
        setDetailedReport(e.currentTarget.checked);
    };

    const onClose = useCloseCustomModal(setOpen, onToggle);

    const prepareBodyReport = () => {
        let requestUrl = getUrl({
            url,
            fileFormat,
            pathname,
            detailedReport,
            index: Number(detailedReport),
        });
        let currentFilter = filters;
        const appName = getAppTitle();
        const localcompanyIds = localLoadIntInArray('companyId', []);
        const companyIds =
            Array.isArray(filters?.companyIds) && filters?.companyIds?.length
                ? filters?.companyIds
                : localcompanyIds;
        if (url === URL.settlementDetailFileDownloadUrl) {
            if (appName === 'bank') {
                const pathDay = getDay(pathname);
                const day = moment(pathDay).format('YYYY-MM-DD');
                currentFilter = {
                    startDate: day,
                    endDate: day,
                    companyIds,
                    searchQuery: filters?.searchQuery,
                };
            } else {
                requestUrl = getUrl({
                    url: URL.settlementFileDownloadUrl,
                    fileFormat,
                    pathname,
                    detailedReport,
                    index: Number(detailedReport),
                });
                currentFilter['companyIds'] = companyIds;
            }
        }
        return { requestUrl, currentFilter };
    };
    const onSubmit = () => {
        const { requestUrl, currentFilter } = prepareBodyReport();

        post(requestUrl, currentFilter, {
            responseType: 'blob',
        })
            .then((response) => {
                download(
                    response.data,
                    getDownloadedFileName(fileName, fileFormat),
                    'application/octet-stream'
                );
            })
            .catch((response) => {
                log(response);
                if (detailedReport) {
                    dispatch(createNotification(errorMessage, 'error'));
                }
            });

        dispatch(createNotification(infoMessage, 'info'));
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            header="Загрузка"
            closeButton="Отмена"
            actionButton="Загрузить"
            onClose={onClose}
            onAction={onSubmit}
        >
            <ModalField numberOfColumns={1}>
                <NewSelect
                    idScrollElement="modal-element-scroll"
                    options={optionsFileFormat}
                    value={fileFormat}
                    onChange={onChangeFileFormat}
                />
            </ModalField>
            <ModalField numberOfColumns={1}>
                <Input
                    background
                    placeholder="Введите название файла"
                    value={fileName}
                    onChange={onChangeFileName}
                />
            </ModalField>

            {isDetailed && (
                <ModalField numberOfColumns={1}>
                    <NewCheckbox
                        isReverseOrder
                        label="Сделать подробную выгрузку"
                        checked={detailedReport}
                        onChange={onChangeDetailedReport}
                    />
                </ModalField>
            )}
        </Modal>
    );
};
