import React, { useCallback, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Moment } from 'moment';
import moment from 'moment';
import 'moment/locale/ru';

import { classNames } from 'src/shared/lib';
import { DatePickerProps } from './config';
import { DateTextInput } from './date-text-input';
import styles from './date-range-picker.module.scss';
import { Button } from '../button';
import { useDispatch, useSelector } from 'react-redux';
import { resetCancelationToken } from 'src/redux/actions';
import { getRequestCancelationToken } from 'src/redux/selectors';

interface Props {
    datepickers: DatePickerProps[];
    onReset?: () => void;
    startDate: Moment | null;
    endDate: Moment | null;
    formatter: (date: Moment) => string;
    position?: 'center' | 'left'; // позиционирование попапа относительно триггера
    criticalScreenWidth?: number; // критическая ширина экрана для двух календарей
    popupClassName?: string;
}

export const DateRangePicker: React.FC<Props> = ({
    datepickers,
    onReset,
    endDate,
    startDate,
    formatter,
    position = 'center',
    criticalScreenWidth = 991,
    popupClassName,
}) => {
    const { token } = useSelector(getRequestCancelationToken);
    const dispatch = useDispatch();

    const [active, setActive] = useState<'start' | 'end' | undefined>();
    const [screenWidth, setScreenWidtn] = useState<number | undefined>();
    const [isShowOneCalendar, setIsShowOneCalendar] = useState(false);
    const [left, setLeft] = useState<number | undefined>();
    const [selectedStartDate, setSelectedStartDate] = useState<Moment | null>(
        startDate
    );
    const [selectedEndDate, setSelectedEndDate] = useState<Moment | null>(
        endDate
    );

    // sync local states with store states
    const resetLocalStates = useCallback(() => {
        setSelectedEndDate(endDate);
        setSelectedStartDate(startDate);
    }, [startDate, endDate]);

    const resetHandler = () => {
        setSelectedStartDate(moment().subtract(7, 'days'));
        setSelectedEndDate(moment());
    };

    const ref = useRef<HTMLDivElement>(null);
    const datepickersRef = useRef<HTMLDivElement>(null);

    const setScreenWidth = () => {
        setScreenWidtn(window.screen.width);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            ref.current &&
            !ref.current.contains(event.target as HTMLDivElement) &&
            !datepickersRef.current?.contains(event.target as HTMLDivElement)
        ) {
            // close popup on click outside
            setActive(undefined);
        }
    };

    // Reset local states if they haven't been applied before popup got closed
    useEffect(() => {
        if (!active) {
            resetLocalStates();
        }
    }, [active, resetLocalStates]);

    useEffect(() => {
        setScreenWidth();

        if (screenWidth && screenWidth < 500) {
            setIsShowOneCalendar(true);
            setLeft(undefined);
        } else if (
            screenWidth &&
            screenWidth < criticalScreenWidth &&
            screenWidth > 500
        ) {
            setLeft(-16);
            setIsShowOneCalendar(true);
        } else {
            setLeft(undefined);
            setIsShowOneCalendar(false);
        }

        window.addEventListener('resize', setScreenWidth);
        return () => {
            window.removeEventListener('resize', setScreenWidth);
        };
    }, [criticalScreenWidth, screenWidth]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const renderDatePicker = ({ end, start }: DatePickerProps, key) => {
        let limit = start
            ? { maxDate: selectedEndDate }
            : { minDate: selectedStartDate };

        const component = (
            <DatePicker
                key={key}
                selected={start ? selectedStartDate : selectedEndDate}
                selectsStart={start}
                selectsEnd={end}
                onChange={(e) =>
                    start ? setSelectedStartDate(e) : setSelectedEndDate(e)
                }
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                calendarClassName={styles['date-picker-calendar']}
                inline
                {...limit}
            />
        );

        if (!isShowOneCalendar) return component;

        if (active === 'start') {
            if (start) return component;
            return null;
        } else {
            if (end) return component;
            return null;
        }
    };

    const renderHeader = ({ start, onChange }: DatePickerProps, key) => {
        const isActive = start ? active === 'start' : active === 'end';

        const handleButton = () => {
            setActive(start ? 'start' : 'end');
        };

        const stylesButton = classNames(styles['date-range-picker__header'], {
            [styles['date-range-picker__header--active']]: isActive,
        });

        const getDate = (data: Moment | null) =>
            data ? formatter(data) : 'выбрать дату';

        return (
            <button className={stylesButton} onClick={handleButton} key={key}>
                <span className={styles['title']}>
                    {start ? 'Начало' : 'Завершение'}
                </span>
                <span className={styles['date']}>
                    <DateTextInput
                        setDate={(e) =>
                            start
                                ? setSelectedStartDate(e)
                                : setSelectedEndDate(e)
                        }
                        startDate={start ? selectedStartDate : selectedEndDate}
                        placeholder={
                            start
                                ? getDate(selectedStartDate)
                                : getDate(selectedEndDate)
                        }
                    />
                </span>
            </button>
        );
    };

    const stylesPopup = classNames(
        styles['date-range-picker__popup'],
        {
            [styles['date-range-picker__popup--open']]: active,
            [styles['date-range-picker__popup--left']]: position === 'left',
        },
        [popupClassName]
    );

    const [
        { onChange: startDateOnChangeHandler },
        { onChange: endDateOnChangeHandler },
    ] = datepickers;

    const cancelRequest = () => {
        token.cancel();
        dispatch(resetCancelationToken());
    };

    // On apply click cancel data fetching request & sync local states with store states
    const applyClickHandler = () => {
        cancelRequest();
        selectedStartDate && startDateOnChangeHandler(selectedStartDate);
        selectedEndDate && endDateOnChangeHandler(selectedEndDate);

        // timeout fixes flickering caused by rerender with old start & end date states
        setTimeout(() => setActive(undefined));
    };

    return (
        <div className={styles['date-range-picker']}>
            <div
                ref={datepickersRef}
                className={styles['date-range-picker__headers']}
            >
                {datepickers.map(renderHeader)}
            </div>
            <div ref={ref} style={{ left }} className={stylesPopup}>
                <div className={styles['date-range-picker__datepickers']}>
                    {datepickers.map(renderDatePicker)}
                </div>
                <div className={styles['controls-wrapper']}>
                    <button
                        className={styles['reset-button']}
                        onClick={resetHandler}
                    >
                        Сбросить значения
                    </button>
                    <Button onClick={applyClickHandler} size="smedium">
                        Применить
                    </Button>
                </div>
            </div>
        </div>
    );
};
