import React from 'react';

const mc = (
    <svg
        width="37"
        height="24"
        viewBox="0 0 37 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M23.5077 2.5625H13.499V21.4302H23.5077V2.5625Z"
            fill="#EA5B0C"
        />
        <path
            d="M14.1319 11.9682C14.1278 10.1571 14.5184 8.36836 15.2747 6.73504C16.031 5.10173 17.1334 3.66589 18.5001 2.53435C16.4912 0.881549 13.9973 -0.0125295 11.4308 -0.000116612C8.3828 0.0240737 5.46834 1.29726 3.32582 3.54055C1.1833 5.78385 -0.0125924 8.81434 0.000100008 11.9682C-0.0125924 15.1221 1.1833 18.1526 3.32582 20.3959C5.46834 22.6391 8.3828 23.9123 11.4308 23.9365C13.9992 23.9379 16.491 23.0313 18.4933 21.3669C17.1324 20.2383 16.0339 18.8078 15.279 17.1811C14.5241 15.5543 14.1321 13.7728 14.1319 11.9682V11.9682Z"
            fill="#DF1612"
        />
        <path
            d="M37 11.9682C37.0126 15.1233 35.8158 18.1548 33.6718 20.3983C31.5279 22.6418 28.6116 23.9142 25.5625 23.9365C22.9919 23.9384 20.4978 23.0318 18.4932 21.3669C19.8572 20.2328 20.9579 18.7965 21.7142 17.1638C22.4705 15.5311 22.8631 13.7436 22.8631 11.933C22.8631 10.1225 22.4705 8.33496 21.7142 6.70226C20.9579 5.06955 19.8572 3.63324 18.4932 2.49916C20.5071 0.859975 23.0002 -0.0214101 25.5625 -0.000111129C28.6116 0.0222249 31.5279 1.2946 33.6718 3.53809C35.8158 5.78158 37.0126 8.81314 37 11.9682V11.9682Z"
            fill="#F59E00"
        />
    </svg>
);

export { mc };
