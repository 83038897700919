import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { convertDateToString } from './utils';
import { DateTextInputProps } from './date-text-input';

export const useDateTextInput = (props: DateTextInputProps) => {
    const { setDate, startDate } = props;

    const [value, setValue] = useState<string>(
        convertDateToString(startDate!.toDate())
    );

    useEffect(() => {
        setValue(convertDateToString(startDate!.toDate()));
    }, [startDate]);

    const onChange = (event) => {
        let value = event.target.value;

        if (value.length < 11) {
            setValue(value);
        }
    };

    const handleBlur = (event) => {
        let value = event.target.value;

        if (value && value?.length === 10) {
            const [year, month, day] = value.split('-');
            const newValue = `${day}.${month}.${year}`;
            setDate(moment(newValue, 'DD.MM.YYYY'));
        } else {
            setValue(convertDateToString(startDate!.toDate()));
        }
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === ' ' || event.key === 'Enter') {
            event.preventDefault();
        }
        if (/(Enter)/.test(event.key)) {
            handleBlur(event);
        }
    };

    return {
        onChange,
        value,
        handleBlur,
        onKeyDown
    };
};
