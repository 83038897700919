export const normalizeDate = (value: number): string => {
    const str = `${value}`;
    return str.length === 1 ? `0${str}` : str;
};

export const convertDateToString = (value: Date): string => {
    const year = normalizeDate(value.getFullYear());
    const month = normalizeDate(value.getMonth() + 1);
    const date = normalizeDate(value.getDate());
    return `${year}-${month}-${date}`;
};

export const isIphoneSafari = (): boolean => {
    const isIos = /iP(ad|od|hone)/i.test(window.navigator.userAgent);
    const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
    return isIos && isSafari;
};
