import React, { FC } from 'react';
import { SBPIcon } from 'src/shared/ui';
import { formatCurrency, formatDate } from '../../lib';
import { OperationSbp } from '../../model';
import { DetailsItem } from '../details-item';
import styles from './operation-details.module.scss';

interface OperationDetailsSbpProps {
    data: OperationSbp;
}

export const OperationDetailsSbp: FC<OperationDetailsSbpProps> = ({
    data,
    children,
}) => {
    const {
        amountNumerically,
        operationType,
        sndPam,
        sndPhone,
        qrcType,
        operationDate,
    } = data;

    return (
        <div className={styles['wrapper']}>
            <div className={`${styles['amount']} ${styles['sbp']}`}>
                {formatCurrency(amountNumerically)}
            </div>
            <div className={styles['body']}>
                <DetailsItem title={operationType} subtitle={'тип операции'} />
                <DetailsItem title={sndPam} subtitle={'ФИО'} />
                <DetailsItem
                    title={sndPhone}
                    subtitle={'маскированный номер телефона'}
                />
                <DetailsItem icon={SBPIcon} title={' '} subtitle={' '} />
                <DetailsItem title={qrcType} subtitle={'тип QR'} />
                <DetailsItem
                    title={formatDate(operationDate, 'long')}
                    subtitle={'дата время операции'}
                />
            </div>
            <div className={styles['buttons']}>{children}</div>
        </div>
    );
};
