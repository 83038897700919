import React from 'react';

export const StatsIcon = (
    <svg
        width="18"
        height="12"
        viewBox="0 0 18 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.917794 1.76261H2.43496C2.95005 1.76261 3.36212 1.36202 3.36212 0.881306C3.36212 0.391691 2.95005 0 2.43496 0H0.917794C0.412071 0 0 0.391691 0 0.881306C0 1.36202 0.412071 1.76261 0.917794 1.76261ZM6.04058 1.60237H17.2414C17.6722 1.60237 18 1.2908 18 0.881306C18 0.47181 17.6722 0.160237 17.2414 0.160237H6.04058C5.60978 0.160237 5.27263 0.47181 5.27263 0.881306C5.27263 1.2819 5.60978 1.60237 6.04058 1.60237ZM0.917794 6.88131H2.43496C2.95005 6.88131 3.36212 6.48071 3.36212 6C3.36212 5.51039 2.95005 5.11869 2.43496 5.11869H0.917794C0.412071 5.11869 0 5.51039 0 6C0 6.48071 0.412071 6.88131 0.917794 6.88131ZM6.04058 6.72107H17.2414C17.6722 6.72107 18 6.4095 18 6C18 5.59941 17.6722 5.27893 17.2414 5.27893H6.04058C5.60978 5.27893 5.27263 5.5905 5.27263 6C5.27263 6.4095 5.60978 6.72107 6.04058 6.72107ZM0.917794 12H2.43496C2.95005 12 3.36212 11.5994 3.36212 11.1187C3.36212 10.6291 2.95005 10.2463 2.43496 10.2463H0.917794C0.412071 10.2463 0 10.6291 0 11.1187C0 11.5994 0.412071 12 0.917794 12ZM6.04058 11.8398H17.2414C17.6722 11.8398 18 11.5282 18 11.1187C18 10.7181 17.6722 10.3976 17.2414 10.3976H6.04058C5.60978 10.3976 5.27263 10.7181 5.27263 11.1187C5.27263 11.5282 5.60978 11.8398 6.04058 11.8398Z"
            fill="#002E5A"
        />
    </svg>
);
