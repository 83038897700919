import { Group } from 'src/entities/terminal';
import { SelectOption } from 'src/shared';
import { WITHOUT_GROUP } from '../model/consts/consts';

export const getOptionsGroups = (array: Group[]) => {
    const options: SelectOption[] = [];

    array.forEach((item: Group) => {
        const option = {} as SelectOption;
        option.value = String(item.id ? item.id : WITHOUT_GROUP);
        option.content = String(item.name);
        options.push(option);
    });
    return options;
};
