import React from 'react';
import { Link } from 'react-router-dom';
import { getAppTitle } from 'src/shared/lib';
import { navRoutes } from './URL';

export const FooterNavigation: React.FC = () => {
    return footerNavigation[getAppTitle()]();
};

const footerNavigation = {
    bank: () => (
        <>
            <Link to={navRoutes.support.main}>Техническая поддержка</Link>
            <Link to={navRoutes.support.main}>Помощь</Link>
            <Link to={navRoutes.support.main}>Как подключить</Link>
        </>
    ),
    merchant: () => (
        <>
            <Link to={navRoutes.support.main}>Техническая поддержка</Link>
            <Link to={navRoutes.support.main}>Помощь</Link>
            <Link to={navRoutes.support.main}>Как подключить</Link>
        </>
    ),
};
