import React from 'react';
import {
    AboutSystem,
    HelpPageLayout,
    HelpPageSidebar,
} from 'src/entities/help';

export const SystemPage = () => {
    return (
        <HelpPageLayout links={<HelpPageSidebar />}>
            <AboutSystem />
        </HelpPageLayout>
    );
};
