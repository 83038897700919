import { isEmpty } from 'lodash';
import React, {
    FC,
    memo,
    useCallback,
    useEffect,
    useReducer,
    useState,
} from 'react';
import { URL } from 'src/app/config';
import { Group, InfoTerminal } from 'src/entities/terminal';
import { createNotification } from 'src/redux/actions/notification';
import { SelectedIcon, ResetFiltersIcon, Input, Button } from 'src/shared';
import { CommissionInfo, Modal, ModalField, NewSelect } from 'src/shared';
import {
    get,
    getOptionsForSelect,
    log,
    post,
    maskPhone,
    validatePhone,
} from 'src/shared/lib';
import store from 'src/store';
import { getOptionsGroups } from '../../lib/getOptionsGroups';
import { requestSuccessTextMapper } from '../../lib/requestSuccessTextMapper';
import { INIT_TERMINAL, WITHOUT_GROUP } from '../../model/consts/consts';
import { ApplicationType } from '../../model/types/types';
import styles from './fetch-info-about-terminal.module.scss';

/**
 * Открытие окна привязано к изменению пропса id
 * setId обнуляет пропс в родительском компоненте
 * changingGroup привязана к родительской функции setGroupRequest
 */

interface FetchInfoAboutTerminalProps {
    id?: string;
    groupName: string;
    isChangedGroup?: boolean;
    setId: (value: null) => void;
    changingGroup?: (arg1: number, arg2?: number) => void;
}

export const FetchInfoAboutTerminal: FC<FetchInfoAboutTerminalProps> = memo(
    (props) => {
        const { id, groupName, isChangedGroup, setId, changingGroup } = props;
        const [isOpen, setIsOpen] = useState(false);
        const [terminal, setTerminal] = useReducer(
            (terminal: InfoTerminal, newTerminal: InfoTerminal) => ({
                ...terminal,
                ...newTerminal,
            }),
            INIT_TERMINAL
        );

        const [groups, setGroups] = useState<Group[]>([]);
        const [currentGroup, setCurrentGroup] = useState('');

        // свойства для закрытия терминала
        const [phoneNumber, setPhoneNumber] = useState('');
        const [applicationType, setApplicationType] =
            useState<ApplicationType>();
        const [validateCloseTerminal, setValidateCloseTerminal] =
            useState(false);

        const changePhoneNumber = useCallback((e) => {
            setPhoneNumber(maskPhone(e.target.value));
        }, []);

        const changeApplicationType = useCallback((value) => {
            setApplicationType(value);
        }, []);

        const resetTerminal = useCallback(() => {
            setTerminal(INIT_TERMINAL);
            setPhoneNumber('');
            setApplicationType(undefined);
            setId(null);
        }, [setId]);

        const onClose = useCallback(() => {
            setIsOpen(false);
            resetTerminal();
        }, [resetTerminal]);

        const onAction = useCallback(() => {
            if (terminal.id) {
                const groupId =
                    currentGroup !== WITHOUT_GROUP
                        ? Number(currentGroup)
                        : undefined;

                changingGroup?.(terminal.id, groupId);
            }
            resetTerminal();
        }, [terminal.id, currentGroup, changingGroup, resetTerminal]);

        const sendRequestToCloseTerminal = useCallback(() => {
            if (terminal.id && applicationType && phoneNumber) {
                const { title, text } =
                    requestSuccessTextMapper(applicationType);
                post(URL.terminalInactiveRequest, {
                    terminalId: terminal.id,
                    applicationType,
                    phoneNumber,
                })
                    .then(() => {
                        store.dispatch(
                            createNotification(
                                {
                                    title: title,
                                    message: text,
                                },
                                'success'
                            )
                        );
                    })
                    .catch((r) => log(r));
                setIsOpen(false);
                resetTerminal();
            }
        }, [applicationType, phoneNumber, terminal.id, resetTerminal]);

        // валидация полей заявки на закрытия терминала
        useEffect(() => {
            setValidateCloseTerminal(
                !!applicationType && validatePhone(phoneNumber)
            );
        }, [applicationType, phoneNumber]);

        useEffect(() => {
            if (!isEmpty(groups)) {
                const groupId = groups.find(
                    (group) => group.name === groupName
                )?.id;

                setCurrentGroup(groupId ? String(groupId) : WITHOUT_GROUP);
            }
        }, [groupName, groups]);

        // запрос на сервер
        useEffect(() => {
            if (id) {
                get(URL.terminalInfo + id)
                    .then((r) => {
                        setTerminal(r.data.payload);
                        setIsOpen(true);
                    })
                    .catch((r) => log(r));

                get(URL.terminalPageAvailableGroups + id)
                    .then((r) => {
                        setGroups(r.data.payload);
                    })
                    .catch((r) => log(r));
            }
        }, [id]);

        if (!id) {
            return null;
        }

        return (
            <Modal
                header={`Терминал ${terminal.uid}`}
                isOpen={isOpen}
                closeButton="Отмена"
                actionButton="Сохранить"
                onClose={onClose}
                onAction={onAction}
            >
                <div className={styles['status-terminal']}>
                    <span className={styles[terminal.activity.toLowerCase()]}>
                        {terminal.activity === 'DISABLED'
                            ? ResetFiltersIcon
                            : SelectedIcon}
                    </span>
                    <p>{terminal.activityInfo.replace('.', '')}</p>
                </div>
                {terminal.applicationTypes &&
                    terminal.activity === 'INACTIVE' && (
                        <>
                            <p className={styles.title}>
                                Заявка на закрытие терминала
                            </p>
                            <ModalField
                                numberOfColumns={1}
                                className={styles.field}
                            >
                                <NewSelect
                                    idScrollElement="modal-element-scroll"
                                    label="Укажите, пожалуйста причину"
                                    value={applicationType}
                                    lengthDisplayValue={100}
                                    options={getOptionsForSelect(
                                        terminal.applicationTypes,
                                        'value',
                                        'applicationTypeInfo'
                                    )}
                                    onChange={changeApplicationType}
                                />
                            </ModalField>
                            <ModalField
                                numberOfColumns={1}
                                className={styles.field}
                            >
                                <Input
                                    background
                                    type="phone"
                                    value={phoneNumber}
                                    placeholder="Введите телефон для связи"
                                    onChange={changePhoneNumber}
                                />
                            </ModalField>
                            <ModalField justifyInput="right">
                                <Button
                                    size="xs"
                                    onClick={sendRequestToCloseTerminal}
                                    disabled={!validateCloseTerminal}
                                >
                                    Отправить заявку
                                </Button>
                            </ModalField>
                        </>
                    )}
                <p className={styles.title}>Установленные лимиты</p>
                <ModalField label="На одну операцию" className={styles.field}>
                    {terminal.operationLimit.replace('₽', 'руб')}
                </ModalField>
                <ModalField label="Недельный лимит" className={styles.field}>
                    {terminal.weekLimit.replace('₽', 'руб')}
                </ModalField>
                <ModalField
                    label="Лимит до конца недели"
                    className={styles['last-field']}
                >
                    {terminal.limitEndOfWeek.replace('₽', 'руб')}
                </ModalField>

                {!isEmpty(terminal.rates) && (
                    <>
                        <p className={styles.title}>Тарифы</p>
                        {terminal.rates.map(({ cardName, rate }, i) => (
                            <ModalField
                                key={cardName + i}
                                label={cardName}
                                className={
                                    terminal.rates.length - 1 === i
                                        ? styles['last-field']
                                        : styles.field
                                }
                            >
                                {rate}%
                            </ModalField>
                        ))}
                    </>
                )}

                <ModalField
                    label="Дополнительная комиссия"
                    dataTip
                    dataFor="com-info"
                    className={styles.field}
                >
                    <span>{terminal.extraCommission}</span>
                </ModalField>
                <CommissionInfo id="com-info" />
                <ModalField
                    label="Комиссия на SIM-карту"
                    className={styles.field}
                >
                    {terminal.simCommission}
                </ModalField>

                {isChangedGroup && (
                    <ModalField label="Выбрать группу">
                        <NewSelect
                            idScrollElement="modal-element-scroll"
                            value={currentGroup}
                            options={getOptionsGroups(groups)}
                            onChange={(value) => {
                                setCurrentGroup(value);
                            }}
                        />
                    </ModalField>
                )}
            </Modal>
        );
    }
);
