import * as type from '../constants';

export const setStartDate = (tableId, startDate) => (dispatch) => {
    dispatch({
        type: type.CHANGE_START_DATE,
        payload: {
            tableId,
            startDate,
        },
    });
};

export const setEndDate = (tableId, endDate) => (dispatch) => {
    dispatch({
        type: type.CHANGE_END_DATE,
        payload: {
            tableId,
            endDate,
        },
    });
};

export const setTotalCountForPagination =
    (tableId, totalCountForPagination) => (dispatch) => {
        dispatch({
            type: type.SET_TOTAL_COUNT_FOR_PAGINATION,
            payload: {
                tableId,
                totalCountForPagination,
            },
        });
    };

export const setOffset = (tableId, offset) => (dispatch) => {
    dispatch({
        type: type.SET_OFFSET,
        payload: {
            tableId,
            offset,
        },
    });
};

export const setPageSize = (tableId, pageSize) => (dispatch) => {
    dispatch({
        type: type.SET_PAGE_SIZE,
        payload: {
            tableId,
            pageSize,
        },
    });
};

export const nextPage = (tableId) => (dispatch) => {
    dispatch({
        type: type.GO_NEXT_PAGE,
        payload: {
            tableId,
        },
    });
};

export const previousPage = (tableId) => (dispatch) => {
    dispatch({
        type: type.GO_PREVIOUS_PAGE,
        payload: {
            tableId,
        },
    });
};

export const setFilters = (tableId, filters) => (dispatch) => {
    dispatch({
        type: type.SET_FILTERS,
        payload: {
            tableId,
            filters,
        },
    });
};

export const setNeedsUpdate =
    (tableId, needsUpdate, findAll = false) =>
    (dispatch) => {
        dispatch({
            type: type.SET_NEEDS_UPDATE,
            payload: {
                tableId,
                needsUpdate,
                findAll,
            },
        });
    };

export const setDataIsFetching = (tableId, fetching) => (dispatch) => {
    dispatch({
        type: type.SET_DATA_IS_FETCHING,
        payload: {
            tableId,
            fetching,
        },
    });
};

export const setPersistedTableState = (tableId, tableData) => (dispatch) => {
    dispatch({
        type: type.SET_PERSISTED_TABLE_DATA,
        payload: {
            tableId,
            tableData,
        },
    });
};

export const setCommonTableFilters = (commonFilters) => (dispatch) => {
    dispatch({
        type: type.SET_COMMON_TABLE_FILTERS,
        payload: {
            commonFilters,
        },
    });
};
