import React, { useState } from 'react';
import { Layout } from '../layout';
import { Sidebar } from '../sidebar';
import { Search } from './search';
import { Navigation } from './navigation';

export const Content = () => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    return (
        <Layout
            title={!searchQuery ? 'Тематики раздела' : 'Результаты поиска'}
            links={<Sidebar />}
            topContent={
                <Search value={searchQuery} onChange={setSearchQuery} />
            }
        >
            <Navigation searchQuery={searchQuery} />
        </Layout>
    );
};
