import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReplaySubject } from 'rxjs';
import { navRoutes, URL } from 'src/app/config';
import { NotificationOfInactiveTerminals } from 'src/features/notification-of-inactive-terminals';
import AwareTable from 'src/components/AwareTable';
import { getInitialFilter, LOCAL_STORAGE_TABLE_NAMES } from 'src/shared/lib';
import StatisticFilter from './StatisticFilter';
import ConversionChart from './ConversionChart';
import MpsChart from './MpsChart';
import DeclineChart from './DeclineChart';
import { OverflowContainer, Paginator } from 'src/shared/ui';
import { DownloadReportStore } from 'src/features/download-report';

import './statistic.scss';

const pageTitle = LOCAL_STORAGE_TABLE_NAMES.statistic;

class StatisticPage extends Component {
    componentWillUnmount() {
        const chartTooltip = document.getElementById('chartjs-tooltip');
        if (!chartTooltip) return;
        chartTooltip.style.opacity = '0';
    }

    filterSubject = new ReplaySubject(1);

    render() {
        const toggle = this.props.toggle === 'sum' ? 0 : 1;
        return (
            <div className="statistic">
                <StatisticFilter pageTitle={pageTitle} />

                <Paginator name={pageTitle}>
                    <OverflowContainer>
                        <AwareTable
                            useCommonFilters
                            totalPaginationCountHidden
                            token={this.props.cancelationToken}
                            newStatuses
                            tableName={pageTitle}
                            emptyTerminalResetData
                            url={URL.statisticTableUrl}
                            toggle={toggle}
                            redirect={(row) => ({
                                pathname: navRoutes.operationLastUrl,
                                state: { rowID: row.ID, date: row.data[0] },
                            })}
                            isStoreTable
                            actions={[
                                <DownloadReportStore
                                    useCommonFilters
                                    pageTitle={pageTitle}
                                    defaultFileName="Статистика"
                                    url={URL.statisticFileDownloadUrl}
                                    filterSubject={this.filterSubject}
                                    getFilterCallback={() =>
                                        getInitialFilter(
                                            ['terminalIds'],
                                            pageTitle
                                        )
                                    }
                                />,
                            ]}
                        />
                    </OverflowContainer>
                </Paginator>
                <ConversionChart toggle={toggle} />
                <div className="bottom-charts">
                    <DeclineChart toggle={toggle} />
                    <MpsChart toggle={toggle} />
                </div>
                <NotificationOfInactiveTerminals />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    toggle: state.charts.toggle,
    fetch: state.fetch.fetch,
    cancelationToken: state.requestCancelation.token,
});

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(StatisticPage);
