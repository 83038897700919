import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from 'src/redux/actions/table';
import { SearchField } from 'src/shared';
import { getClassName, localLoad, localSave } from 'src/shared/lib';
import { FiltersContainer } from 'src/widgets';
import styles from './styles.module.scss';

interface Props {
    pageTitle: string;
}

export const BankUserFilter = ({ pageTitle }: Props) => {
    const queryName = getClassName('BankUserFilter', 'searchQuery');
    const searchQuery = useSelector(
        (state) => state.table?.[pageTitle]?.filters?.searchQuery
    );
    const dispatch = useDispatch();

    dispatch(setFilters(pageTitle, { searchQuery: localLoad(queryName, '') }));

    return (
        <div className={styles['wrapper']}>
            <FiltersContainer>
                <SearchField
                    key={searchQuery}
                    fullsize
                    placeholder="Введите ФИО или email"
                    value={searchQuery}
                    onChange={(value) => {
                        dispatch(
                            setFilters(pageTitle, {
                                searchQuery: value,
                            })
                        );
                        localSave(queryName, value);
                    }}
                />
            </FiltersContainer>
        </div>
    );
};
