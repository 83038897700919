import { isEmpty } from 'lodash';
import React, { FC, useLayoutEffect, useState } from 'react';
import { URL } from 'src/app/config';
import { ReceiptSchema } from 'src/entities/receipt';
import { isEmptyObj, get, log, useCloseCustomModal } from 'src/shared/lib';
import { Modal, ModalField } from 'src/shared/ui';
import { ReceiptItem } from './receipt-item';
import styles from './shows-info-about-receipt.module.scss';

interface Props {
    rrn: string;
    closeModal: () => void;
}

export const ShowsInfoAboutReceipt: FC<Props> = (props) => {
    const { rrn, closeModal } = props;
    const [isOpen, setOpen] = useState(false);
    const [receipt, setReceipt] = useState<ReceiptSchema>();

    const onClose = useCloseCustomModal(setOpen, closeModal);

    const getCashboxReceiptInfo = (rrn: string) => {
        get(URL.recipe + rrn)
            .then((r) => {
                setReceipt(r.data.payload);
                setOpen(true);
            })
            .catch((r) => {
                log(r);
            });
    };

    useLayoutEffect(() => {
        getCashboxReceiptInfo(rrn);
    }, [rrn]);

    return (
        <Modal
            isOpen={isOpen}
            header="Информация о чеке"
            closeButton="Закрыть"
            onClose={onClose}
        >
            {isEmptyObj(receipt) ? (
                <div className={styles.empty}>Нет чеков</div>
            ) : (
                <>
                    <ModalField
                        textField
                        label="Компания"
                        alignField="align-start"
                    >
                        {receipt?.companyName}
                    </ModalField>
                    <ModalField textField label="ИНН" alignField="align-start">
                        {receipt?.inn}
                    </ModalField>
                    <ModalField
                        textField
                        label="Место расчётов"
                        alignField="align-start"
                    >
                        {receipt?.address}
                    </ModalField>

                    <ModalField
                        textField
                        boldLabel
                        label="Кассовый чек №"
                        alignField="align-start"
                        className={styles.title}
                    >
                        {receipt?.fiscalReceiptNumber}
                    </ModalField>
                    <ModalField
                        textField
                        label="Приход"
                        alignField="align-start"
                    >
                        {receipt?.created}
                    </ModalField>
                    <ModalField
                        textField
                        label="Смена"
                        alignField="align-start"
                    >
                        {receipt?.shiftNumber}
                    </ModalField>
                    <ModalField
                        textField
                        label="Применяемая система налогообложения"
                        alignField="align-start"
                    >
                        {receipt?.taxation}
                    </ModalField>
                    <ModalField
                        textField
                        label="Телефон покупателя"
                        alignField="align-start"
                    >
                        {receipt?.phone}
                    </ModalField>
                    <ModalField
                        textField
                        label="Email покупателя"
                        alignField="align-start"
                    >
                        {receipt?.email}
                    </ModalField>
                    <ModalField
                        textField
                        label="Email отправителя чека"
                        alignField="align-start"
                    >
                        {receipt?.emailCompany}
                    </ModalField>
                    <ModalField
                        textField
                        label="Номер автомата"
                        alignField="align-start"
                    >
                        {receipt?.deviceCode}
                    </ModalField>

                    {!isEmpty(receipt?.items) &&
                        receipt?.items.map((product, i) => (
                            <ReceiptItem key={i} product={product} />
                        ))}

                    <ModalField
                        textField
                        boldLabel
                        label="ИТОГ"
                        alignField="align-start"
                        className={styles.title}
                    >
                        {receipt?.totalFiscal}
                    </ModalField>
                    <ModalField
                        textField
                        boldLabel
                        label="БЕЗНАЛИЧНЫМИ"
                        alignField="align-start"
                    >
                        {receipt?.cashless}
                    </ModalField>

                    <ModalField
                        textField
                        label="№ ФН"
                        alignField="align-start"
                        className={styles.title}
                    >
                        {receipt?.fnNumber}
                    </ModalField>
                    <ModalField
                        textField
                        label="Регистрационный номер ККТ"
                        alignField="align-start"
                    >
                        {receipt?.ecrRegistrationNumber}
                    </ModalField>
                    <ModalField textField label="№ ФД" alignField="align-start">
                        {receipt?.fiscalDocumentNumber}
                    </ModalField>
                    <ModalField textField label="№ ФП" alignField="align-start">
                        {receipt?.fiscalDocumentAttribute}
                    </ModalField>
                    <ModalField
                        textField
                        label="Версия ФФД"
                        alignField="align-start"
                    >
                        {receipt?.ffdVersion}
                    </ModalField>

                    <ModalField
                        textField
                        label="Адрес сайта для проверки чека"
                        alignField="align-start"
                        className={styles.title}
                    >
                        <a href={receipt?.fnsSite}>{receipt?.fnsSite}</a>
                    </ModalField>
                </>
            )}
        </Modal>
    );
};
