import React, { forwardRef, useState } from 'react';
import { ReactInputProps, InputProps } from './types';
import { ReactComponent as Eye } from './icons/eye.svg';
import { ReactComponent as EyeOff } from './icons/eyeOff.svg';
import { Input } from './input';

export const InputPassword = forwardRef<
    HTMLInputElement,
    InputProps & ReactInputProps
>(({ type, ...rest }, ref) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    return (
        <Input
            type={passwordVisible ? 'text' : 'password'}
            icon={
                <span
                    onClick={() => {
                        setPasswordVisible((prev) => !prev);
                    }}
                >
                    {!passwordVisible ? <Eye /> : <EyeOff />}
                </span>
            }
            {...rest}
            ref={ref}
        />
    );
});
