export const URL = {
    instructions:
        'https://www.sdm.ru/upload/iblock/34f/34fe0d1854227318abe3d2ab0b65b3e9.pdf',
    receiptUrl: '/operation/sendreceipt',

    // user
    loginUrl: '/login',
    logoutUrl: '/logout',
    userInfoUrl: '/user/info',
    userActivationUrl: '/login/activation/:uid',
    userActivateUrl: '/user/password/activate',
    companyRolesUrl: '/user/company/roles',
    merchantCreateUsersUrl: '/user/add',
    merchantUpdateUsersUrl: '/user/update',
    getMerchantUserInfo: '/user/',
    merchantUserUrl: '/user/users',
    resetUserPasswordUrl: '/user/reset/',
    forgotPasswordPageUrl: '/login/forgot',
    forgotPasswordRequestUrl: '/user/password/forgot',
    changePasswordUrl: '/user/password/change',

    // operations
    operationsUrl: '/operation/page',
    operationDetailUrl: '/operation/one/',
    operationRevertUrl: '/operation/reverse/',
    operationsSBPUrl: '/operation-sbp/page',
    operationsSBPIdentificatorsUrl: 'operation-sbp/page/idnSbp',
    operationSBPDetailUrl: '/operation-sbp/one/',
    operationSBPRevertUrl: '/operation-sbp/reverse/',
    operationsFileDownloadUrl: '/operation/export/',
    operationsRcMessagesUrl: '/operation/rc-messages/',
    operationsSBPFileDownloadUrl: '/operation-sbp/export',
    operationsSBPRcMessagesUrl: '/operation-sbp/rc-messages/',

    // settlement
    settlementUrl: '/settlement/page',
    settlementDetailsUrl: '/settlement/one/',
    settlementDetailsSummaryUrl: '/settlement/summary/',
    settlementFileDownloadUrl: '/settlement/export/',
    settlementDetailFileDownloadUrl: '/settlement/export/detail/',

    // statistic
    mpsChartUrl: '/statistic/mps',
    conversionChartUrl: '/statistic/conversion',
    statisticTableUrl: '/statistic/common',
    declineChartUrl: '/statistic/rc',
    statisticFileDownloadUrl: '/statistic/common/export/',

    dynamicsChartUrl: '/dynamics',

    // terminal
    terminalsUrl: '/terminal/all',
    inactivePeriodPerDays: 'terminal/inactivePeriodPerDays',
    terminalInfo: 'terminal/info/',
    terminalPageGroupUrl: '/terminal/group/all',
    terminalPageAvailableGroups: '/terminal/groups/',
    terminalPageTerminalsUrl: '/terminal/page',
    terminalPageAddGroupUrl: '/terminal/group/add',
    terminalPageRemoveGroupUrl: '/terminal/group/remove',
    terminalPageUpdateGroupUrl: '/terminal/change-group',
    terminalPageSetGroupUrl: '/terminal/set-group',
    terminalInactiveRequest: '/terminal/inactive-terminal',

    /*ecomm*/
    /*cashboxes*/

    ecommCashboxes: '/cashbox/all',
    addEcommCashbox: '/cashbox/create/',
    getEcommCashbox: '/cashbox/info/',
    updateEcommCashbox: '/cashbox/update/',
    getCashboxAvailableFfd: '/cashbox/available-ffd',
    getCashboxRole: '/cashbox/role/',

    // support
    feedbackUrl: '/support/feedback',
    supportSubjects: '/support/subjects',
    bankSupportMsg: '/support/header',

    /*merchantkeys*/

    ecommKeys: '/ecommkey/all',
    getEcommKeyCashboxes: '/ecommkey/cashboxes/',
    getEcommKey: '/ecommkey/info/',
    updateEcommKey: '/ecommkey/update/',

    /*payments*/

    /*paymentsall*/

    payments: '/orderEcomm/all',

    /*payment*/
    payment: '/payment/',
    /*recipes of payments*/
    recipes: '/orderEcomm/info/',
    recipe: '/receipt/info/',

    /*schedules*/

    schedules: '/schedule/all',
    setSchedule: '/schedule/update',

    einvoiceAll: '/einvoice/all',
    einvoice: '/einvoice',
    einvoiceData: '/einvoice/data',
    einvoiceAdd: '/einvoice/add',
    einvoiceSend: '/einvoice/send/',
    einvoiceDelete: '/einvoice/delete/',
    einvoiceDownloadQrcUrl: '/einvoice/export-qr',

    // bank urls:
    reversal: '/reversal/all/',
    reversalExport: '/reversal/export/',
    companyCreateUsersUrl: '/company/user/create/',
    companyTerminalsUrl: '/company/terminals/',
    companyUsersUrl: '/company/users/',
    resetMerchantUserPassword: '/user/merchant/reset/',
    updateMerchantUserUrl: '/user/merchant/',
    merchantUserInfoUrl: '/user/merchant/',
    bankUpdateUsersUrl: '/user/update/',
    getBankUserInfo: '/user/',
    platformCashBoxOneUrl: '/cashbox/info/',
    updatePlatformCashBox: 'cashbox/update/',

    cashboxAll: '/cashbox/all/',
    addCashbox: '/cashbox/create/',
    updateCashbox: '/cashbox/update/',
    getaCashboxInfo: '/cashbox/info/',
    getEcommStatus: '/ecommkey/role/ecomm/info/',
    saveEcommStatus: '/ecommkey/role/ecomm/createOrDelete/',
    ecommkeyall: '/ecommkey/all/',
    addEcommkey: '/ecommkey/create/',
    updateEcommkey: '/ecommkey/update/',
    getEcommkeyInfo: '/ecommkey/info/',
    getEcommkeyCashboxes: '/ecommkey/cashbox/all/',
    userAttempts: '/user/merchant/attempts/',
    companyUrl: '/company',
    companyGroups: '/company/groups',
    companyRoles: '/company/roles',
    subCompanyUrl: '/company/subcompany/',
    subCompanySetUrl: '/company/subcompany/set',
    subCompanyRemoveUrl: '/company/subcompany/remove',
    companyFindSelectUrl: '/company/find',
    bankUserUrl: '/user/users',
    bankCreateUsersUrl: '/user/add',
    createPlatformCashBox: 'cashbox/create/platform',

    /*реестры платформы*/
    platformCashBoxUrl: '/cashbox/allCreatedByBank',

    /*кассы  платформы в работе*/
    platformCashBoxErrorUrl: '/cashbox/errors',

    /*кассы тсп*/
    cashBoxes: 'ecommkey/all',

    /*реестры кассы*/
    cashBoxRegistries: '/cashbox/registry/allRegistersInCashboxOfBank',

    /*чеки*/

    cashBoxReceipt: '/receipt/getAllReceiptsOnIdRegistry',

    cashBoxReceiptInfo: '/receipt/getAllErrorsOnIdReceipt/',
};

export const navRoutes = {
    statistic: '/statistic',
    dynamics: '/dynamics',
    inventory: '/inventory',
    settlement: '/settlement/',
    settlementLastUrl: '/settlement',
    settlementDate: '/settlement/:date',
    operations: '/operations/',
    operationLastUrl: '/operations',
    operationsId: '/operations/:id',
    operationsSBP: '/operations-sbp/',
    operationSBPLastUrl: '/operations-sbp',
    operationsSBPId: '/operations-sbp/:id',

    support: {
        root: '/support',
        faq: '/support/faq',
        main: '/support/main',
        system: '/support/system',
    },

    settings: '/settings/',
    settingsUser: '/settings/user',
    settingsUsers: '/settings/users',
    settingsTerminals: '/settings/terminals',
    ecomm: '/ecomm/',
    payments: '/payment/',
    paymentId: '/payment/:id',
    recipes: '/recipes/',
    einvoice: '/einvoice',
    einvoiceId: '/einvoice/:id',
    schedule: '/schedule',

    // bank navigation

    companyId: '/company/:id',
    company: '/company',
    users: '/users',
    cashBoxes: '/cashboxes',
    platformCashBox: '/cashbox/allCreatedByBank',
    platformErrorCashBox: '/cashbox/errors',
    registresOfCashbox: '/cashbox/registry/allRegistersInCashboxOfBank/:id',
    receiptsOfCashbox: '/receipt/getAllReceiptsOnIdRegistry/:id',
    groups: '/groups',
    settlementDateId: '/settlement/:date/:id',
    reversal: '/reversal',
};
