import React from 'react';
import styles from './styles.module.scss'

enum Variant {
    INACTIVE = 'inActive',
    HIDDEN = 'hidden',
}

const InactiveIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.87091 15.3339H14.0992C14.6779 15.3339 15.1369 15.1543 15.4761 14.7951C15.8254 14.4359 16 13.9919 16 13.463C16 13.1337 15.9102 12.8244 15.7306 12.5351L9.60898 1.60895C9.43935 1.29963 9.20486 1.06514 8.90552 0.905492C8.61615 0.745841 8.31681 0.666016 8.00748 0.666016C7.6782 0.666016 7.36389 0.745841 7.06455 0.905492C6.77518 1.06514 6.54069 1.29963 6.36108 1.60895L0.269411 12.5351C0.0898036 12.8244 0 13.1337 0 13.463C0 13.9919 0.169629 14.4359 0.508887 14.7951C0.858123 15.1543 1.31213 15.3339 1.87091 15.3339ZM7.99252 13.1786C7.68319 13.1786 7.41378 13.0639 7.18428 12.8344C6.95479 12.6149 6.84004 12.3405 6.84004 12.0112C6.84004 11.7019 6.95479 11.4325 7.18428 11.203C7.41378 10.9735 7.68319 10.8587 7.99252 10.8587C8.3218 10.8587 8.60118 10.9735 8.83068 11.203C9.06018 11.4325 9.16994 11.7019 9.15996 12.0112C9.14998 12.3405 9.03524 12.6149 8.81571 12.8344C8.5962 13.0639 8.3218 13.1786 7.99252 13.1786ZM8.00748 9.87088C7.76801 9.87088 7.57343 9.80103 7.42376 9.66134C7.27409 9.51167 7.19426 9.31709 7.18428 9.07762L7.07951 4.97659C7.06954 4.6972 7.14936 4.47269 7.31899 4.30306C7.48862 4.13343 7.71812 4.04862 8.00748 4.04862C8.27689 4.04862 8.49641 4.13343 8.66604 4.30306C8.84565 4.47269 8.93545 4.6972 8.93545 4.97659L8.81571 9.07762C8.80574 9.31709 8.72591 9.51167 8.57624 9.66134C8.43654 9.80103 8.24696 9.87088 8.00748 9.87088Z"
                fill="#828282"
            />
        </svg>
    );
};

interface Props {
    variant?: Variant;
}

export const TerminalStatusIcon = ({ variant = Variant.HIDDEN }: Props) => {
    // TODO 631: test this with actual inactive terminals
    return <div className={styles['wrapper']}>{VARIANT_TO_ICON_MAP[variant]}</div>;
};

const VARIANT_TO_ICON_MAP: Record<Variant, JSX.Element> = {
    [Variant.INACTIVE]: <InactiveIcon />,
    [Variant.HIDDEN]: <div style={{ width: 16 }}></div>,
};

TerminalStatusIcon.variant = Variant;
