import compose from 'compose-function';
import { withChartConfig } from './withChartConfig';
import { withNotifications } from './withNotifications';
import { withStore } from './withStore';
import { withLogVersionApp } from './withLogVersionApp';
import { withBrowserCheck } from './withBrowserCheck';
import { withErrorBoundary } from './withErrorBoundary';

export const withProviders = compose(
    withErrorBoundary,
    withStore,
    withBrowserCheck,
    withChartConfig,
    withLogVersionApp,
    withNotifications
);
