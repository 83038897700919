import React, { FC } from 'react';
import { DocumentsIcon, Title } from 'src/shared/ui';
import { classNames } from 'src/shared/lib';
import styles from './document-links.module.scss';

interface DocumentLinksProps {
    className?: string;
}

export const DocumentLinks: FC<DocumentLinksProps> = ({ className = '' }) => {
    return (
        <div className={classNames('', {}, [className])}>
            <Title icon={DocumentsIcon}>Документы</Title>
            <div className={styles.links}>
                <a
                    href="https://www.sdm.ru/upload/iblock/cf9/cf99e8589d30c008140cb7baf627ea97.pdf"
                    target="_blank"
                    rel="noreferrer nofollow"
                >
                    Правила по операциям эквайринга
                </a>
                <a
                    href="https://www.sdm.ru/upload/iblock/021/02185fb3c0e073b65f6c8fa5a0fe8ced.pdf"
                    target="_blank"
                    rel="noreferrer nofollow"
                >
                    Правила обслуживания <br /> Личного Кабинета
                </a>
                <a
                    href="https://www.sdm.ru/upload/iblock/396/3969bd64174be6169d519cac0f8cb024.pdf"
                    target="_blank"
                    rel="noreferrer nofollow"
                >
                    Тарифы Банка
                </a>
            </div>
        </div>
    );
};
