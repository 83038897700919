import React, { Component } from 'react';
import EinvoiceFilter from './EinvoiceFilter';

import { EinvoiceTable } from 'src/widgets/einvoice-table';
import { LOCAL_STORAGE_TABLE_NAMES } from 'src/shared/lib';

const pageTitle = LOCAL_STORAGE_TABLE_NAMES.eInvoiceMerchant;

class MerchantEinvoicePage extends Component {
    render() {
        return (
            <div className="einvoice-page">
                <EinvoiceFilter pageTitle={pageTitle} />
                <EinvoiceTable pageTitle={pageTitle} />
            </div>
        );
    }
}

export default MerchantEinvoicePage;
