import React, { FC, memo } from 'react';
import { Button } from 'src/shared';
import { classNames } from 'src/shared/lib';
import styles from './trigger-button.module.scss';

interface TriggerButtonProps {
    onClick: () => void;
    theme?: 'custom' | 'default';
    className?: string;
    children?: string;
}

export const TriggerButton: FC<TriggerButtonProps> = memo((props) => {
    const {
        onClick,
        theme = 'custom',
        children = 'Выгрузить',
        className,
    } = props;

    if (theme === 'custom') {
        return (
            <button
                onClick={onClick}
                className={classNames(styles.button, {}, [className])}
            >
                {children}
            </button>
        );
    }

    return (
        <Button onClick={onClick} className={className}>
            {children}
        </Button>
    );
});
