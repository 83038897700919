import React, { useEffect } from 'react';
import { ShowsMessageFromBank } from 'src/features/shows-message-from-bank';
import { getAppTitle } from 'src/shared/lib';
import { Navigation } from './navigation';
import { Header } from './header';
import { Footer } from './footer';
import styles from './page-layout.module.scss';
import { PAGE_CONTENT_CHANGED } from './lib';

interface Props {
    navigation: React.ReactNode;
    pageTitle: string | React.ReactNode;
    userName: string;
    userDropdownNavigation: React.ReactNode;
    homePageUrl: string;
    footerNavigation: React.ReactNode;
}

const PageLayout: React.FC<Props> = ({
    children,
    navigation,
    pageTitle,
    userDropdownNavigation,
    userName,
    homePageUrl,
    footerNavigation,
}) => {
    const isMerchantApp = getAppTitle() === 'merchant';

    useEffect(() => {
        const pageElementScroll = document.getElementById(
            'page-element-scroll'
        );

        const setScrollbarWidth = () => {
            if (pageElementScroll) {
                document.documentElement.style.cssText = `--scrollbar-width: ${
                    pageElementScroll.offsetWidth -
                    pageElementScroll.clientWidth
                }px`;
            }
        };

        setTimeout(() => {
            setScrollbarWidth();
        }, 500);

        ['resize', PAGE_CONTENT_CHANGED].forEach((event) =>
            window.addEventListener(event, setScrollbarWidth)
        );

        return () =>
            ['resize', PAGE_CONTENT_CHANGED].forEach((event) =>
                window.removeEventListener(event, setScrollbarWidth)
            );
    }, []);

    return (
        <div className={styles['page']}>
            <Navigation
                homePageUrl={homePageUrl}
                userName={userName}
                userDropdownNavigation={userDropdownNavigation}
            >
                {navigation}
            </Navigation>
            <main id="page-element-scroll" className={styles['main-part']}>
                <header
                    className={`${styles['header']} ${styles['container']}`}
                >
                    <Header
                        pageTitle={pageTitle}
                        userDropdownNavigation={userDropdownNavigation}
                        userName={userName}
                    />
                    {isMerchantApp && <ShowsMessageFromBank />}
                </header>

                <article
                    className={`${styles['content']} ${styles['container']}`}
                >
                    {children}
                </article>
                <footer
                    className={`${styles['footer']} ${styles['container']}`}
                >
                    <Footer>{footerNavigation}</Footer>
                </footer>
            </main>
        </div>
    );
};

export { PageLayout };
