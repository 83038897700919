type BrowserName =
    | 'Chrome'
    | 'Firefox'
    | 'Edge'
    | 'Internet Explorer'
    | 'Safari'
    | 'Mobile Safari'
    | 'Samsung Browser';

const BrowserConfig: { [key: string]: number } = {
    Chrome: 108,
    Firefox: 109,
    Edge: 108,
    'Internet Explorer': 11,
    Safari: 13,
    'Mobile Safari': 15,
    'Samsung Browser': 15,
};

export { BrowserConfig };
export type { BrowserName };
