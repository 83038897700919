import React, { FC } from 'react';
import styles from './light-table.module.scss';

interface LightTableProps {
    borderTop?: boolean;
    thead?: React.ReactNode;
}

export const LightTable: FC<LightTableProps> = ({
    borderTop,
    thead,
    children,
}) => {
    const computedClassName = [
        styles['light-table'],
        Boolean(borderTop) ? styles['border-top'] : '',
    ].join(' ');

    return (
        <div className={computedClassName}>
            <table>
                <thead>{thead}</thead>
                <tbody>{children}</tbody>
            </table>
        </div>
    );
};
