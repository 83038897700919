import React from 'react';
import { SelectorWithGroups } from 'src/entities/selector-with-groups';
import { MainTerminal, Terminal } from 'src/features/select-terminals/config';
import { filterTerminals } from 'src/features/select-terminals/lib';
import { TerminalGroup, TerminalStatus } from 'src/entities/terminal';
import { TerminalTip } from 'src/features/select-terminals/ui';
import { IndicatorColorVariant } from 'src/entities/selector-with-groups/config';
import { TerminalStatusIcon } from '../terminal-status-icon';

interface Props {
    selected: number[];
    terminalsList: MainTerminal[];
    onChange: (selected: number[]) => void;
    handleTerminalClick: (terminalId: number) => void;
    query: string;
    selectedOnly: boolean;
    inventoryDisplayFilters: string[];
    isOpen: boolean;
}

export const ReworkedTerminalsList = ({
    selected,
    terminalsList,
    onChange,
    handleTerminalClick,
    query,
    selectedOnly,
    isOpen,
    inventoryDisplayFilters,
}: Props) => {
    const handleOrganisationTitleClick = (organisation: MainTerminal) => {
        const allTerminals = organisation.terminalGroups.flatMap((group) =>
            group.terminals.map((terminal) => terminal.id)
        );

        allTerminals.every((id) => selected.includes(id))
            ? onChange(
                  selected.filter(
                      (selected) => !allTerminals.includes(selected)
                  )
              )
            : onChange([
                  ...selected,
                  ...allTerminals.filter((id) => !selected.includes(id)),
              ]);
    };

    const handleGroupClick = (group: MainTerminal) =>
        handleOrganisationTitleClick(group);

    const get = (organisation: MainTerminal) => {
        return organisation.terminalGroups.map((terminalGroup) =>
            terminalGroup.terminals.map((terminal) => terminal)
        );
    };

    const nameGetter = (terminal: Terminal) => {
        return `${terminal.uid} ${terminal.name}`;
    };

    const isSelected = (data: MainTerminal) =>
        data.terminalGroups.some((terminalGroup) =>
            terminalGroup.terminals.some((terminal) =>
                selected.includes(terminal.id)
            )
        );

    const isFullySelected = (data: MainTerminal) =>
        data.terminalGroups.every((terminalGroup) =>
            terminalGroup.terminals.every((terminal) =>
                selected.includes(terminal.id)
            )
        );

    const filterData = (data: MainTerminal[]) => {
        return filterTerminals({
            query,
            terminalsList: data,
            selected,
            selectedOnly,
            inventoryDisplayFilters,
        });
    };

    return (
        <SelectorWithGroups<MainTerminal, TerminalGroup, Terminal, number>
            getRowId={(data) => data.id}
            data={terminalsList}
            rowsGetter={get}
            rowNameGetter={nameGetter}
            selected={selected}
            isCurrentlySelected={function (terminalId: number): boolean {
                return selected.includes(terminalId);
            }}
            query={query}
            selectedOnly={selectedOnly}
            inventoryDisplayFilters={inventoryDisplayFilters}
            handleRowClick={function (id: number): void {
                handleTerminalClick(id);
            }}
            isSelected={function (id: number): boolean {
                return selected.includes(id);
            }}
            groupNameGetter={function (data: MainTerminal): string {
                return data.name;
            }}
            handleGroupClick={handleGroupClick}
            isGroupSelected={isSelected}
            isGroupFullySelected={isFullySelected}
            filterData={filterData}
            groupsGetter={function (data: MainTerminal): TerminalGroup[] {
                return data.terminalGroups;
            }}
            tooltip={(data, tooltipTitle, dotColor) =>
                isOpen ? (
                    <TerminalTip
                        variant={
                            data.disabled
                                ? TerminalTip.tipVariant.DISABLED
                                : MAP_ACTIVITY_TO_VARIANT[data.activity]
                        }
                        text={data.name}
                        title={tooltipTitle}
                        dotColor={dotColor ?? IndicatorColorVariant.BLUE}
                    />
                ) : (
                    <></>
                )
            }
            statusIcon={(data) => (
                <TerminalStatusIcon
                    {...((data.activity === 'INACTIVE' ||
                        (data.activity === 'DISABLED' && !data.disabled)) && {
                        variant: TerminalStatusIcon.variant.INACTIVE,
                    })}
                />
            )}
            isRowDisabled={(data) => Boolean(data.disabled)}
            emptyPlaceholderText="Терминалы подходящие под заданные фильтры не найдены."
        />
    );
};

// было решено интерпретировать disabled как inactive в случае если data.disabled === true
const MAP_ACTIVITY_TO_VARIANT: Record<TerminalStatus, TerminalStatus> = {
    [TerminalStatus.DISABLED]: TerminalStatus.INACTIVE,
    [TerminalStatus.INACTIVE]: TerminalStatus.INACTIVE,
    [TerminalStatus.ACTIVE]: TerminalStatus.ACTIVE,
};
