import React, { FC, memo, useState } from 'react';
import { FileFormat } from '../../model/types';
import { ModalBody } from '../modal-body/modal-body';
import { TriggerButton } from '../trigger-button/trigger-button';
import { useSelector } from 'react-redux';
import { filterObject } from 'src/shared/lib/filterObject';
import { getAppTitle } from 'src/shared/lib';

type Filters = Record<string, unknown>;

interface DownloadReportProps {
    defaultFileName: string;
    url: string;
    getFilterCallback?: () => Filters;
    pageTitle: string;
    isDetailed?: boolean;
    availableFormats?: FileFormat[];
    themeTriggerButton?: 'custom' | 'default';
    nameTriggerButton?: string;
    className?: string;
    useCommonFilters?: boolean;
    initialFilter?: Filters;
}

export const DownloadReportStore: FC<DownloadReportProps> = memo((props) => {
    const {
        themeTriggerButton,
        nameTriggerButton,
        className,
        getFilterCallback,
        pageTitle,
        useCommonFilters,
        initialFilter,
        ...otherProps
    } = props;

    const [isOpen, setOpen] = useState(false);

    const changeToggleModal = () => {
        setOpen((prev) => !prev);
    };

    const table = useSelector((state) => state.table?.[pageTitle]);
    const commonFilters = useSelector((state) => state.table?.commonFilters);

    const filters = table
        ? {
              ...table.filters,
              startDate: table.startDate,
              endDate: table.endDate,
              ...(useCommonFilters && commonFilters),
          }
        : getFilterCallback?.();

    return (
        <>
            <TriggerButton
                theme={themeTriggerButton}
                onClick={changeToggleModal}
                className={className}
            >
                {nameTriggerButton}
            </TriggerButton>
            {isOpen && (
                <ModalBody
                    filters={filterObject(
                        {
                            ...initialFilter,
                            ...filters,
                            qrcType:
                                getAppTitle() === 'merchant'
                                    ? filters?.qrcTypes
                                    : undefined,
                            qrcTypes:
                                getAppTitle() === 'bank'
                                    ? filters?.qrcTypes
                                    : undefined,
                        },
                        ['offset', 'pageSize']
                    )}
                    onToggle={changeToggleModal}
                    {...otherProps}
                />
            )}
        </>
    );
});
