import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { forgotPassword } from 'src/redux/actions/auth';
import { PublicFormWidget } from 'src/widgets';
import { Input, Button } from 'src/shared/ui';

import { ForgotPasswordFormFields, schema } from '../config';

export const Form = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { fetch } = useSelector((state) => state.fetch);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ForgotPasswordFormFields>({
        defaultValues: { email: '' },
        resolver: yupResolver(schema),
    });

    const submit = (values: ForgotPasswordFormFields) => {
        dispatch(forgotPassword(values, history));
    };

    return (
        <PublicFormWidget header="Восстановление пароля">
            <div>
                <form onSubmit={handleSubmit(submit)}>
                    <Input
                        {...register('email')}
                        placeholder="Введите E-Mail"
                        error={Boolean(errors.email)}
                        fluid
                    />
                    <Button
                        fluid
                        type="submit"
                        loading={fetch}
                        disabled={fetch}
                    >
                        Восстановить пароль
                    </Button>
                </form>
            </div>
        </PublicFormWidget>
    );
};
