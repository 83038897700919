import React from 'react';

export const faq = [
    {
        q: 'Какие документы необходимо предоставить для установки/снятия ПОС-терминалов?',
        a: (
            <div>
                на Установку:
                <br />
                1. Заявление на установку (форма заявления на сайте банка:
                раздел "бизнесу" ->{' '}
                <a href="https://www.sdm.ru/business/addtional-services/acquiring/">
                    эквайринг
                </a>{' '}
                -> раздел "Тарифы и документы"
                <br />
                2. Согласие БКИ на всех участников общества или ИП
                <br />
                3. Договор эквайринга
                <br />
                4. Копии паспортов на всех участников + Ген.Директор общества
                <br />
                5. Договор аренды на адрес установки терминала
                <br />
                <br />
                на Снятие:
                <br />
                Заявление на снятие (форма заявления на сайте банка: раздел
                "бизнесу" ->
                <a href="https://www.sdm.ru/business/addtional-services/acquiring/">
                    {' '}
                    эквайринг
                </a>{' '}
                -> внизу раздел "Тарифы и документы")
                <br />
            </div>
        ),
    },
    {
        q: 'Что необходимо сделать для приема платежей по бесконтактным средствам платежа?',
        a: (
            <div>
                1. Оставить заявку на сайте банка: раздел "бизнесу" ->{' '}
                <a href="https://www.sdm.ru/business/addtional-services/acquiring/">
                    эквайринг
                </a>{' '}
                -> кнопка "оставить заявку" или
                <br />
                2. Связаться по телефону{' '}
                <a href="tel:+74957059090">+7 495 705 90 90</a>{' '}
                <a href="tel:+78002000223">+7 800 200 02 23</a> или
                <br />
                3. Отправить письмо с заполненным заявлением о
                регистрации/изменении данных о точках реализации Клиента по
                эл.почте на <a href="mailto:sdm@sdm.ru">sdm@sdm.ru</a> или
                <br />
                4. Отправить письмо с заполненным заявлением о
                регистрации/изменении данных о точках реализации Клиента через
                банк-клиент
                <br />
            </div>
        ),
    },
    {
        q: 'ПОС-терминал не заряжается, что делать?',
        a: (
            <div>
                1. Позвонить в банк по телефону{' '}
                <a href="tel:+74957059090">+7 495 705 90 90</a>,{' '}
                <a href="tel:+78002000223">+7 800 200 02 23</a> и назвать модель
                терминала и контакты точки (название организации, адрес
                установки терминала, телефон для связи) или
                <br />
                2. Оставить заявку в данном ЛК, в банк-клиенте или по почте на
                адрес <a href="mailto:sdm@sdm.ru">sdm@sdm.ru</a> с указанием
                названия организации, адреса установки терминала, телефона для
                связи
            </div>
        ),
    },
    {
        q: 'Превышен лимит по ПОС-терминалу, что делать?',
        a: (
            <div>
                1. Позвоните в банк по телефону{' '}
                <a href="tel:+74957059090">+7 495 705 90 90</a>,{' '}
                <a href="tel:+78002000223">+7 800 200 02 23</a> и назвать модель
                терминала и контакты организации (адрес установки, id терминала,
                телефон для связи) или
                <br />
                2. Оставить заявку через данный ЛК, банк-клиент или по почте на
                адрес <a href="mailto:sdm@sdm.ru">sdm@sdm.ru</a> с указанием
                модели терминала и контактов организации (адрес установки, id
                терминала, телефон для связи)
                <br />
            </div>
        ),
    },
    {
        q: 'Не работает ПОС-терминал, что делать?',
        a: (
            <div>
                1. Позвоните в банк по телефону{' '}
                <a href="tel:+74957059090">+7 495 705 90 90</a>,{' '}
                <a href="tel:+78002000223">+7 800 200 02 23</a>
                <br />
                2. Назвать модель терминала и контакты организации (адрес
                установки, id устройства, телефон для связи)
                <br />
                3. Оставить заявку в свободной форме в ЛК, в банк-клиенте или по
                почте на адрес <a href="mailto:sdm@sdm.ru">sdm@sdm.ru</a>
                <br />
            </div>
        ),
    },
    {
        q: 'Клиент не получил денежные средства по операции «возврат», что делать?',
        a: (
            <div>
                1. Позвоните в банк по телефону{' '}
                <a href="tel:+74957059090">+7 495 705 90 90</a>,{' '}
                <a href="tel:+78002000223">+7 800 200 02 23</a> и назвать
                оператору название организации, id терминала, сумму и дату
                операции или
                <br />
                2. Оставить заявку в данном ЛК, в банк-клиенте или по почте на
                адрес <a href="mailto:sdm@sdm.ru">sdm@sdm.ru</a>, с указанием
                названия организации, id терминала, суммы и даты операции.
                <br />
            </div>
        ),
    },
    {
        q: 'Как происходит обучение персонала по приему платежей по картам и бесконтактным средствам платежа?',
        a: (
            <div>
                1. Через ЛК или банк-клиент в свободной форме оставить заявку на
                повторное обучение, обязательно указать контакты для связи
                <br />
                2. По указанным контактам в заявке, сотрудник банка (инженер)
                связывается с организацией и согласует дату и время обучения
                <br />
                3. При обучении выдается инструкции по работе с пос-терминалом
                (инструкции по работе с пос-терминалами по модельно по ссылке),
                а также подписывется акт о проведении инструктажа (форма акта на
                сайте банка: раздел "бизнесу" ->{' '}
                <a href="https://www.sdm.ru/business/addtional-services/acquiring/">
                    эквайринг
                </a>{' '}
                -> раздел "Тарифы и документы")
                <br />
            </div>
        ),
    },
    {
        q: 'Как начать принимать платежи по картам на моем сайте, какие документы потребуются для подключения?',
        a: (
            <div>
                1. Оставить заявку на подключение интернет эквайринга через ЛК
                или банк-клиент (форма заявления на сайте банка: раздел
                "бизнесу" ->{' '}
                <a href="https://www.sdm.ru/business/addtional-services/acquiring/">
                    эквайринг
                </a>{' '}
                -> раздел "Тарифы и документы")
                <br />
                2. Свяжется сотрудник клиентской службы для подтверждения
                заявки, уточнит данные по сайту (название сайта) и
                проинформирует какие документы необходимы для подключения
                интернет эквайринга
                <br />
            </div>
        ),
    },
    {
        q: 'Опишите, пожалуйста, как происходит процесс технической интеграции для приема платежей по картам на моем сайте?',
        a: (
            <div>
                1. По оставленным контактам в заявлении (форма заявления на
                сайте банка: раздел "бизнесу" ->{' '}
                <a href="https://www.sdm.ru/business/addtional-services/acquiring/">
                    эквайринг
                </a>{' '}
                -> раздел "Тарифы и документы"), технический специалист
                связывается с представителем торговой точки
                <br />
                2. Передается инструкция банка (API) и/или готовый модуль CMS
                <br />
                3. Осуществляется настройка и тестирование сайта на тестовом
                стенде. Проверка платежей и возвратов
                <br />
                4. Осуществляется перенастройка терминала на боевые реквизиты,
                передача их организации по актам приема-передачи
                <br />
            </div>
        ),
    },
    {
        q: 'Сколько времени потребуется для организации приема платежей на сайте?',
        a: 'От 3 дней (в зависимости от степени готовности сайта организации на прием платежей)',
    },
    {
        q: 'Какие требования к сайту?',
        a: (
            <div>
                С предъявляемыми требованиями вы можете ознакомиться на нашем{' '}
                <a href="https://sdm.ru/business/addtional-services/acquiring/">
                    сайте
                </a>
            </div>
        ),
    },
    {
        q: 'Существуют ли какие-либо требования к товарам или услугам, предоставленным на сайте?',
        a: (
            <div>
                С предъявляемыми требованиями вы можете ознакомиться на нашем{' '}
                <a href="https://sdm.ru/business/addtional-services/acquiring/">
                    сайте
                </a>
            </div>
        ),
    },
    {
        q: 'Можно ли принимать оплату по интернет-эквайрингу без сайта?',
        a: (
            <div>
                1. Через личный кабинет формируете счёт на оплату, который
                направляется на e-mail, СМС или мессенджер клиенту;
                <br />
                2. Клиент получает ссылку, по которой переходит для оплаты;
                <br />
                3. После успешной оплаты вы увидите подтверждение в личном
                кабинете, а клиенту приходит электронный чек.
            </div>
        ),
    },
    {
        q: 'Как работает раздел «Счета»?',
        a: (
            <div>
                Для вашего удобства мы разместили инструкцию по использованию
                раздела «Счета» (отправка ссылки на оплату).
                <br />
                <a
                    href="https://www.sdm.ru/upload/iblock/d1c/d1cb1701278eb834d58ae868ef6e7823.pdf"
                    target="_blank"
                    rel="noreferrer"
                >
                    Инструкция.pdf
                </a>
            </div>
        ),
    },
];
