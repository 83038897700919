import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { navRoutes } from 'src/app/config';
import { EmailIcon, PhoneIcon } from 'src/shared/ui';

import styles from './sidebar.module.scss';

interface SideBarkLinkProps {
    url: string;
    icon: React.ReactNode;
}

const SidebarLink: FC<SideBarkLinkProps> = ({ url, icon, children }) => {
    return (
        <Link to={url} className={styles['sidebar__link']}>
            <div className={styles['icon']}>{icon}</div>
            <span>{children}</span>
        </Link>
    );
};

export const Sidebar = () => {
    return (
        <div className={styles['sidebar']}>
            <h2 className={styles['sidebar__header']}>Остались вопросы?</h2>
            <p className={styles['sidebar__text']}>
                Если Вы не нашли нужный ответ, воспользуйтесь формой обратной
                связи или свяжитесь со службой технической поддержки.
            </p>
            <SidebarLink icon={EmailIcon} url={navRoutes.support.main}>
                Обратная связь
            </SidebarLink>
            <SidebarLink icon={PhoneIcon} url={navRoutes.support.main}>
                Техническая поддержка
            </SidebarLink>
        </div>
    );
};
