import React, { ChangeEvent, KeyboardEvent, FC, useState } from 'react';
import { classNames } from 'src/shared/lib';
import { SearchIcon } from 'src/shared/ui';

import styles from './search-field.module.scss';

interface Props {
    onChange: (value: string) => void;
    value: string;
    placeholder?: string;
    fullsize?: boolean;
    className?: string;
    type?: 'all' | 'number';
}

export const SearchField: FC<Props> = ({
    onChange,
    placeholder,
    value,
    fullsize,
    className,
    type = 'all',
}) => {
    const [query, setQuery] = useState<string>(value ?? '');

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        event.key === 'Enter' && onChange(query);
    };

    const handleBlur = () => {
        if (query === '') onChange(query);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (type === 'all') {
            setQuery(value);
        }

        if (type === 'number') {
            if (/^\d*$/.test(value)) {
                setQuery(value);
            }
        }
    };

    const finalClassName = classNames(
        styles['search-field'],
        { [styles['search-field--fullsize']]: fullsize },
        [className]
    );

    return (
        <div className={finalClassName}>
            <button
                className={styles['search-icon']}
                onClick={() => onChange(query)}
            >
                {SearchIcon}
            </button>
            <input
                value={query}
                placeholder={placeholder}
                className={styles['input']}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={handleKeyPress}
            />
        </div>
    );
};
