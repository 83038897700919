const filterObject = <T extends object, K extends keyof T>(
    object: T,
    fields: K[]
): Omit<T, K> => {
    return Object.fromEntries(
        Object.entries(object).filter(([key]) => !fields.includes(key as K))
    ) as Omit<T, K>;
};

export { filterObject };
