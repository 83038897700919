import { MutableRefObject, useEffect, useRef } from 'react';

type TimerId = MutableRefObject<ReturnType<typeof setTimeout>>;

/**
 * Закрывает кастомную модалку с отложенным выполенением callback
 * @param setState функция изменения state флага isOpen
 * @param callback функция для отложенного выполнения
 * @returns функцию для закрытия модалки
 */

export function useCloseCustomModal(
    setState: (arg: boolean) => void,
    callback?: () => void
) {
    const timerRef = useRef() as TimerId;

    const onClose = () => {
        setState(false);
        timerRef.current = setTimeout(() => {
            callback?.();
        }, 0);
    };

    useEffect(() => {
        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    return onClose;
}
