import { useCallback, useEffect } from 'react';

export const useClickOutside = (
    clickOutsideCallback: () => void,
    ref: React.RefObject<HTMLDivElement>,
    exceptionRef?: React.RefObject<HTMLDivElement>
) => {
    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (
                exceptionRef?.current &&
                exceptionRef.current.contains(event.target as HTMLDivElement)
            ) {
                return;
            }

            if (
                ref.current &&
                !ref.current.contains(event.target as HTMLDivElement)
            ) {
                clickOutsideCallback();
            }
        },
        [clickOutsideCallback, exceptionRef, ref]
    );

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside, ref]);
};
