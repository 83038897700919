import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { PaginatorArrow } from './paginator-arrow';
import { setPageSize } from 'src/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { nextPage, previousPage, setOffset } from 'src/redux/actions/table';
import { PaginatorSelect } from './paginator-select';

interface Props {
    name: string;
    children: React.ReactNode;
}

export const Paginator = ({ name, children }: Props) => {
    const dispatch = useDispatch();
    const pageSizeLocalstorageName = `${name}-pagesize`;
    const pageSize = useSelector((state) => state.table?.[name]?.pageSize);
    const offset = useSelector((state) => state.table?.[name]?.offset);
    const fetching = useSelector((state) => state.table?.[name]?.fetching);

    const totalCount = useSelector(
        (state) => state.table?.[name]?.totalCountForPagination
    );

    useEffect(() => {
        dispatch(
            setPageSize(
                name,
                Number(localStorage.getItem(pageSizeLocalstorageName)) || 10
            )
        );
    }, [dispatch, name, pageSizeLocalstorageName]);

    useEffect(() => {
        if (offset === undefined) {
            dispatch(setOffset(name, 0));
        }
    }, [dispatch, name, offset]);

    useEffect(() => {
        localStorage.setItem(pageSizeLocalstorageName, pageSize);
    }, [pageSize, pageSizeLocalstorageName]);

    const upperPaginationBoundary =
        offset + pageSize < totalCount ? offset + pageSize : totalCount;

    const bottomPaginationBoundary = offset + 1;

    return (
        <div className={styles['wrapper']}>
            {children}
            {
                <div className={styles['pagination-wrapper']}>
                    <div className={styles['controls']}>
                        <span>
                            Показано{' '}
                            <span className={styles['highlight']}>
                                {totalCount && !fetching
                                    ? totalCount === '0'
                                        ? '0'
                                        : `${bottomPaginationBoundary} - ${upperPaginationBoundary}`
                                    : `0`}
                            </span>
                            {` из `}
                            <span className={styles['highlight']}>
                                {totalCount && !fetching ? totalCount : '0'}
                            </span>
                        </span>
                        <div className={styles['controls-arrows-wrapper']}>
                            <PaginatorArrow
                                onClick={() => dispatch(previousPage(name))}
                                variant={PaginatorArrow.variant.BACKWARD}
                                active={offset > 0 && !fetching}
                            />
                            <PaginatorArrow
                                onClick={() => dispatch(nextPage(name))}
                                variant={PaginatorArrow.variant.FORWARD}
                                active={
                                    offset + pageSize < totalCount && !fetching
                                }
                            />
                        </div>
                    </div>
                    <div className={styles['size-selector']}>
                        <span className={styles['title']}>
                            Значений на странице
                        </span>

                        <PaginatorSelect
                            onChange={(number) => {
                                dispatch(setPageSize(name, number));
                                localStorage.setItem(name, number.toString());
                            }}
                            selected={pageSize}
                            options={[10, 20, 50, 100]}
                        />
                    </div>
                </div>
            }
        </div>
    );
};
