import React, { FC } from 'react';

import styles from './light-table.module.scss';

interface LightTableProps {
    borderTop?: boolean;
}

export const LightTable: FC<LightTableProps> = ({ borderTop, children }) => {
    const computedClassName = [
        styles['light-table'],
        Boolean(borderTop) ? styles['light-table--border-top'] : '',
    ].join(' ');

    return <div className={computedClassName}>{children}</div>;
};
