import { MainTerminal } from '../model/types/terminal';

/**
 * Получает id всех терминалов компании
 * @param mainTerminals массив терминалов от сервера
 * @returns массив id всех терминалов
 */

export const getAllTerminalIds = (mainTerminals: MainTerminal[]) => {
    const allTerminals = [] as number[];
    mainTerminals.forEach((mainTerminal) =>
        mainTerminal.terminalGroups.forEach((group) =>
            group.terminals.forEach((terminal) =>
                allTerminals.push(terminal.id)
            )
        )
    );

    return allTerminals;
};
