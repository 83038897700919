import React, { Component } from 'react';
import { ReplaySubject } from 'rxjs';

import Container from 'src/components/Container';
import AwareTable from 'src/components/AwareTable';

import { URL } from 'src/app/config';
import { EditRegistryMailingSchedule } from 'src/features/edit-registry-mailing-schedule';
import { localLoad, sendToSubject } from 'src/shared/lib';

class SchedulePage extends Component {
    scheduleSubject = new ReplaySubject(1);

    constructor(props) {
        super(props);
        this.send = sendToSubject.bind(this);

        this.state = {
            id: '',
            interval: 1,
        };
    }

    resetId = () => {
        this.setState({
            id: '',
        });
    };

    callbackOfEditSchedule = () => {
        this.scheduleSubject.next({ forceRequest: true });
        this.resetId();
    };

    getInterval(id, interval) {
        const turnedOff = (interval) => interval === 'Выключено';

        this.setState({
            id,
            interval: turnedOff(interval) ? 0 : Number(interval),
        });
    }

    getData = () => {
        this.send({
            offset: localLoad('schedule-offset', 0),
            size: localLoad('schedule-range', 10),
        });
    };

    onItemClick = ({ ID, data }) => {
        this.getInterval(ID, data[1]);
    };

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <>
                <Container
                    header={true}
                    table={true}
                    footer={true}
                    name={'schedules'}
                    subject={this.scheduleSubject}
                >
                    <AwareTable
                        url={URL.schedules}
                        onItemClick={this.onItemClick}
                    />
                </Container>

                {this.state.id && (
                    <EditRegistryMailingSchedule
                        id={this.state.id}
                        initInterval={this.state.interval}
                        resetId={this.resetId}
                        successCallback={this.callbackOfEditSchedule}
                    />
                )}
            </>
        );
    }
}

export default SchedulePage;
