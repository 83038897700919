import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import styles from './help-link.module.scss';

interface Props {
    to: string;
    icon?: React.ReactNode;
    externalUrl?: boolean;
}

export const HelpLink: FC<Props> = ({
    children,
    icon,
    to,
    externalUrl = false,
}) => {
    return (
        <div className={styles['help-link']}>
            {icon}
            {externalUrl ? (
                <a href={to}>{children}</a>
            ) : (
                <Link to={to}>{children}</Link>
            )}
        </div>
    );
};
