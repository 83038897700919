export enum IndicatorColorVariant {
    BLUE = 'blue',
    PURPLE = 'purple',
    GREEN = 'green',
    GRAY = 'gray',
}

export const INDICATOR_COLOR_VARIANT_MAP: Record<
    IndicatorColorVariant,
    string
> = {
    [IndicatorColorVariant.BLUE]: '#2F80ED',
    [IndicatorColorVariant.GREEN]: '#27AE60',
    [IndicatorColorVariant.PURPLE]: '#BB6BD9',
    [IndicatorColorVariant.GRAY]: '#BDBDBD',
};

export type ShowGroupMode = 'all' | 'selected';
