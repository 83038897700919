import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReplaySubject } from 'rxjs';
import { URL } from 'src/app/config';
import AwareTable from 'src/components/AwareTable';
import { localLoad, sendToSubject } from 'src/shared/lib';
import { OverflowContainer, Paginator } from 'src/shared';

import './group-list-page.scss';

class GroupListPage extends Component {
    filterSubject = new ReplaySubject(1);

    constructor(props) {
        super(props);
        this.send = sendToSubject.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.send({
            offset: localLoad('group-offset', 0),
            size: localLoad('group-range', 10),
        });
    };

    render() {
        return (
            <div className="group-list-page">
                <Paginator name={'GroupsList-Table'}>
                    <OverflowContainer>
                        <AwareTable
                            url={URL.companyGroups}
                            link="/company/"
                            tableName={'GroupsList-Table'}
                            isStoreTable
                        />
                    </OverflowContainer>
                </Paginator>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        fetch: state.fetch.fetch,
    };
}

export default connect(mapStateToProps)(GroupListPage);
