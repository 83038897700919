import React, { Component } from 'react';
import { ReplaySubject } from 'rxjs';

import { Roles, URL } from 'src/app/config';
import {
    DisplayTheme,
    TypeOptions,
    SelectOption,
    SelectingItemsFromList,
} from 'src/features/selecting-items-from-list';
import { getAllTerminals, MainTerminal, Terminal } from 'src/entities/terminal';
import { Input, Modal, ModalField, NewCheckbox } from 'src/shared';
import {
    getOptionsForSelect,
    isAdmin,
    log,
    maskPhone,
    post,
    validateEmail,
} from 'src/shared/lib';
import { createNotification } from 'src/redux/actions/notification';
import store from 'src/store';

interface State {
    email: string;
    open: boolean;
    fullName: string;
    cellPhone: string;
    roles: string[];
    terminals: number[];
    emailIsValid: boolean;
    allTerminals: Terminal[];
    adminChecked?: boolean;
}

interface Props {
    roles: string[];
    companyRoles: { value: string }[];
    terminals: MainTerminal[];
    successCallback?: () => void;
}

export default class AddUserModal extends Component<Props, State> {
    filterSubject = new ReplaySubject(1);

    constructor(props) {
        super(props);

        let allTerminals = getAllTerminals(this.props.terminals);

        this.state = {
            open: false,
            fullName: '',
            emailIsValid: true,
            cellPhone: '',
            email: '',
            roles: [],
            terminals: [],
            adminChecked: false,
            allTerminals,
        };

        this.toggleAddUser = this.toggleAddUser.bind(this);
        this.createMerchantUser = this.createMerchantUser.bind(this);
    }

    toggleAddUser() {
        this.setState({
            fullName: '',
            email: '',
            cellPhone: '',
            roles: [],
            terminals: [],
            open: !this.state.open,
        });
    }

    createMerchantUser() {
        if (this.validateForm()) {
            post(URL.merchantCreateUsersUrl, {
                fullName: this.state.fullName,
                email: this.state.email,
                cellPhone: this.state.cellPhone,
                roles: this.state.adminChecked
                    ? [Roles.ADMIN]
                    : this.state.roles,
                terminalIds: this.state.adminChecked
                    ? []
                    : this.state.terminals,
            })
                .then(() => {
                    this.filterSubject.next({ forceRequest: true });
                    this.props.successCallback?.();
                    store.dispatch(
                        createNotification(
                            {
                                title: 'Создание пользователя',
                                message: 'Пользователь успешно создан',
                            },
                            'success'
                        )
                    );
                })
                .catch((error) => {
                    log(error);
                });
            this.setState({ open: false });
        }
    }

    validateForm() {
        if (
            this.state.fullName &&
            validateEmail(this.state.email) &&
            this.state.cellPhone.length === 12
        ) {
            return true;
        } else {
            return false;
        }
    }
    getErrorValidateEmail() {
        if (!this.state.emailIsValid && !this.state.email.length) {
            return 'Некорректный email';
        }
        return undefined;
    }

    filterRoles(roles) {
        return roles.filter((role) => role.value !== 'ADMIN');
    }

    getTerminalsOptions() {
        const options = [] as SelectOption[];
        this.state.allTerminals.forEach((terminal) => {
            const option = {} as SelectOption;
            option.value = String(terminal.id) || '';
            option.content = `${terminal.uid} ${terminal.name}` || '';
            options.push(option);
        });
        return options;
    }

    render() {
        const { companyRoles } = this.props;

        return (
            <>
                <i
                    title={'Добавить Пользователя'}
                    className="mdi mdi-group-add"
                    onClick={this.toggleAddUser}
                />
                <Modal
                    header="Добавить пользователя"
                    closeButton="Отмена"
                    actionButton="Сохранить"
                    isOpen={this.state.open}
                    onClose={this.toggleAddUser}
                    onAction={this.createMerchantUser}
                    disabledActionButton={!this.validateForm()}
                >
                    <ModalField numberOfColumns={1}>
                        <Input
                            background
                            placeholder="ФИО"
                            value={this.state.fullName}
                            onChange={(fio) =>
                                this.setState({
                                    fullName: fio.target.value,
                                })
                            }
                        />
                    </ModalField>
                    <ModalField
                        numberOfColumns={1}
                        errorMessage={this.getErrorValidateEmail()}
                    >
                        <Input
                            background
                            type="email"
                            value={this.state.email}
                            placeholder="Email"
                            onChange={(email) =>
                                this.setState({ email: email.target.value })
                            }
                        />
                    </ModalField>
                    <ModalField numberOfColumns={1}>
                        <Input
                            background
                            type="phone"
                            value={this.state.cellPhone}
                            placeholder="Введите телефон"
                            onChange={(phone) => {
                                this.setState({
                                    cellPhone: maskPhone(phone.target.value),
                                });
                            }}
                        />
                    </ModalField>
                    {isAdmin(this.props.roles) && (
                        <ModalField numberOfColumns={1}>
                            <NewCheckbox
                                alignCheckbox="right"
                                label="Администратор"
                                checked={this.state.adminChecked}
                                onChange={(e) =>
                                    this.setState({
                                        adminChecked: e.target.checked,
                                    })
                                }
                            />
                        </ModalField>
                    )}
                    {!this.state.adminChecked && (
                        <ModalField numberOfColumns={1}>
                            <SelectingItemsFromList
                                name="Роли"
                                theme={DisplayTheme.MODAL}
                                typeOptions={TypeOptions.ROLES}
                                options={getOptionsForSelect(
                                    this.filterRoles(companyRoles),
                                    'value',
                                    'name'
                                )}
                                onChange={(selectedRoles) =>
                                    this.setState({
                                        roles: [...selectedRoles],
                                    })
                                }
                            />
                        </ModalField>
                    )}
                    {!this.state.adminChecked && (
                        <ModalField numberOfColumns={1}>
                            <SelectingItemsFromList
                                name="Терминалы"
                                theme={DisplayTheme.MODAL}
                                typeOptions={TypeOptions.TERMINALS}
                                options={this.getTerminalsOptions()}
                                onChange={(terminals) => {
                                    this.setState({
                                        terminals: terminals.map((terminal) =>
                                            Number(terminal)
                                        ),
                                    });
                                }}
                            />
                        </ModalField>
                    )}
                </Modal>
            </>
        );
    }
}
