import { InfoTerminal } from 'src/entities/terminal';

export const INIT_TERMINAL = {
    activity: '',
    activityInfo: '',
    extraCommission: '',
    id: 0,
    limitEndOfWeek: '',
    name: '',
    operationLimit: '',
    rates: [],
    simCommission: '',
    uid: '',
    weekLimit: '',
} as InfoTerminal;

export const WITHOUT_GROUP = 'without_group';
