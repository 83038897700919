import React, { Component } from 'react';
import { connect } from 'react-redux';

import { FiltersContainer } from 'src/widgets';
import {
    getClassName,
    sendToSubject,
    getInitialFilter,
    getAppTitle,
    localSave,
    LOCAL_STORAGE_TABLE_NAMES,
} from 'src/shared/lib';
import { SearchField } from 'src/shared/ui';
import { MerchantTerminalsSelector } from 'src/features/select-terminals';
import styles from './styles.module.scss';
import { setFilters } from 'src/redux/actions/table';

class SettlementDetailsFilter extends Component {
    constructor(props) {
        super(props);
        const pageTitleSettlement = 'SettlementPage';
        this.isBankApp = getAppTitle() === 'bank';
        this.searchQuery = getClassName(this.props.tableName, 'searchQuery');
        this.terminalIds = getClassName(this.props.tableName, 'terminalIds');
        const initialFilter = getInitialFilter(
            ['searchQuery', 'terminalIds'],
            this.props.tableName
        );
        this.props.dispatch(
            setFilters(this.props.tableName, {
                ...initialFilter,
            })
        );
        this.send = sendToSubject.bind(this);
        /*
         * TODO: Быстрое решение передачи данных между страницами,
         *  проверка существования terminalIds для переиспользования в селекторе
         *  в том числе при отправке на получение отчета
         */
        const { companyIds: companyIdsSettlementPage } = getInitialFilter(
            ['companyIds'],
            pageTitleSettlement
        );

        const { terminalIds: terminalIdsSettlementPage } = getInitialFilter(
            ['terminalIds'],
            LOCAL_STORAGE_TABLE_NAMES.common
        );
        if (terminalIdsSettlementPage.length) {
            const notIncludedIds = terminalIdsSettlementPage.filter(
                (id) => !initialFilter.terminalIds?.includes(id)
            );

            this.props.dispatch(
                setFilters(this.props.tableName, {
                    terminalIds:
                        initialFilter.terminalIds.concat(notIncludedIds),
                })
            );
            this.saveFilter();
        }
        /*
         * TODO: Быстрое решение передачи данных между страницами,
         *  проверкка существования companyIds для использования при отправке на получение отчета
         */
        if (this.isBankApp && companyIdsSettlementPage.length) {
            const name = getClassName(this.props.tableName, 'companySelectId');
            localSave(name, companyIdsSettlementPage);
            const notIncludedIds = companyIdsSettlementPage.filter(
                (id) => !initialFilter.terminalIds.includes(id)
            );
            // TODO: setting terminal ids here for bank app which doesn't even fetch them in the first place
            // might be a bug prob should be company ids here, look into it
            this.props.dispatch(
                setFilters(this.props.tableName, {
                    terminalIds:
                        initialFilter.terminalIds.concat(notIncludedIds),
                })
            );
            this.saveFilter();
        }
    }

    componentWillUnmount() {
        if (this.subscription) this.subscription.unsubscribe();
    }

    saveFilter() {
        localSave(this.terminalIds, this.props.table?.filters?.terminalIds);
    }

    componentDidUpdate() {
        this.saveFilter();
    }

    render() {
        return (
            <div className={styles['wrapper']}>
                <FiltersContainer>
                    <MerchantTerminalsSelector
                        tableName={this.props.tableName}
                        selected={this.props.table?.filters?.terminalIds ?? []}
                        onChange={(terminalIds) =>
                            this.props.dispatch(
                                setFilters(this.props.tableName, {
                                    terminalIds: terminalIds,
                                })
                            )
                        }
                        terminalsList={this.props.terminals}
                    />
                    <div className={styles['actions-wrapper']}>
                        {this.props.actions}
                    </div>
                    <SearchField
                        fullsize
                        placeholder="Введите RRN или код авторизации"
                        value={this.props.table?.filters?.searchQuery ?? ''}
                        onChange={(value) =>
                            this.props.dispatch(
                                setFilters(this.props.tableName, {
                                    searchQuery: value,
                                })
                            )
                        }
                    />
                </FiltersContainer>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    terminals: state.terminals.terminals,
    table: state.table?.[props.tableName],
});

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(SettlementDetailsFilter);
