import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { post } from 'src/shared/lib';
import CompanySelect from 'src/components/CompanySelect';
import { FiltersContainer } from 'src/widgets';
import {
    getClassName,
    OperationSBPTypeOptions,
    QRCodeTypeOptions,
    localSave,
    getInitialFilter,
    formatDateForInput,
    localLoadIntInArray,
    getAppTitle,
} from 'src/shared/lib';
import { SearchField, DateRangePicker, FiltersSelector } from 'src/shared/ui';
import { SelectSBPIdentificators } from 'src/features/select-sbp-identificators';
import { URL } from 'src/app/config';
import { setEndDate, setStartDate, setFilters } from 'src/redux/actions/table';
import styles from './styles.module.scss';
import _ from 'lodash';
import { setIdnSbp } from 'src/redux/actions/terminals';

class OperationsFilter extends Component {
    constructor(props) {
        super(props);
        this.startDate = getClassName(this.props.pageTitle, 'startDate');
        this.endDate = getClassName(this.props.pageTitle, 'endDate');
        this.searchQuery = getClassName(this.props.pageTitle, 'searchQuery');
        this.companyId = getClassName(
            this.props.pageTitle,
            CompanySelect.companyIds
        );
        this.merchantIds = getClassName(this.props.pageTitle, 'merchantIds');
        this.operationTypes = getClassName(
            this.props.pageTitle,
            'operationTypes'
        );
        this.qrcTypes = getClassName(this.props.pageTitle, 'qrcTypes');
        this.isBank = getAppTitle() === 'bank';

        const { merchantIds, ...initialFilter } = getInitialFilter(
            ['searchQuery', 'merchantIds', 'companyIds'],
            this.props.pageTitle
        );

        this.defaultFilter = {
            ...initialFilter,
            qrcTypes: undefined,
            operationTypes: undefined,
            companyIds: localLoadIntInArray(this.companyId, []),
        };

        this.state = {
            ...this.defaultFilter,
        };

        this.setStartDate(initialFilter.startDate);
        this.setEndDate(initialFilter.endDate);

        // For bank app clear merchantIds if no company is selected
        this.props.dispatch(
            setFilters(this.props.pageTitle, {
                ...initialFilter,
                companyIds: localLoadIntInArray(this.companyId, []),
                merchantIds:
                    this.isBank && !this.defaultFilter.companyIds
                        ? []
                        : merchantIds,
            })
        );

        if (this.props.table?.filters?.searchQuery) {
            this.props.dispatch(
                setFilters(this.props.pageTitle, {
                    searchQuery: this.props.table?.filters?.searchQuery,
                })
            );
        }
    }

    getSBPIdentificators = () => {
        if (this.props.table?.filters?.companyIds) {
            post(URL.operationsSBPIdentificatorsUrl, {
                ...(this.isBank && {
                    companyIds: this.props.table?.filters?.companyIds ?? [],
                }),
            }).then((result) => {
                this.props.dispatch(setIdnSbp(result.data.payload));
            });
        }
    };

    componentDidMount() {
        if (this.props.location?.state?.rowID) {
            const { state } = this.props.location;
            this.setStartDate(moment(state.date, 'DD.MM.YYYY'));
            this.setEndDate(moment(state.date, 'DD.MM.YYYY'));
        }

        if (this.state.companyIds.length === 0 && getAppTitle() === 'bank') {
            this.props.dispatch(
                setFilters(this.props.pageTitle, { merchantIds: [] })
            );
            this.saveFilter();
        }

        this.props?.location?.state?.redirectedThroughNavbar &&
            this.getSBPIdentificators();
    }

    componentWillUnmount() {
        if (this.subscription) this.subscription.unsubscribe();
    }

    handleTypeChange = ({ target }) => {
        this.setState({
            [target.name]: target.value ? target.value : undefined,
        });
    };

    componentDidUpdate(previousProps) {
        const companyIdsAreEqual = _.isEqual(
            previousProps.table?.filters?.companyIds,
            this.props.table.filters?.companyIds
        );

        if (!companyIdsAreEqual) {
            this.getSBPIdentificators();
        }

        if (
            (!this.props.table?.filters?.companyIds || !companyIdsAreEqual) &&
            this.props.table?.filters?.merchantIds.length &&
            previousProps.table?.filters?.companyIds
        ) {
            this.props.dispatch(
                setFilters(this.props.pageTitle, { merchantIds: [] })
            );
        }

        this.saveFilter();
    }

    saveFilter() {
        localSave(this.startDate, this.getStartDate());
        localSave(this.endDate, this.getEndDate());
        localSave(this.merchantIds, this.props.table?.filters?.merchantIds);
        localSave(this.qrcTypes, this.props.table?.filters?.qrcTypes);
        localSave(
            this.operationTypes,
            this.props.table?.filters?.operationTypes
        );
    }

    getStartDate() {
        return this.props.table?.startDate ?? this.state.startDate;
    }

    setStartDate(date) {
        this.props.dispatch(setStartDate(this.props.pageTitle, date));
    }

    getEndDate() {
        return this.props.table?.endDate ?? this.state.endDate;
    }

    setEndDate(date) {
        this.props.dispatch(setEndDate(this.props.pageTitle, date));
    }

    render() {
        return (
            <div className={styles['wrapper']}>
                <FiltersContainer>
                    <FiltersSelector
                        columns={[
                            {
                                title: 'Тип платежа',
                                options: OperationSBPTypeOptions,

                                onChange: (value) =>
                                    this.props.dispatch(
                                        setFilters(this.props.pageTitle, {
                                            operationType: value,
                                        })
                                    ),

                                selected:
                                    this.props.table?.filters?.operationType,
                                isSingleSelect: true,
                            },
                            {
                                title: 'Тип QR',
                                options: QRCodeTypeOptions,
                                onChange: (value) =>
                                    this.props.dispatch(
                                        setFilters(this.props.pageTitle, {
                                            qrcTypes: value,
                                        })
                                    ),
                                selected: this.props.table?.filters?.qrcTypes,
                                isSingleSelect: true,
                            },
                        ]}
                        reset={() => {
                            this.props.dispatch(
                                setFilters(this.props.pageTitle, {
                                    qrcTypes: undefined,
                                    operationType: undefined,
                                })
                            );
                        }}
                    />

                    <DateRangePicker
                        datepickers={[
                            {
                                selected: this.getStartDate(),
                                start: true,
                                onChange: (startDate) =>
                                    this.setStartDate(startDate),
                            },
                            {
                                selected: this.getEndDate(),
                                end: true,
                                onChange: (endDate) => this.setEndDate(endDate),
                            },
                        ]}
                        formatter={formatDateForInput}
                        startDate={this.getStartDate()}
                        endDate={this.getEndDate()}
                        onReset={() => {
                            this.setStartDate(
                                moment(new Date()).add(-7, 'days')
                            );
                            this.setEndDate(moment(new Date()));
                        }}
                    />

                    {getAppTitle() === 'bank' && (
                        <div className={styles['actions-wrapper']}>
                            <CompanySelect
                                classSelect={styles['company-select']}
                                uid={this.props.pageTitle}
                                onChange={({ value }) => {
                                    this.props.dispatch(
                                        setFilters(this.props.pageTitle, {
                                            companyIds: value ? [value] : [],
                                        })
                                    );
                                }}
                            />
                        </div>
                    )}

                    <SelectSBPIdentificators
                        key={this.props.table?.filters?.merchantIds?.length}
                        identificationsList={this.props.idnSbp}
                        selected={this.props.table?.filters?.merchantIds ?? []}
                        onChange={(selected) =>
                            this.props.dispatch(
                                setFilters(this.props.pageTitle, {
                                    merchantIds: selected,
                                })
                            )
                        }
                        disabled={
                            this.isBank &&
                            this.props.table?.filters?.companyIds.length === 0
                        }
                    />

                    <SearchField
                        fullsize
                        placeholder="Введите номер телефона или сумму"
                        value={this.props.table?.filters?.searchQuery}
                        onChange={(value) =>
                            this.props.dispatch(
                                setFilters(this.props.pageTitle, {
                                    searchQuery: value,
                                })
                            )
                        }
                    />
                </FiltersContainer>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        table: state.table?.[props.pageTitle],
        idnSbp: state.terminals.idnSbp,
    };
}

export default withRouter(
    connect(mapStateToProps, null, null)(OperationsFilter)
);
