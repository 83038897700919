import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment/moment';

import {
    getConversionChart,
    getDeclineChart,
    getMpsChart,
    toggleCharts,
} from 'src/redux/actions/charts';
import {
    localSave,
    getClassName,
    getInitialFilter,
    formatDateForInput,
    LOCAL_STORAGE_TABLE_NAMES,
} from 'src/shared/lib';
import { Switcher, DateRangePicker } from 'src/shared/ui';
import { FiltersContainer } from 'src/widgets';
import { setCommonTableFilters } from 'src/redux/actions/table';
import styles from './styles.module.scss';
import { MerchantTerminalsSelector } from 'src/features/select-terminals';

class StatisticFilter extends Component {
    constructor(props) {
        super(props);
        this.startDate = getClassName(
            LOCAL_STORAGE_TABLE_NAMES.common,
            'startDate'
        );
        this.endDate = getClassName(
            LOCAL_STORAGE_TABLE_NAMES.common,
            'endDate'
        );
        this.terminalIds = getClassName(
            LOCAL_STORAGE_TABLE_NAMES.common,
            'terminalIds'
        );

        let initialFilter = getInitialFilter([], this.props.pageTitle);
        const commonSavedState = {
            ...getInitialFilter(
                ['terminalIds'],
                LOCAL_STORAGE_TABLE_NAMES.common
            ),
        };

        this.setStartDate(commonSavedState.startDate);
        this.setEndDate(commonSavedState.endDate);
        this.state = {
            ...initialFilter,
        };

        this.props.dispatch(setCommonTableFilters(commonSavedState));
    }

    isInitialDataRequest() {
        return (
            this.state.startDate === this.props.table?.startDate &&
            this.state.endDate === this.props.table?.endDate
        );
    }

    getStartDate() {
        return this.props.commonFilters?.startDate ?? this.state.startDate;
    }

    setStartDate(date) {
        this.props.dispatch(setCommonTableFilters({ startDate: date }));
    }

    getEndDate() {
        return this.props.commonFilters?.endDate ?? this.state.endDate;
    }

    setEndDate(date) {
        this.props.dispatch(setCommonTableFilters({ endDate: date }));
    }

    componentDidUpdate(previousProps) {
        const { startDate, endDate, terminalIds } = this.props.commonFilters;

        const propsChanged =
            previousProps.commonFilters?.startDate.format('YYYY-MM-DD') !==
                this.props.commonFilters?.startDate.format('YYYY-MM-DD') ||
            previousProps.commonFilters?.endDate.format('YYYY-MM-DD') !==
                this.props.commonFilters?.endDate.format('YYYY-MM-DD') ||
            JSON.stringify(this.props.commonFilters?.terminalIds) !==
                JSON.stringify(previousProps.commonFilters?.terminalIds);
        if (terminalIds && startDate && endDate && propsChanged) this.getData();
    }

    componentDidMount() {
        this.getData();
    }

    saveFilter() {
        localSave(this.startDate, this.getStartDate());
        localSave(this.endDate, this.getEndDate());
        localSave(this.terminalIds, this.props.commonFilters?.terminalIds);
    }

    getData() {
        this.getAllStatisticPageCharts({
            startDate: this.getStartDate(),
            endDate: this.getEndDate(),
            terminalIds: this.props.commonFilters?.terminalIds ?? [],
        });
        this.saveFilter();
    }

    getAllStatisticPageCharts(r) {
        this.props.dispatch(getMpsChart(r));
        this.props.dispatch(getDeclineChart(r));
        this.props.dispatch(getConversionChart(r));
    }

    render() {
        let { dispatch } = this.props;
        return (
            <div className={styles['wrapper']}>
                <FiltersContainer>
                    <MerchantTerminalsSelector
                        tableName={LOCAL_STORAGE_TABLE_NAMES.common}
                        selected={this.props.commonFilters?.terminalIds ?? []}
                        onChange={(terminalIds) =>
                            dispatch(
                                setCommonTableFilters({
                                    terminalIds,
                                })
                            )
                        }
                        terminalsList={this.props.terminals}
                    />
                    <DateRangePicker
                        datepickers={[
                            {
                                selected: this.getStartDate(),
                                start: true,
                                onChange: (startDate) =>
                                    this.setStartDate(startDate),
                            },
                            {
                                selected: this.getEndDate(),
                                end: true,
                                onChange: (endDate) => this.setEndDate(endDate),
                            },
                        ]}
                        formatter={formatDateForInput}
                        criticalScreenWidth={1110}
                        startDate={this.getStartDate()}
                        endDate={this.getEndDate()}
                        onReset={() => {
                            this.setStartDate(
                                moment(new Date()).add(-7, 'days')
                            );
                            this.setEndDate(moment(new Date()));
                        }}
                    />
                    <Switcher
                        initialActiveId={'sum'}
                        elements={[
                            {
                                id: 'sum',
                                title: 'Сумма',
                                onClick: () => {
                                    dispatch(toggleCharts('sum'));
                                },
                            },
                            {
                                id: 'count',
                                title: ' Количество',
                                onClick: () => {
                                    dispatch(toggleCharts('count'));
                                },
                            },
                        ]}
                    />
                    <div className={styles['actions-wrapper']}>
                        {this.props.actions}
                    </div>
                </FiltersContainer>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    terminals: state.terminals.terminals,
    table: state.table?.[props.pageTitle],
    commonFilters: state.table?.commonFilters,
});

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(StatisticFilter);
