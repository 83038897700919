import { BrowserConfig, BrowserName } from './browserConfig';
import { texts } from './texts';

const getText = (browserName: BrowserName) =>
    `От ${BrowserConfig[browserName]} версии`;

export const supportedBrowsersText = {
    [texts.system.ie]: texts.system.supportPartial,
    [texts.system.edge]: getText('Edge'),
    [texts.system.firefox]: getText('Firefox'),
    [texts.system.chrome]: getText('Chrome'),
    [texts.system.safari]: getText('Safari'),
    [texts.system.osSafari]: getText('Mobile Safari'),
    [texts.system.chromeForAndroid]: '102',
    [texts.system.ucBrowserForAndroid]: '13.3',
    [texts.system.samsungInternet]: getText('Samsung Browser'),
};
