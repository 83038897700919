import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logOut } from 'src/redux/actions/auth';
import { getAppTitle } from 'src/shared/lib';
import { isPermit } from 'src/shared/lib';
import { navRoutes, Roles } from '.';
import { ArrowRight } from '../../shared/ui/dropdown/arrow-right';
import { isDesktop } from 'react-device-detect';

export const UserDropdownNavigation: React.FC = () => {
    const dispatch = useDispatch();
    return config[getAppTitle()](dispatch);
};

const config = {
    bank: (dispatch) => (
        <nav>
            <NavLink className="dropdown__link" to={navRoutes.settingsUser}>
                Настройки
            </NavLink>
            <button
                onClick={() => {
                    dispatch(logOut());
                }}
                className="dropdown__button"
            >
                <span>Выход</span>
                <ArrowRight color={isDesktop ? '#002E5A' : '#ffffff'} />
            </button>
        </nav>
    ),
    merchant: (dispatch) => (
        <nav>
            <NavLink className="dropdown__link" to={navRoutes.settingsUser}>
                Профиль
            </NavLink>
            {isPermit(Roles.ADMIN) || isPermit(Roles.SETTING_USER) ? (
                <NavLink
                    className="dropdown__link"
                    to={navRoutes.settingsUsers}
                >
                    Настройки
                </NavLink>
            ) : null}
            {isPermit(Roles.SCHEDULE) && (
                <NavLink className="dropdown__link" to={navRoutes.schedule}>
                    Подписки
                </NavLink>
            )}
            {/* TODO нет макета для этой странице */}
            {/*<NavLink className="dropdown__link" to={'#'}>*/}
            {/*    Документы*/}
            {/*</NavLink>*/}
            <button
                onClick={() => {
                    dispatch(logOut());
                }}
                className="dropdown__button"
            >
                <span>Выход</span>
                <ArrowRight color={isDesktop ? '#002E5A' : '#ffffff'} />
            </button>
        </nav>
    ),
};
