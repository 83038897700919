export const Roles = {
    ADMIN: 'ADMIN',
    ECOMM: 'ECOMM',
    EINVOICE: 'EINVOICE',
    SCHEDULE: 'SCHEDULE',
    DYNAMIC: 'DYNAMIC',
    INVENTORY: 'INVENTORY',
    OPERATIONS: 'OPERATIONS',
    OPERATION_SBP: 'OPERATIONS_SBP',
    STATISTIC: 'STATISTIC',
    REFUND: 'REFUND',
    SETTING_USER: 'SETTING_USER',
    SETTLEMENT: 'SETTLEMENT',

    COMPANY_INFO: 'COMPANY_INFO',
    USER_MANAGEMENT: 'USER_MANAGEMENT',
    CASHBOX: 'CASHBOX',
};

export const BankRoles = {
    ADMIN: 'ADMIN',
    COMPANY_INFO: 'COMPANY_INFO',
    USER_MANAGEMENT: 'USER_MANAGEMENT',
    OPERATIONS: 'OPERATIONS',
    SETTLEMENT: 'SETTLEMENT',
    CASHBOX: 'CASHBOX',
    EINVOCE: 'EINVOICE',
};
