import * as type from '../constants';
import { get, post } from '../../shared/lib/request-wrapper';
import { URL } from 'src/app/config';
import { log } from 'src/shared/lib';

export function getTerminals() {
    return (dispatch) => {
        return post(URL.terminalsUrl)
            .then((response) => {
                let payload = response.data.payload;
                payload.forEach((company, id) =>
                    company.terminalGroups.forEach(
                        (group) => (group.id = group.id || `g${id}`)
                    )
                );
                dispatch({
                    type: type.GET_TERMINALS,
                    payload,
                });
            })
            .catch((response) =>
                dispatch({
                    type: type.GET_TERMINALS_FAILED,
                    error: response.data,
                })
            );
    };
}

export function getInactivePeriodPerDays() {
    return (dispatch) => {
        return get(URL.inactivePeriodPerDays)
            .then((response) => {
                const payload = response.data;
                dispatch({
                    type: type.SET_TERMINALS_INACTIVE_PERIOD_PER_DAYS,
                    payload,
                });
            })
            .catch((response) => log(response));
    };
}

export function getInactiveStatus() {
    return (dispatch) => {
        return get(URL.terminalInactiveRequest)
            .then((response) => {
                dispatch({
                    type: type.SET_TERMINAL_INACTIVE,
                    payload: response.data.payload,
                });
            })
            .catch((r) => log(r));
    };
}

export function getRcMsg() {
    return (dispatch) => {
        return get(URL.operationsRcMessagesUrl)
            .then((response) => {
                dispatch({
                    type: type.GET_RCMSG,
                    payload: response.data.payload,
                });
            })
            .catch((response) =>
                dispatch({
                    type: type.GET_RCMSG_FAILED,
                    error: response.data,
                })
            );
    };
}

export function setIdnSbp(idnSbp) {
    return (dispatch) => {
        dispatch({
            type: type.SET_IDN_SBP,
            payload: idnSbp,
        });
    };
}

export function getSBPRcMsg() {
    return (dispatch) => {
        return get(URL.operationsSBPRcMessagesUrl)
            .then((response) => {
                dispatch({
                    type: type.GET_SBP_RCMSG,
                    payload: response.data.payload,
                });
            })
            .catch((response) =>
                dispatch({
                    type: type.GET_SBP_RCMSG_FAILED,
                    error: response.data,
                })
            );
    };
}
