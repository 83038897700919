import { TerminalStatus } from 'src/entities/terminal';

interface Terminal {
    id: number;
    uid: number;
    name: string;
    inActive?: boolean;
    disabled?: boolean;
    activity: TerminalStatus;
}

interface TerminalGroup {
    name: string;
    terminals: Terminal[];
}

interface MainTerminal {
    id: number;
    inn: number;
    name: string;
    blocked: boolean;
    terminalGroups: TerminalGroup[];
}

export const inventoryFilteringOptions = [
    {
        content: 'Активные',
        value: 'ACTIVE',
    },
    {
        content: 'Без операций',
        value: 'INACTIVE',
    },
    {
        content: 'Отключенные',
        value: 'DISABLED',
    },
];

export type { Terminal, MainTerminal };
