import React from 'react';

const CardIcon = () => (
    <svg
        width="34px"
        height="20px"
        viewBox="0 0 34 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>card</title>
        <path
            fill="#565656"
            d="M31.0484762,0 L2.13619409,0 C0.957604248,0 0,0.950639854 0,2.12065814 L0,17.8793419 C0,19.0493601 0.957604248,20 2.13619409,20 L31.0484762,20 C32.227066,20 33.1846703,19.0493601 33.1846703,17.8793419 L33.1846703,2.12065814 C33.1846703,0.950639854 32.227066,0 31.0484762,0 Z M29.1921563,12.4996485 L25.1040046,12.4996485 C24.9373538,12.4996485 24.80195,12.6714849 24.80195,12.8829758 L24.80195,15.7314948 C24.80195,15.9429857 24.9373538,16.1148221 25.1040046,16.1148221 L29.1921563,16.1148221 C29.3588071,16.1148221 29.4942108,15.9429857 29.4942108,15.7314948 L29.4942108,12.8829758 C29.4942108,12.6714849 29.3588071,12.4996485 29.1921563,12.4996485 Z M31.0484762,19.2687386 L2.13619409,19.2687386 C1.36274451,19.2687386 0.736618653,18.6471664 0.736618653,17.8793419 L0.736618653,9.68921389 L32.4112207,9.68921389 L32.4112207,17.8793419 C32.4480516,18.6471664 31.8219258,19.2687386 31.0484762,19.2687386 Z M32.4480516,4.86288848 L0.773449585,4.86288848 L0.773449585,2.12065814 C0.773449585,1.35283364 1.39957544,0.731261426 2.17302502,0.731261426 L31.0853071,0.731261426 C31.8587567,0.731261426 32.4848826,1.35283364 32.4848826,2.12065814 L32.4848826,4.86288848 L32.4480516,4.86288848 Z"
        ></path>
    </svg>
);

export { CardIcon };
