import React from 'react';

export const telegram = (
    <svg
        width="25"
        height="21"
        viewBox="0 0 25 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.8839 20.2328C19.2059 20.4608 19.6209 20.5178 19.9909 20.3778C20.3609 20.2368 20.6329 19.9208 20.7149 19.5378C21.5839 15.4538 23.6919 5.11676 24.4829 1.40176C24.5429 1.12176 24.4429 0.830762 24.2229 0.643762C24.0029 0.456762 23.6979 0.402761 23.4259 0.503761C19.2329 2.05576 6.31991 6.90076 1.04191 8.85376C0.70691 8.97776 0.48891 9.29976 0.49991 9.65276C0.51191 10.0068 0.74991 10.3138 1.09291 10.4168C3.45991 11.1248 6.56691 12.1098 6.56691 12.1098C6.56691 12.1098 8.01891 16.4948 8.77591 18.7248C8.87091 19.0048 9.08991 19.2248 9.37891 19.3008C9.66691 19.3758 9.97491 19.2968 10.1899 19.0938C11.4059 17.9458 13.2859 16.1708 13.2859 16.1708C13.2859 16.1708 16.8579 18.7898 18.8839 20.2328ZM7.87391 11.5558L9.55291 17.0938L9.92591 13.5868C9.92591 13.5868 16.4129 7.73576 20.1109 4.40076C20.2189 4.30276 20.2339 4.13876 20.1439 4.02376C20.0549 3.90876 19.8909 3.88176 19.7679 3.95976C15.4819 6.69676 7.87391 11.5558 7.87391 11.5558Z"
            fill="white"
        />
    </svg>
);
