import React from 'react';

export const BellIcon = (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M2.09375 14.6309H5.47754C5.53027 16.1953 6.85742 17.6895 8.83496 17.6895C10.7949 17.6895 12.1221 16.2217 12.1836 14.6309H15.5674C16.4727 14.6309 17.0264 14.1211 17.0264 13.3652C17.0264 12.4424 16.2354 11.6777 15.4707 10.9658C14.8555 10.3857 14.7236 9.25195 14.5918 7.98633C14.4512 4.92773 13.502 2.82715 11.3486 2.0625C11.0146 0.9375 10.0742 0.120117 8.83496 0.120117C7.58691 0.120117 6.64648 0.9375 6.3125 2.0625C4.16797 2.82715 3.21875 4.92773 3.06934 7.98633C2.9375 9.25195 2.80566 10.3857 2.19043 10.9658C1.42578 11.6777 0.634766 12.4424 0.634766 13.3652C0.634766 14.1211 1.19727 14.6309 2.09375 14.6309ZM2.84082 12.9521V12.8555C3.08691 12.6182 3.56152 12.1963 3.95703 11.7305C4.51953 11.0801 4.77441 9.80566 4.87109 8.24121C4.98535 5.04199 5.9873 3.93457 7.2793 3.58301C7.48145 3.53906 7.5957 3.44238 7.62207 3.20508C7.66602 2.37012 8.12305 1.8252 8.83496 1.8252C9.53809 1.8252 9.99512 2.37012 10.0479 3.20508C10.0654 3.44238 10.1797 3.53906 10.3818 3.58301C11.6738 3.93457 12.6758 5.04199 12.79 8.24121C12.8867 9.80566 13.1504 11.0801 13.7041 11.7305C14.0996 12.1963 14.5479 12.6182 14.7939 12.8555V12.9521H2.84082ZM8.83496 16.2656C7.9209 16.2656 7.26172 15.624 7.2002 14.6309H10.4609C10.417 15.6064 9.74902 16.2656 8.83496 16.2656Z" />
    </svg>
);
