import React from 'react';
import styles from './styles.module.scss';
import { classNames } from 'src/shared/lib';

interface Props {
    onClick: () => void;
    title?: string;
    customIcon?: string;
}

export const AddIcon = ({ onClick, title, customIcon = 'mdi-add' }: Props) => {
    return (
        <i
            title={title}
            className={classNames(styles['wrapper'], { [`mdi ${customIcon}`]: true })}
            onClick={onClick}
        />
    );
};
