import React from 'react';
import { connect } from 'react-redux';
import './notification.scss';

import Notifications from 'react-notification-system-redux';

class Notification extends React.Component {
    render() {
        const { notifications } = this.props;
        const style = {
            NotificationItem: {
                DefaultStyle: {
                    margin: '10px 5px 2px 1px',
                },
            },
        };
        return <Notifications notifications={notifications} style={style} />;
    }
}

const mapStateToProps = (state) => ({ notifications: state.notifications });

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(Notification);
