import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AwareTable from 'src/components/AwareTable';

import { URL } from 'src/app/config';
import { get, log } from 'src/shared/lib';

import './cashbox-registry-page.scss';
import { OverflowContainer, Paginator } from 'src/shared';

const cashboxRegistriesTableName = 'Cashbox-Registry';

class CashBoxRegistriesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName: '',
        };
    }

    componentDidMount() {
        this.getPlatformCashboxInfo(this.props.match.params.id);
    }

    getPlatformCashboxInfo = (id) => {
        get(URL.platformCashBoxOneUrl + id)
            .then((r) => {
                let { companyName } = r.data.payload;

                this.setState(
                    {
                        companyName,
                    },
                    () => {}
                );
            })
            .catch((r) => {
                log(r);
            });
    };

    render() {
        return (
            <div className="registry-page">
                <h1 className="main-page__title">{`Реестры Компании ${this.state.companyName}`}</h1>
                <Paginator
                    name={
                        cashboxRegistriesTableName + this.props.match.params.id
                    }
                >
                    <OverflowContainer>
                        <AwareTable
                            isStoreTable
                            tableName={
                                cashboxRegistriesTableName +
                                this.props.match.params.id
                            }
                            url={`${URL.cashBoxRegistries}/${this.props.match.params.id}`}
                            link={URL.cashBoxReceipt + '/'}
                        />
                    </OverflowContainer>
                </Paginator>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        fetch: state.fetch.fetch,
    };
}

export default withRouter(connect(mapStateToProps)(CashBoxRegistriesPage));
