import { map } from 'lodash';
import React from 'react';
import { supportedBrowsersText } from '../../config';
import { LightTable } from '../light-table';
import { texts } from '../../config/texts';

import styles from './about-system.module.scss';

export const AboutSystem = () => {
    return (
        <div>
            {' '}
            <h3 className={styles['header']}>
                {texts.system.commonDescription}
            </h3>
            <p className={styles['paragraph']}>
                {texts.system.headerPartOne}
                <a
                    href="https://merchant.sdm.ru"
                    rel="noreferrer nofollow"
                    target="_blank"
                >
                    {texts.system.siteLink}
                </a>
                {texts.system.headerPartTwo}
            </p>
            <LightTable>
                <table className={styles['table']}>
                    <thead>
                        <tr>
                            <td colSpan={2}>{texts.system.supportedBrowser}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {map(supportedBrowsersText, (entry, key) => (
                            <tr key={key}>
                                <td>{key}</td>
                                <td>{entry}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </LightTable>
            <h3 className={styles['header']}>{texts.system.activateAccount}</h3>
            <p className={styles['paragraph']}>{texts.system.footer}</p>
        </div>
    );
};
