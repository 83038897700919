import React from 'react';

export const SelectedIcon = (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M9.14258 17.6543C13.8799 17.6543 17.791 13.7432 17.791 8.99707C17.791 4.25977 13.8711 0.348633 9.13379 0.348633C4.39648 0.348633 0.485352 4.25977 0.485352 8.99707C0.485352 13.7432 4.39648 17.6543 9.14258 17.6543ZM8.21094 13.1279C7.87695 13.1279 7.61328 12.9785 7.36719 12.6709L5.35449 10.2451C5.19629 10.043 5.11719 9.84082 5.11719 9.62109C5.11719 9.16406 5.47754 8.80371 5.93457 8.80371C6.20703 8.80371 6.41797 8.90039 6.62891 9.17285L8.18457 11.124L11.5596 5.73633C11.7529 5.42871 11.999 5.27051 12.2803 5.27051C12.7197 5.27051 13.124 5.58691 13.124 6.04395C13.124 6.24609 13.0361 6.45703 12.9043 6.65039L9.01953 12.6621C8.82617 12.9609 8.53613 13.1279 8.21094 13.1279Z" />
    </svg>
);