type TCacheEntry<T> = {
    value: T;
    expireAt: number;
};

type TCache<Key, Value> = Map<Key, TCache<Key, Value> | TCacheEntry<Value>>;

interface ICache<T> {
    set(key: string[], value: T): void;
    get(key: string[]): T | null;
    clear(key: string[]): void;
}

class DataCache<T> implements ICache<T> {
    private cache: TCache<string, T>;
    private ttl: number;

    constructor(ttl: number = 15 * 60 * 1000) {
        this.cache = new Map();
        this.ttl = ttl;
    }

    public set(key: string[], value: T): void {
        const expireAt = Date.now() + this.ttl;
        const entry: TCacheEntry<T> = { value, expireAt };
        const path = this.getKeyPath(key, true);
        const lastKey = key[key.length - 1];
        const lastMap = path[path.length - 1];
        lastMap.set(lastKey, entry);
    }

    public get(key: string[]): T | null {
        const path = this.getKeyPath(key);
        const lastKey = key[key.length - 1];
        const entry = path[path.length - 1].get(lastKey) as
            | TCacheEntry<T>
            | undefined;
        if (!entry || Date.now() > entry.expireAt) {
            return null;
        }
        return entry.value;
    }

    public clear(key: string[]): void {
        const path = this.getKeyPath(key);

        if (key.length === 0) {
            this.cache.clear();
        } else {
            const lastKey = key[key.length - 1];
            const parentMap = path[path.length - 1];
            parentMap.delete(lastKey);
        }
    }

    private getKeyPath(
        key: string[],
        createIfNotExists: boolean = false
    ): TCache<string, T>[] {
        let currentLevel: TCache<string, T> = this.cache;
        const path = [currentLevel];
        for (let i = 0; i < key.length - 1; i++) {
            if (!currentLevel.has(key[i]) && createIfNotExists) {
                currentLevel.set(key[i], new Map());
            }
            const nextLevel = currentLevel.get(key[i]);
            if (nextLevel instanceof Map) {
                currentLevel = nextLevel;
                path.push(currentLevel);
            } else {
                break;
            }
        }
        return path;
    }
}

export const dataCache = new DataCache();
