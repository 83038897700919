import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { LightTable, LightTableRow } from 'src/shared';
import { getAppTitle, roleNameMapper } from 'src/shared/lib';

export const UserProfile: FC = memo(() => {
    const isBankApp = getAppTitle() === 'bank';
    const { auth } = useSelector((state) => state);
    const { id, companyId, companyName, fullName, email, cellPhone, roles } =
        auth;

    const userRolesToString = (roles: string[]) => {
        return roles
            .reduce((string, role) => string + roleNameMapper(role) + ', ', '')
            .replace(/,.$/, '');
    };

    if (isBankApp) {
        return (
            <LightTable>
                <LightTableRow firstTD="Идентификатор" lastTD={id} boldLastTD />
                <LightTableRow
                    firstTD="Имя пользователя"
                    lastTD={fullName}
                    boldLastTD
                />
                <LightTableRow firstTD="Email" lastTD={email} boldLastTD />
                {cellPhone ? (
                    <LightTableRow
                        firstTD="Телефон"
                        lastTD={cellPhone}
                        boldLastTD
                    />
                ) : null}
                <LightTableRow
                    firstTD="Роли"
                    lastTD={userRolesToString(roles)}
                    boldLastTD
                />
            </LightTable>
        );
    }

    return (
        <LightTable>
            <LightTableRow firstTD="Идентификатор" lastTD={id} boldLastTD />
            <LightTableRow
                firstTD="Идентификатор компании"
                lastTD={companyId}
                boldLastTD
            />
            <LightTableRow firstTD="Компания" lastTD={companyName} boldLastTD />
            <LightTableRow
                firstTD="Имя пользователя"
                lastTD={fullName}
                boldLastTD
            />
            <LightTableRow firstTD="Email" lastTD={email} boldLastTD />
            <LightTableRow firstTD="Телефон" lastTD={cellPhone} boldLastTD />
            <LightTableRow
                firstTD="Роли"
                lastTD={userRolesToString(roles)}
                boldLastTD
            />
        </LightTable>
    );
});
