import React, { FC } from 'react';
import { classNames } from 'src/shared/lib';
import styles from './modal-field.module.scss';

type ModalFieldAlign = 'align-start' | 'align-center' | 'align-end';

interface ModalFieldProps {
    label?: string;
    className?: string;
    numberOfColumns?: 1 | 2;
    justifyInput?: 'left' | 'right';
    alignField?: ModalFieldAlign;
    errorMessage?: string;
    textField?: boolean;
    boldLabel?: boolean;
    dataTip?: boolean;
    dataFor?: string;
}

export const ModalField: FC<ModalFieldProps> = (props) => {
    const {
        label,
        numberOfColumns = 2,
        errorMessage,
        dataTip,
        dataFor,
        children,
        justifyInput = 'left',
        alignField = 'align-center',
        textField = false,
        boldLabel,
        className,
    } = props;

    if (numberOfColumns === 1) {
        return (
            <div
                data-tip={dataTip}
                data-for={dataFor}
                className={classNames(
                    styles.field,
                    { [styles['text-field']]: textField },
                    [className, styles['one-column']]
                )}
            >
                {children}
                {errorMessage && (
                    <div className={styles.error}>{errorMessage}</div>
                )}
            </div>
        );
    }

    return (
        <div
            className={classNames(
                styles.field,
                { [styles['text-field']]: textField },
                [className, styles[alignField]]
            )}
        >
            <div
                data-tip={dataTip}
                data-for={dataFor}
                className={classNames(styles.label, {
                    [styles.bold]: boldLabel,
                })}
            >
                {label}
            </div>
            <div
                className={classNames(styles.input, {}, [styles[justifyInput]])}
            >
                {children}
                {errorMessage && (
                    <div className={styles.error}>{errorMessage}</div>
                )}
            </div>
        </div>
    );
};
