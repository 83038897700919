import React, { Component } from 'react';
import { connect } from 'react-redux';

import { URL } from 'src/app/config';

import AwareTable from 'src/components/AwareTable';
import { getClassName, localLoad, localSave } from 'src/shared/lib';
import { OverflowContainer, SearchField, Paginator } from 'src/shared/ui';
import { FiltersContainer } from 'src/widgets';
import { setFilters } from 'src/redux/actions/table';
import styles from './styles.module.scss';

const tableName = 'Company-Table';

class CompanyListPage extends Component {
    render() {
        return (
            <div className="company-list-page">
                <CompanyFilter />

                <Paginator name={tableName}>
                    <OverflowContainer>
                        <AwareTable
                            isStoreTable
                            url={URL.companyUrl}
                            link="/company/"
                            tableName={tableName}
                        />
                    </OverflowContainer>
                </Paginator>
            </div>
        );
    }
}

const CompanyFilter = connect(mapStateToProps)(
    class CompanyFilter extends Component {
        constructor(props) {
            super(props);
            this.queryName = getClassName(tableName, 'searchQuery');
            this.state = {
                searchQuery: localLoad(this.queryName, ''),
            };
            this.props.dispatch(
                setFilters(tableName, {
                    searchQuery: localLoad(this.queryName, ''),
                })
            );
        }

        componentDidUpdate() {
            localSave(this.queryName, this.props.table?.filters?.searchQuery);
        }

        render() {
            return (
                <div className={styles['wrapper']}>
                    <FiltersContainer>
                        <SearchField
                            key={this.props.table?.filters?.searchQuery || ''}
                            fullsize
                            placeholder="Введите ИНН или название компании"
                            value={this.props.table?.filters?.searchQuery || ''}
                            onChange={(value) => {
                                this.props.dispatch(
                                    setFilters(tableName, {
                                        searchQuery: value,
                                    })
                                );
                            }}
                        />
                    </FiltersContainer>
                </div>
            );
        }
    }
);

function mapStateToProps(state) {
    return {
        fetch: state.fetch.fetch,
        table: state.table?.[tableName],
    };
}

export default connect(mapStateToProps)(CompanyListPage);
