import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Card, CardBody, Collapse, Input } from 'reactstrap';

import { sendFeedback } from 'src/redux/actions/feed';
import Container from 'src/components/Container';

import { Button } from 'src/shared/ui';
import { URL } from 'src/app/config';
import { log, get } from 'src/shared/lib';

import { faq } from './faq';

import './support-page.scss';

class SupportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feedback: '',
            subject: '',
            subjectOrder: 0,
            template: '',
            selectedSubject: '',
            messageList: [],
        };
        this.clearFeedback = this.clearFeedback.bind(this);
        this.getMsgTemplateList = this.getMsgTemplateList.bind(this);
        this.findTempBySubject = this.findTempBySubject.bind(this);
    }

    componentDidMount() {
        this.getMsgTemplateList();
    }

    findTempBySubject(context, selectedSubject) {
        const selectedContext = context.find(
            (item) => item.subject === selectedSubject
        );
        selectedContext.template
            ? this.setState({ feedback: selectedContext.template })
            : this.setState({ feedback: '' });
    }

    getMsgTemplateList() {
        get(URL.supportSubjects, {
            subject: this.state.subject,
            subjectOrder: this.state.subjectOrder,
            template: this.state.template,
        })
            .then((r) => {
                const { payload } = r.data;
                this.setState({
                    messageList: payload,
                    subject: payload.map((item) => item.subject),
                });
            })
            .catch((r) => {
                log(r);
            });
    }

    clearFeedback() {
        this.setState({ feedback: '' });
    }

    render() {
        const { dispatch } = this.props;
        return (
            <div className="support-page">
                <Container
                    header={true}
                    headerName={'Часто задаваемые вопросы'}
                >
                    <ul>
                        {faq.map((f, i) => (
                            <li key={i}>
                                <Faq text={f.q} description={f.a} />
                            </li>
                        ))}
                    </ul>
                </Container>
                <Container header={true} headerName={'Форма обратной связи'}>
                    <div className="feedback-form">
                        <div className="support-form">
                            <div className="support-form__container">
                                <select
                                    className="custom-select"
                                    onChange={(e) => {
                                        this.setState(
                                            { selectedSubject: e.target.value },
                                            () => {
                                                this.findTempBySubject(
                                                    this.state.messageList,
                                                    this.state.selectedSubject
                                                );
                                            }
                                        );
                                    }}
                                >
                                    {this.state.subject
                                        ? this.state.subject.map((sub, i) => (
                                              <option key={i} value={sub}>
                                                  {sub}
                                              </option>
                                          ))
                                        : null}
                                </select>
                            </div>
                        </div>
                        <Input
                            value={this.state.feedback}
                            onChange={(feedback) =>
                                this.setState({
                                    feedback: feedback.target.value,
                                })
                            }
                            type="textarea"
                            style={{ whiteSpace: 'pre-line' }}
                            placeholder={'Введите сообщение'}
                            className="modal-input"
                        />
                        <Button
                            className="btn-main btn btn-secondary m2"
                            type="button"
                            onClick={() =>
                                dispatch(
                                    sendFeedback(
                                        {
                                            subject: this.state.selectedSubject,
                                            feedback: this.state.feedback,
                                        },
                                        this.clearFeedback
                                    )
                                )
                            }
                            fetch={this.props.fetch}
                        >
                            Отправить
                        </Button>
                    </div>
                </Container>
            </div>
        );
    }
}

class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
        };
    }

    render() {
        return (
            <div className="faq-block">
                <h5
                    onClick={() =>
                        this.setState({ collapse: !this.state.collapse })
                    }
                    style={{ cursor: 'pointer' }}
                >
                    {this.props.text}
                </h5>
                <Collapse isOpen={this.state.collapse}>
                    <Card>
                        <CardBody>{this.props.description}</CardBody>
                    </Card>
                </Collapse>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    fetch: state.fetch.fetch,
});

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(SupportPage);
