import React, { Component } from 'react';
import { connect } from 'react-redux';

import OperationsFilter from './OperationsFilter';

import { navRoutes, URL } from 'src/app/config';
import {
    getAppTitle,
    getInitialFilter,
    LOCAL_STORAGE_TABLE_NAMES,
} from 'src/shared/lib';
import AwareTable from 'src/components/AwareTable';

import './operations-page.scss';
import { Paginator, OverflowContainer } from 'src/shared/ui';
import { DownloadReportStore } from 'src/features/download-report';

const pageTitle = LOCAL_STORAGE_TABLE_NAMES.operations;

class OperationsPage extends Component {
    constructor(props) {
        super(props);
        this.isMerchantApp = getAppTitle() === 'merchant';
    }

    render() {
        const actions = [];

        if (this.isMerchantApp)
            actions.push(
                <DownloadReportStore
                    pageTitle={pageTitle}
                    useCommonFilters
                    defaultFileName="Операции"
                    url={URL.operationsFileDownloadUrl}
                    getInitialFilter={() =>
                        getInitialFilter(
                            [
                                'terminalIds',
                                'operationTypes',
                                'operationStatuses',
                                'searchQuery',
                                'responseMessages',
                            ],
                            pageTitle
                        )
                    }
                />
            );

        return (
            <div className="operations-page">
                <OperationsFilter pageTitle={pageTitle} />
                <Paginator name={pageTitle}>
                    <OverflowContainer>
                        <AwareTable
                            useCommonFilters
                            newStatuses
                            token={this.props.cancelationToken}
                            tableName={pageTitle}
                            url={URL.operationsUrl}
                            link={navRoutes.operations}
                            isStoreTable
                            actions={actions}
                        />
                    </OverflowContainer>
                </Paginator>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        fetch: state.fetch.fetch,
        cancelationToken: state.requestCancelation.token,
    };
}

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(OperationsPage);
