import { get } from '../../shared/lib/request-wrapper';
import { URL } from 'src/app/config';
import {
    GET_OPERATION_DETAILS,
    GET_OPERATION_DETAILS_FAILED,
    GET_OPERATION_DETAILS_SUCCESS,
    CLEAR_OPERATION_DETAILS,
} from '../constants';

export function getOperationDetail(id) {
    return (dispatch) => {
        dispatch({ type: GET_OPERATION_DETAILS });
        return get(URL.operationDetailUrl + id)
            .then((response) => {
                dispatch({
                    type: GET_OPERATION_DETAILS_SUCCESS,
                    payload: response.data.payload,
                });
            })
            .catch((response) =>
                dispatch({
                    type: GET_OPERATION_DETAILS_FAILED,
                    error: response.data,
                })
            );
    };
}

export function clearOperationDetails() {
    return (dispatch) => dispatch({ type: CLEAR_OPERATION_DETAILS });
}
