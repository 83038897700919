import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { get, log, post, timeZoneOptions } from 'src/shared/lib';
import {
    Modal,
    ModalField,
    Input,
    NewSelect,
    SelectOption,
    InputPassword,
    NewCheckbox,
} from 'src/shared';
import { URL } from 'src/app/config';
import { schema } from '../../lib/validateScheme';
import { ICashbox, StateType } from '../../model/types';
import { defaultValues } from '../../model/consts';
import { createBody } from '../../lib/createBody';
import { AxiosResponse } from 'axios';
import './add-merchangs-key.scss';

interface AddKeyFormProps {
    isOpen: boolean;
    onUpdate: () => void;
    onClose: () => boolean;
    company: {
        hasSBPRole: boolean;
        pageId: string;
        id: string;
    };
}

const statuses: SelectOption[] = [
    { value: 'Активно', content: 'Активно' },
    { value: 'Выключено', content: 'Выключено' },
];

/**
 * @type {boolean}
 * В соответствии с задачей DPS-359 установить возможность
 * включения/выключения Мир/Обновленного вида создания кассы
 */
const IS_MIR_PAY = true;
export const AddMerchantKeys = (props: AddKeyFormProps) => {
    const { company, isOpen, onClose, onUpdate } = props;

    const [showEmailInput, setShowEmailInput] = useState(false);
    const [showDefaultNotificationUrl, setShowDefaultNotificationUrl] =
        useState(false);
    const [ecomkeyCashboxes, setEcomkeyCashboxes] = useState<ICashbox[]>([]);
    const [paymentType, setPaymentType] = useState(['CARD']);
    const [isClosed, setIsClosed] = useState(false);
    const [isIdTerminalMirError, setIsIdTerminalMirError] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
        getValues,
        reset,
    } = useForm<StateType>({
        defaultValues,
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        reset();
    }, [isOpen, reset]);

    const updateCashbox = (response: AxiosResponse) => {
        const newEcomkeyCashboxes = [
            ...(!getValues().cashboxRequired
                ? [
                      ...response.data.payload,
                      {
                          blocked: false,
                          groupCode: 'без кассы',
                          id: 0,
                      },
                  ]
                : response.data.payload),
        ];
        setEcomkeyCashboxes(newEcomkeyCashboxes);
        setValue('cashbox', `${newEcomkeyCashboxes[0].id}`);
    };

    useEffect(() => {
        get(URL.getEcommkeyCashboxes + company.pageId)
            .then((response) => {
                updateCashbox(response);
            })
            .catch((r) => log(r));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = (values: StateType) => {
        const body = createBody(values, paymentType, company.id);
        if (
            body.paymentType.includes('MIR_PAY') &&
            !body?.mirPayTerminalId?.trim()
        ) {
            setIsIdTerminalMirError(true);
            return;
        }
        post(URL.addEcommkey + company.pageId, body)
            .then((r) => {
                onUpdate();
            })
            .catch((r) => {
                log(r);
            })
            .finally(() => {
                setIsClosed(true);
                setIsIdTerminalMirError(false);
            });
    };

    const onChangePaymentType = (selectedType: string) => () => {
        if (paymentType.includes(selectedType)) {
            const filterPayment = paymentType.filter(
                (pt) => pt !== selectedType
            );
            if (filterPayment.length) {
                return setPaymentType([...filterPayment]);
            } else {
                return setPaymentType(['CARD']);
            }
        }
        return setPaymentType([selectedType, ...paymentType]);
    };

    const onChangeCashboxRequired =
        (field: ControllerRenderProps<StateType, 'cashboxRequired'>) =>
        (value: boolean) => {
            field.onChange(value);
            const { cashbox } = getValues();
            const nextValue =
                cashbox === '' ? `${ecomkeyCashboxes[0].id}` : cashbox;
            if (value && Boolean(ecomkeyCashboxes.find(({ id }) => id === 0))) {
                setValue('cashbox', nextValue);
                setEcomkeyCashboxes(
                    ecomkeyCashboxes.filter(({ id }) => id !== 0)
                );
            }
            if (!ecomkeyCashboxes.find(({ id }) => id === 0)) {
                setValue('cashbox', nextValue);
                setEcomkeyCashboxes([
                    ...ecomkeyCashboxes,
                    { blocked: false, groupCode: 'без кассы', id: 0 },
                ]);
            }
        };

    const onChangeIdTerminalMir = (event) => {
        const currentValue = event?.target?.value?.trim();
        if (isIdTerminalMirError && currentValue) {
            setIsIdTerminalMirError(false);
        }
    };

    const cashboxOptions = ecomkeyCashboxes.map((status) => ({
        value: `${status.id}`,
        content: status.groupCode,
    }));

    const timeSelectOptions = timeZoneOptions.map((timeZone) => ({
        value: `${timeZone.value}`,
        content: timeZone.text,
    }));

    return (
        <Modal
            header="Добавление ключа мерчанта"
            isOpen={isOpen}
            isForm
            isClosed={isClosed}
            actionButton="ДОБАВИТЬ"
            onAction={handleSubmit(submit)}
            onClose={onClose}
        >
            <ModalField label="Статус:">
                <Controller
                    name="ecommkeyStatus"
                    control={control}
                    render={({ field }) => (
                        <NewSelect
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value);
                            }}
                            idScrollElement="modal-element-scroll"
                            options={statuses}
                        />
                    )}
                />
            </ModalField>
            <ModalField label="Блокировка:">
                <Controller
                    name="eCommKeyBlocked"
                    control={control}
                    render={({ field }) => (
                        <NewCheckbox
                            isReverseOrder
                            onChange={(event) => {
                                field.onChange(event.target.checked);
                            }}
                            checked={field.value}
                        />
                    )}
                />
            </ModalField>
            {(paymentType?.includes('CARD') ||
                (paymentType?.includes('MIR_PAY') && IS_MIR_PAY)) && (
                <>
                    <ModalField
                        label="id терминала:"
                        errorMessage={errors.idDefaultETerminal?.message}
                    >
                        <Input
                            {...register('idDefaultETerminal')}
                            background
                            placeholder="Введите id"
                        />
                    </ModalField>
                    <ModalField
                        label="Пароль терминала:"
                        errorMessage={errors.passwordDefaultETerminal?.message}
                    >
                        <InputPassword
                            {...register('passwordDefaultETerminal')}
                            background
                            placeholder="Введите Пароль"
                        />
                    </ModalField>
                </>
            )}
            <ModalField label="Касса обязательна:">
                <Controller
                    name="cashboxRequired"
                    control={control}
                    render={({ field }) => (
                        <NewCheckbox
                            isReverseOrder
                            onChange={(event) => {
                                onChangeCashboxRequired(field)(
                                    event.target.checked
                                );
                            }}
                            checked={field.value}
                        />
                    )}
                />
            </ModalField>
            <ModalField label="Касса:">
                <Controller
                    name="cashbox"
                    control={control}
                    render={({ field }) => (
                        <NewSelect
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value);
                            }}
                            idScrollElement="modal-element-scroll"
                            options={cashboxOptions}
                        />
                    )}
                />
            </ModalField>
            <ModalField
                label="merchantId:"
                errorMessage={errors.merchantId?.message}
            >
                <Input
                    {...register('merchantId')}
                    background
                    placeholder="Введите merchantId"
                />
            </ModalField>
            <ModalField
                label="merchantName:"
                errorMessage={errors.merchantName?.message}
            >
                <Input
                    {...register('merchantName')}
                    background
                    placeholder="Введите merchantName"
                />
            </ModalField>
            <ModalField
                label="merchantUrl:"
                errorMessage={errors.merchantUrl?.message}
            >
                <Input
                    {...register('merchantUrl')}
                    background
                    placeholder="Введите Url"
                />
            </ModalField>
            <ModalField
                label="URL при успехе:"
                errorMessage={errors.defaultSuccessUrl?.message}
            >
                <Input
                    {...register('defaultSuccessUrl')}
                    background
                    placeholder="Введите Url"
                />
            </ModalField>
            <ModalField
                label="URL при неудаче:"
                errorMessage={errors.defaultFailUrl?.message}
            >
                <Input
                    {...register('defaultFailUrl')}
                    background
                    placeholder="Введите Url"
                />
            </ModalField>
            <ModalField label="Временная зона:">
                <Controller
                    name="timeZone"
                    control={control}
                    render={({ field }) => (
                        <NewSelect
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value);
                            }}
                            idScrollElement="modal-element-scroll"
                            options={timeSelectOptions}
                        />
                    )}
                />
            </ModalField>
            <ModalField label="Разрешить виджеты:">
                <Controller
                    name="allowWidgets"
                    control={control}
                    render={({ field }) => (
                        <NewCheckbox
                            isReverseOrder
                            onChange={(event) => {
                                field.onChange(event.target.checked);
                            }}
                            checked={field.value}
                        />
                    )}
                />
            </ModalField>
            <ModalField
                label="Максимальная сумма:"
                errorMessage={errors.maxAmount?.message}
            >
                <Input
                    {...register('maxAmount')}
                    background
                    placeholder="Введите maxAmount"
                />
            </ModalField>
            <ModalField label="Уведомлять по почте:">
                <Controller
                    name="notifyByEmail"
                    control={control}
                    render={({ field }) => (
                        <NewCheckbox
                            isReverseOrder
                            onChange={(event) => {
                                setShowEmailInput(event.target.checked);
                                field.onChange(event.target.checked);
                            }}
                            checked={field.value}
                        />
                    )}
                />
            </ModalField>
            {showEmailInput && (
                <ModalField
                    label="Почта для уведомлений:"
                    errorMessage={errors.defaultNotificationEmail?.message}
                >
                    <Input
                        {...register('defaultNotificationEmail')}
                        background
                        placeholder="Введите Email"
                    />
                </ModalField>
            )}
            <ModalField label="Уведомлять по URL:">
                <Controller
                    name="notifyByUrl"
                    control={control}
                    render={({ field }) => (
                        <NewCheckbox
                            isReverseOrder
                            onChange={(event) => {
                                setShowDefaultNotificationUrl(
                                    event.target.checked
                                );
                                field.onChange(event.target.checked);
                            }}
                            checked={field.value}
                        />
                    )}
                />
            </ModalField>
            {showDefaultNotificationUrl && (
                <ModalField
                    label="URL для уведомлений:"
                    errorMessage={errors.defaultNotificationUrl?.message}
                >
                    <Input
                        {...register('defaultNotificationUrl')}
                        background
                        placeholder="Введите Url"
                    />
                </ModalField>
            )}
            {company.hasSBPRole && paymentType && (
                <>
                    <ModalField label="Оплата Картой:">
                        <NewCheckbox
                            isReverseOrder
                            onChange={onChangePaymentType('CARD')}
                            checked={paymentType.includes('CARD')}
                        />
                    </ModalField>
                    <ModalField label="Оплата через СБП:">
                        <NewCheckbox
                            isReverseOrder
                            onChange={onChangePaymentType('SBP')}
                            checked={paymentType.includes('SBP')}
                        />
                    </ModalField>
                    {paymentType?.includes('SBP') && company.hasSBPRole && (
                        <>
                            <ModalField label="ID Юр Лица в СБП:">
                                <Input
                                    {...register('extEntityId')}
                                    background
                                    placeholder="Введите Id"
                                />
                            </ModalField>
                            <ModalField label="ID терминала в СБП:">
                                <Input
                                    {...register('merchantIdSbp')}
                                    background
                                    placeholder="Введите Id"
                                />
                            </ModalField>
                            <ModalField label="№ счета ТСП в СБП">
                                <Input
                                    {...register('account')}
                                    background
                                    placeholder="Введите № счета"
                                />
                            </ModalField>
                            <ModalField label="Синоним счета">
                                <Input
                                    {...register('accAlias')}
                                    background
                                    placeholder="Введите № счета"
                                />
                            </ModalField>
                        </>
                    )}
                </>
            )}
            {IS_MIR_PAY && (
                <ModalField label="Оплата через MirPay:">
                    <NewCheckbox
                        isReverseOrder
                        onChange={onChangePaymentType('MIR_PAY')}
                        checked={paymentType.includes('MIR_PAY')}
                    />
                </ModalField>
            )}
            {paymentType?.includes('MIR_PAY') && IS_MIR_PAY && (
                <>
                    <ModalField label="MirPay keyId:">
                        <Input
                            {...register('mirPayKid')}
                            background
                            placeholder="Введите keyId"
                        />
                    </ModalField>
                    <ModalField label="MirPay MerchantId:">
                        <Input
                            {...register('mirPayMerchantId')}
                            background
                            placeholder="Введите MerchantId"
                        />
                    </ModalField>
                    <ModalField label="Id терминала для MirPay:">
                        <Input
                            {...register('mirPayTerminalId')}
                            background
                            placeholder="Введите id терминала MirPay"
                            onChange={onChangeIdTerminalMir}
                        />
                        {isIdTerminalMirError && (
                            <div className="required-id-mir">
                                Обязательное поле
                            </div>
                        )}
                    </ModalField>
                    <ModalField label="MirPay Private key">
                        <Input
                            {...register('mirPayPrivateKey')}
                            type="password"
                            background
                            placeholder="Введите Private key"
                        />
                    </ModalField>
                </>
            )}
        </Modal>
    );
};
