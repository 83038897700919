import React, { Component } from 'react';
import { Chart } from 'chart.js';
import './dynamic-chart.scss';
import { ticksFormatter } from '../../../shared/lib/util';

export default class DynamicChart extends Component {
    constructor(props) {
        super(props);
        this.ticksFormatter = ticksFormatter.bind(this);
        this.chart = null;
        this.state = {
            chartConfig: {
                chartType: 'line',
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: '',
                            data: [],
                            backgroundColor: 'rgb(198, 235, 189)',
                            borderColor: 'rgb(198, 235, 189)',
                            borderWidth: 3,
                            lineTension: 0.5,
                            pointRadius: 1.5,
                            fill: false,
                        },
                        {
                            label: '',
                            data: [],
                            backgroundColor: 'rgb(208, 237, 248)',
                            borderColor: 'rgb(208, 237, 248)',
                            borderWidth: 3,
                            lineTension: 0.5,
                            pointRadius: 1.5,
                            fill: false,
                        },
                    ],
                },
                options: {
                    tooltips: {
                        callbacks: {
                            label: (label) => this.ticksFormatter(label.yLabel),
                        },
                    },
                    legend: {
                        display: true,
                        position: 'top',
                    },
                    scales: {
                        x: {
                            stacked: false,
                            scaleLabel: {
                                display: true,
                                labelString: '',
                            },
                            ticks: {
                                min: 0,
                                beginAtZero: true,
                            },
                        },
                        y: {
                            stacked: false,
                            scaleLabel: {
                                display: true,
                                labelString: '',
                            },
                            ticks: {
                                min: 0,
                                beginAtZero: true,
                                callback: this.ticksFormatter,
                            },
                        },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                },
            },
        };
    }

    clearChart() {
        if (this.chart) this.chart.destroy();
    }

    componentWillReceiveProps(newProps) {
        this.configureCharts(newProps);
    }

    componentDidMount() {
        this.configureCharts(this.props);
    }

    configureCharts(props) {
        if (props.data) {
            this.prepareData(props.data);
            let ctx = this.canvas.getContext('2d');
            this.clearChart();
            this.chart = new Chart(ctx, {
                type: this.state.chartConfig.chartType,
                data: this.state.chartConfig.data,
                options: this.state.chartConfig.options,
            });
        }
    }

    componentWillUnmount() {
        this.clearChart();
    }

    prepareData(data) {
        let newChart = this.state.chartConfig;
        newChart.options.scales = {
            y: { ...newChart.options.scales.y, scaleLabel: data.groupName },
            x: { ...newChart.options.scales.x, scaleLabel: data.name },
        };
        newChart.data.labels = [];
        newChart.data.datasets[0].data = [];
        newChart.data.datasets[0].label = data.paramNames[0];
        newChart.data.datasets[1].data = [];
        newChart.data.datasets[1].label = data.paramNames[1];
        data.tuples.forEach((item) => {
            newChart.data.labels.push(item.name);
            newChart.data.datasets[0].data.push(item.values[0]);
            newChart.data.datasets[1].data.push(item.values[1]);
        });
        this.setState({ chartConfig: newChart });
    }

    render() {
        return (
            <div className="dynamic-chart chart">
                <canvas ref={(e) => (this.canvas = e)} />
            </div>
        );
    }
}
