import { combineReducers } from 'redux';

import auth from './auth';
import terminals from './terminals';
import { reducer as notificationsReducer } from 'react-notification-system-redux';
import { reducer as formReducer } from 'redux-form';
import charts from './charts';
import operation from './operation';
import fetch from './fetch';
import settings from './settings';
import table from './table';
import requestCancelation from './requestCancelation';

const reducers = combineReducers({
    notifications: notificationsReducer,
    form: formReducer,
    auth,
    terminals,
    charts,
    operation,
    fetch,
    settings,
    table,
    requestCancelation,
});

export default reducers;
