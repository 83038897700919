import React from 'react';
import styles from './styles.module.scss';
import { classNames } from 'src/shared/lib';
import { SelectorIcon } from 'src/shared/ui/selector-icon';
import {
    INDICATOR_COLOR_VARIANT_MAP,
    IndicatorColorVariant,
} from 'src/entities/selector-with-groups/config';

interface Props {
    handleGroupClick: () => void;
    selectorColor: IndicatorColorVariant;
    isSelected: boolean;
    isFullySelected: boolean;
    name: string;
}

export const GroupRow = ({
    name,
    handleGroupClick,
    selectorColor,
    isSelected,
    isFullySelected,
}: Props) => {
    return (
        <span
            onClick={() => handleGroupClick()}
            className={classNames(styles['title-with-selector'], {
                [styles['selected']]: isSelected,
            })}
        >
            <SelectorIcon
                selected={isSelected}
                color={INDICATOR_COLOR_VARIANT_MAP[selectorColor]}
                selectorFillVariant={
                    isFullySelected
                        ? SelectorIcon.fillerVariant.REGULAR
                        : SelectorIcon.fillerVariant.HALF_SELECTED
                }
            />
            <span className={styles['name']}> {name} </span>
        </span>
    );
};
