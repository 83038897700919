import { boolean, object, string } from 'yup';
import { validationMessages } from 'src/shared/lib';

export const schema = object({
    cashboxBlocked: boolean(),
    cashboxStatus: string(),
    groupCode: string().required(validationMessages.required),
    cashboxLogin: string().required(validationMessages.required),
    cashboxPassword: string().required(validationMessages.required),
    address: string().required(validationMessages.required),
});
