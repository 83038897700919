import React, { FC, MouseEvent } from 'react';
import { isMobile } from 'react-device-detect';
import { Button, ResetFiltersIcon } from 'src/shared';
import { classNames } from 'src/shared/lib';
import { SelectOption } from '../../model/types';
import {
    SelectedOptions,
    TypeOptions,
} from '../selecting-items-from-list/selecting-items-from-list';
import styles from './modal-ui-header.module.scss';

interface ModalUiHeaderProps {
    name: string;
    typeOptions: TypeOptions;
    numberSelectedItems: number;
    entitiesOptions: (string | number)[] | Record<string, SelectOption>;
    selectedOptions: SelectedOptions;
    onClickSelectedOption: (e: MouseEvent<HTMLDivElement>) => void;
    onClickButton: () => void;
    className?: string;
}

export const ModalUiHeader: FC<ModalUiHeaderProps> = (props) => {
    const {
        name,
        typeOptions,
        numberSelectedItems,
        selectedOptions,
        entitiesOptions,
        onClickSelectedOption,
        onClickButton,
        className,
    } = props;

    const getDisplayOption = (value: string) => {
        switch (typeOptions) {
            case TypeOptions.ROLES:
                return entitiesOptions[value].content;
            case TypeOptions.TERMINALS:
                return entitiesOptions[value].content.split(' ')[0];
            default:
                return entitiesOptions[value].content;
        }
    };

    const renderSelectedOptions = Object.values(selectedOptions)?.map(
        (option, index) => {
            if (index > (isMobile ? 1 : 2)) {
                return null;
            }

            return (
                <div
                    key={option}
                    data-value={option}
                    className={styles['selected-option']}
                >
                    {getDisplayOption(option)}
                    <span onClick={onClickSelectedOption}>
                        {ResetFiltersIcon}
                    </span>
                </div>
            );
        }
    );

    return (
        <div className={classNames(styles.header, {}, [className])}>
            <Button size="xs" onClick={onClickButton}>
                + {name}
            </Button>
            <div className={styles['selected-options']}>
                {renderSelectedOptions}
            </div>

            {numberSelectedItems > (isMobile ? 1 : 2) && (
                <Button
                    size="xs"
                    onClick={onClickButton}
                    className={styles.counter}
                >
                    + {numberSelectedItems}
                </Button>
            )}
        </div>
    );
};
