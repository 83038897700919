import React from 'react';

export const DataFetchingIcon = () => {
    return (
        <svg
            width="51"
            height="40"
            viewBox="0 0 51 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.90137 16.1787C3.46582 16.1787 3.09668 17.2041 3.87598 18.291L8.34668 24.5869C8.98242 25.4893 9.94629 25.4688 10.5615 24.5869L15.0117 18.2705C15.791 17.2041 15.4219 16.1787 14.0068 16.1787H11.1973C12.8994 8.95996 19.3594 3.64844 27.1318 3.64844C36.1963 3.64844 43.4766 10.9082 43.4766 19.9932C43.4766 29.0781 36.1963 36.3789 27.1318 36.3584C21.6973 36.3379 16.9395 33.7334 14.0068 29.6934C13.3506 28.873 12.3457 28.6064 11.5049 29.1602C10.6846 29.6729 10.4795 30.8008 11.1973 31.7236C14.8682 36.584 20.6719 39.8652 27.1318 39.8652C38.001 39.8652 47.0039 30.8623 47.0039 19.9932C47.0039 9.12402 38.001 0.121094 27.1318 0.121094C17.5547 0.121094 9.43359 7.09375 7.62891 16.1787H4.90137ZM26.3936 8.65234C25.5117 8.65234 24.7939 9.37012 24.7939 10.252V20.9775C24.7939 21.4287 24.9375 21.8389 25.2656 22.2695L30.0029 28.5244C30.6592 29.3857 31.6025 29.5293 32.4023 28.9551C33.1406 28.4424 33.2021 27.4785 32.6279 26.6787L27.9727 20.4443V10.252C27.9727 9.37012 27.2754 8.65234 26.3936 8.65234Z"
                fill="#002E5A"
            />
        </svg>
    );
};
