import React from 'react';

export const UserDropdownIcon = (
    <svg
        width="33"
        height="33"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="16.5" cy="16.5" r="16.5" fill="#002E5A" />
        <path
            d="M16.3008 15.6086C17.8624 15.6086 19.1441 14.2311 19.1441 12.4854C19.1441 10.7838 17.855 9.42847 16.3008 9.42847C14.7465 9.42847 13.4427 10.7986 13.4501 12.4928C13.4575 14.2311 14.7318 15.6086 16.3008 15.6086ZM11.999 22.1349H20.5952C21.8106 22.1349 22.2231 21.7372 22.2231 21.0521C22.2231 19.2695 19.9102 16.824 16.2934 16.824C12.684 16.824 10.3711 19.2695 10.3711 21.0521C10.3711 21.7372 10.7836 22.1349 11.999 22.1349Z"
            fill="white"
        />
    </svg>
);
