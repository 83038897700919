import React, { FC, memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { URL } from 'src/app/config';
import { createNotification } from 'src/redux/actions/notification';
import { log, post, useCloseCustomModal } from 'src/shared/lib';
import { Button, Input, Modal, ModalField } from 'src/shared/ui';
import { validationScheme } from '../../lib/validationScheme';
import { removeMessage, submitMessage } from '../../model/consts/consts';

interface Inputs {
    name: string;
}

interface Props {
    id?: string;
    groupName: string;
    resetId: () => void;
    successCallback: () => void;
}

export const EditGroupOfTerminals: FC<Props> = memo((props) => {
    const { id, groupName, resetId, successCallback } = props;
    const [isOpen, setOpen] = useState(true);
    const [isClosed, setClosed] = useState(false);
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>({
        mode: 'onSubmit',
        defaultValues: { name: groupName },
        resolver: yupResolver(validationScheme),
    });

    const onClose = useCloseCustomModal(setOpen, resetId);

    const onSubmit = ({ name }: Inputs) => {
        post(URL.terminalPageUpdateGroupUrl, {
            id,
            name,
        })
            .then(() => {
                successCallback?.();
                dispatch(createNotification(submitMessage, 'success'));
            })
            .catch((r) => log(r));
        setClosed(true);
    };

    const onRemove = () => {
        post(URL.terminalPageRemoveGroupUrl, {
            id,
        })
            .then(() => {
                resetId();
                successCallback?.();
                dispatch(createNotification(removeMessage, 'success'));
            })
            .catch((r) => log(r));
        setClosed(true);
    };

    return (
        <Modal
            isForm
            isOpen={isOpen}
            isClosed={isClosed}
            header="Редактирование группы"
            closeButton="Отмена"
            actionButton="Сохранить"
            onClose={onClose}
            onAction={handleSubmit(onSubmit)}
        >
            <ModalField numberOfColumns={1} errorMessage={errors.name?.message}>
                <Input
                    background
                    placeholder="Введите название группы"
                    {...register('name')}
                />
            </ModalField>
            <ModalField justifyInput="right" label="Удаление группы">
                <Button type="button" size="xs" onClick={onRemove}>
                    Удалить
                </Button>
            </ModalField>
        </Modal>
    );
});
