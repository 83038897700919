import React, { forwardRef } from 'react';
import { ReactInputProps, InputProps } from './types';
import styles from './input.module.scss';

export const Input = forwardRef<HTMLInputElement, InputProps & ReactInputProps>(
    (
        {
            background,
            error,
            warning,
            fluid,
            icon,
            customInput,
            readonly = false,
            ...rest
        },
        ref
    ) => {
        const computedClassName = [
            styles['input'],

            Boolean(background) ? styles['input--bg'] : '',
            Boolean(error) ? styles['input--error'] : '',
            Boolean(fluid) ? styles['input--fluid'] : '',
            Boolean(icon) ? styles['input--with-icon'] : '',
            Boolean(readonly) ? styles['readonly'] : '',
        ].join(' ');

        return (
            <div className={styles['input-wrapper']}>
                {!customInput ? (
                    <input
                        {...rest}
                        ref={ref}
                        readOnly={readonly}
                        className={`${computedClassName} ${
                            rest.className || ''
                        }`}
                    />
                ) : (
                    <div
                        className={
                            'custom-input ' +
                                computedClassName +
                                rest.className || ''
                        }
                    >
                        {customInput}
                    </div>
                )}

                {typeof error === 'string' && (
                    <div className={styles['error-message']}>{error}</div>
                )}
                {warning && (
                    <div className={styles['warning-message']}>{warning}</div>
                )}
                {icon && <div className={styles['icon']}>{icon}</div>}
            </div>
        );
    }
);
