import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button, FilterSelectorIcon } from 'src/shared/ui';
import { FilterColumn, ColumnState, Option } from './config';

import styles from './filters-selector.module.scss';

interface Props {
    columns: FilterColumn[];
    reset: () => void;
}

export const FiltersSelector: FC<Props> = ({ columns, reset }) => {
    const [open, setOpen] = useState<boolean>();
    const [columnsState, setColumnsState] = useState<ColumnState>({});
    let ref = useRef<HTMLDivElement>(null);

    const initColumnsState = useCallback(
        (reset?: boolean) => {
            let newColumnsState = {};
            columns.forEach(({ title, selected }) => {
                let resetValue = Array.isArray(selected) ? [] : undefined;
                newColumnsState[title] = {
                    selected: reset ? resetValue : selected,
                };
            });
            setColumnsState(newColumnsState);
        },
        [columns]
    );

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                ref.current &&
                !ref.current.contains(event.target as HTMLDivElement)
            ) {
                // close popup on click outside
                setOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside, true);
        initColumnsState();
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [initColumnsState]);

    const count = () => {
        let total = 0;
        columns.forEach((column) => {
            const selected = columnsState[column.title]?.selected;
            if (Array.isArray(selected)) {
                total += selected.length;
            } else if (selected) {
                total += 1;
            }
        });
        return total;
    };

    const handleMultipleOptionsChange = (value: string, title: string) => {
        const currentSelected = columnsState[title]?.selected as string[];
        const newSelected = [...(currentSelected || [])];
        const idx = newSelected.findIndex((oldValue) => value === oldValue);
        if (idx !== -1) {
            newSelected.splice(idx, 1);
        } else {
            newSelected.push(value);
        }
        setColumnsState({
            ...columnsState,
            [title]: { selected: newSelected },
        });
    };

    const handleSingleOptionChange = (value: string, title: string) => {
        const selected = columnsState[title]?.selected === value;
        if (selected) {
            setColumnsState({
                ...columnsState,
                [title]: { selected: undefined },
            });
        } else {
            setColumnsState({
                ...columnsState,
                [title]: { selected: value },
            });
        }
    };

    const renderFilterColumn = (column: FilterColumn, index: number) => {
        const renderOption = (option: Option, index: number) => {
            const selected =
                columnsState[column.title]?.selected === option.value ||
                (Array.isArray(columnsState[column.title]?.selected) &&
                    columnsState[column.title]?.selected?.includes(
                        option.value
                    ));
            const className = [
                styles['filter-column__option'],
                selected ? styles['filter-column__option--selected'] : '',
            ].join(' ');
            const handleChange = (value: string) => {
                const currentSelected = columnsState[column.title]?.selected;
                if (Array.isArray(currentSelected) && !column.isSingleSelect) {
                    handleMultipleOptionsChange(value, column.title);
                } else {
                    handleSingleOptionChange(value, column.title);
                }
            };
            return (
                <button
                    key={index + column.title}
                    className={className}
                    onClick={() => handleChange(option.value)}
                >
                    {option.text}
                </button>
            );
        };

        return (
            <div className={styles['filter-column']} key={index + column.title}>
                <span className={styles['filter-column__title']}>
                    {column.title}
                </span>
                {column.options.map(renderOption)}
            </div>
        );
    };

    const handleChange = () => {
        columns.forEach((column) => {
            const selected = columnsState[column.title].selected;
            if (Array.isArray(selected)) {
                column.onChange([...selected]);
            } else {
                column.onChange(selected);
            }
        });
        setOpen(false);
    };

    return (
        <div className={styles['wrapper']}>
            <button
                className={[
                    styles['trigger'],
                    open ? styles['trigger--open'] : '',
                ].join(' ')}
                onClick={() => setOpen(true)}
            >
                {FilterSelectorIcon}
                <div
                    className={[
                        styles['trigger__count'],
                        count() ? styles['trigger__count--visible'] : '',
                    ].join(' ')}
                >
                    {count()}
                </div>
            </button>
            <div
                className={[
                    styles['popup'],
                    open ? styles['popup--open'] : '',
                ].join(' ')}
                ref={ref}
            >
                <div className={styles['columns']}>
                    {columns.map(renderFilterColumn)}
                </div>
                <div className={styles['actions']}>
                    <Button onClick={handleChange} size="tiny">
                        Применить
                    </Button>
                    <Button
                        outline
                        size="tiny"
                        onClick={() => {
                            reset();
                            initColumnsState(true);
                        }}
                    >
                        Сбросить значения
                    </Button>
                </div>
            </div>
        </div>
    );
};
