export const LOG_IN = 'LOG_IN';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILED = 'LOG_IN_FAILED';

export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_FAILED = 'ACTIVATE_USER_FAILED';

export const GET_COMPANY_ROLES = 'GET_COMPANY_ROLES';
export const GET_COMPANY_ROLES_FAILED = 'GET_COMPANY_ROLES_FAILED';
export const GET_COMPANY_ROLES_SUCCESS = 'GET_COMPANY_ROLES_SUCCESS';

export const FORGOT_PASSWORD_USER_SUCCESS = 'FORGOT_PASSWORD_USER_SUCCESS';
export const FORGOT_PASSWORD_USER_FAILED = 'FORGOT_PASSWORD_USER_FAILED';

export const GET_USER_AFTER_LOGIN_ROLES_SUCCESS =
    'GET_USER_AFTER_LOGIN_ROLES_SUCCESS';
export const LOG_OUT_AND_CLEAR_LOCAL = 'LOG_OUT_AND_CLEAR_LOCAL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_USER_EXPIRED = 'CHANGE_PASSWORD_USER_EXPIRED';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const TOGGLE_CHART = 'TOGGLE_CHART';
export const GET_MPS_CHARTS_SUCCESS = 'GET_MPS_CHARTS_SUCCESS';
export const GET_MPS_CHARTS_FAILED = 'GET_MPS_CHARTS_FAILED';
export const GET_DINAMIC_CHARTS_SUCCESS = 'GET_DINAMIC_CHARTS_SUCCESS';
export const GET_DINAMIC_CHARTS_FAIL = 'GET_DINAMIC_CHARTS_FAIL';
export const GET_DECLINE_CHARTS_SUCCESS = 'GET_DECLINE_CHARTS_SUCCESS';
export const GET_DECLINE_CHARTS_FAIL = 'GET_DECLINE_CHARTS_FAIL';
export const GET_CONVERSION_CHARTS_SUCCESS = 'GET_CHARTS';
export const GET_CONVERSION_CHARTS_FAIL = 'GET_CHARTS';

export const GET_OPERATION_DETAILS = 'GET_OPERATION_DETAILS';
export const GET_OPERATION_DETAILS_FAILED = 'GET_OPERATION_DETAILS_FAILED';
export const GET_OPERATION_DETAILS_SUCCESS = 'GET_OPERATION_DETAILS_SUCCESS';
export const CLEAR_OPERATION_DETAILS = 'CLEAR_OPERATION_DETAILS';

export const GET_OPERATION_SBP_DETAILS = 'GET_OPERATION_SBP_DETAILS';
export const CHANGE_OPERATION_SBP_DETAILS = 'CHANGE_OPERATION_SBP_DETAILS';
export const GET_OPERATION_SBP_DETAILS_FAILED =
    'GET_OPERATION_SBP_DETAILS_FAILED';
export const GET_OPERATION_SBP_DETAILS_SUCCESS =
    'GET_OPERATION_SBP_DETAILS_SUCCESS';
export const CLEAR_OPERATION_SBP_DETAILS = 'CLEAR_OPERATION_SBP_DETAILS';

export const GET_TABLES = 'GET_TABLES';
export const GET_TABLES_FAILED = 'GET_TABLES_FAILED';

export const GET_TERMINALS = 'GET_TERMINALS';
export const GET_TERMINALS_FAILED = 'GET_TERMINALS_FAILED';
export const SET_TERMINALS_INACTIVE_PERIOD_PER_DAYS =
    'SET_TERMINALS_INACTIVE_PERIOD_PER_DAYS';
export const SET_TERMINAL_INACTIVE = 'SET_TERMINAL_INACTIVE';
export const SET_IDN_SBP = 'SET_IDN_SBP';

export const GET_RCMSG = 'GET_RCMSG';
export const GET_RCMSG_FAILED = 'GET_RCMSG_FAILED';

export const GET_SBP_RCMSG = 'GET_SBP_RCMSG';
export const GET_SBP_RCMSG_FAILED = 'GET_SBP_RCMSG_FAILED';

export const FETCH_START = 'FETCH_START';
export const FETCH_STOP = 'FETCH_STOP';

export const CHANGE_ROUTE = 'CHANGE_ROUTE';

export const CHANGE_START_DATE = 'CHANGE_START_DATE';
export const CHANGE_END_DATE = 'CHANGE_END_DATE';

// pagination
export const SET_OFFSET = 'SET_OFFSET';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const GO_NEXT_PAGE = 'GO_NEXT_PAGE';
export const GO_PREVIOUS_PAGE = 'GO_PREVIOUS_PAGE';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_TOTAL_COUNT_FOR_PAGINATION = 'SET_TOTAL_COUNT_FOR_PAGINATION';
export const SET_DATA_IS_FETCHING = 'SET_DATA_IS_FETCHING'

export const SET_NEEDS_UPDATE = 'SET_NEEDS_UPDATE';
export const RESET_TOKEN = 'RESET_TOKEN';
export const SET_PERSISTED_TABLE_DATA = 'SET_PERSISTED_TABLE_DATA';

export const SET_COMMON_TABLE_FILTERS = 'SET_COMMON_TABLE_FILTERS';
