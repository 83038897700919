import React, { Component } from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';

class LightTable extends Component {
    render() {
        return (
            <Table bordered striped responsive size="sm">
                <thead>
                    <tr>
                        {this.props.headers.map((header, idx) => (
                            <th key={header + idx}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {this.props.data && this.props.data.length ? (
                        this.props.data.map((row, index) => (
                            <tr key={index}>
                                {row.map((column, idx) => (
                                    <td key={column + idx}>{column}</td>
                                ))}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={this.props.headers.length}>
                                Данные не найдены
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }
}

LightTable.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

export default LightTable;
