import axios from 'axios';
import store from '../../store';
import { LOG_OUT_AND_CLEAR_LOCAL } from '../../redux/constants';
import { createNotification } from '../../redux/actions/notification';
import { fetchStart, fetchStop } from '../../redux/actions/fetch';
import { Errors } from './ErrorTypes';
import { log } from './util';

const baseURL = '/';
const client = axios.create({
    withCredentials: true,
    baseURL,
});

const success = (response, resolve) => {
    store.dispatch(fetchStop());
    resolve(response);
};

const fail = (response, reject) => {
    store.dispatch(fetchStop());

    if (axios.isCancel(response)) {
        log(response?.message);
        reject(response?.message || 'request canceled');
        return;
    }

    if (response.response.status === 401) {
        store.dispatch({ type: LOG_OUT_AND_CLEAR_LOCAL });
    }
    if (response.response.status === 500) {
        store.dispatch(
            createNotification(
                {
                    autoDismiss: 60,
                    ...{
                        ...Errors.DEFAULT,
                        message:
                            response.response.data.message ||
                            Errors.SERVER_NOT_AVAILABLE.title,
                    },
                },
                'error'
            )
        );
        reject(response.response);
    }
    if (response.response.status > 500) {
        store.dispatch(
            createNotification(
                {
                    ...Errors.SERVER_NOT_AVAILABLE,
                },
                'error'
            )
        );
        store.dispatch({ type: LOG_OUT_AND_CLEAR_LOCAL });
    }
    reject(response.response);
};
const addApiPrefix = (url) => ('/api/' + url).replace('//', '/');

export function post(url, params = {}, headers = {}, cancelToken) {
    return new Promise((resolve, reject) => {
        store.dispatch(fetchStart());
        return client
            .post(addApiPrefix(url), params, {
                ...headers,
                cancelToken: cancelToken?.token,
            })
            .then((response) => success(response, resolve))
            .catch((response) => fail(response, reject));
    });
}

export function get(url) {
    return new Promise((resolve, reject) => {
        store.dispatch(fetchStart());
        return client
            .get(addApiPrefix(url))
            .then((response) => success(response, resolve))
            .catch((response) => fail(response, reject));
    });
}
