import moment from 'moment';
import { FileFormat } from '../model/types';

export const getDownloadedFileName = (
    fileName: string,
    fileFormat: FileFormat
) => {
    const format = fileFormat.toLocaleLowerCase();

    if (fileName) {
        return `${fileName}.${format}`;
    }

    return `Экспорт ${moment().format('DD MMMM YYYY')}.${format}`;
};
