import React from 'react';

export const SearchIcon = (
    <svg
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.5166 14.1738C8.81738 14.1738 10.0303 13.7959 11.0586 13.1455L14.5742 16.6611C14.8291 16.9248 15.1719 17.0479 15.5322 17.0479C16.2969 17.0479 16.8594 16.459 16.8594 15.7031C16.8594 15.3604 16.7363 15.0176 16.4814 14.7539L12.9922 11.2646C13.7129 10.21 14.126 8.93555 14.126 7.56445C14.126 3.93457 11.1553 0.955078 7.5166 0.955078C3.89551 0.955078 0.916016 3.92578 0.916016 7.56445C0.916016 11.2031 3.88672 14.1738 7.5166 14.1738ZM7.5166 12.2666C4.94141 12.2666 2.82324 10.1484 2.82324 7.56445C2.82324 4.98047 4.94141 2.8623 7.5166 2.8623C10.1006 2.8623 12.2188 4.98047 12.2188 7.56445C12.2188 10.1484 10.1006 12.2666 7.5166 12.2666Z"
            fill="#002E5A"
        />
    </svg>
);
