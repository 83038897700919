import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { NewPasswordInput } from 'src/features/new-password-input';
import { activateUser } from 'src/redux/actions/auth';
import { Input, Button, InputPassword } from 'src/shared/ui';
import { PublicFormWidget } from 'src/widgets';
import {
    ActivateFormFields,
    Params,
    schemaBank,
    schemaMerchant,
} from './config';
import { getAppTitle } from 'src/shared/lib';
import styles from './form.module.scss';

export const Form = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isMerchant = getAppTitle() === 'merchant';
    const schema = isMerchant ? schemaMerchant : schemaBank;
    const { uid } = useParams<Params>();
    const { fetch } = useSelector((state) => state.fetch);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<ActivateFormFields>({
        defaultValues: { password: '', repeatPassword: '', code: '' },
        resolver: yupResolver(schema),
    });

    const submit = ({ password, code }: ActivateFormFields) => {
        dispatch(
            activateUser(
                {
                    token: uid,
                    password,
                    code: isMerchant ? code : undefined,
                },
                history
            )
        );
    };

    return (
        <PublicFormWidget header="Активация аккаунта">
            <form className={styles.form} onSubmit={handleSubmit(submit)}>
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <NewPasswordInput
                            error={errors['password']?.message}
                            field={field}
                            placeholder="Придумайте пароль"
                            inputProps={{ fluid: true }}
                        />
                    )}
                />
                <InputPassword
                    {...register('repeatPassword')}
                    placeholder="Повторите пароль"
                    type="password"
                    error={errors.repeatPassword?.message}
                    fluid
                />
                {isMerchant ? (
                    <Input
                        {...register('code')}
                        placeholder="Введите код подтверждения"
                        type="text"
                        error={errors.code?.message}
                        fluid
                    />
                ) : null}
                <Button fluid type="submit" loading={fetch} disabled={fetch}>
                    Отправить
                </Button>
            </form>
        </PublicFormWidget>
    );
};
