import * as type from '../constants';

const initState = {};

export default function table(state = initState, action) {
    switch (action.type) {
        case type.SET_TOTAL_COUNT_FOR_PAGINATION: {
            const { tableId, totalCountForPagination } = action.payload;
            const newState = { ...state };
            newState[tableId] = {
                ...(newState[tableId] && newState[tableId]),
                totalCountForPagination: totalCountForPagination,
            };

            return newState;
        }

        case type.SET_PAGE_SIZE: {
            const { tableId, pageSize } = action.payload;
            const newState = { ...state };
            newState[tableId] = {
                ...(newState[tableId] && newState[tableId]),
                pageSize: pageSize,
            };

            return newState;
        }

        case type.SET_OFFSET: {
            const { tableId, offset } = action.payload;
            const newState = { ...state };
            newState[tableId] = {
                ...(newState[tableId] && newState[tableId]),
                offset: offset,
            };

            return newState;
        }

        case type.GO_NEXT_PAGE: {
            const { tableId } = action.payload;
            const newState = { ...state };
            newState[tableId] = {
                ...(newState[tableId] && newState[tableId]),
                offset: newState[tableId].offset + newState[tableId].pageSize,
            };

            return {
                ...newState,
            };
        }

        case type.GO_PREVIOUS_PAGE: {
            const { tableId } = action.payload;
            const newState = { ...state };
            newState[tableId] = {
                ...(newState[tableId] && newState[tableId]),
                offset:
                    newState[tableId].offset - newState[tableId].pageSize > 0
                        ? newState[tableId].offset - newState[tableId].pageSize
                        : 0,
            };

            return {
                ...newState,
            };
        }

        case type.SET_FILTERS: {
            const { tableId, filters } = action.payload;
            const newState = { ...state };
            newState[tableId] = {
                ...(newState[tableId] && newState[tableId]),
                filters: {
                    ...(newState[tableId]?.filters &&
                        newState[tableId].filters),
                    ...filters,
                },
            };

            return {
                ...newState,
            };
        }

        case type.CHANGE_START_DATE: {
            const { tableId, startDate } = action.payload;
            const newState = { ...state };
            newState[tableId] = {
                ...(newState[tableId] && newState[tableId]),
                startDate: startDate,
            };

            return newState;
        }

        case type.CHANGE_END_DATE: {
            const { tableId, endDate } = action.payload;
            const newState = { ...state };
            newState[tableId] = {
                ...(newState[tableId] && newState[tableId]),
                endDate: endDate,
            };

            return newState;
        }

        case type.SET_NEEDS_UPDATE: {
            const { tableId, needsUpdate, findAll } = action.payload;
            const newState = { ...state };

            if (findAll) {
                Object.keys(newState).forEach((key) => {
                    if (key.startsWith(tableId)) {
                        newState[key] = {
                            ...newState[key],
                            needsUpdate,
                        };
                    }
                });
            } else {
                newState[tableId] = {
                    ...(newState[tableId] && newState[tableId]),
                    needsUpdate,
                };
            }

            return newState;
        }

        case type.SET_PERSISTED_TABLE_DATA: {
            const { tableId, tableData } = action.payload;
            const newState = { ...state };
            newState[tableId] = {
                ...(newState[tableId] && newState[tableId]),
                tableData,
            };

            return newState;
        }

        case type.SET_COMMON_TABLE_FILTERS: {
            const { commonFilters } = action.payload;
            const newState = { ...state };
            newState.commonFilters = {
                ...newState.commonFilters,
                ...commonFilters,
            };

            return newState;
        }

        case type.SET_DATA_IS_FETCHING: {
            const { tableId, fetching } = action.payload;
            const newState = { ...state };
            newState[tableId] = {
                ...(newState[tableId] && newState[tableId]),
                fetching,
            };

            return newState;
        }

        default:
            return state;
    }
}
