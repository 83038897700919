import React, { FC } from 'react';
import { classNames } from 'src/shared/lib';
import styles from './article-layout.module.scss';

interface ArticleLayoutProps {
    bigColumn: React.ReactNode;
    smallColumn: React.ReactNode;
    className?: string;
}

export const ArticleLayout: FC<ArticleLayoutProps> = ({
    bigColumn,
    smallColumn,
    className = '',
}) => {
    return (
        <div className={classNames(styles['wrapper'], {}, [className])}>
            <div className={styles['big-column']}>{bigColumn}</div>
            <div className={styles['small-column']}>{smallColumn}</div>
        </div>
    );
};
