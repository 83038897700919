import React from 'react';

export const InfoIcon = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M10.2695 19.9492C15.5332 19.9492 19.8789 15.6035 19.8789 10.3301C19.8789 5.06641 15.5234 0.720703 10.2598 0.720703C4.99609 0.720703 0.650391 5.06641 0.650391 10.3301C0.650391 15.6035 4.99609 19.9492 10.2695 19.9492ZM10.2207 7.17578C9.46875 7.17578 8.86328 6.56055 8.86328 5.81836C8.86328 5.04688 9.46875 4.45117 10.2207 4.45117C10.9629 4.45117 11.5684 5.04688 11.5684 5.81836C11.5684 6.56055 10.9629 7.17578 10.2207 7.17578ZM8.54102 15.5645C8.0918 15.5645 7.73047 15.2324 7.73047 14.7637C7.73047 14.334 8.0918 13.9824 8.54102 13.9824H9.66406V10.1836H8.7168C8.26758 10.1836 7.90625 9.86133 7.90625 9.39258C7.90625 8.95312 8.26758 8.60156 8.7168 8.60156H10.5625C11.1484 8.60156 11.4512 9.01172 11.4512 9.62695V13.9824H12.4473C12.9062 13.9824 13.2676 14.334 13.2676 14.7637C13.2676 15.2324 12.9062 15.5645 12.4473 15.5645H8.54102Z" />
    </svg>
);
