import React from 'react';
import { useEffect } from 'react';
import { SwitcherElement } from './config';

import styles from './switcher.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCharts } from 'src/redux/actions/charts';

interface Props {
    initialActiveId?: string;
    elements: SwitcherElement[];
}

export const Switcher: React.FC<Props> = ({
    initialActiveId = '',
    elements,
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (initialActiveId) dispatch(toggleCharts(initialActiveId));
    }, [dispatch, initialActiveId]);

    const activeNow = useSelector((state) => state?.charts?.toggle);
    const renderElement = ({ title, id, onClick }: SwitcherElement, key) => {
        let className = [
            styles['switcher-element'],
            activeNow === id ? styles['switcher-element--active'] : '',
        ].join(' ');
        return (
            <button
                key={id + key}
                className={className}
                onClick={() => {
                    if (activeNow !== id) {
                        onClick();
                    }
                }}
            >
                {title}
            </button>
        );
    };

    return (
        <div className={styles['switcher']}>{elements.map(renderElement)}</div>
    );
};
