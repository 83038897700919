import { statusNameMapper } from 'src/shared/lib';
import { EditMerchantKeysState } from '../model/types/EditMerchantKeysState';

export const createBody = (
    values: EditMerchantKeysState,
    paymentType: string[],
    company: string
) => {
    const {
        ecommkeyStatus,
        eCommKeyBlocked: blocked,
        idDefaultETerminal,
        passwordDefaultETerminal,
        cashbox,
        maxAmount,
        extEntityId,
        merchantIdSbp,
        accAlias,
        account,
        mirPayKid,
        mirPayMerchantId,
        mirPayPrivateKey,
        mirPayTerminalId,
        ...rest
    } = values;

    return {
        ...rest,
        company,
        blocked,
        paymentType,
        status: statusNameMapper(ecommkeyStatus, 'en'),
        cashbox: +cashbox === 0 ? null : +cashbox,
        maxAmount: +maxAmount,
        mirPayKid,
        mirPayMerchantId,
        mirPayTerminalId,
        mirPayPrivateKey: mirPayPrivateKey,
        ...(paymentType.includes('CARD') && {
            idDefaultETerminal,
            passwordDefaultETerminal,
        }),
        ...(paymentType.includes('SBP') && {
            extEntityId,
            merchantIdSbp,
            accAlias,
            account,
        }),
    };
};
