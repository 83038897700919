import { URL } from 'src/app/config';
import { FileFormat } from '../model/types';

interface GetUrl {
    url: string;
    fileFormat: FileFormat;
    pathname: string;
    detailedReport: boolean;
    index?: number;
}

export const getDay = (pathname: string) => {
    if (pathname.includes('/settlement/')) {
        return pathname.split('/')[2];
    }
    return '';
};

const getInvoiceId = (pathname: string) => {
    if (pathname.includes('/einvoice/')) {
        return pathname.replace(/\D/g, '');
    }
    return '';
};

const checkUrl = (url: string, detailedReport: boolean) => {
    if (url === URL.settlementFileDownloadUrl && detailedReport) {
        return URL.settlementDetailFileDownloadUrl;
    }
    return url;
};

export const getUrl = ({
    url,
    fileFormat,
    pathname,
    detailedReport,
    index,
}: GetUrl) => {
    const newUrl = checkUrl(url, detailedReport);
    const day = getDay(pathname);
    const invoiceId = getInvoiceId(pathname);

    switch (newUrl) {
        case URL.statisticFileDownloadUrl:
            return `${newUrl}${index}/${fileFormat}`;

        case URL.settlementFileDownloadUrl:
            return day
                ? `${newUrl}${day}/${fileFormat}`
                : `${newUrl}${fileFormat}`;

        case URL.settlementDetailFileDownloadUrl:
            return `${newUrl}${fileFormat}`;

        case URL.operationsFileDownloadUrl:
            return `${newUrl}${fileFormat}`;

        case URL.operationsSBPFileDownloadUrl:
            return `${newUrl}`;

        case URL.einvoiceDownloadQrcUrl:
            return `${newUrl}/${invoiceId}/${fileFormat}`;

        case URL.reversalExport:
            return `${newUrl}${fileFormat}`;

        default:
            return newUrl;
    }
};
