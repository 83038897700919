import React, { Component } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import CompanySelect from 'src/components/CompanySelect';
import { connect } from 'react-redux';
import { URL } from 'src/app/config';
import { FiltersContainer } from 'src/widgets';
import { getAllTerminalIds } from 'src/entities/terminal';
import {
    localLoad,
    localLoadMoment,
    localLoadSerialize,
    localLoadIntInArray,
    localSave,
    getClassName,
    log,
    get,
    formatDateForInput,
    localSaveSerialize,
    getInitialFilter,
} from 'src/shared/lib';
import { DateRangePicker } from 'src/shared/ui';
import { setEndDate, setFilters, setStartDate } from 'src/redux/actions/table';
import styles from './styles.module.scss';
import { BankTerminalsSelector } from 'src/features/select-terminals/ui';
import _ from 'lodash';

class ReversalPageFilter extends Component {
    constructor(props) {
        super(props);
        this.startDate = getClassName(this.props.pageTitle, 'startDate');
        this.endDate = getClassName(this.props.pageTitle, 'endDate');
        this.companyId = getClassName(this.props.pageTitle, 'companySelectId');
        this.companyTerminals = getClassName(
            this.props.pageTitle,
            'companyTerminals'
        );
        this.terminalIds = getClassName(
            this.props.pageTitle,
            'companyTerminalIds'
        );

        const initialFilter = getInitialFilter(
            ['companyTerminalIds'],
            this.props.pageTitle
        );
        this.defaultFilter = {
            startDate: localLoadMoment(
                this.startDate,
                moment(new Date()).add(-7, 'days')
            ),
            endDate: localLoadMoment(this.endDate, moment(new Date())),
            companyIds: localLoad(this.companyId, []),
            companyTerminals: localLoadSerialize(this.companyTerminals, []),
            terminalIds: localLoad(this.terminalIds, []),
        };
        this.state = {
            ...this.defaultFilter,
        };
        this.setStartDate(this.defaultFilter.startDate);
        this.setEndDate(this.defaultFilter.endDate);
        this.props.dispatch(
            setFilters(this.props.pageTitle, {
                companyIds: localLoadIntInArray(this.companyId, []),
                terminalIds: initialFilter.companyTerminalIds,
            })
        );
    }

    componentDidUpdate(previousProps) {
        const companyIdsAreEqual = _.isEqual(
            previousProps.table?.filters?.companyIds,
            this.props.table.filters?.companyIds
        );

        if (!companyIdsAreEqual) {
            this.getTerminals(this.props.table.filters?.companyIds[0]);
        }

        this.saveFilter();
    }

    createFilterFromState = (state) => {
        const { companyTerminals, companyId, terminalIds, ...filter } = state;
        // Если выбрана компания, то по умолчанию на сервер необходимо отправить все ее терминалы.
        // Если отправить путой массив, то сервер вернет возвраты по всем компаниям.
        if (companyId && isEmpty(terminalIds)) {
            let allTerminalIds = getAllTerminalIds(companyTerminals);
            return { terminalIds: allTerminalIds, ...filter };
        }
        return { terminalIds, ...filter };
    };

    saveFilter() {
        localSave(this.startDate, this.getStartDate());
        localSave(this.endDate, this.getEndDate());
        localSave(this.companyId, this.props.table?.filters?.companyIds || []);
        localSaveSerialize(this.companyTerminals, this.state.companyTerminals);
        localSave(this.terminalIds, this.props.table?.filters?.terminalIds);
    }

    getTerminals = (companyId) => {
        if (companyId) {
            get(`${URL.terminalsUrl}/${companyId}`)
                .then((response) => {
                    this.setState({
                        companyTerminals: response.data.payload,
                    });
                })
                .catch((err) => log(err));
        } else {
            this.setState({ companyTerminals: [] });
        }
    };

    getStartDate() {
        return this.props.table?.startDate ?? this.state.startDate;
    }

    setStartDate(date) {
        this.props.dispatch(setStartDate(this.props.pageTitle, date));
    }

    getEndDate() {
        return this.props.table?.endDate ?? this.state.endDate;
    }

    setEndDate(date) {
        this.props.dispatch(setEndDate(this.props.pageTitle, date));
    }

    render() {
        return (
            <div className={styles['wrapper']}>
                <FiltersContainer>
                    <DateRangePicker
                        datepickers={[
                            {
                                selected: this.getStartDate(),
                                start: true,
                                onChange: (startDate) =>
                                    this.setStartDate(startDate),
                            },
                            {
                                selected: this.getEndDate(),
                                end: true,
                                onChange: (endDate) => this.setEndDate(endDate),
                            },
                        ]}
                        formatter={formatDateForInput}
                        position="left"
                        startDate={this.getStartDate()}
                        endDate={this.getEndDate()}
                        onReset={() => {
                            this.setStartDate(
                                moment(new Date()).add(-7, 'days')
                            );
                            this.setEndDate(moment(new Date()));
                        }}
                    />
                    <div className={styles['actions-wrapper']}>
                        <CompanySelect
                            uid={this.props.pageTitle}
                            onChange={({ value }) => {
                                const companyIds = value ? [value] : [];

                                const isCompanyIdsEqual = _.isEqual(
                                    companyIds,
                                    this.props.table?.filters?.companyIds
                                );

                                if (!isCompanyIdsEqual) {
                                    this.props.dispatch(
                                        setFilters(this.props.pageTitle, {
                                            terminalIds: [],
                                        })
                                    );
                                }

                                this.props.dispatch(
                                    setFilters(this.props.pageTitle, {
                                        companyIds,
                                    })
                                );
                            }}
                        />
                    </div>

                    {this.props.table?.filters?.companyIds?.length &&
                    this.state.companyTerminals &&
                    this.state.companyTerminals.length ? (
                        <BankTerminalsSelector
                            selected={
                                this.props.table?.filters?.terminalIds.map(
                                    (value) => Number(value)
                                ) || []
                            }
                            onChange={(terminalIds) => {
                                this.props.dispatch(
                                    setFilters(this.props.pageTitle, {
                                        terminalIds,
                                    })
                                );
                            }}
                            terminalsList={this.state.companyTerminals}
                        />
                    ) : null}
                    <div className={styles['actions-wrapper']}>
                        {this.props.actions}
                    </div>
                </FiltersContainer>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        table: state.table?.[props.pageTitle],
    };
}

export default connect(mapStateToProps, null, null)(ReversalPageFilter);
