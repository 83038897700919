import * as yup from 'yup';

export const validationScheme = yup.object({}).shape({
    amount: yup
        .number()
        .typeError('Значение должно быть числом')
        .positive('Значение должно быть больше 0')
        .lessThan(
            yup.ref('fullAmount'),
            `Значение должно быть меньше всей суммы операции`
        )
        .required('Обязательное поле'),
});
