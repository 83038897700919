import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReplaySubject } from 'rxjs';

import AwareTable from 'src/components/AwareTable';

import { URL } from 'src/app/config';

import { formatDate, log, sendToSubject, localLoad, get } from 'src/shared/lib';

import './cashbox-registry-page.scss';
import { OverflowContainer, Paginator } from 'src/shared';

const cashboxesReceiptTableName = 'Cashboxes-Receipt';

class CashBoxReceiptPage extends Component {
    filterSubject = new ReplaySubject(1);

    constructor(props) {
        super(props);
        this.send = sendToSubject.bind(this);
        this.toggle_receipt_info = this.toggle_receipt_info.bind(this);
        this.state = {
            open_receipt_info: false,
            receiptOrderId: '',
            receiptCreated: '',
            receiptTotalFiscal: '',
            registryId: '',
            errorInfo: '',
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.send({
            offset: localLoad('receipt-offset', 0),
            size: localLoad('receipt-range', 10),
        });
    };

    getCashboxReceiptInfo = (id) => {
        get(URL.cashBoxReceiptInfo + id)
            .then((r) => {
                let {
                    receiptOrderId,
                    receiptCreated,
                    receiptTotalFiscal,
                    registryId,
                    errorInfo,
                } = r.data.payload;

                this.setState(
                    {
                        open_receipt_info: true,
                        receiptOrderId,
                        receiptCreated,
                        receiptTotalFiscal,
                        registryId,
                        errorInfo,
                    },
                    () => {}
                );
            })
            .catch((r) => {
                this.setState({ open_receipt_info: false });
                log(r);
            });
    };

    toggle_receipt_info() {
        this.setState({
            open_receipt_info: !this.state.open_receipt_info,
        });
    }

    render() {
        return (
            <div className="registry-page">
                <Paginator
                    name={
                        cashboxesReceiptTableName + this.props.match.params.id
                    }
                >
                    <OverflowContainer>
                        <AwareTable
                            isStoreTable
                            tableName={
                                cashboxesReceiptTableName +
                                this.props.match.params.id
                            }
                            onItemClick={(e) =>
                                this.getCashboxReceiptInfo(e.ID)
                            }
                            url={`${URL.cashBoxReceipt}/${this.props.match.params.id}`}
                        />
                    </OverflowContainer>
                </Paginator>
                <Modal
                    isOpen={this.state.open_receipt_info}
                    toggle={this.toggle_receipt_info}
                >
                    <ModalHeader toggle={this.toggle_receipt_info}>
                        Информация о чеке
                    </ModalHeader>
                    <ModalBody className="registry-check-modal">
                        <div className="registry-check-form">
                            <div className="registry-check__info">
                                <span>
                                    Идентификатор чека:{' '}
                                    {this.state.receiptOrderId}
                                </span>
                            </div>
                            <div className="registry-check__info">
                                <span>
                                    Дата чека:{' '}
                                    {formatDate(this.state.receiptCreated)}
                                </span>
                            </div>
                            <div className="registry-check__info">
                                <span>
                                    Фискализированная сумма:{' '}
                                    {this.state.receiptTotalFiscal}
                                </span>
                            </div>
                            <div className="registry-check-error">
                                <span>Список ошибок:</span>
                                <br />
                                <div className="registry-check-error__errorlist">
                                    {this.state.errorInfo
                                        ? this.state.errorInfo.map((err, i) => (
                                              <span key={i}>&nbsp;{err}</span>
                                          ))
                                        : null}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        fetch: state.fetch.fetch,
    };
}

export default withRouter(connect(mapStateToProps)(CashBoxReceiptPage));
