import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { URL } from 'src/app/config';
import { createNotification } from 'src/redux/actions/notification';
import { log, post, useCloseCustomModal } from 'src/shared/lib';
import { Button, Modal, ModalField, NewSelect } from 'src/shared/ui';
import { SelectOption } from 'src/shared/ui';
import { submitMessage } from '../model/consts';
import styles from './edit-registry-mailing-schedule.module.scss';

interface Props {
    id: string;
    initInterval: number;
    resetId: () => void;
    successCallback: () => void;
}

const ACTIVE: string = 'Активно';
const INACTIVE: string = 'Выключено';

const statusOptions: SelectOption[] = [
    { value: ACTIVE, content: ACTIVE },
    { value: INACTIVE, content: INACTIVE },
];

export const EditRegistryMailingSchedule: FC<Props> = (props) => {
    const { id, initInterval, resetId, successCallback } = props;
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(true);
    const [status, setStatus] = useState(!initInterval ? INACTIVE : ACTIVE);
    const [interval, setInterval] = useState(initInterval);

    const onClose = useCloseCustomModal(setOpen, resetId);

    const changeStatus = (value: string) => {
        if (value === INACTIVE) setInterval(0);
        if (value === ACTIVE) setInterval(1);
        setStatus(value);
    };

    const increaseInterval = () => {
        interval > 6 ? setInterval(1) : setInterval((prev) => prev + 1);
    };
    const decreaseInterval = () => {
        interval < 2 ? setInterval(7) : setInterval((prev) => prev - 1);
    };

    const onSubmit = () => {
        post(URL.setSchedule, {
            companyId: id,
            interval,
        })
            .then((r) => {
                successCallback();
                dispatch(createNotification(submitMessage, 'success'));
            })
            .catch((r) => {
                log(r);
            });
        setOpen(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            header="Редактирование подписки"
            closeButton="Отмена"
            actionButton="Сохранить"
            onClose={onClose}
            onAction={onSubmit}
        >
            <ModalField label="Статус">
                <NewSelect
                    idScrollElement="modal-element-scroll"
                    options={statusOptions}
                    value={status}
                    onChange={changeStatus}
                />
            </ModalField>
            <ModalField label="Интервал">
                <div className={styles.interval}>
                    <div className={styles.amount}>
                        <Button
                            disabled={status === INACTIVE}
                            size="xs"
                            onClick={decreaseInterval}
                            className={styles.button}
                        >
                            -
                        </Button>
                        <div>{interval}</div>
                        <Button
                            disabled={status === INACTIVE}
                            size="xs"
                            onClick={increaseInterval}
                            className={styles.button}
                        >
                            +
                        </Button>
                    </div>
                    <span id="info" className={styles.icon}>
                        <i
                            data-tip=""
                            data-for="info"
                            className="mdi mdi-info-outline"
                        />
                    </span>
                    <ReactTooltip
                        id="info"
                        className={`tool-tip ${styles['tool-tip']}`}
                        place="top"
                        type="light"
                        effect="float"
                    >
                        Интервал отправки реестра - от 1 до 7 дней
                    </ReactTooltip>
                </div>
            </ModalField>
        </Modal>
    );
};
