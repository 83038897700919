import { post } from '../../shared/lib/request-wrapper';
import { createNotification } from './notification';
import { URL } from 'src/app/config';

export function sendFeedback(params, callback) {
    return (dispatch) => {
        return post(URL.feedbackUrl, params)
            .then(() => {
                dispatch(
                    createNotification(
                        {
                            title: 'Отправка сообщения',
                            message: 'Отправка сообщения прошла успешно',
                        },
                        'success'
                    )
                );
                if (callback) callback();
            })
            .catch(() => {
                dispatch(
                    createNotification(
                        {
                            title: 'Отправка сообщения',
                            message: 'Ошибка отправки сообщения',
                        },
                        'error'
                    )
                );
            });
    };
}
