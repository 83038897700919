import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { createNotification } from 'src/redux/actions/notification';
import { URL } from 'src/app/config';
import { useCloseCustomModal } from 'src/shared/lib';
import { getOptionsForSelect, log, post } from 'src/shared/lib';
import { Input, Modal, ModalField, NewSelect } from 'src/shared';
import { validationScheme } from '../lib/validationScheme';
import { submitMessage } from '../model/consts';
import { AddGroupSchema } from '../model/addGroupSchema';

interface Props {
    closeModal: () => void;
    successCallback: () => void;
}

export const AddTerminalGroup: FC<Props> = (props) => {
    const { closeModal, successCallback } = props;
    const [isOpen, setOpen] = useState(true);
    const [isClosed, setClosed] = useState(false);
    const dispatch = useDispatch();
    const companies = useSelector((state) => state.auth.assignedCompanies);
    const companiesOptions = getOptionsForSelect(companies, 'id', 'name');

    const onClose = useCloseCustomModal(setOpen, closeModal);

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm<AddGroupSchema>({
        mode: 'onSubmit',
        defaultValues: { companyId: '', name: '' },
        resolver: yupResolver(validationScheme),
    });

    const changeCompany = (value: string) => {
        setValue('companyId', value);
    };

    const onSubmit = ({ companyId, name }: AddGroupSchema) => {
        post(URL.terminalPageAddGroupUrl, {
            name,
            companyId: Number(companyId),
        })
            .then(() => {
                successCallback();
                dispatch(createNotification(submitMessage, 'success'));
            })
            .catch((r) => log(r));

        setClosed(true);
    };

    return (
        <Modal
            isForm
            isOpen={isOpen}
            isClosed={isClosed}
            header="Добавление группы"
            closeButton="Отмена"
            actionButton="Сохранить"
            onClose={onClose}
            onAction={handleSubmit(onSubmit)}
        >
            <ModalField
                numberOfColumns={1}
                errorMessage={errors.companyId?.message}
            >
                <NewSelect
                    idScrollElement="modal-element-scroll"
                    label="Выберите компанию"
                    options={companiesOptions}
                    value={getValues('companyId')}
                    onChange={changeCompany}
                />
            </ModalField>
            <ModalField numberOfColumns={1} errorMessage={errors.name?.message}>
                <Input
                    background
                    placeholder="Введите название группы"
                    {...register('name')}
                />
            </ModalField>
        </Modal>
    );
};
