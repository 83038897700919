import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import {
    Input,
    InputPassword,
    ModalField,
    NewCheckbox,
    Modal,
    NewSelect,
    SelectOption,
} from 'src/shared';
import {
    get,
    getOptionsForSelect,
    log,
    post,
    timeZoneOptions,
} from 'src/shared/lib';
import { URL } from 'src/app/config';
import { ICashbox } from '../../add-merchants-key/model/types';
import { schema } from '../lib/validateScheme';
import { EditMerchantKeysState } from '../model/types/EditMerchantKeysState';
import { InitialData } from '../model/types/InitialData';
import { getDefaultValues } from '../lib/getDefaultValues';
import { createBody } from '../lib/createBody';
import './edit-merchant-keys.scss';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onUpdate: () => void;
    initialData: InitialData;
    company: {
        id: string;
        pageId: string;
        hasSBPRole: boolean;
    };
}

const statuses: SelectOption[] = [
    { value: 'Активно', content: 'Активно' },
    { value: 'Выключено', content: 'Выключено' },
];

/**
 * В соответствии с задачей DPS-359 установить возможность
 * включения/выключения Мир/Обновленного вида создания кассы
 */
const IS_MIR_PAY = true;

/**
 * TODO: удалить данную переменную после тестирования со стороны заказчика
 */
const IS_PAYMENT_TO_INCLUDE = true;

export const EditMerchantKeys = (props: Props) => {
    const { isOpen, company, initialData, onClose, onUpdate } = props;
    const [paymentType, setPaymentType] = useState(
        initialData.paymentType || ['CARD']
    );
    const [isClosed, setIsClosed] = useState(false);
    const [ecomkeyCashboxes, setEcomkeyCashboxes] = useState<ICashbox[]>([]);
    const [showEmailInput, setShowEmailInput] = useState(
        initialData.notifyByEmail
    );
    const [showDefaultNotificationUrl, setShowDefaultNotificationUrl] =
        useState(initialData.notifyByUrl);
    const [isIdTerminalMirError, setIsIdTerminalMirError] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
        getValues,
        reset,
    } = useForm<EditMerchantKeysState>({
        defaultValues: getDefaultValues(initialData),
        resolver: yupResolver(schema),
    });

    const onChangePaymentType = (selectedType: string) => () => {
        if (paymentType.includes(selectedType)) {
            const filterPayment = paymentType.filter(
                (pt) => pt !== selectedType
            );
            if (filterPayment.length) {
                return setPaymentType([...filterPayment]);
            } else {
                return setPaymentType(['CARD']);
            }
        }
        return setPaymentType([selectedType, ...paymentType]);
    };

    const onChangeCashboxRequired = (
        field: ControllerRenderProps<EditMerchantKeysState, 'cashboxRequired'>
    ) => {
        return (value: boolean) => {
            field.onChange(value);
            const { cashbox } = getValues();
            const nextValue =
                cashbox === '' ? `${ecomkeyCashboxes[0].id}` : cashbox;
            if (value && Boolean(ecomkeyCashboxes.find(({ id }) => id === 0))) {
                setValue('cashbox', nextValue);
                setEcomkeyCashboxes(
                    ecomkeyCashboxes.filter(({ id }) => id !== 0)
                );
            }
            if (!ecomkeyCashboxes.find(({ id }) => id === 0)) {
                setValue('cashbox', nextValue);
                setEcomkeyCashboxes([
                    ...ecomkeyCashboxes,
                    { blocked: false, groupCode: 'без кассы', id: 0 },
                ]);
            }
        };
    };

    const submit = (values: EditMerchantKeysState): void => {
        const body = createBody(values, paymentType, initialData.key);
        if (
            body.paymentType.includes('MIR_PAY') &&
            !body?.mirPayTerminalId?.trim()
        ) {
            setIsIdTerminalMirError(true);
            return;
        }
        post(`${URL.updateEcommkey}${initialData.key}`, body)
            .then((r) => {
                onUpdate();
            })
            .catch((r) => {
                log(r);
            })
            .finally(() => {
                setIsClosed(true);
                setIsIdTerminalMirError(false);
            });
    };

    const updateCashbox = (response: AxiosResponse) => {
        const newEcomkeyCashboxes = [
            ...(!getValues().cashboxRequired
                ? [
                      ...response.data.payload,
                      {
                          blocked: false,
                          groupCode: 'без кассы',
                          id: 0,
                      },
                  ]
                : response.data.payload),
        ];
        setEcomkeyCashboxes(newEcomkeyCashboxes);
    };
    const onChangeIdTerminalMir = (event) => {
        const currentValue = event?.target?.value?.trim();
        if (isIdTerminalMirError && currentValue) {
            setIsIdTerminalMirError(false);
        }
    };

    useEffect(() => {
        get(URL.getEcommkeyCashboxes + company.pageId)
            .then((response) => {
                updateCashbox(response);
            })
            .catch((r) => log(r));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        reset();
    }, [isOpen, reset]);

    return (
        <Modal
            header="Редактирование ключа мерчанта"
            isOpen={isOpen}
            isForm
            isClosed={isClosed}
            actionButton="Сохранить"
            closeButton="Отмена"
            onAction={handleSubmit(submit)}
            onClose={onClose}
        >
            <ModalField label="Ключ мерчанта:">
                <span>{initialData.key}</span>
            </ModalField>
            {initialData?.mcc && IS_PAYMENT_TO_INCLUDE && (
                <ModalField label="mcc">
                    <span>{initialData.mcc}</span>
                </ModalField>
            )}
            <ModalField
                label="Пароль мерчанта:"
                errorMessage={errors.password?.message}
            >
                <InputPassword
                    {...register('password')}
                    background
                    placeholder="Введите Пароль"
                />
            </ModalField>
            <ModalField label="Статус:">
                <Controller
                    name="ecommkeyStatus"
                    control={control}
                    render={({ field }) => (
                        <NewSelect
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value);
                            }}
                            idScrollElement="modal-element-scroll"
                            options={statuses}
                        />
                    )}
                />
            </ModalField>
            <ModalField label="Блокировка:">
                <Controller
                    name="eCommKeyBlocked"
                    control={control}
                    render={({ field }) => (
                        <NewCheckbox
                            isReverseOrder
                            onChange={(event) => {
                                field.onChange(event.target.checked);
                            }}
                            checked={field.value}
                        />
                    )}
                />
            </ModalField>
            {(paymentType?.includes('CARD') ||
                (paymentType?.includes('MIR_PAY') && IS_MIR_PAY)) && (
                <>
                    <ModalField
                        label="id терминала:"
                        errorMessage={errors.idDefaultETerminal?.message}
                    >
                        <Input
                            {...register('idDefaultETerminal')}
                            background
                            placeholder="Введите id"
                        />
                    </ModalField>
                    <ModalField
                        label="Пароль терминала:"
                        errorMessage={errors.passwordDefaultETerminal?.message}
                    >
                        <InputPassword
                            {...register('passwordDefaultETerminal')}
                            background
                            placeholder="Введите Пароль"
                        />
                    </ModalField>
                </>
            )}
            <ModalField label="Касса обязательна:">
                <Controller
                    name="cashboxRequired"
                    control={control}
                    render={({ field }) => (
                        <NewCheckbox
                            isReverseOrder
                            onChange={(event) => {
                                onChangeCashboxRequired(field)(
                                    event.target.checked
                                );
                            }}
                            checked={field.value}
                        />
                    )}
                />
            </ModalField>
            <ModalField label="Касса:">
                <Controller
                    name="cashbox"
                    control={control}
                    render={({ field }) => (
                        <NewSelect
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value);
                            }}
                            idScrollElement="modal-element-scroll"
                            options={getOptionsForSelect(
                                ecomkeyCashboxes,
                                'id',
                                'groupCode'
                            )}
                        />
                    )}
                />
            </ModalField>
            <ModalField
                label="merchantId:"
                errorMessage={errors.merchantId?.message}
            >
                <Input
                    {...register('merchantId')}
                    background
                    placeholder="Введите merchantId"
                />
            </ModalField>
            <ModalField
                label="merchantName:"
                errorMessage={errors.merchantName?.message}
            >
                <Input
                    {...register('merchantName')}
                    background
                    placeholder="Введите merchantName"
                />
            </ModalField>
            <ModalField
                label="merchantUrl:"
                errorMessage={errors.merchantUrl?.message}
            >
                <Input
                    {...register('merchantUrl')}
                    background
                    placeholder="Введите Url"
                />
            </ModalField>
            <ModalField
                label="URL при успехе:"
                errorMessage={errors.defaultSuccessUrl?.message}
            >
                <Input
                    {...register('defaultSuccessUrl')}
                    background
                    placeholder="Введите Url"
                />
            </ModalField>
            <ModalField
                label="URL при неудаче:"
                errorMessage={errors.defaultFailUrl?.message}
            >
                <Input
                    {...register('defaultFailUrl')}
                    background
                    placeholder="Введите Url"
                />
            </ModalField>
            <ModalField label="Временная зона:">
                <Controller
                    name="timeZone"
                    control={control}
                    render={({ field }) => (
                        <NewSelect
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value);
                            }}
                            idScrollElement="modal-element-scroll"
                            options={getOptionsForSelect(
                                timeZoneOptions,
                                'value',
                                'text'
                            )}
                        />
                    )}
                />
            </ModalField>
            <ModalField label="Разрешить виджеты:">
                <Controller
                    name="allowWidgets"
                    control={control}
                    render={({ field }) => (
                        <NewCheckbox
                            isReverseOrder
                            onChange={(event) => {
                                field.onChange(event.target.checked);
                            }}
                            checked={field.value}
                        />
                    )}
                />
            </ModalField>
            <ModalField
                label="Максимальная сумма:"
                errorMessage={errors.maxAmount?.message}
            >
                <Input
                    {...register('maxAmount')}
                    background
                    placeholder="Введите maxAmount"
                />
            </ModalField>
            <ModalField label="Уведомлять по почте:">
                <Controller
                    name="notifyByEmail"
                    control={control}
                    render={({ field }) => (
                        <NewCheckbox
                            isReverseOrder
                            onChange={(event) => {
                                setShowEmailInput(event.target.checked);
                                field.onChange(event.target.checked);
                            }}
                            checked={field.value}
                        />
                    )}
                />
            </ModalField>
            {showEmailInput && (
                <ModalField
                    label="Почта для уведомлений:"
                    errorMessage={errors.defaultNotificationEmail?.message}
                >
                    <Input
                        {...register('defaultNotificationEmail')}
                        background
                        placeholder="Введите Email"
                    />
                </ModalField>
            )}
            <ModalField label="Уведомлять по URL:">
                <Controller
                    name="notifyByUrl"
                    control={control}
                    render={({ field }) => (
                        <NewCheckbox
                            isReverseOrder
                            onChange={(event) => {
                                setShowDefaultNotificationUrl(
                                    event.target.checked
                                );
                                field.onChange(event.target.checked);
                            }}
                            checked={field.value}
                        />
                    )}
                />
            </ModalField>
            {showDefaultNotificationUrl && (
                <ModalField
                    label="URL для уведомлений:"
                    errorMessage={errors.defaultNotificationUrl?.message}
                >
                    <Input
                        {...register('defaultNotificationUrl')}
                        background
                        placeholder="Введите Url"
                    />
                </ModalField>
            )}
            {company.hasSBPRole && paymentType && (
                <>
                    <ModalField label="Оплата Картой:">
                        <NewCheckbox
                            isReverseOrder
                            onChange={onChangePaymentType('CARD')}
                            checked={paymentType.includes('CARD')}
                        />
                    </ModalField>
                    <ModalField label="Оплата через СБП:">
                        <NewCheckbox
                            isReverseOrder
                            onChange={onChangePaymentType('SBP')}
                            checked={paymentType.includes('SBP')}
                        />
                    </ModalField>
                    {paymentType?.includes('SBP') && company.hasSBPRole && (
                        <>
                            <ModalField label="ID Юр Лица в СБП:">
                                <Input
                                    {...register('extEntityId')}
                                    background
                                    placeholder="Введите Id"
                                />
                            </ModalField>
                            <ModalField label="ID терминала в СБП:">
                                <Input
                                    {...register('merchantIdSbp')}
                                    background
                                    placeholder="Введите Id"
                                />
                            </ModalField>
                            <ModalField label="№ счета ТСП в СБП">
                                <Input
                                    {...register('account')}
                                    background
                                    placeholder="Введите № счета"
                                />
                            </ModalField>
                            <ModalField label="Синоним счета">
                                <Input
                                    {...register('accAlias')}
                                    background
                                    placeholder="Введите № счета"
                                />
                            </ModalField>
                        </>
                    )}
                </>
            )}
            {IS_MIR_PAY && (
                <ModalField label="Оплата через MirPay:">
                    <NewCheckbox
                        isReverseOrder
                        onChange={onChangePaymentType('MIR_PAY')}
                        checked={paymentType.includes('MIR_PAY')}
                    />
                </ModalField>
            )}
            {paymentType?.includes('MIR_PAY') && IS_MIR_PAY && (
                <>
                    <ModalField label="MirPay keyId:">
                        <Input
                            {...register('mirPayKid')}
                            background
                            placeholder="Введите keyId"
                        />
                    </ModalField>
                    <ModalField label="MirPay MerchantId:">
                        <Input
                            {...register('mirPayMerchantId')}
                            background
                            placeholder="Введите MerchantId"
                        />
                    </ModalField>
                    <ModalField label="Id терминала для MirPay:">
                        <Input
                            {...register('mirPayTerminalId')}
                            background
                            placeholder="Введите id терминала MirPay"
                            onChange={onChangeIdTerminalMir}
                        />
                        {isIdTerminalMirError && (
                            <div className="required-id-mir">
                                Обязательное поле
                            </div>
                        )}
                    </ModalField>
                    <ModalField label="MirPay Private key">
                        <Input
                            {...register('mirPayPrivateKey')}
                            type="password"
                            background
                            placeholder="Сохранен, введите для изменения"
                        />
                    </ModalField>
                </>
            )}
        </Modal>
    );
};
