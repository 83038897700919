import React, { FC } from 'react';
import { Product } from 'src/entities/receipt';
import { ModalField } from 'src/shared';
import styles from './shows-info-about-receipt.module.scss';

interface Props {
    product: Product;
    className?: string;
}

export const ReceiptItem: FC<Props> = ({ product, className }) => {
    return (
        <div className={className}>
            <ModalField
                textField
                boldLabel
                label="Наименование товара"
                alignField="align-start"
                className={styles.title}
            >
                {product.name}
            </ModalField>
            <ModalField
                textField
                label="Количество x Цена"
                alignField="align-start"
            >
                {product.quantity} x {product.price}
            </ModalField>
            <ModalField
                textField
                label="Общая стоимость позиции с учетом скидок и наценок"
                alignField="align-start"
            >
                {product.amount}
            </ModalField>
            <ModalField textField label="Ставка НДС" alignField="align-start">
                {product.tax}
            </ModalField>
            <ModalField textField label="Сумма НДС" alignField="align-start">
                {product.amountTax}
            </ModalField>
            <ModalField
                textField
                label="Предмет рассчёта"
                alignField="align-start"
            >
                {product.paymentObject}
            </ModalField>
            <ModalField
                textField
                label="Способ рассчёта"
                alignField="align-start"
            >
                {product.paymentMethod}
            </ModalField>
        </div>
    );
};
