import React from 'react';

export const CloseIcon = (
    <svg
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <line
            x1="7.10831"
            y1="7.30579"
            x2="16.9788"
            y2="17.1763"
            stroke="#002E5A"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <line
            x1="16.9785"
            y1="7.10855"
            x2="7.10802"
            y2="16.979"
            stroke="#002E5A"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);
