import { MainTerminal, Terminal } from '../model/types/terminal';

/**
 * Получает все терминалы компании
 * @param mainTerminals массив терминалов от сервера
 * @returns массив всех терминалов
 */

export const getAllTerminals = (mainTerminals: MainTerminal[]) => {
    const allTerminals = [] as Terminal[];
    mainTerminals.forEach((mainTerminal) =>
        mainTerminal.terminalGroups.forEach((group) =>
            group.terminals.forEach((terminal) => allTerminals.push(terminal))
        )
    );

    return allTerminals;
};
