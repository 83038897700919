export enum TerminalStatus {
    DISABLED = 'DISABLED',
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
}

export interface Terminal {
    id: number;
    uid: number;
    name: string;
    activity: TerminalStatus;
}

export interface TerminalGroup {
    name: string;
    terminals: Terminal[];
}

export interface MainTerminal {
    id: number;
    inn: number;
    name: string;
    blocked: boolean;
    terminalGroups: TerminalGroup[];
}

export interface CardRate {
    cardName: string;
    rate: number;
}

interface ApplicationTypes {
    id: number;
    value: string;
    applicationTypeInfo: string;
}

export interface InfoTerminal {
    id: number;
    uid: string;
    name: string;
    operationLimit: string;
    weekLimit: string;
    limitEndOfWeek: string;
    rates: CardRate[];
    extraCommission: string;
    simCommission: string;
    activity: string;
    activityInfo: string;
    applicationTypes?: ApplicationTypes[];
}

export interface Group {
    id?: number;
    name: string;
}
