import React, { PureComponent, createRef } from 'react';
import { connect } from 'react-redux';
import { Chart } from 'chart.js';

import { ticksFormatter, renderCustomTooltip } from 'src/shared/lib';
import Container from 'src/components/Container';

import './conversion-chart.scss';

const legendMargin = {
    id: 'legendMargin',
    beforeInit(chart) {
        const fitValue = chart.legend.fit;
        chart.legend.fit = function fit() {
            fitValue.bind(chart.legend)();
            return (this.height += 22);
        };
    },
};

const hoverSectionBackgroundColor = {
    id: 'hoverSectionBackgroundColor',
    beforeDatasetsDraw(chart) {
        const {
            ctx,
            tooltip,
            chartArea: { top, height },
            scales: { x },
        } = chart;
        if (tooltip._active && tooltip._active.length > 0) {
            const index = tooltip._active[0].index;
            if (x._gridLineItems.length > index + 1) {
                const currentX = x._gridLineItems[index].x1;
                const nextX = x._gridLineItems[index + 1]
                    ? x._gridLineItems[index + 1].x1
                    : currentX;
                const newWidth = currentX - nextX;
                ctx.fillStyle = 'rgba(239, 239, 243, 0.5)';
                ctx.fillRect(nextX, top, newWidth, height);
            }
        }
    },
};

class ConversionChart extends PureComponent {
    constructor(props) {
        super(props);
        this.ticksFormatter = ticksFormatter.bind(this);
        this.chart = null;
        this.canvas = createRef();
        this.state = {
            chartConfig: {
                chartType: 'bar',
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: '',
                            data: [],
                            backgroundColor: '#EAEDF3',
                            borderRadius: 5,
                            lineTension: 0,
                            hoverBackgroundColor: '#002E5A',
                            maxBarThickness: 30,
                        },
                        {
                            label: '',
                            data: [],
                            backgroundColor: '#E2CECE',
                            borderRadius: 5,
                            lineTension: 0,
                            hoverBackgroundColor: '#C47978',
                            maxBarThickness: 30,
                        },
                    ],
                },
                options: {
                    hover: {
                        mode: 'index',
                        intersect: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        tooltip: {
                            enabled: false,
                            mode: 'index',
                            intersect: false,
                            external: (context) =>
                                renderCustomTooltip(
                                    context,
                                    this.renderTooltipContent
                                ),
                        },
                        legend: {
                            display: true,
                            position: 'top',
                            align: 'start',
                            labels: {
                                usePointStyle: true,
                                pointStyle: 'rectRounded',
                                boxWidth: 18,
                                boxHeight: 18,
                            },
                        },
                    },
                    scales: {
                        x: {
                            beginAtZero: true,
                            scaleLabel: {
                                display: true,
                                labelString: '',
                            },
                            grid: {
                                display: true,
                                borderColor: '#f7f8f8',
                                tickColor: '#f7f8f8',
                            },
                            ticks: {
                                display: true,
                                tickWidth: 111,
                                color: '#000',
                            },
                        },
                        y: {
                            min: 0, // it is for ignoring negative step.
                            beginAtZero: true,
                            scaleLabel: {
                                display: true,
                                labelString: '',
                            },
                            ticks: {
                                callback: this.ticksFormatter,
                                display: true,
                                color: '#000',
                            },
                            grid: {
                                display: false,
                                tickColor: '#f7f8f8',
                                borderColor: '#f7f8f8',
                            },
                        },
                    },
                },
            },
        };
    }

    clearChart() {
        if (this.chart) this.chart.destroy();
    }

    configureCharts(data) {
        this.prepareData(data);
        let ctx = this.canvas.current.getContext('2d');
        this.clearChart();
        this.chart = new Chart(ctx, {
            plugins: [legendMargin, hoverSectionBackgroundColor],
            type: this.state.chartConfig.chartType,
            data: this.state.chartConfig.data,
            options: this.state.chartConfig.options,
        });
    }

    componentWillUnmount() {
        this.clearChart();
    }

    renderTooltipContent(context) {
        let innerHtml = '';
        // add tooltip content
        const dataSets = context.tooltip.chart.config.data.datasets;
        const currentDataIndex = context.tooltip.dataPoints[0].dataIndex;
        dataSets.forEach((dataset) => {
            innerHtml += `<div style="
                min-width: 107px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            ">
                <span style="font-weight: 400; font-size: 12px; margin-right: 10px;">
                    ${dataset.label}
                </span>
                <span style="
                    color: ${dataset.hoverBackgroundColor};
                    font-weight: 600;
                    text-align: right;
                    font-size: 12px;
                ">
                    ${dataset.data[currentDataIndex]}
                </span>`;
            innerHtml += '</div>';
        });
        return innerHtml;
    }

    prepareData(data) {
        let newChart = this.state.chartConfig;
        newChart.options.scales = {
            y: {
                ...this.state.chartConfig.options.scales.y,
                scaleLabel: {
                    labelString: data.groupName,
                },
            },
            x: this.state.chartConfig.options.scales.x,
        };
        newChart.data.labels = [];
        newChart.data.datasets[0].data = [];
        newChart.data.datasets[0].label = data.paramNames[0];
        newChart.data.datasets[1].data = [];
        newChart.data.datasets[1].label = data.paramNames[1];
        data.tuples.forEach((item) => {
            newChart.data.labels.push(item.name);
            newChart.data.datasets[0].data.push(item.values[0]);
            newChart.data.datasets[1].data.push(item.values[1]);
        });
        this.setState({ chartConfig: newChart });
    }

    get chartRenderCheck() {
        return !!(
            this.props.data && this.props.data[this.props.toggle].tuples.length
        );
    }

    componentDidUpdate() {
        if (this.chartRenderCheck)
            this.configureCharts(this.props.data[this.props.toggle]);
    }

    render() {
        if (!this.chartRenderCheck) return null;
        return (
            <Container header headerName="Конверсии" background>
                <div className="conversion-chart chart">
                    <canvas ref={this.canvas} />
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({ data: state.charts.conversion });

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(ConversionChart);
