import React, { useState, useEffect } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import NiceInputPassword from 'react-nice-input-password';

import { Input } from 'src/shared/ui';
import { LevelsBar } from './levels-bar';
import { oldPasswordSecurityLevel, securityLevels } from '../config';

import styles from './new-password-input.module.scss';
import { ReactComponent as Eye } from './icons/eye.svg';
import { ReactComponent as EyeOff } from './icons/eyeOff.svg';

interface Props<T> {
    field: ControllerRenderProps<T, any>;
    error?: string | boolean;
    placeholder: string;
    inputProps: Record<string, unknown>;
    currentPassword?: string;
}

const NewPasswordInput = function <T>({
    field,
    error,
    inputProps,
    placeholder,
    currentPassword,
}: Props<T>) {
    const [passwordVisible, setPasswordVisible] = useState<boolean>();
    const [securityLevelVisible, setSecurityLevelVisible] = useState<boolean>();
    const [capsLockOn, setCapsLockOn] = useState<boolean>();
    const [levels, setLevels] = useState<any[]>(securityLevels);

    const handlePasswordBlur = () => {
        setSecurityLevelVisible(true);
    };
    const detectCapsLock = (event) => {
        const capsLockOn =
            event.getModifierState && event.getModifierState('CapsLock');
        setCapsLockOn(capsLockOn);
    };

    useEffect(() => {
        currentPassword &&
            setLevels([
                ...securityLevels,
                oldPasswordSecurityLevel(currentPassword),
            ]);
    }, [currentPassword]);

    return (
        <div className={styles['new-password-input']}>
            <NiceInputPassword
                name={field?.name}
                showSecurityLevelDescription={securityLevelVisible}
                value={field?.value}
                showSecurityLevelBar={securityLevelVisible}
                securityLevels={levels}
                visible={passwordVisible}
                onChange={(event) => {
                    field?.onChange(event.value);
                }}
                InputComponent={Input}
                InputComponentProps={{
                    error,
                    warning: capsLockOn ? 'Включен Caps Lock' : '',
                    placeholder,
                    type: passwordVisible ? 'text' : 'password',
                    onBlur: handlePasswordBlur,
                    onKeyDown: detectCapsLock,
                    autoComplete: 'new-password',
                    icon: (
                        <div
                            className={styles.icon}
                            onClick={(event) => {
                                event.preventDefault();
                                setPasswordVisible(!passwordVisible);
                            }}
                        >
                            {!passwordVisible ? <Eye /> : <EyeOff />}
                        </div>
                    ),
                    ...inputProps,
                }}
                renderLevelBarComponent={LevelsBar}
            />
        </div>
    );
};

export { NewPasswordInput };
