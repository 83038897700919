import React from 'react';
import { classNames } from 'src/shared/lib';
import styles from './styles.module.scss';

enum SelectorFillerVariant {
    HALF_SELECTED = 'halfSelected',
    REGULAR = 'regular',
}

interface Props {
    selected: boolean;
    color: string;
    selectorFillVariant?: SelectorFillerVariant;
}

export const SelectorIcon = ({
    selected,
    selectorFillVariant = SelectorFillerVariant.REGULAR,
    color,
}: Props) => {
    return (
        <svg
            className={styles['wrapper']}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="8" cy="8" r="7" stroke={color} strokeWidth="2" />
            <path
                className={classNames(styles['checkmark'], {
                    [styles['selected']]: selected,
                    [styles['hide']]:
                        selectorFillVariant === SelectorFillerVariant.REGULAR,
                })}
                d="M8 16C9.09673 16 10.1267 15.7912 11.0898 15.3737C12.0529 14.9562 12.9019 14.38 13.6367 13.6451C14.3772 12.9102 14.9562 12.0612 15.3737 11.0981C15.7912 10.1294 16 9.09673 16 8C16 6.90884 15.7884 5.8817 15.3653 4.91858C14.9478 3.9499 14.3688 3.09812 13.6284 2.36326C12.8935 1.62283 12.0445 1.04384 11.0814 0.626305C10.1183 0.208768 9.08838 0 7.99165 0C6.90049 0 5.87335 0.208768 4.91023 0.626305C3.94711 1.04384 3.09534 1.62283 2.35491 2.36326C1.62004 3.09812 1.04384 3.9499 0.626305 4.91858C0.208768 5.8817 0 6.90884 0 8C0 9.09673 0.208768 10.1294 0.626305 11.0981C1.04941 12.0612 1.62839 12.9102 2.36326 13.6451C3.09812 14.38 3.94711 14.9562 4.91023 15.3737C5.87335 15.7912 6.90327 16 8 16ZM4.75157 8.63466C4.54001 8.63466 4.36743 8.58177 4.23382 8.47599C4.10578 8.36465 4.04175 8.21155 4.04175 8.0167C4.04175 7.81628 4.10578 7.66319 4.23382 7.55741C4.36187 7.44607 4.53445 7.3904 4.75157 7.3904H11.2735C11.4906 7.3904 11.6604 7.44607 11.7829 7.55741C11.9054 7.66319 11.9666 7.81628 11.9666 8.0167C11.9666 8.21155 11.9026 8.36465 11.7745 8.47599C11.6465 8.58177 11.4795 8.63466 11.2735 8.63466H4.75157Z"
                fill={color}
            />
            <path
                className={classNames(styles['checkmark'], {
                    [styles['selected']]: selected,
                    [styles['hide']]:
                        selectorFillVariant ===
                        SelectorFillerVariant.HALF_SELECTED,
                })}
                d="M8.00406 16C12.384 16 16 12.384 16 7.99594C16 3.61605 12.3758 0 7.99594 0C3.61605 0 0 3.61605 0 7.99594C0 12.384 3.61605 16 8.00406 16ZM7.14271 11.8151C6.83393 11.8151 6.59015 11.677 6.36262 11.3926L4.50178 9.14982C4.35551 8.96292 4.28238 8.77603 4.28238 8.57288C4.28238 8.15033 4.61554 7.81717 5.03809 7.81717C5.29 7.81717 5.48502 7.90655 5.68004 8.15846L7.11833 9.96242L10.2387 4.98121C10.4175 4.6968 10.645 4.55053 10.905 4.55053C11.3113 4.55053 11.6851 4.84307 11.6851 5.26562C11.6851 5.45251 11.6039 5.64754 11.482 5.82631L7.8903 11.3845C7.71153 11.6607 7.44337 11.8151 7.14271 11.8151Z"
                fill={color}
            />
        </svg>
    );
};

SelectorIcon.fillerVariant = SelectorFillerVariant;
