import * as type from '../constants';

const initState = {
    detail: {},
};

export default function charts(state = initState, action) {
    switch (action.type) {
        case type.GET_OPERATION_DETAILS:
            return {
                ...state,
            };
        case type.GET_OPERATION_DETAILS_FAILED:
            return {
                ...state,
                error: action.payload,
            };
        case type.GET_OPERATION_DETAILS_SUCCESS:
            return {
                ...state,
                detail: action.payload,
            };
        case type.CLEAR_OPERATION_DETAILS:
            return {
                ...state,
                detail: {},
            };
        default:
            return state;
    }
}
