import React, { Component } from 'react';
import { connect } from 'react-redux';
import AwareTable from '../../components/AwareTable';
import { URL } from 'src/app/config';
import { OverflowContainer, Paginator } from 'src/shared';

const tableName = 'EPayment-Details';

class EpaymentDetailsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
        };
    }

    onNameSave = (name) => this.setState({ name });

    render() {
        return (
            <div className="epayment-details">
                <Paginator name={tableName + this.props.match.params.id}>
                    <OverflowContainer title={`${this.state.name}`}>
                        <AwareTable
                            tableName={tableName + this.props.match.params.id}
                            isStoreTable
                            onGetTable={({ name }) => this.onNameSave(name)}
                            url={URL.recipes + this.props.match.params.id}
                        />
                    </OverflowContainer>
                </Paginator>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        fetch: state.fetch.fetch,
    };
}

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(EpaymentDetailsPage);
