import React from 'react';

export const PointIcon = (
    <svg
        width="17"
        height="23"
        viewBox="0 0 17 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.7546 13.3253C15.8483 11.9256 16.5 10.1639 16.5 8.25C16.5 3.69365 12.8063 0 8.25 0C3.69365 0 0 3.69365 0 8.25C0 10.1639 0.651728 11.9256 1.74536 13.3253L8.25 22.9821L14.7546 13.3253ZM11.7857 8.24979C11.7857 10.2025 10.2027 11.7855 8.25 11.7855C6.29728 11.7855 4.71429 10.2025 4.71429 8.24979C4.71429 6.29707 6.29728 4.71408 8.25 4.71408C10.2027 4.71408 11.7857 6.29707 11.7857 8.24979Z"
        />
    </svg>
);
