import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReplaySubject } from 'rxjs';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { URL } from 'src/app/config';
import AwareTable from 'src/components/AwareTable';
import {
    get,
    post,
    localLoad,
    log,
    sendToSubject,
    statusNameMapper,
    getClassName,
    localSaveSerialize,
    localSave,
} from 'src/shared/lib';
import {
    Button,
    CheckBox,
    OverflowContainer,
    Paginator,
    AddIcon,
} from 'src/shared/ui';

import './platform-cashbox-page.scss';
import CashBoxWithRoles from './CashBoxWithRoles/index.js';
import { setNeedsUpdate } from 'src/redux/actions/table';
import { cashboxesTableName } from '../CompanyDetailsPage';

const platformCashboxTableName = 'Platform-Cashbox';

/**
 * @type {boolean}
 * В соответствии с задачей DPS-359 установить возможность
 * включения/выключения Мир/Обновленного вида создания кассы
 */
const IS_CASH_BOX = true;
class PlatformCashBoxPage extends Component {
    filterSubject = new ReplaySubject(1);

    constructor(props) {
        super(props);
        this.send = sendToSubject.bind(this);
        this.state = {
            open_add_cashbox: false,
            open_update_cashbox: false,
            login: '',
            password: '',
            blocked: true,
            groupCode: '',
            id: '',
            companyName: '',
            ffdVersion: '',
            cashboxAvailableFfdList: [],
            status: ['Активно', 'Выключено'],
            defStatus: 'Активно',
            createUpdateCashBoxError: false,
        };
        if (IS_CASH_BOX) {
            this.state['role'] = ['PLAT_FORM'];
            this.state['companyIds'] = [];
        }
    }

    componentDidMount() {
        this.getData();
        this.getCashboxAvailableFfd();
    }

    getCashboxAvailableFfd() {
        get(URL.getCashboxAvailableFfd)
            .then((r) => {
                let ffdVersion = r.data.payload[0].version;
                let cashboxAvailableFfdList = r.data.payload;
                this.setState({
                    ffdVersion: ffdVersion,
                    cashboxAvailableFfdList: cashboxAvailableFfdList,
                });
            })
            .catch((r) => log(r));
    }

    toggle_add_cashbox = () => {
        this.setState({
            login: '',
            password: '',
            blocked: true,
            groupCode: '',
            companyName: '',
            defStatus: 'Активно',
            open_add_cashbox: !this.state.open_add_cashbox,
            createUpdateCashBoxError: false,
        });
    };
    toggle_update_cashbox = () => {
        this.setState({
            open_update_cashbox: !this.state.open_update_cashbox,
            createUpdateCashBoxError: false,
        });
    };
    getData = () => {
        this.send({
            offset: localLoad('platform-cashbox-offset', 0),
            size: localLoad('platform-cashbox-range', 10),
        });
    };

    createPlatformCashbox = () => {
        const {
            login,
            companyName,
            password,
            groupCode,
            defStatus,
            ffdVersion,
        } = this.state;
        const createParams = {
            login,
            companyName,
            password,
            groupCode,
            ffdVersion,
            status: statusNameMapper(defStatus, 'en'),
        };
        if (IS_CASH_BOX) {
            if (
                !this.state.companyIds.length &&
                this.state.role.includes('E_COMMERCE')
            ) {
                this.setState({ createUpdateCashBoxError: true });
                return;
            }
            createParams['role'] = this.state.role;
            if (this.state.companyIds.length) {
                createParams['idCompany'] = this.state.companyIds[0];
                createParams['reassignIdCompany'] = this.state.companyIds[0];
            }
        }

        post(URL.createPlatformCashBox, createParams)
            .then((r) => {
                this.setState({
                    open_add_cashbox: false,
                    createUpdateCashBoxError: false,
                });
                this.props.dispatch(
                    setNeedsUpdate(platformCashboxTableName, true)
                );
            })
            .catch((r) => {
                log(r);
                this.setState({ open_add_user: false });
            });
    };
    prepareParamsUpdateCashBox = (id) => {
        const paramsUpdate = {
            id: id,
            companyName: this.state.companyName,
            login: this.state.login,
            password: this.state.password,
            blocked: this.state.blocked,
            groupCode: this.state.groupCode,
            ffdVersion: this.state.ffdVersion,
            status: statusNameMapper(this.state.defStatus, 'en'),
            idCompany: this.state.idCompany,
        };
        if (IS_CASH_BOX) {
            if (
                (!this.state.companyIds.length &&
                    this.state.role.includes('E_COMMERCE')) ||
                (!this.state.companyIds[0] &&
                    this.state.role.includes('E_COMMERCE'))
            ) {
                this.setState({ createUpdateCashBoxError: true });
                return;
            }
            paramsUpdate['role'] = this.state.role;
            if (this.state.companyIds.length) {
                paramsUpdate['reassignIdCompany'] = this.state.companyIds[0];
            }
            if (
                !this.state.role.includes('E_COMMERCE') &&
                !this.state.companyIds.length
            ) {
                paramsUpdate['reassignIdCompany'] =
                    this.state.currentCompanyIds[0];
            }
        }
        return paramsUpdate;
    };

    updatePlatformCashbox = (id) => {
        const paramsUpdate = this.prepareParamsUpdateCashBox(id);
        if (!paramsUpdate) return;
        post(URL.updatePlatformCashBox + id, paramsUpdate)
            .then((r) => {
                this.setState({
                    open_update_cashbox: false,
                    createUpdateCashBoxError: false,
                    companyIds: [],
                });
                this.props.dispatch(
                    setNeedsUpdate(platformCashboxTableName, true)
                );
                this.props.dispatch(
                    setNeedsUpdate(
                        cashboxesTableName + String(paramsUpdate.idCompany),
                        true
                    )
                );
            })
            .catch((r) => {
                log(r);
                this.setState({ open_update_cashbox: false });
            });
    };

    /**
     *  DPS-359:  Добавлен дополнительный метод который вызывается если
     *  IS_CASH_BOX и searchQuery не falsy values
     *  задача метода установка в локал сторадж значения для селектора выбора компаний
     *  и при любом варианте запроса на find установка newState через setState
     */
    getCompanies = (searchQuery, newState) => {
        const companyIds = 'companySelectId';
        const companySelect = 'companySelect';
        const companySelectList = 'companySelectList';
        const nameFieldLocal = 'cashbox-with-roles';
        const listSelect = getClassName(nameFieldLocal, companySelectList);
        const id = getClassName(nameFieldLocal, companyIds);
        const value = getClassName(nameFieldLocal, companySelect);

        return post(
            URL.companyFindSelectUrl,
            { searchQuery, offset: 0, size: 10 },
            {},
            false
        )
            .then((response) => {
                const list = response.data.payload.map((e) => {
                    return { value: e.id, label: e.name };
                });
                const company = list.find(
                    (listItem) => listItem.value === searchQuery
                );
                localSaveSerialize(listSelect, company ? [company] : undefined);
                localSaveSerialize(value, company);
                localSave(id, company ? company.value : undefined);
            })
            .catch((response) => log(response))
            .finally(() => {
                this.setState(newState);
            });
    };

    getPlatformCashboxInfo = (id) => {
        get(URL.platformCashBoxOneUrl + id)
            .then((r) => {
                let {
                    id,
                    companyName,
                    login,
                    password,
                    blocked,
                    groupCode,
                    status,
                    ffdVersion,
                    idCompany,
                } = r.data.payload;
                const newState = {
                    id,
                    companyName,
                    login,
                    password,
                    blocked,
                    groupCode,
                    ffdVersion,
                    idCompany,
                    defStatus: statusNameMapper(status, 'ru'),
                    open_update_cashbox: true,
                };
                if (IS_CASH_BOX) {
                    newState['role'] = r.data.payload.role;
                    newState['companyIds'] = [r.data.payload.idCompany];
                    newState['currentCompanyIds'] = [r.data.payload.idCompany];
                }
                return newState;
            })
            .then((newState) => {
                if (IS_CASH_BOX && newState?.companyIds?.length) {
                    this.getCompanies(newState?.companyIds[0], newState);
                } else {
                    this.setState(newState);
                }
            })
            .catch((r) => {
                this.setState({ open_update_cashbox: false });
                log(r);
            });
    };

    onChangeCashBoxWithRoles(fields) {
        const newState = {
            ...fields,
        };
        if (
            this.state.createUpdateCashBoxError &&
            fields?.companyIds?.length &&
            fields?.companyIds[0]
        ) {
            newState['createUpdateCashBoxError'] = false;
        }
        if (
            this.state.createUpdateCashBoxError &&
            !fields?.role?.includes('E_COMMERCE')
        ) {
            newState['createUpdateCashBoxError'] = false;
        }
        this.setState(newState);
    }

    render() {
        return (
            <div className="platform-cashbox-page">
                <Paginator name={platformCashboxTableName}>
                    <OverflowContainer
                        icon={
                            <AddIcon
                                title="Добавить Онлайн Кассу"
                                onClick={this.toggle_add_cashbox}
                            />
                        }
                    >
                        <AwareTable
                            type="editOption"
                            editing={this.getPlatformCashboxInfo}
                            locationUrl={this.props.history.location.pathname}
                            url={URL.platformCashBoxUrl}
                            link={URL.cashBoxRegistries + '/'}
                            tableName={platformCashboxTableName}
                            isStoreTable
                        />
                    </OverflowContainer>
                </Paginator>
                <Modal
                    isOpen={this.state.open_add_cashbox}
                    toggle={this.toggle_add_cashbox}
                    autoComplete="off"
                >
                    <ModalHeader toggle={this.toggle_add_cashbox}>
                        Добавление Кассы ПЛАТ-ФОРМЫ
                    </ModalHeader>
                    <ModalBody className={'add-bank-user-modal'}>
                        <div className="user-add-form">
                            <div className="user-add-form__info">
                                Код Группы
                            </div>
                            <Input
                                onChange={(groupCode) =>
                                    this.setState({
                                        groupCode: groupCode.target.value,
                                    })
                                }
                                value={this.state.groupCode}
                                placeholder={'Введите код группы ... '}
                                className="modal-input"
                            />
                            <div className="user-add-form__info">
                                Имя Компании
                            </div>
                            <Input
                                onChange={(companyName) =>
                                    this.setState({
                                        companyName: companyName.target.value,
                                    })
                                }
                                value={this.state.companyName}
                                placeholder={'Введите имя компании ... '}
                                className="modal-input"
                            />
                            <div className="user-add-form__info">Логин</div>
                            <Input
                                onChange={(login) =>
                                    this.setState({ login: login.target.value })
                                }
                                value={this.state.login}
                                placeholder={'Введите логин ... '}
                                className="modal-input"
                                autoComplete="new-login"
                            />
                            <div className="user-add-form__info">Пароль</div>
                            <Input
                                onChange={(password) =>
                                    this.setState({
                                        password: password.target.value,
                                    })
                                }
                                value={this.state.password}
                                placeholder={'Введите пароль ... '}
                                type="password"
                                className="modal-input"
                                autoComplete="new-password"
                            />
                            <div className="user-add-form__info user-add-form__info-select">
                                <select
                                    value={this.state.ffdVersion}
                                    className="custom-select"
                                    onChange={(e) => {
                                        this.setState({
                                            ffdVersion: e.target.value,
                                        });
                                    }}
                                >
                                    {this.state.cashboxAvailableFfdList.map(
                                        (ffdVersion, i) => (
                                            <option
                                                key={i}
                                                value={ffdVersion.version}
                                            >
                                                {ffdVersion.nameFfd}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                            <div className="user-add-form__info user-add-form__info-select">
                                <select
                                    value={this.state.defStatus}
                                    className="custom-select"
                                    onChange={(e) => {
                                        e.persist();
                                        this.setState({
                                            defStatus: e.target.value,
                                        });
                                    }}
                                >
                                    {this.state.status.map((status, i) => (
                                        <option key={i} value={status}>
                                            {status}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {IS_CASH_BOX && (
                                <CashBoxWithRoles
                                    isPlatform={true}
                                    onChange={(fields) =>
                                        this.onChangeCashBoxWithRoles(fields)
                                    }
                                    createUpdateError={
                                        this.state.createUpdateCashBoxError
                                    }
                                />
                            )}
                            <Button
                                className="mt-4"
                                fluid
                                size="tiny"
                                onClick={this.createPlatformCashbox}
                                disabled={this.state.createUpdateCashBoxError}
                            >
                                Добавить
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={this.state.open_update_cashbox}
                    toggle={this.toggle_update_cashbox}
                    autoComplete="off"
                >
                    <ModalHeader toggle={this.toggle_update_cashbox}>
                        Редактирование Кассы ПЛАТ-ФОРМЫ
                    </ModalHeader>
                    <ModalBody className={'add-bank-user-modal'}>
                        <div className="user-add-form">
                            <div className="user-add-form__info">
                                Код Группы
                            </div>
                            <div>{this.state.groupCode}</div>
                            <div className="user-add-form__info">
                                Имя Компании
                            </div>
                            <Input
                                onChange={(companyName) =>
                                    this.setState({
                                        companyName: companyName.target.value,
                                    })
                                }
                                value={this.state.companyName}
                                placeholder={'Введите имя компании ... '}
                                className="modal-input"
                            />
                            <div className="user-add-form__info">Логин</div>
                            <Input
                                onChange={(login) =>
                                    this.setState({ login: login.target.value })
                                }
                                value={this.state.login}
                                placeholder={'Введите логин ... '}
                                className="modal-input"
                                autoComplete="new-login"
                            />
                            <div className="user-add-form__info">Пароль</div>
                            <Input
                                onChange={(password) =>
                                    this.setState({
                                        password: password.target.value,
                                    })
                                }
                                value={this.state.password}
                                placeholder={'Введите пароль ... '}
                                type="password"
                                className="modal-input"
                                autoComplete="new-password"
                            />
                            <div className="user-add-form__info">
                                <select
                                    value={this.state.ffdVersion}
                                    className="custom-select"
                                    onChange={(e) => {
                                        this.setState({
                                            ffdVersion: e.target.value,
                                        });
                                    }}
                                >
                                    {this.state.cashboxAvailableFfdList.map(
                                        (ffdVersion, i) => (
                                            <option
                                                key={i}
                                                value={ffdVersion.version}
                                            >
                                                {ffdVersion.nameFfd}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                            <div className="user-add-form__info">
                                <select
                                    value={this.state.defStatus}
                                    className="custom-select"
                                    onChange={(e) => {
                                        e.persist();
                                        this.setState({
                                            defStatus: e.target.value,
                                        });
                                    }}
                                >
                                    {this.state.status.map((status, i) => (
                                        <option key={i} value={status}>
                                            {status}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="user-add-form__info">
                                <span>Блокирован: </span>
                                <CheckBox
                                    onChange={() =>
                                        this.setState({
                                            blocked: !this.state.blocked,
                                        })
                                    }
                                    checked={this.state.blocked}
                                />
                            </div>
                            {IS_CASH_BOX && (
                                <CashBoxWithRoles
                                    isPlatform={true}
                                    onChange={(fields) =>
                                        this.onChangeCashBoxWithRoles(fields)
                                    }
                                    role={this.state.role}
                                    idCompany={this.state.companyIds}
                                    createUpdateError={
                                        this.state.createUpdateCashBoxError
                                    }
                                />
                            )}
                            <Button
                                className="mt-4"
                                fluid
                                size="tiny"
                                onClick={() =>
                                    this.updatePlatformCashbox(this.state.id)
                                }
                                disabled={this.state.createUpdateCashBoxError}
                            >
                                Сохранить
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        fetch: state.fetch.fetch,
    };
}

export default withRouter(connect(mapStateToProps)(PlatformCashBoxPage));
