import React, { useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { classNames } from 'src/shared/lib';
import styles from './styles.module.scss';
import { Popup, ResetFiltersIcon } from 'src/shared/ui';
import { Button } from 'src/shared/ui';
import { Position } from 'src/shared/ui/popup/popup';

interface Props {
    filterName: string;
    children: React.ReactNode;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    firstRowControls?: React.ReactNode;
    secondRowControls?: React.ReactNode;
    actions?: React.ReactNode;
    selectedCount?: number;
    onApplyClick?: () => void;
    onReset?: () => void;
    disabled?: boolean;
    maxWidth?: number;
}

const ID_SCROLL_ELEMENT = 'page-element-scroll';
const MAX_HEIGHT_POPUP = isMobile ? 350 : 520;

export const FilterDropdown = ({
    children,
    selectedCount,
    onReset,
    filterName,
    firstRowControls,
    secondRowControls,
    onApplyClick,
    actions,
    setIsOpen,
    isOpen,
    disabled,
    maxWidth,
}: Props) => {
    const triggerRef = useRef<HTMLButtonElement>(null);

    const onToggle = () => {
        setIsOpen((prev) => !prev);
    };

    const resetDisplayed = !!(selectedCount && onReset && selectedCount > 0);

    return (
        <div
            className={classNames(styles['wrapper'], {
                [styles['disabled']]: disabled,
            })}
        >
            <button
                ref={triggerRef}
                className={classNames(styles.trigger, {
                    [styles['trigger--open']]: isOpen,
                })}
                onClick={onToggle}
            >
                <span className={styles['trigger__header']}>{filterName}</span>
                {selectedCount !== undefined && (
                    <span className={styles['trigger__count']}>
                        Выбрано {selectedCount}
                    </span>
                )}
                {resetDisplayed && (
                    <span
                        className={styles['trigger__reset-icon']}
                        onClick={(event) => {
                            event.stopPropagation();
                            onReset();
                        }}
                    >
                        {ResetFiltersIcon}
                    </span>
                )}
            </button>
            {
                <Popup
                    maxWidth={maxWidth}
                    triggerRef={triggerRef}
                    idScrollElement={ID_SCROLL_ELEMENT}
                    marginFromTrigger={10}
                    maxHeightPopup={MAX_HEIGHT_POPUP}
                    position={Position.BOTTOM_LEFT}
                    maxWidthPopup="max"
                    hiddenPopup={() => setIsOpen(false)}
                    hideWhenResizing={true}
                    className={classNames(styles['popup'], {
                        [styles['open']]: isOpen,
                    })}
                >
                    <div className={styles['search-field']}>
                        <div className={styles['controls-wrapper']}>
                            {firstRowControls}
                        </div>
                    </div>
                    {secondRowControls}
                    <div
                        className={classNames(styles.terminals, {
                            [styles['hide-overflow-x']]: true,
                        })}
                    >
                        {children}
                    </div>

                    <div className={styles['actions-wrapper']}>
                        <div className={styles['actions']}>{actions}</div>
                        {onApplyClick && (
                            <Button size="smedium" onClick={onApplyClick}>
                                Применить
                            </Button>
                        )}
                    </div>
                </Popup>
            }
        </div>
    );
};
