import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { URL } from 'src/app/config';
import AwareTable from 'src/components/AwareTable';
import { FetchInfoAboutTerminal } from 'src/features/fetch-info-about-terminal';
import { AddTerminalGroup } from 'src/features/add-terminal-group';
import { EditGroupOfTerminals } from 'src/features/edit-group-of-terminals';
import { log, post } from 'src/shared/lib';
import TerminalsFilter from './terminals-filter';
import { OverflowContainer, Paginator, AddIcon } from 'src/shared';
import { setNeedsUpdate } from 'src/redux/actions/table';
import { getTerminals } from 'src/redux/actions/terminals';

const terminalsTableName = 'Inventory-Terminals';
const groupsTableName = 'Inventory-Groups';

class TerminalsPage extends Component {
    filterSubject = new ReplaySubject(1);
    groupsSubject = new BehaviorSubject({ size: 300, offset: 0 });

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            groupId: null,
            groupName: '',
            openAddGroup: false,
        };
        this.setGroupRequest = this.setGroupRequest.bind(this);
        this.resetGroupId = this.resetGroupId.bind(this);
        this.onClickOnGroup = this.onClickOnGroup.bind(this);
        this.toggleAddGroup = this.toggleAddGroup.bind(this);
        this.fetchUpdatedData = this.fetchUpdatedData.bind(this);
    }

    toggleAddGroup() {
        this.setState({ openAddGroup: !this.state.openAddGroup });
    }

    resetGroupId() {
        this.setState({
            groupId: null,
        });
    }

    fetchUpdatedData() {
        this.props.dispatch(getTerminals());
    }

    // TODO Перенести в в фичу FetchInfoAboutTerminal
    setGroupRequest(terminalId, groupId) {
        post(URL.terminalPageSetGroupUrl, {
            terminalId,
            groupId: groupId ?? null,
        })
            .then(() => {
                this.props.dispatch(setNeedsUpdate(terminalsTableName, true));
                this.props.dispatch(setNeedsUpdate(groupsTableName, true));
            })
            .catch((r) => log(r));
    }

    setTerminalHeader() {
        if (this.props.showTerminalHeader) return 'Терминалы';
        return '';
    }

    onClickOnGroup({ ID, data }) {
        this.setState({
            groupId: ID,
            groupName: data[0],
        });
    }

    render() {
        return (
            <>
                <TerminalsFilter pageTitle={terminalsTableName} />
                <Paginator name={terminalsTableName}>
                    <OverflowContainer title={this.setTerminalHeader()}>
                        <AwareTable
                            isStoreTable
                            tableName={terminalsTableName}
                            url={URL.terminalPageTerminalsUrl}
                            onItemClick={({ ID, data }) => {
                                this.setState({
                                    id: ID,
                                    groupName: data[3],
                                });
                            }}
                        />
                    </OverflowContainer>
                </Paginator>

                <Paginator name={groupsTableName}>
                    <OverflowContainer
                        title="Группы"
                        icon={<AddIcon onClick={this.toggleAddGroup} />}
                    >
                        <AwareTable
                            isStoreTable
                            tableName={groupsTableName}
                            url={URL.terminalPageGroupUrl}
                            onItemClick={this.onClickOnGroup}
                        />
                    </OverflowContainer>
                </Paginator>

                {/* TODO переписать реализацию как в фиче EditGroupOfTerminals */}
                <FetchInfoAboutTerminal
                    isChangedGroup
                    id={this.state.id}
                    groupName={this.state.groupName}
                    setId={(value) =>
                        this.setState({
                            id: value,
                        })
                    }
                    changingGroup={(terminalId, groupId) =>
                        this.setGroupRequest(terminalId, groupId)
                    }
                />
                {this.state.openAddGroup && (
                    <AddTerminalGroup
                        closeModal={this.toggleAddGroup}
                        successCallback={() => {
                            this.props.dispatch(
                                setNeedsUpdate(terminalsTableName, true)
                            );
                            this.props.dispatch(
                                setNeedsUpdate(groupsTableName, true)
                            );
                        }}
                    />
                )}

                {this.state.groupId && (
                    <EditGroupOfTerminals
                        id={this.state.groupId}
                        groupName={this.state.groupName}
                        resetId={this.resetGroupId}
                        successCallback={() => {
                            this.props.dispatch(
                                setNeedsUpdate(terminalsTableName, true)
                            );
                            this.props.dispatch(
                                setNeedsUpdate(groupsTableName, true)
                            );
                        }}
                    />
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        terminals: state.terminals.terminals,
        fetch: state.fetch.fetch,
    };
}

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(TerminalsPage);
