import React, { Component } from 'react';
import { URL } from 'src/app/config';

import AwareTable from 'src/components/AwareTable';
import EinvoiceFilter from './EinvoiceFilter';

import { OverflowContainer, Paginator } from 'src/shared';
import { LOCAL_STORAGE_TABLE_NAMES } from 'src/shared/lib';

const pageTitle = LOCAL_STORAGE_TABLE_NAMES.eInvoiceBank;

class BankEinvoicePage extends Component {
    render() {
        return (
            <div className="einvoice-page">
                <EinvoiceFilter pageTitle={pageTitle} />
                <Paginator name={pageTitle}>
                    <OverflowContainer>
                        <AwareTable
                            newStatuses
                            tableName={pageTitle}
                            type="editOption"
                            readonly
                            url={URL.einvoiceAll}
                            link={URL.einvoice + '/'}
                            isStoreTable
                        />
                    </OverflowContainer>
                </Paginator>
            </div>
        );
    }
}

export default BankEinvoicePage;
