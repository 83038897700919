import React, {
    FC,
    memo,
    InputHTMLAttributes,
    ChangeEventHandler,
} from 'react';
import { classNames } from 'src/shared/lib';
import styles from './new-checkbox.module.scss';

interface NewCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    className?: string;
    isReverseOrder?: boolean;
    alignCheckbox?: 'left' | 'right';
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const NewCheckbox: FC<NewCheckboxProps> = memo((props) => {
    const {
        label,
        className,
        isReverseOrder = false,
        alignCheckbox = 'left',
        onChange,
        ...otherProps
    } = props;
    
    return !isReverseOrder ? (
        <label className={classNames(styles.label, {}, [className])}>
            <span className={styles['custom-label']}>{label}</span>
            <input
                type="checkbox"
                onChange={onChange}
                className={styles['default-checkbox']}
                {...otherProps}
            />

            <span
                className={classNames(styles['custom-checkbox'], {}, [
                    styles[alignCheckbox],
                ])}
            />
        </label>
    ) : (
        <label className={classNames(styles.label, {}, [className])}>
            <input
                type="checkbox"
                onChange={onChange}
                className={styles['default-checkbox']}
                {...otherProps}
            />
            <span
                className={classNames(styles['custom-checkbox'], {}, [
                    styles[alignCheckbox],
                ])}
            />
            <span
                className={classNames(styles['custom-label'], {}, [
                    styles.reverse,
                ])}
            >
                {label}
            </span>
        </label>
    );
});
