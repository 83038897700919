import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Card, CardBody } from 'reactstrap';
import { ReplaySubject } from 'rxjs';

import AwareTable from 'src/components/AwareTable';

import { navRoutes, URL } from 'src/app/config';
import { DownloadReportStore } from 'src/features/download-report';
import { log, getInitialFilter, post } from 'src/shared/lib';

import SetttlementDetailsFilter from './SetttlementDetailsFilter';

import './settlement-details.scss';
import { OverflowContainer, Paginator } from 'src/shared';

const SettlementDetailsTableName = 'Settlement-Details';

class SettlementDetails extends Component {
    filterSubject = new ReplaySubject(1);
    state = { summary: {} };

    summaryRequest(body) {
        const { date, id } = this.props.match.params;
        post(URL.settlementDetailsSummaryUrl + date + `/${id ? id : ''}`, body)
            .then((response) =>
                this.setState({ summary: response.data.payload })
            )
            .catch((response) => log(response));
    }

    render() {
        const { date, id } = this.props.match.params;

        return (
            <div className="settlement-details">
                <SetttlementDetailsFilter
                    tableName={SettlementDetailsTableName + date + id}
                    getSummary={(request) => this.summaryRequest(request)}
                />
                <div className="settlement-details__sum-view">
                    {this.state.summary.amount ? (
                        <Card>
                            <CardBody>
                                <div className="sum-view-container">
                                    <span>Сумма: </span>
                                    <span>{this.state.summary.amount}</span>
                                </div>
                            </CardBody>
                        </Card>
                    ) : null}
                    {this.state.summary.feeAmount ? (
                        <Card>
                            <CardBody>
                                <div className="sum-view-container">
                                    <span>Комиссия: </span>
                                    <span>{this.state.summary.feeAmount}</span>
                                </div>
                            </CardBody>
                        </Card>
                    ) : null}
                    {this.state.summary.settlAmount ? (
                        <Card>
                            <CardBody>
                                <div className="sum-view-container">
                                    <span>Возмещение: </span>
                                    <span>
                                        {this.state.summary.settlAmount}
                                    </span>
                                </div>
                            </CardBody>
                        </Card>
                    ) : null}
                </div>
                <Paginator name={SettlementDetailsTableName + date + id}>
                    <OverflowContainer>
                        <AwareTable
                            newStatuses
                            actions={[
                                <DownloadReportStore
                                    defaultFileName={
                                        'Возмещения за ' +
                                        moment(date, 'YYYYMMDD').format(
                                            'DD-MM-YYYY'
                                        )
                                    }
                                    url={URL.settlementDetailFileDownloadUrl}
                                    filterSubject={this.filterSubject}
                                    initialFilter={{
                                        ...getInitialFilter(
                                            [
                                                'terminalIds',
                                                'companyIds',
                                                'searchQuery',
                                            ],
                                            SettlementDetailsTableName +
                                                date +
                                                id
                                        ),
                                        companyIds: [Number(id)],
                                    }}
                                    pageTitle={
                                        SettlementDetailsTableName + date + id
                                    }
                                />,
                            ]}
                            isStoreTable
                            tableName={SettlementDetailsTableName + date + id}
                            updateTableCallback={(request) =>
                                this.summaryRequest(request)
                            }
                            url={`${URL.settlementDetailsUrl}${date}${
                                id ? '/' + id : ''
                            }`}
                            link={navRoutes.operations}
                        />
                    </OverflowContainer>
                </Paginator>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        fetch: state.fetch.fetch,
    };
}

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(SettlementDetails);
