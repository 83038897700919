import React from 'react';
import styles from './styles.module.scss';

interface Props {
    children: React.ReactNode;
    onClick?: () => void;
}

export const QuickActionButton = ({ children, onClick }: Props) => {
    return (
        <span onClick={onClick} className={styles['wrapper']}>
            {children}
        </span>
    );
};
