import React from 'react';
import styles from './styles.module.scss';
import { classNames } from 'src/shared/lib';
import {
    IndicatorColorVariant,
    INDICATOR_COLOR_VARIANT_MAP,
} from 'src/entities/selector-with-groups/config';
import { TerminalStatus } from 'src/entities/terminal';
import { useSelector } from 'react-redux';
import { getInactivePeriodPerDaysCorrectPostfix } from '../../lib';

interface Props {
    variant: TerminalStatus;
    text: string;
    title: string;
    dotColor: IndicatorColorVariant;
}

export const TerminalTip = ({ variant, text, title, dotColor }: Props) => {
    const inactivePeriodPerDays = useSelector(
        (state) => state.terminals.inactivePeriodPerDays
    );

    return (
        <div className={styles['wrapper']}>
            <div className={styles['title']}>
                <div
                    className={styles['circle']}
                    style={{
                        backgroundColor: INDICATOR_COLOR_VARIANT_MAP[dotColor],
                    }}
                />
                {title}
            </div>
            <div className={styles['content']}>
                <div className={styles['text']}>{text}</div>
                {getBadgesMap(inactivePeriodPerDays)[variant]}
            </div>
        </div>
    );
};

TerminalTip.tipVariant = TerminalStatus;

const getBadgesMap = (inactivePeriodPerDays: number) => ({
    DISABLED: (
        <div
            className={classNames(styles['icon'], {
                [styles['disabled']]: true,
            })}
        >
            Терминал отключен
        </div>
    ),
    ACTIVE: (
        <div
            className={classNames(styles['icon'], {
                [styles['active']]: true,
            })}
        >
            Активен
        </div>
    ),
    INACTIVE: (
        <div
            className={classNames(styles['icon'], {
                [styles['no-ops']]: true,
            })}
        >
            <svg
                width="14"
                height="13"
                viewBox="0 0 14 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.54935 12.1469H11.6759C12.1551 12.1469 12.5352 11.9981 12.8162 11.7007C13.1054 11.4032 13.25 11.0355 13.25 10.5975C13.25 10.3248 13.1756 10.0687 13.0269 9.82905L7.95744 0.78087C7.81696 0.524712 7.62278 0.330527 7.37488 0.198316C7.13525 0.0661054 6.88736 0 6.6312 0C6.35851 0 6.09822 0.0661054 5.85033 0.198316C5.6107 0.330527 5.41651 0.524712 5.26777 0.78087L0.223106 9.82905C0.0743686 10.0687 0 10.3248 0 10.5975C0 11.0355 0.140474 11.4032 0.421422 11.7007C0.710633 11.9981 1.08661 12.1469 1.54935 12.1469ZM6.6188 10.362C6.36264 10.362 6.13954 10.267 5.94949 10.0769C5.75943 9.89515 5.66441 9.66791 5.66441 9.39523C5.66441 9.13907 5.75943 8.91597 5.94949 8.72591C6.13954 8.53586 6.36264 8.44083 6.6188 8.44083C6.89149 8.44083 7.12286 8.53586 7.31291 8.72591C7.50296 8.91597 7.59386 9.13907 7.58559 9.39523C7.57733 9.66791 7.4823 9.89515 7.30051 10.0769C7.11872 10.267 6.89149 10.362 6.6188 10.362ZM6.6312 7.62278C6.43288 7.62278 6.27175 7.56494 6.1478 7.44925C6.02385 7.3253 5.95775 7.16417 5.94949 6.96586L5.86272 3.56969C5.85446 3.33832 5.92056 3.1524 6.06104 3.01193C6.20151 2.87145 6.39157 2.80122 6.6312 2.80122C6.8543 2.80122 7.03609 2.87145 7.17657 3.01193C7.3253 3.1524 7.39967 3.33832 7.39967 3.56969L7.30051 6.96586C7.29225 7.16417 7.22615 7.3253 7.1022 7.44925C6.98651 7.56494 6.82951 7.62278 6.6312 7.62278Z"
                    fill="#F2C94C"
                />
            </svg>
            {`Активен, нет операций за ${getInactivePeriodPerDaysCorrectPostfix(
                inactivePeriodPerDays ?? 1
            )}`}
        </div>
    ),
});
