import React, { useState } from 'react';
import styles from './styles.module.scss';
import { classNames } from 'src/shared/lib';

interface Props {
    children: React.ReactNode[];
    title?: string;
    icon?: JSX.Element;
}

export const OverflowContainer = ({ children, title, icon }: Props) => {
    const [bounce, setBounce] = useState(false);

    const performBounce = () => {
        setBounce(true);
        setTimeout(() => setBounce(false), 1000);
    };

    return (
        <div className={styles['wrapper']}>
            {(title || icon) && (
                <h2 className={styles['header']}>
                    <span className={styles['title']}>{title}</span>
                    <span
                        className={classNames(styles['icon-wrapper'], {
                            [styles['bounce']]: bounce,
                        })}
                        onClick={performBounce}
                    >
                        {icon}
                    </span>
                </h2>
            )}
            <div className={styles['overflow-wrapper']}>{children}</div>
        </div>
    );
};
