import React, { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { navRoutes } from 'src/app/config';
import { SET_TERMINAL_INACTIVE } from 'src/redux/constants';
import { Modal } from 'src/shared';

export const NotificationOfInactiveTerminals: FC = memo(() => {
    const dispatch = useDispatch();
    const isInactiveTerminals = useSelector(
        (state) => state.terminals.inactive
    );

    const setInactive = useCallback(() => {
        dispatch({
            type: SET_TERMINAL_INACTIVE,
            payload: false,
        });
    }, [dispatch]);

    const onCloseModal = useCallback(() => {
        setInactive();
    }, [setInactive]);

    return (
        <Modal
            isOpen={isInactiveTerminals}
            header="Уважаемый клиент"
            closeButton="Закрыть"
            onClose={onCloseModal}
        >
            <p>
                Обращаем ваше внимание, что у вас есть терминалы, по которым
                отсутствуют транзакции более 30 календарных дней. Для вашего
                удобства можете проставить статусы по данным терминалам. Для
                этого перейдите в раздел{' '}
                <Link onClick={setInactive} to={navRoutes.settingsTerminals}>
                    «Терминалы»
                </Link>
                , нажмите на терминал, выделенный желтым цветом, и выберите
                соответствующий статус из выпадающего списка.
            </p>
        </Modal>
    );
});
