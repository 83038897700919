import React, {
    MouseEventHandler,
    ElementType,
    ReactNode,
    CSSProperties,
    FC,
} from 'react';

import './spin.scss';

interface CSSModule {
    [className: string]: string;
}

/**
 *
 * @types
 * @Component Spin
 *
 */

type Props = {
    onClick?: MouseEventHandler<any>;
    size?: any;
    children?: ReactNode;
    id?: string;
    key?: number;
    className?: string;
    style?: CSSProperties;
    active?: boolean;
    block?: boolean;
    color?: string;
    disabled?: boolean;
    tag?: ElementType;
    cssModule?: CSSModule;
    fetch?: number;
};

const Spin: FC<Props> = ({ onClick: handleClick, color, fetch = 0 }) => {
    return (
        <div
            className={
                fetch > 0 ? 'spin-container show' : 'spin-container hide'
            }
            style={{ color }}
            onClick={handleClick}
        >
            <div className="spin" />
        </div>
    );
};

export { Spin };
