import React, { FC } from 'react';
import { CardIcon, mir, mc, visa } from 'src/shared/ui';

import { formatCurrency, formatDate } from '../../lib';
import { Operation } from '../../model';
import { DetailsItem } from '../details-item';
import styles from './operation-details.module.scss';

interface OperationDetailsProps {
    data: Operation;
}

export const OperationDetails: FC<OperationDetailsProps> = ({
    data,
    children,
}) => {
    const {
        responseCodeDescription,
        cardSystemId,
        cardNumber,
        amount,
        operationTypeName,
        statusName,
        dateTime,
        rrn,
        authCode,
        cardTypeName,
        responseCodeStr,
    } = data;

    const formatCardNumber = (str: string) => {
        return str.replace('****', '**** ****');
    };

    const selectCardSystem = (id: number) => {
        return id
            ? [CardIcon, mc, mc, visa, visa, visa, mir, mir, CardIcon][id]
            : CardIcon;
    };

    return (
        <div className={styles['wrapper']}>
            <div className={styles['header']}>
                <div>{operationTypeName}</div>
                <div
                    className={
                        statusName === 'Подтверждена' ? styles['green'] : ''
                    }
                >
                    {statusName}
                </div>
            </div>
            <div className={styles['amount']}>{formatCurrency(amount)}</div>
            <div className={styles['body']}>
                <DetailsItem
                    title={responseCodeStr}
                    subtitle={responseCodeDescription}
                />
                <DetailsItem
                    title={formatCardNumber(cardNumber)}
                    subtitle={'номер карты'}
                />
                <DetailsItem
                    icon={selectCardSystem(cardSystemId)}
                    title={cardTypeName}
                    subtitle={'тип карты'}
                />
                <DetailsItem
                    title={formatDate(dateTime, 'long')}
                    subtitle={'дата время операции'}
                />
                <DetailsItem title={rrn} subtitle={'RRN'} />
                <DetailsItem title={authCode} subtitle={'код авторизации'} />
            </div>
            <div className={styles['buttons']}>{children}</div>
        </div>
    );
};
