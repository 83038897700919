import React, { Component } from 'react';
import { ReplaySubject } from 'rxjs';
import { connect } from 'react-redux';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { Roles, URL } from 'src/app/config';

import {
    isPermit,
    log,
    PaymentTypeOptions,
    QRCodeTypeOptions,
    sendToSubject,
    get,
    post,
    maskPhone,
    getOptionsForSelect,
    getAppTitle,
} from 'src/shared/lib';

import AwareTable from 'src/components/AwareTable';

import styles from './einvoice-table.module.scss';
import {
    Button,
    Datepicker,
    Input,
    Modal,
    ModalField,
    NewSelect,
    OverflowContainer,
    Textarea,
    Paginator,
    AddIcon,
} from 'src/shared';
import {
    getBankPaymentTypeOptions,
    BankQRCodeTypeOptions,
    PaymentCardTypeOptions,
} from 'src/shared/lib/util';
import { setNeedsUpdate } from 'src/redux/actions/table';
import { createNotification } from 'src/redux/actions/notification';

/**
 * TODO: переделать компонент в tsx / ts and convert to Enum
 */
const CARD = 'CARD';
const SBP = 'SBP';

const isBankApp = getAppTitle() === 'bank';

class EinvoiceTable extends Component {
    einvoiceSubject = new ReplaySubject(1);

    constructor(props) {
        super(props);
        this.send = sendToSubject.bind(this);
        this.einvoiceOptions = {
            ecommKeyIdsOptions: [],
            paymentMethodOptions: [],
            paymentObjectOptions: [],
            taxOptions: [],
            taxationOptions: [],
        };

        this.einvoiceItem = {
            name: '',
            price: '',
            quantity: '',
            amount: '',
            paymentMethod: '',
            paymentObject: '',
            tax: '',
            measurementUnit: '',
        };

        this.einvoice = {
            paymentType: isBankApp
                ? getBankPaymentTypeOptions()[0].value
                : PaymentTypeOptions(false)[0].value,
            qrcType: isBankApp
                ? BankQRCodeTypeOptions[0].value
                : QRCodeTypeOptions[1].value,
            ecommKey: '',
            customerEmail: '',
            customerPhone: '',
            description: '',
            merchantEmail: '',
            paymentToInfoValue: '',
            dueDate: moment(new Date().setDate(new Date().getDate() + 1)),
            taxation: '',
            items: [],
        };
        this.state = {
            ...this.einvoice,
            ...this.einvoiceItem,
            ...this.einvoiceOptions,
            einvoiceAmmount: '',
            open_update_einvoice: false,
            open_add_einvoice: false,
            confirm_add_einvoice: false,
            open_add_einvoice_item: true,
            splitButtonOpen: false,
        };
    }

    toggle_add_einvoice = () => {
        this.setState({
            ...this.einvoice,
            ...this.einvoiceItem,
            einvoiceAmmount: '',
            open_add_einvoice: !this.state.open_add_einvoice,
            confirm_add_einvoice: false,
        });
    };

    toggle_confirm_add_einvoice = () => {
        this.setState({
            confirm_add_einvoice: !this.state.confirm_add_einvoice,
        });
    };

    toggle_update_einvoice = () => {
        this.setState({
            ...this.einvoice,
            open_update_einvoice: !this.state.open_update_einvoice,
        });
    };

    createEinvoice = () => {
        const {
            paymentType,
            qrcType,
            ecommKey,
            customerEmail,
            customerPhone,
            description,
            merchantEmail,
            dueDate,
            taxation,
            items,
            cashboxRequired,
            einvoiceAmmount,
            paymentToInfoValue,
        } = this.state;
        post(URL.einvoiceAdd, {
            paymentType,
            paymentTo: paymentToInfoValue,
            ...(paymentType.includes('SBP') && { qrcType }),
            ecommKey,
            ...(this.handleIsNotStaticOrByCard() && {
                customerEmail,
                customerPhone,
            }),
            description: description.replaceAll('\n', ' '),
            merchantEmail,
            dueDate: dueDate.format('YYYY-MM-DD'),
            taxation,
            amount: einvoiceAmmount ? einvoiceAmmount : undefined,
            items: cashboxRequired
                ? items.map((item) => {
                      delete item.itemId;
                      delete item.edit;
                      return item;
                  })
                : undefined,
        })
            .then(() => {
                this.setState({
                    ...this.state,
                    ...this.einvoice,
                });
                this.props.dispatch(setNeedsUpdate(this.props.pageTitle, true));
                this.props.dispatch(
                    createNotification(
                        {
                            title: 'Создание счёта',
                            message: 'Счёт успешно создан',
                        },
                        'success'
                    )
                );
            })
            .catch((r) => log(r));
        this.setState({
            open_add_einvoice: false,
        });
    };

    defaultPaymentKey = (ecommKeys) => {
        const allWithCardSbp = ecommKeys.filter(({ paymentType }) => {
            return (
                paymentType.length === 2 &&
                paymentType.includes(CARD) &&
                paymentType.includes(SBP)
            );
        });
        if (allWithCardSbp.length) {
            return allWithCardSbp[0];
        }
        const allWithCard = ecommKeys.filter(({ paymentType }) => {
            return paymentType.length === 1 && paymentType.includes(CARD);
        });

        if (allWithCard.length) {
            return allWithCard[0];
        }
    };

    getEinvoiceInfo = () => {
        const request = !isBankApp
            ? get(URL.einvoiceData)
            : post(URL.einvoiceData, { companyId: [this.props.companyId] });

        request
            .then((r) => {
                const {
                    ecommKeys,
                    paymentMethod,
                    paymentObject,
                    tax,
                    taxation,
                } = r.data.payload;

                const defaultPaymentKey = this.defaultPaymentKey(ecommKeys);
                const defaultEcommKey = defaultPaymentKey
                    ? defaultPaymentKey
                    : ecommKeys?.length
                    ? ecommKeys[0]
                    : {
                          isNeedPaymentTo: false,
                          paymentToInfo: 'Нет даннных о ключе',
                          key: 0,
                          merchantName: 'Нет созданных ключей',
                          cashboxRequired: false,
                          paymentType: [],
                      };

                this.setState({
                    isNeedPaymentTo: defaultEcommKey.isNeedPaymentTo,
                    paymentToInfo: defaultEcommKey.paymentToInfo,
                    ecommKey: defaultEcommKey.key,
                    cashboxRequired: defaultEcommKey.cashboxRequired,
                    paymentMethod: paymentMethod[0].value,
                    paymentObject: paymentObject[0].value,
                    tax: tax[0].value,
                    taxation: taxation[0].value,

                    ecommKeyIdsOptions: ecommKeys,
                    paymentMethodOptions: paymentMethod,
                    paymentObjectOptions: paymentObject,
                    taxOptions: tax,
                    taxationOptions: taxation,
                    paymentType: !isBankApp
                        ? defaultEcommKey.paymentType
                        : this.state.paymentType,
                });
            })
            .catch((r) => log(r));
    };

    itemEmptyCheck = () => {
        const {
            name,
            price,
            quantity,
            amount,
            paymentMethod,
            paymentObject,
            tax,
        } = this.state;

        return [
            name,
            price,
            quantity,
            amount,
            paymentMethod,
            paymentObject,
            tax,
        ].includes('');
    };

    itemOnEditEmptyCheck = (itemId) => {
        const items = this.state.items;
        const item = items[items.findIndex((item) => item.itemId === itemId)];
        const {
            name,
            price,
            quantity,
            amount,
            paymentMethod,
            paymentObject,
            tax,
        } = item;
        return [
            name,
            price,
            quantity,
            amount,
            paymentMethod,
            paymentObject,
            tax,
        ].includes('');
    };

    einvoiceItemRemove = (itemId) =>
        this.setState({
            items: this.state.items.filter((item) => item.itemId !== itemId),
        });

    einvoiceItemEdit = (itemId) => {
        const items = this.state.items;
        if (
            !this.state.open_add_einvoice_item &&
            items.every((item) => item.edit !== true)
        ) {
            items[
                items.findIndex((item) => item.itemId === itemId)
            ].edit = true;
            this.setState({ items });
        }
    };

    einvoiceItemSave = (itemId) => {
        const items = this.state.items;
        items[items.findIndex((item) => item.itemId === itemId)].edit = false;
        this.setState({ items });
    };

    einvoiceEmptyCheck = () => {
        const {
            ecommKey,
            customerEmail,
            customerPhone,
            description,
            merchantEmail,
            dueDate,
            taxation,
            items,
            einvoiceAmmount,
            cashboxRequired,
        } = this.state;
        return (
            [
                ecommKey,
                ...(this.state.qrcType !== '01' && !isBankApp
                    ? [customerEmail ? customerEmail : customerPhone]
                    : []),
                ...(this.state.qrcType === '01' || isBankApp
                    ? []
                    : [
                          description,
                          cashboxRequired ? merchantEmail : einvoiceAmmount,
                      ]),
                dueDate,
                taxation,
            ].includes('') ||
            (cashboxRequired && items.length === 0)
        );
    };

    handleTerminalChange = (value) => {
        const ecommKeyIdsOption = this.state.ecommKeyIdsOptions.find(
            (ecommKey) => ecommKey.key === Number(value)
        );

        this.setState({
            ecommKey: Number(value),
            isNeedPaymentTo: ecommKeyIdsOption.isNeedPaymentTo,
            paymentToInfo: ecommKeyIdsOption.paymentToInfo,
            cashboxRequired:
                this.state.qrcType === '01' &&
                this.state.paymentType.includes('SBP')
                    ? false
                    : ecommKeyIdsOption.cashboxRequired,
            //TODO: следующее значение скрыто пока не пройдет тестирование DPS-643
            // paymentType: ecommKeyIdsOption.paymentType,
        });
    };

    handlePaymentTypeChange = (value) => {
        this.setState(
            {
                paymentType: value.split(','),
                ...(value.split(',').includes('SBP') && { qrcType: '02' }),
            },
            () => {
                this.setState(
                    {
                        ecommKey: this.filteredEcommKeys()[0]?.key,
                    },
                    () => {
                        this.setState({
                            cashboxRequired: this.state.ecommKeyIdsOptions.find(
                                (ecommKey) =>
                                    ecommKey.key === Number(this.state.ecommKey)
                            ).cashboxRequired,
                        });
                    }
                );
            }
        );
    };

    handleTypeChange = (value) => {
        const { ecommKey } = this.state;
        const cReqOfSelectedEcommkey = this.filteredEcommKeys().find(
            ({ key }) => key === Number(ecommKey)
        );
        this.setState({
            qrcType: value,
            cashboxRequired:
                value === '01'
                    ? false
                    : cReqOfSelectedEcommkey
                    ? cReqOfSelectedEcommkey.cashboxRequired
                    : this.filteredEcommKeys()[0]?.cashboxRequired,
        });
    };

    handleFieldChangeSelect = (value, name, itemId = undefined) => {
        if (itemId) {
            const items = this.state.items;
            // поиск нужного свойства по name в редактируемом элементе(товаре) массива this.state.items
            items[items.findIndex((item) => item.itemId === itemId)][name] =
                value;
            this.setState({ items });
        } else {
            this.setState({ [name]: value });
        }
    };

    handleFieldChangeInput = ({ target }, itemId = undefined) => {
        if (itemId) {
            const items = this.state.items;
            items[items.findIndex((item) => item.itemId === itemId)][
                target.name
            ] = target.value;
            this.setState({ items });
        } else {
            this.setState({ [target.name]: target.value });
        }
    };

    handlePhoneChange = ({ target }, itemId = undefined) => {
        const sanitizedPhone = maskPhone(target.value);

        if (itemId) {
            const items = this.state.items;
            items[items.findIndex((item) => item.itemId === itemId)][
                target.name
            ] = sanitizedPhone;
            this.setState({ items });
        } else {
            this.setState({
                [target.name]: sanitizedPhone,
            });
        }
    };

    handleQuantityChange = ({ target }, itemId = undefined) => {
        const sanitizedQuantity = target.value
            .replace(/[^0-9.]/g, '')
            .replace(/([.][0-9]{4})([0-9.]*)/g, '')
            .replace(/[.]{2}/g, '');

        if (itemId) {
            const items = this.state.items;
            const item =
                items[items.findIndex((item) => item.itemId === itemId)];

            items[items.findIndex((item) => item.itemId === itemId)][
                target.name
            ] = sanitizedQuantity;
            this.setState({ items }, () => {
                items[
                    items.findIndex((item) => item.itemId === itemId)
                ].amount = ![item.price, item.quantity].includes('')
                    ? +(Number(item.price) * Number(item.quantity)).toFixed(2)
                    : '';
                this.setState({ items });
            });
        } else {
            this.setState(
                {
                    [target.name]: sanitizedQuantity,
                },
                () => {
                    this.setState({
                        amount: ![
                            this.state.price,
                            this.state.quantity,
                        ].includes('')
                            ? +(
                                  Number(this.state.price) *
                                  Number(this.state.quantity)
                              ).toFixed(2)
                            : '',
                    });
                }
            );
        }
    };

    handlePriceChange = ({ target }, itemId = undefined) => {
        const sanitizedPrice = target.value
            .replace(/[^0-9.]/g, '')
            .replace(/([.][0-9]{3})([0-9.]*)/g, '')
            .replace(/[.]{2}/g, '');

        if (itemId) {
            const items = this.state.items;
            const item =
                items[items.findIndex((item) => item.itemId === itemId)];
            items[items.findIndex((item) => item.itemId === itemId)][
                target.name
            ] = sanitizedPrice;
            this.setState({ items }, () => {
                items[
                    items.findIndex((item) => item.itemId === itemId)
                ].amount = ![item.price, item.quantity].includes('')
                    ? +(Number(item.price) * Number(item.quantity)).toFixed(2)
                    : '';
                this.setState({ items });
            });
        } else {
            this.setState(
                {
                    [target.name]: sanitizedPrice,
                },
                () => {
                    this.setState({
                        amount: ![
                            this.state.price,
                            this.state.quantity,
                        ].includes('')
                            ? +(
                                  Number(this.state.price) *
                                  Number(this.state.quantity)
                              ).toFixed(2)
                            : '',
                    });
                }
            );
        }
    };

    handleDescrChange = ({ target }) => {
        target.value.length <= 125 &&
            this.setState({ [target.name]: target.value });
    };

    handleEmailChange = ({ target }, itemId = undefined) => {
        const sanitizedEmail = target.value
            .replace(/[^A-Z0-9._%+-]+@\S+\.\S+/gi, '')
            .replace(/[@]{2}/g, '@')
            .replace(/[.]{3}/g, '.');
        if (itemId) {
            const items = this.state.items;
            items[items.findIndex((item) => item.itemId === itemId)][
                target.name
            ] = sanitizedEmail;
            this.setState({ items });
        } else {
            this.setState({ [target.name]: sanitizedEmail });
        }
    };

    handlePaymentToInfo = ({ target }) => {
        this.setState({
            paymentToInfoValue: target.value,
        });
    };

    handleAmmountChange = ({ target }, itemId = undefined) => {
        const sanitizedAmount = target.value
            .replace(/[^0-9.]/g, '')
            .replace(/([.][0-9]{3})([0-9.]*)/g, '')
            .replace(/[.]{2}/g, '');

        if (itemId) {
            const items = this.state.items;
            items[items.findIndex((item) => item.itemId === itemId)][
                target.name
            ] = sanitizedAmount;
            this.setState({ items });
        } else {
            this.setState({
                ...this.state,
                [target.name]: sanitizedAmount,
            });
        }
    };

    handleDueDate = (data) => {
        this.setState({
            dueDate: data,
        });
    };

    handleIsNotStaticOrByCard = () => {
        const { paymentType, qrcType } = this.state;
        return (
            (paymentType && paymentType.includes('CARD')) || qrcType !== '01'
        );
    };

    saveEinvoiceItem = () => {
        this.setState(
            {
                items: [
                    ...this.state.items,
                    {
                        amount: this.state.amount,
                        measurementUnit: this.state.measurementUnit,
                        name: this.state.name,
                        paymentMethod: this.state.paymentMethod,
                        paymentObject: this.state.paymentObject,
                        price: this.state.price,
                        quantity: this.state.quantity,
                        tax: this.state.tax,
                        edit: false,
                        itemId: Math.random().toString(36).slice(2),
                    },
                ],
                open_add_einvoice_item: !this.state.open_add_einvoice_item,
            },
            () => {
                this.setState({
                    ...this.state,
                    ...this.einvoiceItem,
                    paymentMethod: this.state.paymentMethodOptions[0].value,
                    paymentObject: this.state.paymentObjectOptions[0].value,
                    tax: this.state.taxOptions[0].value,
                });
            }
        );
    };

    addEinvoiceItem = () => {
        this.state.items.every((item) => item.edit !== true) &&
            this.setState({
                open_add_einvoice_item: !this.state.open_add_einvoice_item,
            });
    };

    filteredEcommKeys = () => {
        const { ecommKeyIdsOptions } = this.state;

        return ecommKeyIdsOptions.filter(({ paymentType }) => {
            if (this.state.paymentType.length > 1) {
                const allNoMir = this.state.paymentType.filter(
                    (type) => type !== 'MIR_PAY'
                );
                return (
                    paymentType.length > 1 &&
                    allNoMir.every((noMirType) =>
                        paymentType.includes(noMirType)
                    )
                );
            } else {
                return paymentType.some(
                    (r) => this.state.paymentType.indexOf(r) >= 0
                );
            }
        });
    };

    getEcommKeyIdsOptions = () => {
        const options = [];

        const filteredEcommKeyIdsOptions = () => {
            const { ecommKeyIdsOptions } = this.state;

            return ecommKeyIdsOptions.filter(({ paymentType }) => {
                if (this.state.paymentType.length && paymentType.length) {
                    return this.state.paymentType.every((r) =>
                        paymentType.includes(r)
                    );
                }
                return false;
            });
        };

        filteredEcommKeyIdsOptions().forEach(({ merchantName, key }) => {
            options.push({
                value: String(key),
                content: `${key} ${merchantName}`,
            });
        });

        return options;
    };

    render() {
        const {
            open_add_einvoice_item,
            cashboxRequired,
            einvoiceAmmount,

            paymentType,
            qrcType,
            ecommKey,
            paymentToInfo,
            isNeedPaymentTo,
            paymentToInfoValue,
            customerEmail,
            customerPhone,
            description,
            merchantEmail,
            taxation,
            dueDate,

            name,
            price,
            quantity,
            amount,
            paymentMethod,
            paymentObject,
            tax,
            measurementUnit,

            ecommKeyIdsOptions,
            paymentMethodOptions,
            paymentObjectOptions,
            taxOptions,
            taxationOptions,
        } = this.state;

        const onlyOneEcommKey = ecommKeyIdsOptions.length < 2;
        const companyRolesHasSBP = isPermit(Roles.OPERATION_SBP);
        const companyRolesHasECOM = isPermit(Roles.ECOMM);
        const companyRolesHasEINVOICE = isPermit(Roles.EINVOICE);
        const EcommkeysSbporCardOnly = ecommKeyIdsOptions.every(
            ({ paymentType }) =>
                paymentType.length < 2 &&
                paymentType[0].length > 1 &&
                paymentType[0] === ecommKeyIdsOptions[0].paymentType[0]
        );
        const EcommKeysHasSBPandCard = ecommKeyIdsOptions.some(
            ({ paymentType }) => paymentType.length > 1
        );
        const EcommKeysHasCard = ecommKeyIdsOptions.some(({ paymentType }) =>
            paymentType.includes('CARD')
        );
        /**
         * Проверка на присутствие ролей Электронная коммерция и
         * Электронные счета и отсутствие роли Операции СБП
         */
        const isCompanyRolesHasNoSBP =
            !companyRolesHasSBP &&
            companyRolesHasECOM &&
            companyRolesHasEINVOICE &&
            EcommKeysHasCard;
        /**
         * Значение отображения/скрытия поля Способа оплаты
         */
        const isShowPaymentMethod =
            (companyRolesHasSBP && !EcommkeysSbporCardOnly) ||
            isCompanyRolesHasNoSBP ||
            isBankApp;
        const currentPaymentTypeHasSBP =
            paymentType && paymentType.includes('SBP');
        const paymentTypeHasSBP = currentPaymentTypeHasSBP
            ? true
            : onlyOneEcommKey
            ? ecommKeyIdsOptions.some(({ paymentType }) => {
                  const ifOneSbp = paymentType[0] === 'SBP';
                  ifOneSbp &&
                      this.setState({
                          paymentType: ['SBP'],
                      });
                  return ifOneSbp;
              })
            : false;
        const paymentTypeIsNotEmpty =
            ecommKeyIdsOptions.filter(({ paymentType }) =>
                this.state.paymentType.length > 1
                    ? paymentType.length > 1
                    : paymentType.some(
                          (r) => this.state.paymentType.indexOf(r) >= 0
                      )
            ).length === 0;

        const defaultValuePaymentType = isBankApp
            ? String(getBankPaymentTypeOptions()[0].value)
            : paymentType.includes('MIR_PAY')
            ? String(paymentType.filter((type) => type !== 'MIR_PAY'))
            : String(paymentType);

        return (
            <>
                <Paginator name={this.props.pageTitle}>
                    <OverflowContainer
                        title={this.props.title}
                        icon={
                            <AddIcon
                                title="Добавить электронный счёт"
                                onClick={() => {
                                    this.toggle_add_einvoice();
                                    this.getEinvoiceInfo();
                                }}
                            />
                        }
                    >
                        <AwareTable
                            newStatuses={this.props.newStatuses ?? true}
                            tableName={this.props.pageTitle}
                            token={this.props.cancelationToken}
                            type="editOption"
                            url={URL.einvoiceAll}
                            link={URL.einvoice + '/'}
                            isStoreTable
                        />
                    </OverflowContainer>
                </Paginator>

                {/*einvoice modals*/}

                <Modal
                    isConfirm
                    header="Вы действительно хотите выйти?"
                    closeButton="Отмена"
                    actionButton="Выйти"
                    isOpen={this.state.confirm_add_einvoice}
                    onClose={this.toggle_confirm_add_einvoice}
                    onAction={this.toggle_add_einvoice}
                >
                    Все введенные данные будут потеряны.
                </Modal>
                <Modal
                    isConfirmToClose
                    header="Добавить счет"
                    closeButton="Отмена"
                    actionButton="Сохранить"
                    isOpen={this.state.open_add_einvoice}
                    onClose={this.toggle_confirm_add_einvoice}
                    onAction={this.createEinvoice}
                    disabledActionButton={[
                        this.einvoiceEmptyCheck(),
                        isBankApp ? false : paymentTypeIsNotEmpty,
                        !this.state.ecommKeyIdsOptions?.length,
                    ].includes(true)}
                >
                    <p className={styles.errorKeys}>
                        {!this.state.ecommKeyIdsOptions?.length
                            ? 'Для добавлениея счета необходимо создать ключ'
                            : ''}
                    </p>
                    {isShowPaymentMethod && (
                        <ModalField label="Способы оплаты">
                            <NewSelect
                                disabled={isBankApp}
                                idScrollElement="modal-element-scroll"
                                value={defaultValuePaymentType}
                                options={getOptionsForSelect(
                                    isBankApp
                                        ? getBankPaymentTypeOptions()
                                        : isCompanyRolesHasNoSBP
                                        ? PaymentCardTypeOptions(
                                              this.state.ecommKeyIdsOptions
                                          )
                                        : PaymentTypeOptions(
                                              EcommKeysHasSBPandCard,
                                              this.state.ecommKeyIdsOptions
                                          ),
                                    'value',
                                    'text'
                                )}
                                onChange={this.handlePaymentTypeChange}
                            />
                        </ModalField>
                    )}
                    {companyRolesHasSBP && paymentTypeHasSBP && (
                        <ModalField label="Тип QR">
                            <NewSelect
                                idScrollElement="modal-element-scroll"
                                value={qrcType}
                                options={getOptionsForSelect(
                                    isBankApp
                                        ? BankQRCodeTypeOptions
                                        : QRCodeTypeOptions,
                                    'value',
                                    'text'
                                )}
                                onChange={this.handleTypeChange}
                                disabled={
                                    isBankApp ||
                                    (paymentType?.length > 1 &&
                                        paymentType.includes('SBP'))
                                }
                            />
                        </ModalField>
                    )}
                    <ModalField label="Электронный ключ">
                        <NewSelect
                            idScrollElement="modal-element-scroll"
                            value={String(ecommKey)}
                            options={this.getEcommKeyIdsOptions()}
                            onChange={this.handleTerminalChange}
                            disabled={onlyOneEcommKey}
                        />
                    </ModalField>
                    {(this.handleIsNotStaticOrByCard() || isBankApp) && (
                        <>
                            <ModalField label="E-mail покупателя">
                                <Input
                                    background
                                    type="email"
                                    name="customerEmail"
                                    value={customerEmail}
                                    onChange={this.handleEmailChange}
                                />
                            </ModalField>
                            <ModalField label="Телефон покупателя">
                                <Input
                                    background
                                    name="customerPhone"
                                    value={customerPhone}
                                    onChange={this.handlePhoneChange}
                                />
                            </ModalField>
                        </>
                    )}
                    <ModalField
                        label="Описание заказа"
                        alignField="align-start"
                    >
                        <Textarea
                            rows={3}
                            name="description"
                            value={description}
                            onChange={this.handleDescrChange}
                        />
                    </ModalField>
                    {isNeedPaymentTo && (
                        <ModalField label={paymentToInfo}>
                            <Input
                                background
                                name="paymentToInfoValue"
                                value={paymentToInfoValue}
                                onChange={this.handlePaymentToInfo}
                            />
                        </ModalField>
                    )}
                    {cashboxRequired ? (
                        <ModalField label="E-mail отправителя чека">
                            <Input
                                background
                                name="merchantEmail"
                                value={merchantEmail}
                                onChange={this.handleEmailChange}
                            />
                        </ModalField>
                    ) : (
                        <ModalField label="Сумма, руб">
                            <Input
                                background
                                name="einvoiceAmmount"
                                value={einvoiceAmmount}
                                onChange={this.handleAmmountChange}
                            />
                        </ModalField>
                    )}
                    <ModalField
                        label="Дата истечения срока оплаты"
                        className={styles.datepicker}
                    >
                        <Datepicker
                            withPortal={isMobile}
                            selected={dueDate}
                            onChange={this.handleDueDate}
                        />
                    </ModalField>
                    {cashboxRequired && (
                        <ModalField label="Система налогообложения">
                            <NewSelect
                                idScrollElement="modal-element-scroll"
                                value={taxation}
                                options={getOptionsForSelect(
                                    taxationOptions,
                                    'value',
                                    'key'
                                )}
                                onChange={(value) =>
                                    this.handleFieldChangeSelect(
                                        value,
                                        'taxation'
                                    )
                                }
                            />
                        </ModalField>
                    )}

                    {cashboxRequired && open_add_einvoice_item && (
                        <>
                            <ModalField
                                boldLabel
                                label="Товар"
                                className={styles.title}
                            />
                            <ModalField label="Наименование">
                                <Input
                                    background
                                    name="name"
                                    value={name}
                                    onChange={this.handleFieldChangeInput}
                                />
                            </ModalField>
                            <ModalField label="Цена">
                                <Input
                                    background
                                    name="price"
                                    value={price}
                                    onChange={this.handlePriceChange}
                                />
                            </ModalField>
                            <ModalField label="Количество">
                                <Input
                                    background
                                    name="quantity"
                                    value={quantity}
                                    onChange={this.handleQuantityChange}
                                />
                            </ModalField>
                            <ModalField label="Единица измерения">
                                <Input
                                    background
                                    name="measurementUnit"
                                    value={measurementUnit}
                                    onChange={this.handleFieldChangeInput}
                                />
                            </ModalField>
                            <ModalField label="Сумма, руб">
                                <Input
                                    background
                                    name="amount"
                                    value={amount}
                                    onChange={this.handleAmmountChange}
                                />
                            </ModalField>
                            <ModalField label="Ставка налога">
                                <NewSelect
                                    idScrollElement="modal-element-scroll"
                                    value={tax}
                                    options={getOptionsForSelect(
                                        taxOptions,
                                        'value',
                                        'key'
                                    )}
                                    onChange={(value) =>
                                        this.handleFieldChangeSelect(
                                            value,
                                            'tax'
                                        )
                                    }
                                />
                            </ModalField>
                            <ModalField label="Признак способа расчета">
                                <NewSelect
                                    idScrollElement="modal-element-scroll"
                                    value={paymentMethod}
                                    options={getOptionsForSelect(
                                        paymentMethodOptions,
                                        'value',
                                        'key'
                                    )}
                                    onChange={(value) =>
                                        this.handleFieldChangeSelect(
                                            value,
                                            'paymentMethod'
                                        )
                                    }
                                />
                            </ModalField>
                            <ModalField label="Признак предмета расчета">
                                <NewSelect
                                    idScrollElement="modal-element-scroll"
                                    value={paymentObject}
                                    options={getOptionsForSelect(
                                        paymentObjectOptions,
                                        'value',
                                        'key'
                                    )}
                                    onChange={(value) =>
                                        this.handleFieldChangeSelect(
                                            value,
                                            'paymentObject'
                                        )
                                    }
                                />
                            </ModalField>
                            <ModalField justifyInput="right">
                                <Button
                                    size="xs"
                                    onClick={this.saveEinvoiceItem}
                                    disabled={this.itemEmptyCheck()}
                                >
                                    Сохранить товар
                                </Button>
                            </ModalField>
                        </>
                    )}
                    {cashboxRequired && (
                        <>
                            <ModalField
                                boldLabel
                                justifyInput="right"
                                label="Товары, работы, услуги"
                                className={styles.title}
                            >
                                {!open_add_einvoice_item && (
                                    <Button
                                        size="xs"
                                        onClick={this.addEinvoiceItem}
                                    >
                                        Добавить товар
                                    </Button>
                                )}
                            </ModalField>
                            {this.state.items.map(
                                ({
                                    itemId,
                                    name,
                                    edit,
                                    price,
                                    quantity,
                                    amount,
                                    tax,
                                    paymentMethod,
                                    paymentObject,
                                    measurementUnit,
                                }) =>
                                    edit ? (
                                        <div key={itemId}>
                                            <ModalField label="Наименование">
                                                <Input
                                                    background
                                                    name="name"
                                                    value={name}
                                                    onChange={(e) =>
                                                        this.handleFieldChangeInput(
                                                            e,
                                                            itemId
                                                        )
                                                    }
                                                />
                                            </ModalField>
                                            <ModalField label="Цена">
                                                <Input
                                                    background
                                                    name="price"
                                                    value={price}
                                                    onChange={(e) =>
                                                        this.handlePriceChange(
                                                            e,
                                                            itemId
                                                        )
                                                    }
                                                />
                                            </ModalField>
                                            <ModalField label="Количество">
                                                <Input
                                                    background
                                                    name="quantity"
                                                    value={quantity}
                                                    onChange={(e) =>
                                                        this.handleQuantityChange(
                                                            e,
                                                            itemId
                                                        )
                                                    }
                                                />
                                            </ModalField>
                                            <ModalField label="Единица измерения">
                                                <Input
                                                    background
                                                    name="measurementUnit"
                                                    value={measurementUnit}
                                                    onChange={(e) =>
                                                        this.handleFieldChangeInput(
                                                            e,
                                                            itemId
                                                        )
                                                    }
                                                />
                                            </ModalField>
                                            <ModalField label="Сумма, руб">
                                                <Input
                                                    background
                                                    name="amount"
                                                    value={amount}
                                                    onChange={(e) =>
                                                        this.handleAmmountChange(
                                                            e,
                                                            itemId
                                                        )
                                                    }
                                                />
                                            </ModalField>
                                            <ModalField label="Ставка налога">
                                                <NewSelect
                                                    idScrollElement="modal-element-scroll"
                                                    value={tax}
                                                    options={getOptionsForSelect(
                                                        taxOptions,
                                                        'value',
                                                        'key'
                                                    )}
                                                    onChange={(value) =>
                                                        this.handleFieldChangeSelect(
                                                            value,
                                                            'tax',
                                                            itemId
                                                        )
                                                    }
                                                />
                                            </ModalField>
                                            <ModalField label="Признак способа расчета">
                                                <NewSelect
                                                    idScrollElement="modal-element-scroll"
                                                    value={paymentMethod}
                                                    options={getOptionsForSelect(
                                                        paymentMethodOptions,
                                                        'value',
                                                        'key'
                                                    )}
                                                    onChange={(value) =>
                                                        this.handleFieldChangeSelect(
                                                            value,
                                                            'paymentMethod',
                                                            itemId
                                                        )
                                                    }
                                                />
                                            </ModalField>
                                            <ModalField label="Признак предмета расчета">
                                                <NewSelect
                                                    idScrollElement="modal-element-scroll"
                                                    value={paymentObject}
                                                    options={getOptionsForSelect(
                                                        paymentObjectOptions,
                                                        'value',
                                                        'key'
                                                    )}
                                                    onChange={(value) =>
                                                        this.handleFieldChangeSelect(
                                                            value,
                                                            'paymentObject',
                                                            itemId
                                                        )
                                                    }
                                                />
                                            </ModalField>
                                            <ModalField justifyInput="right">
                                                <Button
                                                    size="xs"
                                                    onClick={() =>
                                                        this.einvoiceItemSave(
                                                            itemId
                                                        )
                                                    }
                                                    disabled={this.itemOnEditEmptyCheck(
                                                        itemId
                                                    )}
                                                >
                                                    Сохранить товар
                                                </Button>
                                            </ModalField>
                                        </div>
                                    ) : (
                                        <ModalField
                                            key={itemId}
                                            label={name}
                                            justifyInput="right"
                                            className={styles['einvoice-item']}
                                        >
                                            <Button
                                                size="xs"
                                                onClick={() => {
                                                    this.einvoiceItemEdit(
                                                        itemId
                                                    );
                                                }}
                                            >
                                                Изменить
                                            </Button>
                                            <Button
                                                size="xs"
                                                onClick={() =>
                                                    this.einvoiceItemRemove(
                                                        itemId
                                                    )
                                                }
                                            >
                                                Удалить
                                            </Button>
                                        </ModalField>
                                    )
                            )}
                        </>
                    )}
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        roles: state.auth.companyRoles,
        companyRoles: state.auth.companyRoles,
        cancelationToken: state.requestCancelation.token,
    };
}

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(EinvoiceTable);
