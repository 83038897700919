import React from 'react'

interface Props {
    changeDescription: string;
}

export const ChangeRow = ({changeDescription}: Props) => {
  return (
    <div>{changeDescription}</div>
  )
}
