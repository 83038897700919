import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReplaySubject } from 'rxjs';

import { URL } from 'src/app/config';

import AwareTable from 'src/components/AwareTable';

import { localLoad, sendToSubject } from 'src/shared/lib';
import { OverflowContainer, Paginator } from 'src/shared';

import './platform-cashbox-error-page.scss';

const platformCashboxErrorTableName = 'Platform-Cashbox-Error';

class PlatformCashBoxErrorsPage extends Component {
    filterSubject = new ReplaySubject(1);

    constructor(props) {
        super(props);
        this.send = sendToSubject.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.send({
            offset: localLoad('platform-cashbox-offset', 0),
            size: localLoad('platform-cashbox-range', 10),
        });
    };

    render() {
        return (
            <div className="platform-cashbox-page">
                <Paginator name={platformCashboxErrorTableName}>
                    <OverflowContainer>
                        <AwareTable
                            tableName={platformCashboxErrorTableName}
                            url={URL.platformCashBoxErrorUrl}
                            link={URL.cashBoxReceipt + '/'}
                            isStoreTable
                        />
                    </OverflowContainer>
                </Paginator>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        fetch: state.fetch.fetch,
    };
}

export default withRouter(connect(mapStateToProps)(PlatformCashBoxErrorsPage));
