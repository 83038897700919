import React from 'react';

export const SdmLogo = (
    <svg
        width="38"
        height="32"
        viewBox="0 0 38 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0 24.6233L5.24272 28.1453C8.31188 27.5767 21.3935 24.8698 21.3935 20.2611C21.3935 16.8045 17.5596 14.5052 14.1735 12.4775C11.4716 10.8574 9.14206 9.45867 9.14206 7.72786C9.14206 5.86121 10.7672 4.32664 13.8414 3.2952C14.527 3.06787 15.2239 2.87642 15.9294 2.72162C13.1219 3.10401 7.87917 4.28135 7.87917 7.7983C7.87917 12.7341 18.6866 14.6008 18.6866 19.7127C18.6816 23.7227 0 24.6233 0 24.6233Z"
            fill="#002E5A"
        />
        <path
            d="M10.8427 7.49133C10.8427 5.16682 13.4037 3.81337 15.4313 3.02847C12.7697 3.65237 9.44394 5.04103 9.44394 7.73283C9.44394 9.28754 11.7081 10.646 14.3345 12.2158C17.791 14.2887 21.7055 16.6334 21.7055 20.2661C21.7055 22.5755 18.8225 24.6736 13.1521 26.51C10.6756 27.2864 8.15551 27.9164 5.60498 28.3968L9.83639 31.2496C9.83639 31.2496 24.1507 27.0735 24.1507 21.2019C24.1507 11.602 10.8427 10.7718 10.8427 7.49133Z"
            fill="#002E5A"
        />
        <path
            d="M24.115 5.21275C24.115 5.87996 23.85 6.51984 23.3782 6.99162C22.9064 7.46341 22.2665 7.72845 21.5993 7.72845H20.8697V8.94102H21.6345C22.62 8.94102 23.5651 8.54955 24.2619 7.85272C24.9587 7.15589 25.3502 6.21079 25.3502 5.22533C25.3502 4.23987 24.9587 3.29477 24.2619 2.59794C23.5651 1.90112 22.62 1.50964 21.6345 1.50964H17.9062V10.4303H19.1188V6.79261V2.71718H21.6345C22.2921 2.72637 22.92 2.99271 23.3836 3.45917C23.8473 3.92563 24.1098 4.5551 24.115 5.21275Z"
            fill="#002E5A"
        />
        <path
            d="M21.635 0H16.4023V10.4301H17.6099V1.21257H21.635C22.6279 1.31224 23.5485 1.7772 24.2179 2.51724C24.8874 3.25728 25.2581 4.21963 25.2581 5.21756C25.2581 6.21549 24.8874 7.17784 24.2179 7.91788C23.5485 8.65793 22.6279 9.12289 21.635 9.22256H20.8702V10.4351H21.635C22.3202 10.4351 22.9987 10.3002 23.6317 10.038C24.2647 9.77575 24.8399 9.39143 25.3244 8.90694C25.8089 8.42244 26.1932 7.84726 26.4554 7.21424C26.7176 6.58121 26.8526 5.90274 26.8526 5.21756C26.8526 4.53238 26.7176 3.85391 26.4554 3.22089C26.1932 2.58786 25.8089 2.01268 25.3244 1.52819C24.8399 1.04369 24.2647 0.65937 23.6317 0.397163C22.9987 0.134956 22.3202 -1.021e-08 21.635 0V0Z"
            fill="#002E5A"
        />
        <path
            d="M30.8623 18.4398V11.9795C30.8714 11.3421 30.6352 10.7256 30.2026 10.2574C29.77 9.78914 29.174 9.50506 28.5378 9.46383C28.2138 9.45331 27.8909 9.50802 27.5884 9.6247C27.2859 9.74138 27.0099 9.91766 26.7768 10.1431C26.5382 10.3725 26.348 10.6474 26.2175 10.9516C26.087 11.2558 26.0189 11.5831 26.0171 11.9141V18.4549H27.2548V11.9141C27.2555 11.7493 27.2905 11.5864 27.3576 11.4358C27.4247 11.2852 27.5224 11.1502 27.6445 11.0395C27.7666 10.9287 27.9104 10.8446 28.0668 10.7925C28.2232 10.7404 28.3888 10.7214 28.5529 10.7368C28.85 10.7704 29.124 10.913 29.3219 11.137C29.5199 11.361 29.6277 11.6504 29.6246 11.9493V18.4902L30.8623 18.4398Z"
            fill="#002E5A"
        />
        <path
            d="M36.0049 18.4402V11.9799C36.006 11.3479 35.7653 10.7394 35.3322 10.2791C34.8991 9.81888 34.3063 9.54172 33.6754 9.50447C33.3498 9.4923 33.0251 9.54587 32.7206 9.66197C32.4162 9.77806 32.1383 9.95432 31.9035 10.1802C31.6687 10.4061 31.4818 10.677 31.354 10.9767C31.2262 11.2764 31.1601 11.5988 31.1597 11.9246V18.4654H32.4024V11.9246C32.3965 11.755 32.427 11.5861 32.4918 11.4293C32.5567 11.2725 32.6544 11.1315 32.7783 11.0156C32.9023 10.8998 33.0496 10.8118 33.2104 10.7577C33.3713 10.7036 33.5418 10.6846 33.7106 10.7019C34.0076 10.7356 34.2816 10.8782 34.4796 11.1021C34.6776 11.3261 34.7854 11.6156 34.7823 11.9145V18.4553L36.0049 18.4402Z"
            fill="#002E5A"
        />
        <path
            d="M33.6552 8.01012C32.6858 7.9893 31.7431 8.32804 31.0086 8.96106C30.274 8.32846 29.3314 7.98977 28.3621 8.01012C27.3201 8.04792 26.3334 8.48862 25.6098 9.23942C24.8862 9.99022 24.4822 10.9925 24.4829 12.0352V18.4402H25.7206V11.9849C25.7172 11.2714 25.9896 10.5841 26.4809 10.0666C26.9721 9.54907 27.6443 9.24132 28.3571 9.2076C28.8767 9.19328 29.3895 9.32776 29.8352 9.59518C30.2809 9.8626 30.6409 10.2518 30.8728 10.717L31.0187 11.0088L31.1646 10.717C31.4358 10.17 31.884 9.73077 32.4364 9.47063C32.9887 9.21048 33.6128 9.14471 34.2073 9.284C34.8017 9.42329 35.3316 9.75945 35.7109 10.2379C36.0902 10.7163 36.2966 11.309 36.2966 11.9195V18.4603H37.5344V12.0403C37.5364 10.9967 37.133 9.99311 36.4092 9.24123C35.6855 8.48935 34.6981 8.04795 33.6552 8.01012Z"
            fill="#002E5A"
        />
    </svg>
);
