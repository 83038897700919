import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import { MainPage } from 'src/pages';
import { URL } from 'src/app/config';
import { PublicFooterNavigation } from './public-footer-navigation';
import {
    ActivationForm,
    ChangePasswordForm,
    ForgotPasswordForm,
    LoginForm,
} from 'src/features';
import { PublicPageLayout } from 'src/shared/ui';

const publicRoutes = (
    <BrowserRouter basename={'/'}>
        <Switch>
            <Route
                path={URL.forgotPasswordPageUrl}
                render={() => (
                    <PublicPageLayout
                        footerNavigation={<PublicFooterNavigation />}
                    >
                        <ForgotPasswordForm />
                    </PublicPageLayout>
                )}
            />
            <Route
                path={URL.changePasswordUrl}
                render={() => (
                    <PublicPageLayout
                        footerNavigation={<PublicFooterNavigation />}
                    >
                        <ChangePasswordForm />
                    </PublicPageLayout>
                )}
            />
            <Route
                path={URL.userActivationUrl}
                render={() => (
                    <PublicPageLayout
                        footerNavigation={<PublicFooterNavigation />}
                    >
                        <ActivationForm />
                    </PublicPageLayout>
                )}
            />
            <Route
                path={URL.loginUrl}
                render={() => (
                    <PublicPageLayout
                        footerNavigation={<PublicFooterNavigation />}
                    >
                        <LoginForm />
                    </PublicPageLayout>
                )}
            />
            <Route path="/" component={MainPage} />
        </Switch>
    </BrowserRouter>
);

export { publicRoutes };
