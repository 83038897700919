import { isPasswordStrong } from 'src/features/new-password-input';
import { object, ref, string } from 'yup';

interface ChangePasswordFormFields {
    currentPassword: string;
    newPassword: string;
    repeatPassword: string;
}

type ChangePasswordRequest = Pick<
    ChangePasswordFormFields,
    'currentPassword' | 'newPassword'
> & { email: string };

const schema = object({
    currentPassword: string().required('Введите текущий пароль'),
    newPassword: string()
        .required('Введите новый пароль')
        .test(
            'securePassword',
            'Пароль не прошел проверку сложности',
            (value) => (value ? isPasswordStrong(value) : false)
        ),
    repeatPassword: string().oneOf(
        [ref('newPassword'), null],
        'Пароли должны совпадать'
    ),
});

export { schema };

export type { ChangePasswordFormFields, ChangePasswordRequest };
