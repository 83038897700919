import React, { useEffect } from 'react';
import { getAppTitle } from 'src/shared/lib';

export const withLogVersionApp = (component: () => React.ReactNode) => () => {
    useEffect(() => {
        const appTitle = getAppTitle();
        const pjson = require('../../../package.json');
        console.log(`app: ${appTitle}\nversion: ${pjson.version}`);
    }, []);

    return <>{component()}</>;
};
