export const errorMessage = {
    title: 'Ошибка выбора дат',
    message: 'Длительность выбранного периода не должна превышать 90 дней',
};

export const infoMessage = {
    title: 'Загрузка',
    message:
        'Запрос отправлен на сервер. Загрузка файла начнется автоматически.',
};
