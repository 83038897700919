import React from 'react';
import Notification from 'src/components/Notification';

export const withNotifications = (component: () => React.ReactNode) => () =>
    (
        <>
            {component()}
            <Notification />
        </>
    );
