import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {
    clearOperationDetails,
    getOperationDetail,
} from 'src/redux/actions/operation';
import { Roles } from 'src/app/config';
import { SendReceiptAboutOperation } from 'src/features/send-receipt-about-operation';
import { SendRefundRequest } from 'src/features/send-refund-request';
import { ShowsInfoAboutReceipt } from 'src/features/shows-info-about-receipt';
import { OperationDescription, OperationDetails } from 'src/entities/operation';
import { Button, Spin, Title, ArticleLayout, PointIcon } from 'src/shared/ui';
import { isPermit, getAppTitle } from 'src/shared/lib';
import './operation-details.scss';

class OperationDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rrn: null,
            modal: false,
            receiptModal: false,
            isAlreadyRevert: false,
        };
        this.toggle = this.toggle.bind(this);
        this.receiptModalToggle = this.receiptModalToggle.bind(this);
        this.permission = isPermit.bind(this);
        this.isMerchantApp = getAppTitle() === 'merchant';
        this.successCallback = this.successCallback.bind(this);
        this.setRrn = this.setRrn.bind(this);
        this.closeReceiptModal = this.closeReceiptModal.bind(this);
    }
    setRrn(rrn) {
        this.setState({ rrn });
    }

    closeReceiptModal() {
        this.setState({ rrn: null });
    }

    toggle() {
        this.setState({ modal: !this.state.modal });
    }

    receiptModalToggle() {
        this.setState({ receiptModal: !this.state.receiptModal });
    }

    componentDidMount() {
        this.props.getOperation(this.props.match.params.id);
    }

    componentWillUnmount() {
        this.props.clearOperation();
    }

    formatDate(date, type) {
        const format =
            type === 'long' ? 'DD MMMM YYYY HH:mm' : 'DD-MM-YYYY HH:mm';
        return date ? moment(date).format(format) : date;
    }

    formatCurrency(num) {
        return num.replace(',', ' ');
    }

    successCallback() {
        this.props.getOperation(this.props.match.params.id);
        this.setState({ isAlreadyRevert: true });
    }

    linkedOperationRow(item) {
        let cls =
            this.props.detail.id === item.id
                ? 'order-history-list-row current'
                : 'order-history-list-row';
        return (
            <div
                key={item.id}
                className={cls}
                onClick={() => {
                    if (item.id !== this.props.detail.id) {
                        this.props.history.push(`/operations/${item.id}`);
                        this.props.getOperation(item.id);
                    }
                }}
            >
                <div className="type-name">{item.operationTypeName}</div>
                <div className="operation-info">
                    <div>{this.formatCurrency(item.amount)}</div>
                    <div>{this.formatDate(item.dateTime, 'short')}</div>
                </div>
            </div>
        );
    }

    render() {
        let {
            id,
            responseCode,
            amount,
            operationTypeName,
            statusName,
            hasReceipt,
            rrn,
            terminalName,
            linkedOperations,
            reversalAmount,
            reversalDateTime,
            amountNumerically,
        } = this.props.detail;

        return amount ? (
            <div className="operation-details">
                <div className="operation-details__header">
                    <Title
                        icon={PointIcon}
                        className="header-add-info"
                        subtitle="торговая точка"
                    >
                        {terminalName}
                    </Title>
                </div>
                <ArticleLayout
                    className="operation-details__body"
                    bigColumn={
                        <OperationDescription data={this.props.detail}>
                            {linkedOperations &&
                                !isEmpty(linkedOperations) &&
                                linkedOperations.map((item) =>
                                    this.linkedOperationRow(item)
                                )}
                        </OperationDescription>
                    }
                    smallColumn={
                        <OperationDetails data={this.props.detail}>
                            {!this.state.isAlreadyRevert &&
                                !reversalDateTime &&
                                !responseCode &&
                                (operationTypeName === 'Покупка' ||
                                    operationTypeName ===
                                        'Предавторизация') && (
                                    <>
                                        {this.isMerchantApp &&
                                            this.permission(Roles.REFUND) && (
                                                <Button onClick={this.toggle}>
                                                    Заявка на возврат
                                                </Button>
                                            )}
                                        {this.isMerchantApp &&
                                            statusName === 'Взаиморасчет' && (
                                                <Button
                                                    outline
                                                    onClick={
                                                        this.receiptModalToggle
                                                    }
                                                >
                                                    Отправить квитанцию
                                                </Button>
                                            )}
                                        {hasReceipt && (
                                            <Button
                                                outline
                                                onClick={() => this.setRrn(rrn)}
                                            >
                                                Показать чек
                                            </Button>
                                        )}
                                    </>
                                )}
                            {reversalDateTime && (
                                <>
                                    <div>Заявка на возврат</div>
                                    <div>
                                        {this.formatDate(
                                            reversalDateTime,
                                            'long'
                                        )}
                                    </div>
                                    {reversalAmount && (
                                        <div>
                                            {this.formatCurrency(
                                                reversalAmount
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                        </OperationDetails>
                    }
                />
                <SendReceiptAboutOperation
                    isOpen={this.state.receiptModal}
                    setOpen={this.receiptModalToggle}
                />
                {this.state.modal && (
                    <SendRefundRequest
                        operationId={id}
                        fullAmount={amountNumerically}
                        closeModal={this.toggle}
                        successCallback={this.successCallback}
                    />
                )}
                {this.state.rrn && (
                    <ShowsInfoAboutReceipt
                        rrn={this.state.rrn}
                        closeModal={this.closeReceiptModal}
                    />
                )}
            </div>
        ) : (
            <Spin fetch={this.props.fetch} />
        );
    }
}

function mapStateToProps(state) {
    return {
        detail: state.operation.detail,
        auth: state.auth,
        fetch: state.fetch.fetch,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getOperation: (id) => dispatch(getOperationDetail(id)),
        clearOperation: () => dispatch(clearOperationDetails()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(OperationDetailPage);
