import React from 'react';

export const HelpIcon = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.2695 19.9492C15.5332 19.9492 19.8789 15.6035 19.8789 10.3301C19.8789 5.06641 15.5234 0.720703 10.2598 0.720703C4.99609 0.720703 0.650391 5.06641 0.650391 10.3301C0.650391 15.6035 4.99609 19.9492 10.2695 19.9492ZM10.2695 17.8887C6.08008 17.8887 2.7207 14.5195 2.7207 10.3301C2.7207 6.14062 6.07031 2.78125 10.2598 2.78125C14.4492 2.78125 17.8086 6.14062 17.8184 10.3301C17.8281 14.5195 14.4492 17.8887 10.2695 17.8887ZM10.2207 7.2832C10.9434 7.2832 11.5195 6.69727 11.5195 5.99414C11.5195 5.27148 10.9434 4.69531 10.2207 4.69531C9.51758 4.69531 8.93164 5.27148 8.93164 5.99414C8.93164 6.69727 9.51758 7.2832 10.2207 7.2832ZM8.62891 15.3301H12.3691C12.7988 15.3301 13.1406 15.0176 13.1406 14.5781C13.1406 14.1582 12.7988 13.8359 12.3691 13.8359H11.4023V9.62695C11.4023 9.05078 11.1094 8.66992 10.5625 8.66992H8.79492C8.36523 8.66992 8.02344 8.99219 8.02344 9.41211C8.02344 9.85156 8.36523 10.1641 8.79492 10.1641H9.71289V13.8359H8.62891C8.19922 13.8359 7.85742 14.1582 7.85742 14.5781C7.85742 15.0176 8.19922 15.3301 8.62891 15.3301Z"
            fill="white"
        />
    </svg>
);
