import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { DateRangePicker } from 'src/shared/ui';
import {
    localSave,
    getClassName,
    formatDateForInput,
    getInitialFilter,
    getAppTitle,
    LOCAL_STORAGE_TABLE_NAMES,
} from 'src/shared/lib';
import CompanySelect from 'src/components/CompanySelect';
import {
    setFilters,
    setCommonTableFilters,
    setPersistedTableState,
} from 'src/redux/actions/table';
import './settlement-filter.scss';
import { MerchantTerminalsSelector } from 'src/features/select-terminals';

class SettlementFilter extends Component {
    constructor(props) {
        super(props);
        this.isBankApp = getAppTitle() === 'bank';
        this.startDate = getClassName(
            LOCAL_STORAGE_TABLE_NAMES.common,
            'startDate'
        );
        this.endDate = getClassName(
            LOCAL_STORAGE_TABLE_NAMES.common,
            'endDate'
        );
        this.terminalIds = getClassName(
            LOCAL_STORAGE_TABLE_NAMES.common,
            'terminalIds'
        );
        this.companyIds = getClassName(
            this.props.pageTitle,
            CompanySelect.companyIds
        );
        this.state = {
            ...getInitialFilter(['companyIds'], this.props.pageTitle),
        };
        const commonSavedState = getInitialFilter(
            ['terminalIds'],
            LOCAL_STORAGE_TABLE_NAMES.common
        );

        this.props.dispatch(
            setFilters(this.props.pageTitle, {
                companyIds: [],
            })
        );

        this.props.dispatch(setCommonTableFilters(commonSavedState));
    }

    componentWillUnmount() {
        if (this.subscription) this.subscription.unsubscribe();
    }

    componentDidMount() {
        // TODO 724: moving table names to a constant might be good
        // check if clearing cached state on navigation click is better
        this.props.dispatch(
            setPersistedTableState(LOCAL_STORAGE_TABLE_NAMES.operations, undefined)
        );
    }

    saveFilter() {
        localSave(this.startDate, this.getStartDate());
        localSave(this.endDate, this.getEndDate());
        localSave(this.terminalIds, this.props.commonFilters?.terminalIds);
    }

    componentDidUpdate(previousProps) {
        if (
            this.getEndDate() !== previousProps.table?.endDate ||
            this.getStartDate() !== previousProps.table?.startDate ||
            _.isEqual(previousProps.table?.filters, this.props.table?.filters)
        ) {
            this.saveFilter();
        }
    }

    getStartDate() {
        return this.props.commonFilters?.startDate ?? this.state.startDate;
    }

    setStartDate(date) {
        this.props.dispatch(setCommonTableFilters({ startDate: date }));
    }

    getEndDate() {
        return this.props.commonFilters?.endDate ?? this.state.endDate;
    }

    setEndDate(date) {
        this.props.dispatch(setCommonTableFilters({ endDate: date }));
    }

    render() {
        return (
            <div className="settlement-filter">
                <MerchantTerminalsSelector
                    key={this.props.table?.filters?.terminalIds?.length}
                    tableName={LOCAL_STORAGE_TABLE_NAMES.common}
                    selected={this.props.commonFilters?.terminalIds ?? []}
                    onChange={(terminalIds) => {
                        this.props.dispatch(
                            setCommonTableFilters({ terminalIds })
                        );
                    }}
                    terminalsList={this.props.terminals}
                />
                <DateRangePicker
                    datepickers={[
                        {
                            selected: this.getStartDate(),
                            start: true,
                            onChange: (startDate) =>
                                this.setStartDate(startDate),
                        },
                        {
                            selected: this.getEndDate(),
                            end: true,
                            onChange: (endDate) => this.setEndDate(endDate),
                        },
                    ]}
                    formatter={formatDateForInput}
                    criticalScreenWidth={this.isBankApp ? undefined : 1130}
                    position={this.isBankApp ? 'left' : 'center'}
                    startDate={this.getStartDate()}
                    endDate={this.getEndDate()}
                    onReset={() => {
                        this.setStartDate(moment(new Date()).add(-7, 'days'));
                        this.setEndDate(moment(new Date()));
                    }}
                />
                {this.isBankApp && (
                    <CompanySelect
                        uid="SettlementFilter"
                        onChange={({ value }) => {
                            this.props.dispatch(
                                setFilters(this.props.pageTitle, {
                                    companyIds: value ? [value] : [],
                                })
                            );
                        }}
                    />
                )}
                <div className={'actions-wrapper'}>{this.props.actions}</div>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        table: state.table?.[props.pageTitle],
        commonFilters: state.table?.commonFilters,
        terminals: state.terminals.terminals,
    };
}

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(SettlementFilter);
