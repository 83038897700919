import React, { useState } from 'react';

import { SbpIdentificatorSelector } from './sbp-identificator-selector';
import { Organisation } from './sbp-identificator-selector/type';
import { FilterDropdown } from 'src/shared/ui/filter-dropdown';
import { SearchField } from 'src/shared';
import { ShowGroupMode, Switcher } from 'src/entities/selector-with-groups';

interface Props {
    identificationsList: Organisation[];
    selected: string[];
    onChange: (selected: string[]) => void;
    disabled?: boolean;
}

export const SelectSBPIdentificators = ({
    onChange,
    selected,
    identificationsList,
    disabled,
}: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState('');
    const [pickedIdentificators, setPickedIdentificators] =
        useState<string[]>(selected);
    const [showGroup, setShowGroup] = useState<ShowGroupMode>('all');

    const allIdentificationsIds = identificationsList.flatMap((organisation) =>
        organisation.identificationsSbp.map(
            (identification) => identification.merchantId
        )
    );

    const handleOnChange = (selected: string[]) =>
        setPickedIdentificators(selected);

    const handleRowClick = (sbpIdentificatorId: string) => {
        pickedIdentificators.includes(sbpIdentificatorId)
            ? setPickedIdentificators([
                  ...pickedIdentificators.filter(
                      (id) => sbpIdentificatorId !== id
                  ),
              ])
            : setPickedIdentificators([
                  ...pickedIdentificators,
                  sbpIdentificatorId,
              ]);
    };

    const selectAllidentificators = () => {
        setPickedIdentificators(allIdentificationsIds);
    };

    const resetSelectedIdentificators = () => {
        setPickedIdentificators([]);
    };

    const hardResetSelectedIdentificators = () => {
        onChange([]);
    };

    React.useEffect(() => {
        if (!isOpen) {
            setPickedIdentificators(selected);
        }
    }, [isOpen, selected]);

    return (
        <FilterDropdown
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onReset={hardResetSelectedIdentificators}
            filterName="Идентификаторы СБП"
            selectedCount={selected.length}
            onApplyClick={() => {
                onChange(pickedIdentificators);
                setIsOpen(false);
            }}
            disabled={disabled}
            firstRowControls={[
                <SearchField
                    value={query}
                    onChange={setQuery}
                    placeholder="Введите имя или ID идентификатора СБП"
                />,
            ]}
            secondRowControls={[
                <Switcher
                    mode={showGroup}
                    count={{
                        all: allIdentificationsIds.length,
                        selected: pickedIdentificators.length,
                    }}
                    handleChange={setShowGroup}
                />,
            ]}
            actions={[
                <button
                    onClick={selectAllidentificators}
                    disabled={
                        pickedIdentificators.length ===
                        allIdentificationsIds.length
                    }
                >
                    Выбрать все
                </button>,
                <button onClick={resetSelectedIdentificators}>
                    Сбросить значения
                </button>,
            ]}
        >
            <SbpIdentificatorSelector
                selected={pickedIdentificators}
                identificatorsList={identificationsList}
                onChange={handleOnChange}
                handleIdentificationClick={handleRowClick}
                query={query}
                selectedOnly={showGroup === 'selected'}
            />
        </FilterDropdown>
    );
};
