import React from 'react';
import { ShowGroupMode } from '../../config';

import styles from './switcher.module.scss';

interface Props {
    count: {
        all: number;
        selected: number;
    };
    mode: ShowGroupMode;
    handleChange: (mode: ShowGroupMode) => void;
}

export const Switcher = ({ count, handleChange, mode }: Props) => {
    const buttonClassName = (buttonMode: ShowGroupMode) => {
        return [
            styles['switcher-button'],
            mode === buttonMode ? styles['switcher-button--active'] : ' ',
        ].join(' ');
    };

    const handleClick = (mode: ShowGroupMode) => {
        handleChange(mode);
    };

    const renderButton = (mode: ShowGroupMode, key) => (
        <button
            className={buttonClassName(mode)}
            onClick={() => handleClick(mode)}
            key={key}
        >
            {mode === 'all' ? 'Все' : 'Выбранные'}: ({count[mode]})
        </button>
    );

    return (
        <div className={styles['switcher']}>
            {(['all', 'selected'] as ShowGroupMode[]).map(renderButton)}
        </div>
    );
};
