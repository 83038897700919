import React from 'react';

const visa = (
    <svg
        width="48"
        height="16"
        viewBox="0 0 48 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.2012 0.288217L10.1538 10.8107L8.52071 1.8738C8.3787 0.936865 7.59763 0.288217 6.67456 0.288217H0.0710059L0 0.720649C1.34911 1.00894 2.62722 1.44137 3.83432 2.01795C4.33136 2.30623 4.68639 2.81074 4.7574 3.38732L7.81065 15.7116H11.929L18.2485 0.288217H14.2012V0.288217ZM19.8817 0.288217L16.6864 15.7116H20.5917L23.787 0.288217H19.8817V0.288217ZM42.1775 4.4684L43.3846 10.2342H40.1893L42.1775 4.4684ZM41.6095 0.288217C40.8994 0.288217 40.1893 0.720649 39.9763 1.44137L34.0828 15.7116H38.1302L38.9112 13.4053H43.8817L44.3787 15.7116H48L44.8757 0.288217H41.6095ZM24.8521 5.11704C24.8521 7.35128 26.7692 8.5765 28.2604 9.29722C29.7515 10.0179 30.2485 10.5225 30.2485 11.2432C30.2485 12.2522 29.0414 12.7567 27.9053 12.7567C26.4852 12.7567 25.1361 12.4684 23.929 11.7477L23.2189 15.1351C24.568 15.7116 26.0592 15.9279 27.5503 15.9279C31.5976 15.9279 34.2959 13.8378 34.2959 10.6666C34.2959 6.63056 28.8284 6.34227 28.8284 4.54047C28.8284 3.96389 29.3254 3.38732 30.4615 3.24317C31.8107 3.09903 33.0888 3.38732 34.2959 3.96389L35.0059 0.648577C33.8698 0.216145 32.5917 -0.0721436 31.3846 -0.0721436C27.5503 -7.14887e-05 24.8521 2.09002 24.8521 5.11704Z"
            fill="url(#paint0_linear_417_3461)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_417_3461"
                x1="0"
                y1="7.98912"
                x2="48"
                y2="7.98912"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#272158" />
                <stop offset="1" stopColor="#154294" />
            </linearGradient>
        </defs>
    </svg>
);

export { visa };
