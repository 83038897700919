import React, { FC, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { classNames } from 'src/shared/lib';
import { SelectedIcon, ResetFiltersIcon } from 'src/shared';
import { SearchField } from 'src/shared/ui';
import { Popup, Position } from 'src/shared/ui/popup/popup';
import { MainTerminal } from 'src/features/select-terminals/config';
import { ShowGroupMode, Switcher } from 'src/entities/selector-with-groups';
import styles from './styles.module.scss';

interface Props {
    selected: number[];
    terminalsList: MainTerminal[];
    onChange: (selected: number[]) => void;
    maxWidth?: number;
}

const ID_SCROLL_ELEMENT = 'page-element-scroll';
const MAX_HEIGHT_POPUP = isMobile ? 400 : 500;

export const BankTerminalsSelector: FC<Props> = ({
    onChange,
    selected,
    terminalsList,
    maxWidth,
}) => {
    const [isOpen, setOpen] = useState(false);
    const [query, setQuery] = useState('');
    const [showGroup, setShowGroup] = useState<ShowGroupMode>('all');

    const triggerRef = useRef<HTMLButtonElement>(null);
    const allTerminals = terminalsList.flatMap((mainTerminal) =>
        mainTerminal.terminalGroups.flatMap((group) =>
            group.terminals.flatMap((terminal) => terminal.id)
        )
    );

    const onToggle = () => {
        setOpen((prev) => !prev);
    };

    const selectAllTerminals = () => {
        onChange(allTerminals);
    };

    const resetTerminals = () => {
        onChange([]);
    };

    const isCurrentlySelected = (terminalId: number) =>
        selected.includes(terminalId);
    const handleTerminalClick = (terminalId: number) => {
        let currentlySelected = selected.includes(terminalId);
        if (currentlySelected) {
            let newSelected = selected.filter((id) => terminalId !== id);
            onChange(newSelected);
        } else {
            let newSelected = [...selected];
            newSelected.push(terminalId);
            onChange(newSelected);
        }
    };

    const renderTerminal = ({ terminalGroups }: MainTerminal) => {
        return terminalGroups.map((group) =>
            group.terminals.map(({ id, name, uid }, key) => {
                if (showGroup === 'selected' && !selected.includes(id)) {
                    return null;
                }
                if (
                    query &&
                    !name.toUpperCase().includes(query.toUpperCase()) &&
                    !String(uid).toUpperCase().includes(query.toUpperCase())
                ) {
                    return null;
                }
                return (
                    <div
                        className={classNames(
                            styles.terminal,
                            {
                                [styles['terminal--selected']]:
                                    isCurrentlySelected(id),
                            },
                            []
                        )}
                        key={id + key + name}
                        onClick={() => handleTerminalClick(id)}
                    >
                        <span className={styles['selected-icon']}>
                            {SelectedIcon}
                        </span>
                        <span>
                            {uid} ({name})
                        </span>
                    </div>
                );
            })
        );
    };

    const getTerminalsLength = () => {
        return terminalsList.reduce((total, currentValue) => {
            let groupTerminalsLength = currentValue.terminalGroups.reduce(
                (total, group) => (total += group.terminals.length),
                0
            );
            return (total += groupTerminalsLength);
        }, 0);
    };

    if (!terminalsList.length) {
        return null;
    }

    return (
        <div className={styles['wrapper']}>
            <button
                ref={triggerRef}
                className={classNames(styles.trigger, {
                    [styles['trigger--open']]: isOpen,
                })}
                onClick={onToggle}
            >
                <span className={styles['trigger__header']}>Терминалы</span>
                <span className={styles['trigger__count']}>
                    Выбрано {selected.length}
                </span>
                {selected.length > 0 && (
                    <span
                        className={styles['trigger__reset-icon']}
                        onClick={(event) => {
                            event.stopPropagation();
                            resetTerminals();
                        }}
                    >
                        {ResetFiltersIcon}
                    </span>
                )}
            </button>
            {isOpen && (
                <Popup
                    maxWidth={maxWidth}
                    triggerRef={triggerRef}
                    idScrollElement={ID_SCROLL_ELEMENT}
                    marginFromTrigger={10}
                    maxHeightPopup={MAX_HEIGHT_POPUP}
                    position={Position.BOTTOM_LEFT}
                    maxWidthPopup="max"
                    hiddenPopup={onToggle}
                    hideWhenResizing={true}
                    className={styles.popup}
                >
                    <div className={styles['search-field']}>
                        <SearchField
                            value={query}
                            onChange={setQuery}
                            placeholder="Уточните адрес по номеру терминала или улице"
                        />
                    </div>
                    <Switcher
                        mode={showGroup}
                        count={{
                            all: allTerminals.length,
                            selected: selected.length,
                        }}
                        handleChange={setShowGroup}
                    />
                    <div className={classNames(styles.terminals)}>
                        {terminalsList.map(renderTerminal)}
                    </div>
                    <div className={styles['actions']}>
                        <button
                            onClick={selectAllTerminals}
                            disabled={selected.length === getTerminalsLength()}
                        >
                            Выбрать все
                        </button>
                        <button onClick={resetTerminals}>
                            Сбросить значения
                        </button>
                    </div>
                </Popup>
            )}
        </div>
    );
};
