import React from 'react';

export const MenuIcon = (
    <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.01977 2.64392H2.70551C3.27784 2.64392 3.73569 2.04303 3.73569 1.32196C3.73569 0.587537 3.27784 0 2.70551 0H1.01977C0.457856 0 0 0.587537 0 1.32196C0 2.04303 0.457856 2.64392 1.01977 2.64392ZM6.71176 2.40356H19.1571C19.6358 2.40356 20 1.9362 20 1.32196C20 0.707715 19.6358 0.240356 19.1571 0.240356H6.71176C6.23309 0.240356 5.85848 0.707715 5.85848 1.32196C5.85848 1.92285 6.23309 2.40356 6.71176 2.40356ZM1.01977 10.322H2.70551C3.27784 10.322 3.73569 9.72107 3.73569 9C3.73569 8.26558 3.27784 7.67804 2.70551 7.67804H1.01977C0.457856 7.67804 0 8.26558 0 9C0 9.72107 0.457856 10.322 1.01977 10.322ZM6.71176 10.0816H19.1571C19.6358 10.0816 20 9.61424 20 9C20 8.39911 19.6358 7.9184 19.1571 7.9184H6.71176C6.23309 7.9184 5.85848 8.38576 5.85848 9C5.85848 9.61424 6.23309 10.0816 6.71176 10.0816ZM1.01977 18H2.70551C3.27784 18 3.73569 17.3991 3.73569 16.678C3.73569 15.9436 3.27784 15.3694 2.70551 15.3694H1.01977C0.457856 15.3694 0 15.9436 0 16.678C0 17.3991 0.457856 18 1.01977 18ZM6.71176 17.7596H19.1571C19.6358 17.7596 20 17.2923 20 16.678C20 16.0772 19.6358 15.5964 19.1571 15.5964H6.71176C6.23309 15.5964 5.85848 16.0772 5.85848 16.678C5.85848 17.2923 6.23309 17.7596 6.71176 17.7596Z"
            fill="#F3F3F3"
        />
    </svg>
);
