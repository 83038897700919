/**
 * Приводит телефон к формату +7xxxxxxxxxx
 * @param value
 * @returns
 */

export const maskPhone = (value: string) => {
    const valueWithoutNotDigitChars = value.replace(/\D/g, ''); // Удаляет все символы которые не цифры

    if (value.length < 2) {
        return valueWithoutNotDigitChars.replace(/^(.)/, '+7$1'); // Добавляет +7 в начало, "012" -> "+7012"
    } else {
        const result = valueWithoutNotDigitChars.replace(/^(.)/, '+7'); // Заменяет первое число на +7, "012" -> "+712"
        return result.slice(0, 12);
    }
};
