import React from 'react';

export const CallIcon = (
    <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.01562 16.3594C12.2266 16.3594 15.7031 12.8828 15.7031 8.66406C15.7031 4.45312 12.2188 0.976562 8.00781 0.976562C3.79688 0.976562 0.320312 4.45312 0.320312 8.66406C0.320312 12.8828 3.79688 16.3594 8.01562 16.3594ZM8.01562 14.7109C4.66406 14.7109 1.97656 12.0156 1.97656 8.66406C1.97656 5.3125 4.65625 2.625 8.00781 2.625C11.3594 2.625 14.0469 5.3125 14.0547 8.66406C14.0625 12.0156 11.3594 14.7109 8.01562 14.7109ZM6.24219 10.3828C7.96094 12.0938 10.1172 13.1797 11.3984 11.8984C11.4453 11.8438 11.4844 11.7969 11.5312 11.75C11.8359 11.375 11.9453 10.8828 11.5078 10.5547C11.1484 10.3047 10.7812 10.0391 10.2266 9.65625C9.78125 9.34375 9.51562 9.42969 9.1875 9.75L8.89844 10.0312C8.8125 10.1172 8.67188 10.1016 8.57812 10.0469C8.32031 9.89062 7.89062 9.54688 7.48438 9.14062C7.08594 8.74219 6.72656 8.29688 6.58594 8.05469C6.53906 7.97656 6.5 7.84375 6.59375 7.74219L6.88281 7.44531C7.20312 7.09375 7.28125 6.84375 6.96875 6.40625L6.07812 5.13281C5.78125 4.71094 5.33594 4.77344 4.83594 5.14844C4.79688 5.17188 4.76562 5.20312 4.73438 5.23438C3.45312 6.51562 4.53906 8.67969 6.24219 10.3828Z"
            fill="#002E5A"
        />
    </svg>
);
