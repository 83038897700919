import * as type from '../constants';

export function fetchStart() {
    return (dispatch) => {
        dispatch({ type: type.FETCH_START });
    };
}

export function fetchStop() {
    return (dispatch) => {
        dispatch({ type: type.FETCH_STOP });
    };
}
