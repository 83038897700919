import React, { Component } from 'react';
import { getClassName, localSave } from 'src/shared/lib';
import CompanySelect from '../../../components/CompanySelect';
import { NewCheckbox } from '../../../shared';

import './cashbox-with-roles.scss';

class CashBoxWithRoles extends Component {
    static title = 'cashbox-with-roles';
    static companyIds = 'companySelectId';
    static companySelect = 'companySelect';
    static companySelectList = 'companySelectList';
    constructor(props) {
        super(props);
        this.id = getClassName(
            CashBoxWithRoles.title,
            CashBoxWithRoles.companyIds
        );
        this.value = getClassName(
            CashBoxWithRoles.title,
            CashBoxWithRoles.companySelect
        );
        this.list = getClassName(
            CashBoxWithRoles.title,
            CashBoxWithRoles.companySelectList
        );
        const defaultRole =
            this.props.role && this.props.role.length
                ? this.props.role
                : this.props.isPlatform
                ? ['PLAT_FORM']
                : ['E_COMMERCE'];

        this.state = {
            cashBoxRole: [],
            companyIds: [],
            currentRole: defaultRole,
        };
    }

    componentWillUnmount() {
        this.componentUpdateLocal();
    }

    componentUpdateLocal() {
        localSave(this.id, undefined);
        localSave(this.value, undefined);
        localSave(this.list, undefined);
    }
    componentDidMount() {
        if (
            this.props.isPlatform &&
            this.props.idCompany &&
            this.props.idCompany.length
        ) {
            this.setState({
                companyIds: this.props.idCompany,
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.onChange && this.needUpdate(prevState, this.state)) {
            const newUpdateState = {
                role: this.state.currentRole,
                companyIds: this.state.companyIds,
            };
            if (!this.state.companyIds.length) {
                this.componentUpdateLocal();
            }
            if (
                this.props.isPlatform &&
                !this.state.currentRole.includes('E_COMMERCE')
            ) {
                newUpdateState['companyIds'] = [];
                this.setState({
                    companyIds: [],
                });
                this.componentUpdateLocal();
            }
            this.props.onChange(newUpdateState);
        }
        if (
            this.props.role &&
            (prevProps.role !== this.props.role ||
                prevProps.role.length !== this.props.role.length)
        ) {
            this.getCashboxRole();
        }
    }
    needUpdate(pre, cur) {
        return (
            pre.currentRole !== cur.currentRole ||
            pre.companyIds[0] !== cur.companyIds[0]
        );
    }

    getCashboxRole() {
        const newState = {
            currentRole: this.props.role,
        };
        this.setState(newState);
    }
    onChangeCheckBox(value) {
        let currentValue = this.state.currentRole;
        if (this.state.currentRole.includes(value)) {
            currentValue = currentValue.filter(
                (valueItem) => valueItem !== value
            );
        } else {
            currentValue = [...currentValue, value];
        }
        this.setState({
            currentRole: currentValue.length
                ? currentValue
                : this.props.isPlatform
                ? ['PLAT_FORM']
                : ['E_COMMERCE'],
        });
    }
    render() {
        return (
            <div className="cashbox-role">
                <div className="cashbox-role__header">РОЛИ:</div>
                <div className="cashbox-role__check-container">
                    <NewCheckbox
                        id="rolePlatform"
                        name="rolePlatform"
                        label="ПЛАТ-ФОРМА"
                        onChange={() => this.onChangeCheckBox('PLAT_FORM')}
                        checked={this.state.currentRole?.includes('PLAT_FORM')}
                    />
                </div>
                <div className="cashbox-role__check-container">
                    <NewCheckbox
                        label="ЭЛ.КОММЕРЦИЯ"
                        id="roleComertion"
                        name="roleComertion"
                        onChange={() => this.onChangeCheckBox('E_COMMERCE')}
                        checked={this.state.currentRole?.includes('E_COMMERCE')}
                    />
                </div>
                {this.state.currentRole.includes('E_COMMERCE') &&
                    this.props.isPlatform && (
                        <div className="cashbox-role__company-container">
                            <div className="cashbox-role__header">
                                Закрепить за компанией:
                            </div>
                            <div>
                                <CompanySelect
                                    uid="cashbox-with-roles"
                                    classSelect="cashbox-role__select"
                                    onChange={({ value }) => {
                                        this.setState({
                                            companyIds: value ? [value] : [],
                                        });
                                    }}
                                />
                                {this.props.isPlatform &&
                                    this.props.createUpdateError && (
                                        <div className="cashbox-role__error">
                                            Обязательное поле
                                        </div>
                                    )}
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

export default CashBoxWithRoles;
