import React, { FC, memo, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Roles } from 'src/app/config';
import { navRoutes } from 'src/app/config/URL';
import { AdvertisingOfEcommerceService } from 'src/features/advertising-of-ecommerce-service';
import { isPermit, getAppTitle } from 'src/shared/lib';
import { HelpIcon, InventoryIcon, StatsIcon } from 'src/shared/ui';

export const Navigation: FC = memo(() => {
    const [showServiceAds, setShowServiceAds] = useState(false);
    const isRoleEcomm = isPermit(Roles.ECOMM);
    const isRoleAdmin = isPermit(Roles.ADMIN);

    const stylesSectionLink = isMobile
        ? { paddingLeft: 50, display: 'block', marginTop: 30 }
        : { paddingLeft: 47 };

    const showAdvertisingEcommService = (e) => {
        if (!isRoleEcomm) {
            setShowServiceAds(true);
            e.preventDefault();
        }
    };

    return getAppTitle() === 'bank' ? (
        <>
            {isPermit(Roles.OPERATIONS) && (
                <NavLink
                    style={{ paddingLeft: isMobile ? 50 : 47 }}
                    className="section-link"
                    to={navRoutes.operations}
                >
                    Операции
                </NavLink>
            )}

            {isPermit(Roles.OPERATIONS) && (
                <NavLink className="link" to={navRoutes.operationSBPLastUrl}>
                    Операции СБП
                </NavLink>
            )}
            {isPermit(Roles.SETTLEMENT) && (
                <NavLink className="link" to={navRoutes.settlement}>
                    Возмещения
                </NavLink>
            )}
            {isRoleAdmin && (
                <NavLink className="link" to={navRoutes.reversal}>
                    Возвраты
                </NavLink>
            )}
            {isPermit(Roles.EINVOICE) && (
                <NavLink className="link" to={navRoutes.einvoice}>
                    Счета
                </NavLink>
            )}
            {isPermit(Roles.CASHBOX) && (
                <Link
                    style={stylesSectionLink}
                    className="section-link"
                    to={navRoutes.platformCashBox}
                >
                    Реестры
                </Link>
            )}
            {isPermit(Roles.CASHBOX) && (
                <NavLink className="link" to={navRoutes.platformCashBox}>
                    Реестры ПЛАТ-ФОРМЫ
                </NavLink>
            )}
            {isPermit(Roles.CASHBOX) && (
                <NavLink className="link" to={navRoutes.platformErrorCashBox}>
                    Реестры в работе
                </NavLink>
            )}
            {isPermit(Roles.COMPANY_INFO) && (
                <Link
                    style={stylesSectionLink}
                    className="section-link"
                    to={navRoutes.company}
                >
                    Пользователи
                </Link>
            )}
            {isPermit(Roles.COMPANY_INFO) && (
                <NavLink className="link" to={navRoutes.company}>
                    Управление ТСП
                </NavLink>
            )}
            {isPermit(Roles.COMPANY_INFO) && (
                <NavLink className="link" to={navRoutes.groups}>
                    Группы ТСП
                </NavLink>
            )}
            {isPermit(Roles.USER_MANAGEMENT) && (
                <NavLink className="link" to={navRoutes.users}>
                    Управление пользователями
                </NavLink>
            )}
            {isRoleAdmin && (
                <NavLink className="link" to={navRoutes.cashBoxes}>
                    Ключи мерчанта
                </NavLink>
            )}
        </>
    ) : (
        <>
            {isPermit(Roles.STATISTIC) && (
                <NavLink className={'section-link'} to={navRoutes.statistic}>
                    <span className={'section-link__icon'}>{StatsIcon}</span>
                    <span>Статистика</span>
                </NavLink>
            )}
            <div className={'section-links'}>
                {isPermit(Roles.OPERATIONS) && (
                    <NavLink
                        className="link"
                        to={{
                            pathname: navRoutes.operationLastUrl,
                            state: { redirectedThroughNavbar: true },
                        }}
                    >
                        Операции
                    </NavLink>
                )}
                {isPermit(Roles.OPERATION_SBP) && (
                    <NavLink
                        className="link"
                        to={{
                            pathname: navRoutes.operationSBPLastUrl,
                            state: { redirectedThroughNavbar: true },
                        }}
                    >
                        Операции СБП
                    </NavLink>
                )}
                <NavLink
                    className={!isRoleEcomm ? 'link-disabled' : 'link'}
                    to={{
                        pathname: navRoutes.payments,
                        state: { redirectedThroughNavbar: true },
                    }}
                    onClick={showAdvertisingEcommService}
                >
                    Онлайн заказы
                </NavLink>
                {isPermit(Roles.SETTLEMENT) && (
                    <NavLink
                        className="link"
                        to={{
                            pathname: navRoutes.settlementLastUrl,
                            state: { redirectedThroughNavbar: true },
                        }}
                    >
                        Возмещения
                    </NavLink>
                )}
                {isPermit(Roles.DYNAMIC) && (
                    <NavLink className="link" to={navRoutes.dynamics}>
                        Динамика
                    </NavLink>
                )}
            </div>
            {isPermit(Roles.INVENTORY) && (
                <NavLink className={'section-link'} to={navRoutes.inventory}>
                    <span className={'section-link__icon'}>
                        {InventoryIcon}
                    </span>
                    <span>Инвентарь</span>
                </NavLink>
            )}
            <div className={'section-links'}>
                {isRoleAdmin && (
                    <NavLink className="link" to={navRoutes.settingsTerminals}>
                        Терминалы
                    </NavLink>
                )}

                <NavLink
                    className={!isRoleEcomm ? 'link-disabled' : 'link'}
                    to={navRoutes.ecomm}
                    onClick={showAdvertisingEcommService}
                >
                    Электронная коммерция
                </NavLink>

                <NavLink
                    className={!isRoleEcomm ? 'link-disabled' : 'link'}
                    to={navRoutes.einvoice}
                    onClick={showAdvertisingEcommService}
                >
                    Счета
                </NavLink>
                {/* TODO нет макета для этой странице */}
                {/*<NavLink className="link" to={'/empty-page'}>*/}
                {/*    QR-коды для оплаты*/}
                {/*</NavLink>*/}
            </div>

            <NavLink className={'section-link'} to={navRoutes.support.main}>
                <span className={'section-link__icon'}>{HelpIcon}</span>
                <span>Помощь и справка</span>
            </NavLink>

            {!isRoleEcomm && (
                <AdvertisingOfEcommerceService
                    isOpen={showServiceAds}
                    setOpen={setShowServiceAds}
                />
            )}
        </>
    );
});
