import React, { FC, memo, MutableRefObject, useEffect, useRef } from 'react';
import { classNames } from 'src/shared/lib';
import { Button } from 'src/shared/ui';
import styles from './page-error.module.scss';

type TimerId = MutableRefObject<ReturnType<typeof setTimeout>>;

interface PageErrorProps {
    className?: string;
}

export const PageError: FC<PageErrorProps> = memo(({ className }) => {
    const timerRef = useRef() as TimerId;
    const reloadPage = () => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    };

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            reloadPage();
        }, 3000);

        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    return (
        <div className={classNames(styles.wrapper, {}, [className])}>
            <h2>Произошла внутренняя ошибка на сайте</h2>
            <h4>Страница обновится через 3 секунды...</h4>
            <Button onClick={reloadPage}>Обновить страницу</Button>
        </div>
    );
});
