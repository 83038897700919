import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FeedbackMenu } from 'src/shared/ui/feedback-menu';

import {
    CloseIcon,
    SDMFullLogo,
    UserDropdownIcon,
    MenuIcon,
} from 'src/shared/ui/media';

import styles from './mobile-navigation.module.scss';

interface Props {
    homePageUrl: string;
    userName: string;
    userDropdownNavigation: React.ReactNode;
}

export const MobileNavigation: React.FC<Props> = ({
    children,
    homePageUrl,
    userName,
    userDropdownNavigation,
}) => {
    const [open, setOpen] = useState<boolean>(true);

    const location = useLocation();
    useEffect(() => {
        // close menu on location change
        setOpen(false);
    }, [location]);

    return (
        <>
            <nav className={styles['mobile-navigation']}>
                <div
                    className={[
                        styles['backdrop'],
                        open ? styles['backdrop--open'] : '',
                    ].join(' ')}
                    onClick={() => setOpen(false)}
                ></div>
                <div
                    className={[
                        styles['links'],
                        open ? styles['links--open'] : '',
                    ].join(' ')}
                >
                    <button
                        className={styles['close-icon']}
                        onClick={() => setOpen(false)}
                    >
                        {CloseIcon}
                    </button>
                    <div className={styles['logo']}>
                        <NavLink to={homePageUrl}>{SDMFullLogo}</NavLink>
                    </div>
                    {children}
                    <div className={styles['user-menu']}>
                        <div className={styles['user-menu__user']}>
                            <div>{UserDropdownIcon}</div>
                            <span>{userName}</span>
                        </div>
                        {userDropdownNavigation}
                    </div>
                </div>
                <div className={styles['menu']}>
                    <div className={styles['logo']}>
                        <NavLink to={homePageUrl}>{SDMFullLogo}</NavLink>
                    </div>
                    <div className={styles['menu-icons']}>
                        {/* TODO: user dropdown */}
                        {/* <div className={styles['user-dropdown']}>
                            {UserIconStroke}
                        </div> */}
                        <FeedbackMenu />
                        <button onClick={() => setOpen(true)}>
                            {MenuIcon}
                        </button>
                    </div>
                </div>
            </nav>
        </>
    );
};
