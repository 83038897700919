/**
 * @param number число для округление
 * @param count количество знаков после запятой
 * @returns число
 */

export const roundsNumber = (number?: string | number, count = 2) => {
    const newNumber = Number(number);
    if (Number.isInteger(newNumber)) {
        return newNumber;
    }
    return Number(newNumber?.toFixed(count));
};
