import moment from 'moment';

export function localSave(name, value) {
    localStorage.setItem(name, value);
}

export function localSaveSerialize(name, value) {
    localStorage.setItem(name, JSON.stringify(value));
}

export function localLoad(name, def) {
    let item = localStorage.getItem(name);
    return item ? parseFalsyValues(item) : parseFalsyValues(def);
}

export function localLoadMoment(name, def) {
    let item = localStorage.getItem(name);
    if (item === 'null') {
        return null;
    }
    return item ? moment(Date.parse(item)) : def;
}

export function localLoadInt(name, def) {
    let item = localStorage.getItem(name);
    return item ? parseInt(item, 10) : def;
}

export function localLoadSerialize(name, def) {
    let item = localStorage.getItem(name);
    return item && item !== 'undefined'
        ? parseFalsyValues(JSON.parse(item))
        : parseFalsyValues(def);
}

export function localLoadIntInArray(name, def) {
    let item = localStorage.getItem(name);
    return item ? [parseInt(item, 10)] : def;
}

function parseFalsyValues(string) {
    switch (string) {
        case 'undefined':
        case 'null':
            return '';
        default:
            return string;
    }
}
