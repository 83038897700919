import React from 'react';

export const vk = (
    <svg
        width="30"
        height="17"
        viewBox="0 0 30 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.7306 13.7619C27.8076 12.7211 26.7691 11.7959 25.846 10.7551C24.923 9.82993 24.9229 9.36735 25.6153 8.32653C26.4229 7.17007 27.2306 6.12925 28.0383 5.08843C28.7306 4.04762 29.5383 3.0068 29.8845 1.85034C30.1153 1.04082 29.8845 0.693878 29.0768 0.578231C28.9614 0.578231 28.846 0.578231 28.6153 0.578231H23.8845C23.3076 0.578231 22.9614 0.809525 22.7306 1.38776C22.4999 2.08163 22.1537 2.77551 21.8076 3.46939C20.9999 4.97279 20.1922 6.47619 19.0383 7.7483C18.8076 7.97959 18.4614 8.32653 17.9999 8.21088C17.4229 7.97959 17.3076 7.05442 17.3076 6.82313V1.27211C17.1922 0.462585 17.0768 0.115646 16.2691 0H11.3076C10.6153 0 10.2691 0.231293 9.92295 0.693878C9.69218 0.92517 9.69218 1.04082 10.0383 1.15646C10.846 1.27211 11.3076 1.85034 11.4229 2.65986C11.6537 3.93197 11.6537 5.31973 11.5383 6.59184C11.5383 6.93878 11.4229 7.40136 11.3076 7.7483C11.0768 8.32653 10.7306 8.44218 10.1537 8.09524C9.69218 7.7483 9.34602 7.28571 9.11525 6.93878C7.96141 5.20408 7.03833 3.46939 6.23064 1.5034C5.99987 0.925169 5.65372 0.578231 5.07679 0.578231C3.69218 0.578231 2.19218 0.578231 0.807563 0.578231C-0.000129566 0.578231 -0.346283 1.04082 -0.000129372 1.73469C1.49987 5.20408 3.23064 8.55782 5.53833 11.6803C6.69218 13.2993 8.07679 14.6871 9.69218 15.6122C11.6537 16.7687 13.7306 17.1156 15.9229 17C16.9614 17 17.3076 16.6531 17.3076 15.6122C17.3076 14.9184 17.4229 14.2245 17.7691 13.5306C18.1153 12.9524 18.5768 12.8367 19.2691 13.1837C19.6153 13.415 19.846 13.6463 20.0768 13.8776C20.6537 14.5714 21.2306 15.1497 21.8076 15.8435C22.6153 16.6531 23.4229 17.1156 24.5768 17H28.9614C29.6537 17 29.9999 16.0748 29.6537 15.2653C29.5383 14.8027 29.1922 14.2245 28.7306 13.7619Z"
            fill="white"
        />
    </svg>
);
