import React, { useRef, useState } from 'react';
import styles from './styles.module.scss';
import { classNames } from 'src/shared/lib';
import { useClickOutside } from 'src/shared/lib';

interface ChevronProps {
    open: boolean;
}

const ChevronIcon = ({ open }: ChevronProps) => (
    <svg
        className={classNames(styles['chevron'], {
            [styles['rotate-chevron']]: open,
        })}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_2383_2312)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.34057 8.25352C8.00664 7.91549 7.46524 7.91549 7.13131 8.25352C6.79738 8.59155 6.79738 9.1396 7.13131 9.47763L12.302 14.7118C12.3125 14.7236 12.3233 14.7352 12.3345 14.7466C12.5663 14.9811 12.8979 15.0529 13.1908 14.9619C13.3205 14.9219 13.4426 14.85 13.5452 14.7461C13.5534 14.7378 13.5613 14.7294 13.5691 14.7209L18.7484 9.47806C19.0824 9.14003 19.0824 8.59198 18.7484 8.25395C18.4145 7.91592 17.8731 7.91592 17.5392 8.25395L12.9401 12.9095L8.34057 8.25352Z"
                fill="#164883"
            />
        </g>
        <defs>
            <clipPath id="clip0_2383_2312">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

interface Props {
    onChange: (option: number) => void;
    options: number[];
    selected: number;
}

export const PaginatorSelect = ({ onChange, options, selected }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = () => setIsOpen((prev) => !prev);
    const optionsRef = useRef(null);
    const inputRef = useRef(null);

    useClickOutside(() => setIsOpen(false), optionsRef, inputRef);

    return (
        <div className={styles['wrapper']}>
            <div
                ref={inputRef}
                className={styles['input']}
                onClick={toggleIsOpen}
            >
                <span>{selected}</span>
                <ChevronIcon open={isOpen} />
            </div>
            <div
                ref={optionsRef}
                className={classNames(styles['options'], {
                    [styles['open']]: isOpen,
                })}
            >
                {options.map((option) => (
                    <div
                        key={option}
                        onClick={() => {
                            onChange(option);
                            setIsOpen(false);
                        }}
                        className={styles['option']}
                    >
                        {option}
                    </div>
                ))}
            </div>
        </div>
    );
};
