const uniquePasswordValidator = (newPassword, currentPassword) => {
    if (!currentPassword || !newPassword) {
        return true;
    }
    let counter = 0;
    const length =
        newPassword.length > currentPassword.length
            ? newPassword.length
            : currentPassword.length;
    for (let i = 0; i < length; i++) {
        if (currentPassword[i] === newPassword[i]) {
            counter++;
        }
        if (counter > 4) {
            return false;
        }
    }
    return true;
};

export { uniquePasswordValidator };
