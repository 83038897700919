import React from 'react';
import styles from './styles.module.scss';
import { classNames } from 'src/shared/lib';
import { NoDataIcon } from 'src/shared/ui/media/table-icons/no-data-icon';
import { QuickActionButton } from '../quick-action-button';
import { DataFetchingIcon } from 'src/shared/ui/media/table-icons/data-fetching-icon';
import { useDispatch } from 'react-redux';
import { setEndDate, setStartDate } from 'src/redux/actions/table';
import moment from 'moment';

enum Variant {
    INITIAL = 'initial',
    LOADING = 'loading',
    NO_DATA = 'noData',
}

const VARIANT_MAP: Record<
    Variant,
    { content: JSX.Element; oldContent: JSX.Element; icon: JSX.Element }
> = {
    [Variant.INITIAL]: {
        content: (
            <>
                <p className={styles['text']}>Данные отсутствуют</p>
            </>
        ),
        icon: <NoDataIcon />,
        oldContent: <span className="common-table__no-data">Нет данных</span>,
    },
    [Variant.LOADING]: {
        content: (
            <>
                <p className={styles['text']}>
                    Загрузка данных. <br />
                    Пожалуйста подождите
                </p>
            </>
        ),
        icon: <DataFetchingIcon />,
        oldContent: <span className="common-table__no-data">Загрузка</span>,
    },
    [Variant.NO_DATA]: {
        content: (
            <>
                <p className={styles['text']}>
                    За выбранный период данные отсутствуют.
                    <br /> Пожалуйста, выберите другой интервал.
                </p>
            </>
        ),
        icon: <NoDataIcon />,
        oldContent: <span className="common-table__no-data">Нет данных</span>,
    },
};

interface Props {
    variant: Variant;
    tableName: string;
    newStatuses?: boolean;
}

export const DisplayDataStatus = ({
    variant = Variant.NO_DATA,
    newStatuses,
    tableName,
}: Props) => {
    const dispatch = useDispatch();
    const lastMonthClickHandler = () => {
        dispatch(
            setStartDate(
                tableName,
                moment().subtract(1, 'month').startOf('month')
            )
        );
        dispatch(
            setEndDate(tableName, moment().subtract(1, 'month').endOf('month'))
        );
    };

    const thisMonthClickHandler = () => {
        dispatch(setStartDate(tableName, moment().startOf('month')));
        dispatch(setEndDate(tableName, moment().endOf('month')));
    };
    return newStatuses ? (
        <div className={classNames(styles['wrapper'])}>
            {VARIANT_MAP[variant].icon}
            {VARIANT_MAP[variant].content}
            {variant === Variant.NO_DATA && (
                <p className={styles['bottom-text']}>
                    Быстрый выбор: <br />
                    <QuickActionButton onClick={lastMonthClickHandler}>
                        прошлый месяц
                    </QuickActionButton>
                    <span>, </span>
                    <QuickActionButton onClick={thisMonthClickHandler}>
                        текущий месяц
                    </QuickActionButton>
                </p>
            )}
        </div>
    ) : (
        <>{VARIANT_MAP[variant].oldContent}</>
    );
};

DisplayDataStatus.variant = Variant;
