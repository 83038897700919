import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReplaySubject } from 'rxjs';

import { URL } from 'src/app/config';

import AwareTable from 'src/components/AwareTable';
import Container from 'src/components/Container';

import MerchantUserFilter from './MerchantUserFilter';
import AddUserModal from './modals/add-user/add-user';
import EditUserModal from './modals/edit-user/edit-user';

import { isAdmin, roleNameMapper } from 'src/shared/lib';

import './merchant-user-list-page.scss';

class MerchantUserListPage extends Component {
    filterSubject = new ReplaySubject(1);
    awareTableRef = null;

    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            adminChecked: undefined,
            updateUserModalOpen: false,
            terminalIds: [],
        };
        this.toggleUpdateUser = this.toggleUpdateUser.bind(this);
    }

    componentDidMount() {
        if (isAdmin(this.props.roles)) {
            this.setState({ adminChecked: true });
        }
    }

    toggleUpdateUser(open) {
        this.setState({
            updateUserModalOpen: open,
        });

        if (!open) {
            this.setState({
                id: undefined,
            });
        }
    }

    returnRoleAsObject(role) {
        return role.map((role) => {
            return {
                value: role,
                name: roleNameMapper(role),
            };
        });
    }

    render() {
        let { companyRoles } = this.props;
        companyRoles = companyRoles
            ? this.returnRoleAsObject(companyRoles)
            : companyRoles;
        return (
            <div className="merchant-user-list-page">
                <Container header={true} subject={this.filterSubject}>
                    <MerchantUserFilter />
                </Container>
                <Container
                    table={true}
                    footer={true}
                    name={'company'}
                    subject={this.filterSubject}
                >
                    <AwareTable
                        ref={(component) => {
                            this.awareTableRef = component;
                        }}
                        actions={
                            <div className="merchant-user-list-page__actions">
                                <AddUserModal
                                    terminals={this.props.terminals}
                                    roles={this.props.roles}
                                    companyRoles={companyRoles}
                                    successCallback={() =>
                                        this.awareTableRef?.reloadData()
                                    }
                                />
                            </div>
                        }
                        url={URL.merchantUserUrl}
                        onItemClick={(e) => {
                            this.setState(
                                {
                                    id: e.ID,
                                },
                                () => {
                                    this.setState({
                                        updateUserModalOpen: true,
                                    });
                                }
                            );
                        }}
                    />
                </Container>
                {this.state.id && (
                    <EditUserModal
                        id={this.state.id}
                        open={this.state.updateUserModalOpen}
                        toggleUpdateUser={this.toggleUpdateUser}
                        roles={this.props.roles}
                        companyRoles={companyRoles}
                        terminals={this.props.terminals}
                        successCallback={() => this.awareTableRef?.reloadData()}
                    />
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        roles: state.auth.roles,
        terminals: state.terminals.terminals,
        companyRoles: state.auth.companyRoles,
        fetch: state.fetch.fetch,
    };
}

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(MerchantUserListPage);
