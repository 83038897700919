import React, { Component } from 'react';
import { omit } from 'lodash';
import { connect } from 'react-redux';
import MaskedInput from 'react-maskedinput';
import { withRouter } from 'react-router-dom';
import {
    Input,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalHeader,
} from 'reactstrap';
import { ReplaySubject } from 'rxjs';
import { URL, navRoutes, Roles } from 'src/app/config';
import { createNotification } from 'src/redux/actions/notification';
import Container from 'src/components/Container';
import AwareTable from 'src/components/AwareTable';
import LightTable from 'src/components/LightTable';
import CompanySelect from 'src/components/CompanySelect';
import {
    localLoad,
    getClassName,
    log,
    roleNameMapper,
    sendToSubject,
    statusNameMapper,
    validateEmail,
    get,
    post,
    localSave,
    isPermit,
    LOCAL_STORAGE_TABLE_NAMES,
} from 'src/shared/lib';
import {
    CardIcon,
    mir,
    visa,
    mc,
    CirrusIcon,
    Button,
    CheckBox,
    MultipleSelect,
    Paginator,
    OverflowContainer,
    AddIcon,
} from 'src/shared/ui';
import { AddMerchantCashbox } from 'src/features/add-merchant-cashbox';
import { AddMerchantKeys } from 'src/features/add-merchants-key';
import { EditMerchantKeys } from 'src/features/edit-merchant-keys';
import './company-details.scss';
import CashBoxWithRoles from '../PlatformCashBoxPage/CashBoxWithRoles';
import { setNeedsUpdate } from 'src/redux/actions/table';
import { EinvoiceTable } from 'src/widgets/einvoice-table';
import EinvoiceFilter from '../BankEinvoicePage/EinvoiceFilter';
import { BankQRCodeTypeOptions } from 'src/shared/lib/util';

/**
 * В соответствии с задачей DPS-359 установить возможность
 * включения/выключения Мир/Обновленного вида создания кассы
 */
const IS_CASH_BOX = true;
const IS_MIR_PAY = true;

/**
 * TODO: удалить данную переменную после тестирования со стороны заказчика
 */
const IS_PAYMENT_TO_INCLUDE = true;

const terminalsTableName = 'Company-Details-Terminals';
const cashboxesTableName = 'Company-Details-Cashboxes';
const usersTableName = 'Company-Details-Users';
const linkedCompaniesTableName = 'Company-Details-Linked-Companies';
const merchantKeysTableName = 'Company-Details-Merchant-Keys';

class CompanyDetailsPage extends Component {
    filterSubject = new ReplaySubject(1);

    constructor(props) {
        super(props);
        this.clsName = 'OperationsFilter';
        this.mainCompanyId = getClassName(this.clsName, 'mainCompanyId');
        this.subCompanyId = getClassName(this.clsName, 'subCompanyId');
        this.send = sendToSubject.bind(this);
        this.defaultFilter = {
            mainCompanyId: localLoad(this.mainCompanyId),
            subCompanyId: localLoad(this.subCompanyId),
        };
        this.cashbox = {
            defCashbox: '',
            companyName: '',
            groupCode: '',
            cashboxId: '',
            idCompany: '',
            cashboxLogin: '',
            cashboxPassword: '',
            cashboxStatus: 'Активно',
            cashboxBlocked: false,
            address: '',
        };
        if (IS_CASH_BOX) {
            this.cashbox['role'] = ['E_COMMERCE'];
        }
        this.cashboxFields = {
            cashboxChecked: true,
            cashboxFields: {
                cashboxLogin: null,
                cashboxPassword: null,
                address: null,
                groupCode: null,
            },
        };

        this.ecomKeys = {
            key: '',
            ecommkeyStatus: 'Активно',
            eCommKeyBlocked: false,
            idDefaultETerminal: '',
            passwordDefaultETerminal: '',
            created: '',
            company: '',
            cashboxRequired: false,
            cashbox: undefined,
            password: '',
            merchantId: '',
            merchantName: '',
            merchantUrl: '',
            defaultNotificationUrl: '',
            defaultSuccessUrl: '',
            defaultFailUrl: '',
            timeZone: '+3',
            allowWidgets: false,
            maxAmount: '',
            defaultNotificationEmail: '',
            notifyByEmail: false,
            notifyByUrl: false,
            paymentType: ['CARD'],
            extEntityId: '',
            merchantIdSbp: '',
            accAlias: '',
            account: '',
        };
        this.ecomKeyFields = {
            ecomKeysChecked: true,
            ecomKeyFields: {
                idDefaultETerminal: null,
                passwordDefaultETerminal: null,

                merchantId: null,
                merchantName: null,
                merchantUrl: null,
                defaultSuccessUrl: null,
                defaultFailUrl: null,
                maxAmount: null,
                defaultNotificationUrl: true,
                defaultNotificationEmail: true,

                extEntityId: null,
                merchantIdSbp: null,

                account: null,
                accAlias: null,
            },
        };
        this.state = {
            ...this.defaultFilter,
            ...this.ecomKeys,
            ...this.ecomKeyFields,
            ...this.cashbox,
            ...this.cashboxFields,
            id: undefined,
            bankBlocked: undefined,
            statusName: undefined,
            open_add_user: false,
            open_update_user: false,
            open_update_company_role: false,
            open_terminal_info: false,
            open_user_history: false,
            open_add_ecommKey: false,
            open_update_ecommKey: false,
            open_add_cashbox: false,
            open_update_cashbox: false,
            fullName: '',
            email: '',
            cellPhone: '',
            roles: [],
            cashboxAvailableFfdList: [],
            userHistory: [],
            info: undefined,
            bankStandartRoles: [],
            showHistory: false,
            uid: '',
            name: '',
            operationLimit: '',
            extraCommission: '',
            simCommission: '',
            weekLimit: '',
            limitEndOfWeek: '',
            rates: [],
            ecomkeyCashboxes: [],
            emailIsValid: true,
            statuses: ['Активно', 'Выключено'],
            activateEcomm: true,
            activateEcommStatus: true,
            reassignIdCompany: undefined,
        };
        if (IS_MIR_PAY) {
            this.state['mirPayKid'] = '';
            this.state['mirPayMerchantId'] = '';
            this.state['mirPayPrivateKey'] = '';
            this.state['mirPayTerminalId'] = '';
        }
        this.toggle_add_user = this.toggle_add_user.bind(this);
        this.toggle_update_user = this.toggle_update_user.bind(this);
        this.toggle_user_history = this.toggle_user_history.bind(this);
        this.createCompanyUser = this.createCompanyUser.bind(this);
        this.toggle_show_terminal_info =
            this.toggle_show_terminal_info.bind(this);
    }

    handleChange =
        (key) =>
        ({ target }) =>
            this.setState({
                ecomKeyFields: {
                    ...this.state.ecomKeyFields,
                    [key]: !target.value ? false : true,
                },
                [key]: target.value,
            });

    handleSBPOptionalChange =
        (key) =>
        ({ target }) => {
            this.setState({
                ecomKeyFields: {
                    ...this.state.ecomKeyFields,
                    ...{
                        account:
                            Boolean(target.value) ||
                            Boolean(
                                this.state.accAlias &&
                                    this.state.accAlias.length
                            ),
                        accAlias:
                            Boolean(target.value) ||
                            Boolean(
                                this.state.account && this.state.account.length
                            ),
                    },
                },
                [key]: target.value,
            });
        };

    handlePaymentType =
        (selectedPmType, editingMode = false) =>
        () => {
            const { paymentType } = this.state;

            this.setState(
                {
                    paymentType: [
                        ...(paymentType.includes(selectedPmType)
                            ? paymentType.length === 2
                                ? paymentType.filter(
                                      (pt) => pt !== selectedPmType
                                  )
                                : [...paymentType]
                            : [...paymentType, selectedPmType]),
                    ],
                },
                () => {
                    editingMode
                        ? this.handleEditingEcommFields()
                        : this.handleEcommFields();
                }
            );
        };

    selectAllPaymentTypes = (editingMode = false) => {
        const { paymentType } = this.state;
        this.setState(
            {
                paymentType: [
                    ...(paymentType.length === 2
                        ? paymentType.filter((pt) => pt !== 'SBP')
                        : ['SBP', 'CARD']),
                ],
            },
            () => {
                editingMode
                    ? this.handleEditingEcommFields()
                    : this.handleEcommFields();
            }
        );
    };

    saveAndSend() {
        post(URL.subCompanySetUrl, {
            mainCompanyId: this.state.mainCompanyId,
            subCompanyId: this.state.subCompanyId,
        })
            .then(() => {
                this.props.dispatch(
                    setNeedsUpdate(
                        linkedCompaniesTableName + this.props.match.params.id,
                        true
                    )
                );
                this.props.dispatch(
                    createNotification(
                        {
                            title: 'Добавление связанной компании',
                            message: 'Связанная компания успешно добавлена',
                        },
                        'success'
                    )
                );
            })
            .catch((r) => log(r))
            .finally(() => this.setState({ open_add_sub_group: false }));
    }

    cardIconSelect = (cardName) => {
        return {
            МИР: mir,
            VISA: visa,
            MasterCard: mc,
            'Cirrus/Maestro': CirrusIcon,
            'КАРТЫ СДМ-БАНКА': CardIcon,
        }[cardName];
    };

    deleteRequest(mainCompanyId, subCompanyId) {
        post(URL.subCompanyRemoveUrl, {
            mainCompanyId: Number(mainCompanyId),
            subCompanyId: Number(subCompanyId),
        })
            .then(() => {
                this.props.dispatch(
                    setNeedsUpdate(
                        linkedCompaniesTableName + this.props.match.params.id,
                        true
                    )
                );
                this.props.dispatch(
                    createNotification(
                        {
                            title: 'Удаление связанной компании',
                            message: 'Связанная компания успешно удалена',
                        },
                        'success'
                    )
                );
            })
            .catch((r) => log(r));
    }

    toggle_add_user() {
        this.setState({ fullName: '', email: '', cellPhone: '' });
        this.setState({ open_add_user: !this.state.open_add_user });
    }

    toggle_show_terminal_info() {
        this.setState({ open_terminal_info: !this.state.open_terminal_info });
    }

    toggleSubCompany({ name }) {
        this.setState({ [name]: !this.state[name] });
    }

    toggle_update_company_role() {
        this.setState({
            open_update_company_role: !this.state.open_update_company_role,
        });
    }

    toggle_update_user() {
        this.setState({ open_update_user: !this.state.open_update_user });
    }

    toggle_user_history() {
        this.setState({
            open_user_history: !this.state.open_user_history,
            showHistory: !this.state.showHistory,
        });
    }

    componentDidMount() {
        /* hidden feature to request and turn on/off merchant status */
        // this.getEcommStatus();
        this.getCompanyInfo();
        this.getCashboxAvailableFfd();
        this.handleEcommFields();
    }

    getTerminalInfo(id) {
        get(URL.terminalInfo + id)
            .then((r) => {
                this.setState({
                    uid: r.data.payload.uid,
                    name: r.data.payload.name,
                    operationLimit: r.data.payload.operationLimit,
                    weekLimit: r.data.payload.weekLimit,
                    limitEndOfWeek: r.data.payload.limitEndOfWeek,
                    extraCommission: r.data.payload.extraCommission,
                    simCommission: r.data.payload.simCommission,
                    rates: r.data.payload.rates,
                });
            })
            .catch((r) => log(r));
    }

    /*ecommKey */

    toggle_add_ecommKey = () => {
        this.setState(
            {
                ...this.ecomKeys,
                ecomKeysChecked: true,
                ecomKeyFields: { ...this.ecomKeyFields.ecomKeyFields },
            },
            () => {
                this.handleEcommFields();
            }
        );
        this.setState({
            open_add_ecommKey: !this.state.open_add_ecommKey,
        });
    };

    toggle_update_ecommKey = () => {
        this.setState({
            open_update_ecommKey: !this.state.open_update_ecommKey,
        });
    };

    getEcommKey(id) {
        return get(URL.getEcommkeyInfo + id)
            .then(
                (r) => {
                    const {
                        key,
                        status,
                        blocked,
                        idDefaultETerminal,
                        passwordDefaultETerminal,
                        created,
                        company,
                        cashboxRequired,
                        cashbox,
                        password,
                        merchantId,
                        merchantName,
                        merchantUrl,
                        defaultNotificationUrl,
                        defaultSuccessUrl,
                        defaultFailUrl,
                        timeZone,
                        allowWidgets,
                        maxAmount,
                        defaultNotificationEmail,
                        notifyByEmail,
                        notifyByUrl,
                        paymentType,
                    } = r.data.payload;

                    const newState = {
                        key,
                        ecommkeyStatus: statusNameMapper(status, 'ru'),
                        eCommKeyBlocked: blocked,
                        idDefaultETerminal,
                        passwordDefaultETerminal,
                        created,
                        company,
                        cashboxRequired,
                        cashbox,
                        password,
                        merchantId,
                        merchantName,
                        merchantUrl,
                        defaultNotificationUrl,
                        defaultSuccessUrl,
                        defaultFailUrl,
                        timeZone,
                        allowWidgets,
                        maxAmount,
                        defaultNotificationEmail,
                        notifyByEmail,
                        notifyByUrl,
                        paymentType,
                        // ...(r.data.payload.paymentType.includes("SBP"))?
                        // {
                        extEntityId: r.data.payload.extEntityId,
                        merchantIdSbp: r.data.payload.merchantIdSbp,
                        accAlias: r.data.payload.accAlias,
                        account: r.data.payload.account,
                        // }
                        // :
                        // {
                        //     extEntityId:'',
                        //     merchantIdSbp:'',
                        //     accAlias:'',
                        //     account:''
                        // }
                        ecomKeyFields: {
                            ...this.state.ecomKeyFields,

                            idDefaultETerminal: true,
                            passwordDefaultETerminal: true,

                            merchantId: Boolean(merchantId),
                            merchantName: Boolean(merchantName),
                            merchantUrl: Boolean(merchantUrl),
                            defaultSuccessUrl: Boolean(defaultSuccessUrl),
                            defaultFailUrl: Boolean(defaultFailUrl),
                            maxAmount: Boolean(maxAmount),

                            defaultNotificationUrl: true,
                            defaultNotificationEmail: true,

                            extEntityId: true,
                            merchantIdSbp: true,
                            account: true,
                            accAlias: true,
                        },
                    };
                    if (IS_MIR_PAY) {
                        newState['mirPayKid'] = r.data.payload.mirPayKid;
                        newState['mirPayMerchantId'] =
                            r.data.payload.mirPayMerchantId;
                        newState['mirPayPrivateKey'] =
                            r.data.payload.mirPayPrivateKey;
                        newState['mirPayTerminalId'] =
                            r.data.payload.mirPayTerminalId;
                    }
                    if (IS_PAYMENT_TO_INCLUDE) {
                        newState['mcc'] = r.data.payload?.mcc;
                    }

                    this.setState(newState);
                },
                () => {
                    this.handleEditingEcommFields();
                }
            )
            .catch((r) => log(r));
    }

    getEcommKeyCashbox = () => {
        return get(URL.getEcommkeyCashboxes + this.props.match.params.id)
            .then((r) => {
                this.setState({
                    ecomkeyCashboxes: [
                        ...(!this.state.cashboxRequired
                            ? [
                                  ...r.data.payload,
                                  {
                                      blocked: false,
                                      groupCode: 'без кассы',
                                      id: 0,
                                  },
                              ]
                            : r.data.payload),
                    ],
                    // cashbox: r.data.payload[0].id
                });
            })
            .catch((r) => log(r));
    };

    /*cashbox */

    toggle_add_cashbox = () => {
        this.setState({
            ...this.cashbox,
            cashboxChecked: true,
            cashboxFields: { ...this.cashboxFields.cashboxFields },
            open_add_cashbox: !this.state.open_add_cashbox,
        });
    };

    toggle_update_cashbox = () => {
        this.setState({
            cashboxChecked: true,
            cashboxFields: {
                ...this.cashboxFields.cashboxFields,
                groupCode: true,
            },
            open_update_cashbox: !this.state.open_update_cashbox,
        });
        this.resetLocalReassignSelect();
    };

    updateCashbox = (id) => {
        const {
            cashboxLogin,
            cashboxPassword,
            cashboxStatus,
            cashboxBlocked,
            idCompany,
            groupCode,
            address,
            reassignIdCompany,
            ffdVersion,
        } = this.state;
        const paramsReq = {
            login: cashboxLogin,
            password: cashboxPassword,
            status: statusNameMapper(cashboxStatus, 'en'),
            blocked: cashboxBlocked,
            idCompany,
            ffdVersion,
            groupCode,
            address,
            reassignIdCompany,
        };
        if (IS_CASH_BOX) {
            paramsReq['role'] = this.state.role;
        }

        post(URL.updateCashbox + id, paramsReq)
            .then((r) => {
                this.setState({ open_update_cashbox: false });
                this.props.dispatch(
                    setNeedsUpdate(
                        cashboxesTableName + this.props.match.params.id,
                        true
                    )
                );
            })
            .catch((r) => {
                log(r);
                this.setState({ open_update_cashbox: false });
            })
            .finally(() => {
                this.resetLocalReassignSelect();
            });
    };

    resetLocalReassignSelect() {
        const id = 'OperationsFilter-companySelectId';
        const value = 'OperationsFilter-companySelect';
        const list = 'OperationsFilter-companySelectList';
        localSave(id, undefined);
        localSave(value, undefined);
        localSave(list, undefined);
    }

    getCashbox(id) {
        get(URL.getaCashboxInfo + id)
            .then((r) => {
                const {
                    blocked,
                    companyName,
                    groupCode,
                    id,
                    address,
                    idCompany,
                    login,
                    password,
                    status,
                    ffdVersion,
                } = r.data.payload;
                const newState = {
                    cashboxBlocked: blocked,
                    companyName,
                    groupCode,
                    ffdVersion,
                    cashboxId: id,
                    idCompany,
                    reassignIdCompany: idCompany,
                    currentReassignIdCompany: idCompany,
                    cashboxLogin: login,
                    cashboxPassword: password,
                    address,
                    cashboxStatus: statusNameMapper(status, 'ru'),
                    cashboxChecked: true,
                    cashboxFields: {
                        ...this.cashboxFields.cashboxFields,
                        groupCode: true,
                    },
                    open_update_cashbox: !this.state.open_update_cashbox,
                };
                if (IS_CASH_BOX) {
                    newState['role'] = r.data.payload.role;
                }
                this.setState(newState);
            })
            .catch((r) => log(r));
    }

    returnRoleAsObject(role) {
        return role.map((role) => {
            return {
                value: role,
                name: roleNameMapper(role),
            };
        });
    }

    handleEcommFields = () => {
        const {
            idDefaultETerminal,
            passwordDefaultETerminal,

            extEntityId,
            merchantIdSbp,
            account,
            accAlias,
        } = this.state.ecomKeyFields;
        const res = this.state.ecomKeyFields;

        if (this.state.paymentType.length > 1) {
            this.setState({
                ...this.state,
                ecomKeyFields: {
                    ...this.state.ecomKeyFields,
                    idDefaultETerminal: Boolean(idDefaultETerminal),
                    passwordDefaultETerminal: Boolean(passwordDefaultETerminal),

                    extEntityId: Boolean(extEntityId),
                    merchantIdSbp: Boolean(merchantIdSbp),
                    account: Boolean(account),
                    accAlias: Boolean(accAlias),
                },
            });
        } else {
            if (this.state.paymentType[0] === 'SBP') {
                this.setState({
                    ...this.state,
                    ecomKeyFields: {
                        ...omit(
                            res,
                            'idDefaultETerminal',
                            'passwordDefaultETerminal'
                        ),
                        extEntityId: Boolean(extEntityId),
                        merchantIdSbp: Boolean(merchantIdSbp),
                        account: Boolean(account),
                        accAlias: Boolean(accAlias),
                    },
                });
            }

            if (this.state.paymentType[0] === 'CARD') {
                this.setState({
                    ...this.state,
                    ecomKeyFields: {
                        ...omit(
                            res,
                            'extEntityId',
                            'merchantIdSbp',
                            'account',
                            'accAlias'
                        ),
                        idDefaultETerminal: Boolean(idDefaultETerminal),
                        passwordDefaultETerminal: Boolean(
                            passwordDefaultETerminal
                        ),
                    },
                });
            }
        }
    };

    handleEditingEcommFields = () => {
        const {
            idDefaultETerminal,
            passwordDefaultETerminal,

            extEntityId,
            merchantIdSbp,
            account,
            accAlias,
        } = this.state;

        const res = this.state.ecomKeyFields;

        if (this.state.paymentType.length > 1) {
            this.setState({
                ...this.state,
                ecomKeyFields: {
                    ...this.state.ecomKeyFields,
                    idDefaultETerminal: Boolean(idDefaultETerminal),
                    passwordDefaultETerminal: Boolean(passwordDefaultETerminal),

                    extEntityId: Boolean(extEntityId),
                    merchantIdSbp: Boolean(merchantIdSbp),
                    account: Boolean(account ? account : accAlias),
                    accAlias: Boolean(accAlias ? accAlias : account),
                },
            });
        } else {
            if (this.state.paymentType[0] === 'SBP') {
                this.setState({
                    ...this.state,
                    ecomKeyFields: {
                        ...omit(
                            res,
                            'idDefaultETerminal',
                            'passwordDefaultETerminal'
                        ),
                        extEntityId: Boolean(extEntityId),
                        merchantIdSbp: Boolean(merchantIdSbp),
                        account: Boolean(account ? account : accAlias),
                        accAlias: Boolean(accAlias ? accAlias : account),
                    },
                });
            }

            if (this.state.paymentType[0] === 'CARD') {
                this.setState({
                    ...this.state,
                    ecomKeyFields: {
                        ...omit(
                            res,
                            'extEntityId',
                            'merchantIdSbp',
                            'account',
                            'accAlias'
                        ),
                        idDefaultETerminal: Boolean(idDefaultETerminal),
                        passwordDefaultETerminal: Boolean(
                            passwordDefaultETerminal
                        ),
                    },
                });
            }
        }
    };

    hideForm = () => {
        this.setState({
            open_add_cashbox: false,
            open_add_ecommKey: false,
        });
    };

    hideEditMerchantKeysForm = () => {
        this.setState({
            open_update_ecommKey: false,
        });
    };

    sendEcomKeysForceRequest = () => {
        this.props.dispatch(
            setNeedsUpdate(
                merchantKeysTableName + this.props.match.params.id,
                true
            )
        );
    };

    onSuccessCashboxAction = () => {
        this.props.dispatch(
            setNeedsUpdate(
                cashboxesTableName + this.props.match.params.id,
                true
            )
        );
    };

    getCashboxAvailableFfd() {
        get(URL.getCashboxAvailableFfd)
            .then((r) => {
                this.setState({
                    ffdVersion: r.data.payload[0].version,
                    cashboxAvailableFfdList: r.data.payload,
                });
            })
            .catch((r) => log(r));
    }

    getInitialDataForMerchantKey = () => {
        const initialDataMerchant = {
            key: this.state.key,
            cashbox: this.state.cashbox,
            password: this.state.password,
            ecommkeyStatus: this.state.ecommkeyStatus,
            maxAmount: this.state.maxAmount,
            extEntityId: this.state.extEntityId,
            merchantIdSbp: this.state.merchantIdSbp,
            account: this.state.account,
            accAlias: this.state.accAlias,
            merchantId: this.state.merchantId,
            merchantName: this.state.merchantName,
            idDefaultETerminal: this.state.idDefaultETerminal,
            timeZone: this.state.timeZone,
            merchantUrl: this.state.merchantUrl,
            defaultNotificationUrl: this.state.defaultNotificationUrl,
            defaultNotificationEmail: this.state.defaultNotificationEmail,
            defaultSuccessUrl: this.state.defaultSuccessUrl,
            defaultFailUrl: this.state.defaultFailUrl,
            passwordDefaultETerminal: this.state.passwordDefaultETerminal,
            allowWidgets: this.state.allowWidgets,
            notifyByUrl: this.state.notifyByUrl,
            eCommKeyBlocked: this.state.eCommKeyBlocked,
            notifyByEmail: this.state.notifyByEmail,
            cashboxRequired: this.state.cashboxRequired,
            paymentType: this.state.paymentType,
        };
        if (IS_MIR_PAY) {
            initialDataMerchant['mirPayKid'] = this.state.mirPayKid;
            initialDataMerchant['mirPayMerchantId'] =
                this.state.mirPayMerchantId;
            initialDataMerchant['mirPayPrivateKey'] =
                this.state.mirPayPrivateKey;
            initialDataMerchant['mirPayTerminalId'] =
                this.state.mirPayTerminalId;
        }
        if (IS_PAYMENT_TO_INCLUDE) {
            initialDataMerchant['mcc'] = this.state.mcc;
        }
        return initialDataMerchant;
    };

    render() {
        const { info } = this.state;
        const bankStandartRoles = this.returnRoleAsObject(
            Object.values(this.state.bankStandartRoles)
        );
        const hasSBPRoleInCompany =
            info && info.roles && info.roles.includes('OPERATIONS_SBP');
        const isRoleCashboxNotAdmin =
            info &&
            info.roles &&
            (info.roles.includes('CASHBOX') || info.roles.includes('ADMIN'));

        return (
            <div className="company-details">
                {this.state.info ? (
                    <div
                        className={
                            this.state.info.roles
                                ? 'company-details__info-container'
                                : ''
                        }
                    >
                        <Container header={true} headerName={'Инфо'}>
                            <div className="company-details__info">
                                <div className="company-details__info__box">
                                    <span>Идентификатор: </span>
                                    <span>{this.state.info.id}</span>
                                </div>
                                <div className="company-details__info__box">
                                    <span>Наименование компании: </span>
                                    <span>{this.state.info.name}</span>
                                </div>
                                <div className="company-details__info__box">
                                    <span>ИНН: </span>
                                    <span>{this.state.info.inn}</span>
                                </div>
                                <div className="company-details__info__box">
                                    <span>Состояние компании: </span>
                                    <span>
                                        {this.state.info.blocked
                                            ? 'Заблокирована'
                                            : 'Активна'}
                                    </span>
                                </div>
                                <div className="company-details__info__box">
                                    {this.state.info.mainCompanyId
                                        ? [
                                              <span key={0}>
                                                  Главная компания:{' '}
                                              </span>,
                                              <a
                                                  key={1}
                                                  href={`${navRoutes.company}/${this.state.info.mainCompanyId}`}
                                              >
                                                  {
                                                      this.state.info
                                                          .mainCompanyName
                                                  }
                                              </a>,
                                          ]
                                        : null}
                                </div>
                            </div>
                        </Container>
                        {this.state.info.roles && (
                            <Container
                                header={true}
                                headerName={'Роли'}
                                icon={
                                    <i
                                        className="mdi mdi-mode-edit role-icon"
                                        onClick={() => {
                                            this.getCompanyRoles();
                                            this.toggle_update_company_role();
                                        }}
                                    />
                                }
                            >
                                <div className="company-details__info">
                                    <div className="company-details__info__box">
                                        <span>Роли компании: </span>
                                        <span>
                                            {this.state.info.roles &&
                                                this.state.info.roles.map(
                                                    (role, i, roles) =>
                                                        `${roleNameMapper(
                                                            role
                                                        )}${
                                                            i ===
                                                            roles.length - 1
                                                                ? ' '
                                                                : ', '
                                                        }`
                                                )}
                                        </span>
                                    </div>
                                </div>
                            </Container>
                        )}
                    </div>
                ) : null}
                <Paginator
                    name={terminalsTableName + this.props.match.params.id}
                >
                    <OverflowContainer title="Терминалы">
                        <AwareTable
                            isStoreTable
                            tableName={
                                terminalsTableName + this.props.match.params.id
                            }
                            url={
                                URL.companyTerminalsUrl +
                                this.props.match.params.id
                            }
                            onItemClick={({ ID }) => {
                                this.toggle_show_terminal_info();
                                this.getTerminalInfo(ID);
                            }}
                        />
                    </OverflowContainer>
                </Paginator>
                <Paginator name={usersTableName + this.props.match.params.id}>
                    <OverflowContainer
                        title="Пользователи"
                        icon={
                            <AddIcon
                                onClick={this.toggle_add_user}
                                title="Добавить пользователя"
                                customIcon="mdi-group-add"
                            />
                        }
                    >
                        <AwareTable
                            isStoreTable
                            tableName={
                                usersTableName + this.props.match.params.id
                            }
                            onItemClick={(e) => {
                                this.getUserInfo(e.ID);
                                this.getUserHistory(e.ID);
                            }}
                            companyHistory={() => this.toggle_user_history()}
                            url={
                                URL.companyUsersUrl + this.props.match.params.id
                            }
                        />
                    </OverflowContainer>
                </Paginator>
                {this.state.info ? (
                    this.state.info.mainCompanyId ? null : (
                        <Paginator
                            name={
                                linkedCompaniesTableName +
                                this.props.match.params.id
                            }
                        >
                            <OverflowContainer
                                title="Связанные Компании"
                                icon={
                                    <AddIcon
                                        onClick={() =>
                                            this.toggleSubCompany({
                                                name: 'open_add_sub_group',
                                            })
                                        }
                                    />
                                }
                            >
                                <AwareTable
                                    isStoreTable
                                    tableName={
                                        linkedCompaniesTableName +
                                        this.props.match.params.id
                                    }
                                    type="removeCompanyOption"
                                    url={
                                        URL.subCompanyUrl +
                                        this.props.match.params.id
                                    }
                                    onItemClick={() =>
                                        this.toggleSubCompany({
                                            name: 'open_add_sub_group',
                                        })
                                    }
                                    locationUrl={
                                        this.props.history.location.pathname
                                    }
                                    mainCompanyId={this.props.match.params.id}
                                    deleting={(mainCompanyId, subCompanyId) =>
                                        this.deleteRequest(
                                            mainCompanyId,
                                            subCompanyId
                                        )
                                    }
                                    link="/company/"
                                />
                            </OverflowContainer>
                        </Paginator>
                    )
                ) : null}
                {/* Hidden feature of bank to turn on/off merchant ecomm */}
                {/*<Container header={true}*/}
                {/*           headerName={`Электронная коммерция: ${this.state.activateEcommStatus ? "включена" : "отключена"}`}>*/}
                {/*    <div className="company-details__info">*/}
                {/*        <div className="company-details__info__box ecomm-info">*/}
                {/*            <span>Разрешена:&nbsp;&nbsp;</span>*/}
                {/*            <span>*/}
                {/*                    <CheckBox*/}
                {/*                        onChange={() => this.setState({activateEcomm: !this.state.activateEcomm})}*/}
                {/*                        checked={this.state.activateEcomm}/>*/}
                {/*                </span>*/}
                {/*            <span>*/}
                {/*              <Button*/}
                {/*                  onClick={() => {*/}
                {/*                      this.setState({*/}
                {/*                          activateEcommStatus: this.state.activateEcomm*/}
                {/*                      });*/}
                {/*                      this.saveEcommStatus()*/}
                {/*                  }}*/}
                {/*                  className="company-details__button">*/}
                {/*                  Применить*/}
                {/*              </Button>*/}
                {/*            </span>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Container>*/}
                <Paginator
                    name={merchantKeysTableName + this.props.match.params.id}
                >
                    <OverflowContainer
                        title="Ключи мерчанта"
                        icon={
                            <AddIcon
                                onClick={this.toggle_add_ecommKey}
                                title="Добавить ключ"
                            />
                        }
                    >
                        <AwareTable
                            isStoreTable
                            tableName={
                                merchantKeysTableName +
                                this.props.match.params.id
                            }
                            onItemClick={(e) => {
                                this.getEcommKey(e.ID)
                                    .then(() => this.getEcommKeyCashbox())
                                    .then(() => this.toggle_update_ecommKey());
                                //   this.getUserHistory(e.ID);
                            }}
                            companyHistory={() => this.toggle_user_history()}
                            url={URL.ecommkeyall + this.props.match.params.id}
                        />
                    </OverflowContainer>
                </Paginator>

                {isPermit(Roles.EINVOICE) && (
                    <>
                        <h1 className="einvoce-table-title">Счета</h1>
                        <EinvoiceFilter
                            pageTitle={`${LOCAL_STORAGE_TABLE_NAMES.eInvoiceMerchant}/${this.props.match.params.id}`}
                            mode="simple"
                            additionalFilter={{
                                companyId: [this.props.match.params.id],
                                qrcType: BankQRCodeTypeOptions[0].value,
                            }}
                            datePickerPopupClassName="einvoce-filter-date-picker"
                        />
                        <EinvoiceTable
                            newStatuses={false}
                            pageTitle={`${LOCAL_STORAGE_TABLE_NAMES.eInvoiceMerchant}/${this.props.match.params.id}`}
                            companyId={this.props.match.params.id}
                        />
                    </>
                )}

                <Paginator
                    name={cashboxesTableName + this.props.match.params.id}
                >
                    <OverflowContainer
                        title="Кассы"
                        icon={
                            isRoleCashboxNotAdmin && (
                                <AddIcon
                                    title={'Добавить кассу'}
                                    onClick={this.toggle_add_cashbox}
                                />
                            )
                        }
                    >
                        {/* user tokens and auth attempts */}
                        <AwareTable
                            isStoreTable
                            tableName={
                                cashboxesTableName + this.props.match.params.id
                            }
                            onItemClick={(e) => {
                                this.getCashbox(e.ID);
                            }}
                            companyHistory={() => this.toggle_user_history()}
                            url={URL.cashboxAll + this.props.match.params.id}
                        />
                    </OverflowContainer>
                </Paginator>
                <Modal
                    isOpen={this.state.open_user_history}
                    toggle={this.toggle_user_history}
                    size="md"
                >
                    <ModalHeader toggle={this.toggle_user_history}>
                        Токены и попытки авторизации
                    </ModalHeader>
                    <ModalBody className={'user-history-modal'}>
                        <div className="user-history-header__info">
                            Действующие токены:
                        </div>
                        {this.state.userHistory.userTokens && (
                            <LightTable
                                headers={[
                                    'Срок действия токена',
                                    'Кол-во использованных попыток',
                                    'Кол-во оставшихся попыток',
                                ]}
                                data={this.state.userHistory.userTokens.map(
                                    ({
                                        tokenDuration,
                                        usedAttemptCount,
                                        attemptCount,
                                    }) => [
                                        tokenDuration,
                                        usedAttemptCount,
                                        attemptCount,
                                    ]
                                )}
                            />
                        )}
                        <div className="user-history-header__info">
                            Попытки входа:
                        </div>
                        {this.state.userHistory.attempts && (
                            <LightTable
                                headers={['Дата, время', 'Статус']}
                                data={this.state.userHistory.attempts.map(
                                    ({ dateTime, success }) => [
                                        dateTime,
                                        success,
                                    ]
                                )}
                            />
                        )}
                    </ModalBody>
                </Modal>
                {/*user modals*/}
                <Modal
                    isOpen={this.state.open_add_user}
                    toggle={this.toggle_add_user}
                >
                    <ModalHeader toggle={this.toggle_add_user}>
                        Добавить пользователя
                    </ModalHeader>
                    <ModalBody className={'add-user-modal'}>
                        <div className="user-add-form">
                            <Input
                                onChange={(fio) =>
                                    this.setState({
                                        fullName: fio.target.value,
                                    })
                                }
                                value={this.state.fullName}
                                placeholder={'Введите ФИО'}
                                className="modal-input"
                            />
                            {this.state.emailIsValid ||
                            !this.state.email.length ? null : (
                                <span className="error-msg">
                                    Неправильный Email
                                </span>
                            )}
                            <Input
                                onChange={(email) =>
                                    this.setState(
                                        { email: email.target.value },
                                        () => {
                                            this.setState({
                                                emailIsValid: validateEmail(
                                                    this.state.email
                                                ),
                                            });
                                        }
                                    )
                                }
                                value={this.state.email}
                                placeholder={'Введите email'}
                                className="modal-input"
                                type="email"
                            />
                            <MaskedInput
                                value={this.state.cellPhone}
                                placeholder={'Введите телефон'}
                                className="modal-input form-control"
                                type="phone"
                                mask="+71111111111"
                                onChange={(phone) => {
                                    this.setState({
                                        cellPhone: phone.target.value,
                                    });
                                }}
                            />
                            <Button
                                className="company-details__button"
                                onClick={
                                    !this.state.emailIsValid
                                        ? null
                                        : !this.state.emailIsValid
                                        ? null
                                        : this.createCompanyUser
                                }
                            >
                                Создать
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={this.state.open_update_user}
                    toggle={this.toggle_update_user}
                >
                    <ModalHeader toggle={this.toggle_update_user}>
                        Редактирование пользователя
                    </ModalHeader>
                    <ModalBody className={'add-user-modal'}>
                        <div className="user-add-form">
                            <div className="user-add-form__info">
                                <span>ID пользователя:</span>
                                <span>{this.state.id}</span>
                            </div>
                            <div className="user-add-form__info">
                                <span>Блокировка:</span>
                                <span>
                                    <CheckBox
                                        onChange={() =>
                                            this.setState({
                                                bankBlocked:
                                                    !this.state.bankBlocked,
                                            })
                                        }
                                        checked={this.state.bankBlocked}
                                    />
                                </span>
                            </div>
                            <div className="user-add-form__info">
                                <span>Статус:</span>
                                <span>{this.state.statusName}</span>
                            </div>
                            <Input
                                onChange={(fio) =>
                                    this.setState({
                                        fullName: fio.target.value,
                                    })
                                }
                                value={this.state.fullName}
                                placeholder={'Введите ФИО'}
                                className="modal-input"
                            />
                            <Input
                                onChange={(email) =>
                                    this.setState({ email: email.target.value })
                                }
                                value={this.state.email}
                                placeholder={'Введите email'}
                                className="modal-input"
                                type="email"
                            />
                            <MaskedInput
                                value={this.state.cellPhone}
                                placeholder={'Введите телефон'}
                                className="modal-input form-control"
                                type="phone"
                                mask="+71111111111"
                                onChange={(phone) =>
                                    this.setState({
                                        cellPhone: phone.target.value,
                                    })
                                }
                            />
                            <div className="user-add-form__info column custom-selected">
                                <span>Роли</span>
                                <ListGroup>
                                    {this.state.roles.map((role) => (
                                        <ListGroupItem key={role}>
                                            {role}
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </div>
                            <Button
                                className="company-details__button"
                                fluid
                                size="tiny"
                                onClick={() =>
                                    this.updateCompanyUser(this.state.id)
                                }
                            >
                                Сохранить
                            </Button>
                            <Button
                                className="company-details__button m05"
                                fluid
                                size="tiny"
                                onClick={() =>
                                    this.resetMerchantUser(this.state.id)
                                }
                            >
                                Сбросить пароль
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
                {/*ecommkey modals*/}
                {this.state.open_add_ecommKey && (
                    <AddMerchantKeys
                        onUpdate={this.sendEcomKeysForceRequest.bind(this)}
                        onClose={this.hideForm.bind(this)}
                        isOpen={this.state.open_add_ecommKey}
                        toggle={this.toggle_add_ecommKey}
                        company={{
                            hasSBPRole: hasSBPRoleInCompany,
                            id: this.state.id,
                            pageId: this.props.match.params.id,
                        }}
                    />
                )}
                {this.state.open_update_ecommKey && (
                    <EditMerchantKeys
                        onUpdate={this.sendEcomKeysForceRequest.bind(this)}
                        onClose={this.hideEditMerchantKeysForm}
                        isOpen={this.state.open_update_ecommKey}
                        initialData={this.getInitialDataForMerchantKey()}
                        company={{
                            hasSBPRole: hasSBPRoleInCompany,
                            id: this.state.id,
                            pageId: this.props.match.params.id,
                        }}
                    />
                )}
                {/*cashbox modals*/}
                <AddMerchantCashbox
                    isOpen={this.state.open_add_cashbox}
                    onClose={this.hideForm.bind(this)}
                    onUpdate={this.onSuccessCashboxAction.bind(this)}
                    company={{
                        inn: this.state.info?.inn,
                        id: this.props.match.params.id,
                    }}
                />
                <Modal
                    isOpen={this.state.open_update_cashbox}
                    toggle={this.toggle_update_cashbox}
                    autoComplete="off"
                >
                    <ModalHeader toggle={this.toggle_update_cashbox}>
                        Редактировать кассу
                    </ModalHeader>
                    <ModalBody className={'add-user-modal'}>
                        <div className="user-add-form">
                            <div className="user-add-form__info">
                                <span>Статус: </span>
                                <span>
                                    <select
                                        value={this.state.cashboxStatus}
                                        className="custom-select"
                                        onChange={(e) => {
                                            e.persist();
                                            this.setState({
                                                cashboxStatus: e.target.value,
                                            });
                                        }}
                                    >
                                        {this.state.statuses.map(
                                            (status, i) => (
                                                <option key={i} value={status}>
                                                    {status}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </span>
                            </div>
                            <div className="user-add-form__info">
                                <span>Блокировка:</span>
                                <span>
                                    <CheckBox
                                        onChange={() =>
                                            this.setState({
                                                cashboxBlocked:
                                                    !this.state.cashboxBlocked,
                                            })
                                        }
                                        checked={this.state.cashboxBlocked}
                                    />
                                </span>
                            </div>
                            <div className="user-add-form__info">
                                <span>Адрес кассы:</span>
                            </div>
                            <Input
                                onChange={(e) =>
                                    this.setState({
                                        address: e.target.value,
                                    })
                                }
                                value={this.state.address}
                                placeholder={'Введите Адрес'}
                                className="modal-input"
                            />
                            <div className="user-add-form__info">
                                <span>Логин кассы:</span>
                            </div>
                            <Input
                                onChange={(e) =>
                                    this.setState({
                                        cashboxLogin: e.target.value,
                                    })
                                }
                                value={this.state.cashboxLogin}
                                placeholder={'Введите Логин кассы'}
                                className="modal-input"
                                autoComplete="new-login"
                            />
                            <div className="user-add-form__info">
                                <span>Пароль кассы:</span>
                            </div>
                            <Input
                                onChange={(e) =>
                                    this.setState({
                                        cashboxPassword: e.target.value,
                                    })
                                }
                                type="password"
                                value={this.state.cashboxPassword}
                                placeholder={'Введите Пароль кассы'}
                                className="modal-input"
                                autoComplete="new-password"
                            />
                            <div className="user-add-form__info">
                                <select
                                    value={this.state.ffdVersion}
                                    className="custom-select"
                                    onChange={(e) => {
                                        this.setState({
                                            ffdVersion: e.target.value,
                                        });
                                    }}
                                >
                                    {this.state.cashboxAvailableFfdList.map(
                                        (ffdVersion, i) => (
                                            <option
                                                key={i}
                                                value={ffdVersion.version}
                                            >
                                                {ffdVersion.nameFfd}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                            {this.state.reassignIdCompany && (
                                <>
                                    <div className="user-add-form__info">
                                        <span>Переназначить на компанию: </span>
                                    </div>
                                    <CompanySelect
                                        def={{
                                            label: this.state.companyName,
                                            value: this.state.reassignIdCompany,
                                        }}
                                        classSelect="company-details__select"
                                        uid={this.clsName}
                                        onChange={({ value }) => {
                                            this.setState({
                                                reassignIdCompany: value
                                                    ? value
                                                    : this.state
                                                          .currentReassignIdCompany,
                                            });
                                        }}
                                    />
                                </>
                            )}
                            {IS_CASH_BOX && (
                                <CashBoxWithRoles
                                    isPlatform={false}
                                    onChange={(fields) => {
                                        this.setState({
                                            ...fields,
                                        });
                                    }}
                                    role={this.state.role}
                                />
                            )}
                            <Button
                                className="company-details__button"
                                fluid
                                onClick={() =>
                                    this.updateCashbox(this.state.cashboxId)
                                }
                            >
                                Сохранить
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={this.state.open_terminal_info}
                    toggle={() => this.toggle_show_terminal_info()}
                >
                    <ModalHeader
                        toggle={() => this.toggle_show_terminal_info()}
                    >
                        Информация о терминале
                    </ModalHeader>
                    <ModalBody
                        className={'terminals-group-modal'}
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {
                            <div className="user-terminal-form">
                                <div className="user-terminal-form__info">
                                    <span>ID терминала</span>
                                    <span className="ml5mr5">:</span>
                                    <span>{this.state.uid}</span>
                                </div>
                                <div className="user-terminal-form__info">
                                    <b>Установленные лимиты: </b>
                                </div>
                                <div className="user-terminal-form__info">
                                    <b>На одну операцию</b>
                                    <span className="ml5mr5">-</span>
                                    <span> {this.state.operationLimit}</span>
                                </div>
                                <div className="user-terminal-form__info">
                                    <b>Недельный лимит</b>
                                    <span className="ml5mr5">-</span>
                                    <span>{this.state.weekLimit}</span>
                                </div>
                                <div className="user-terminal-form__info">
                                    <b>Лимит до конца недели</b>
                                    <span className="ml5mr5">-</span>
                                    <span>{this.state.limitEndOfWeek}</span>
                                </div>
                                <div className="user-terminal-form__info">
                                    <b>Тарифы: </b>
                                </div>
                                {this.state.rates.map(
                                    ({ cardName, rate }, i) => (
                                        <div
                                            key={i}
                                            className="user-terminal-form__info"
                                        >
                                            <span>{cardName}: </span>
                                            {/*hidden feature showing bank cards*/}
                                            {/*<div className="img-wrapper"><img src={this.cardIconSelect(cardName)} alt=""/>*/}
                                            {/*</div>*/}
                                            <span>&nbsp;{rate} %</span>
                                        </div>
                                    )
                                )}
                                <div className="user-terminal-form__info">
                                    <b data-tip data-for="com-info">
                                        Дополнительная комиссия:{' '}
                                        {this.state.extraCommission}
                                    </b>
                                    {/*<CommissionInfo id="com-info"/>*/}
                                </div>
                                <div className="user-terminal-form__info">
                                    <b>
                                        Комиссия на SIM-карту:{' '}
                                        {this.state.simCommission}
                                    </b>
                                </div>
                            </div>
                        }
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={this.state.open_add_sub_group}
                    toggle={() =>
                        this.toggleSubCompany({
                            name: 'open_add_sub_group',
                        })
                    }
                >
                    <ModalHeader
                        toggle={() =>
                            this.toggleSubCompany({
                                name: 'open_add_sub_group',
                            })
                        }
                    >
                        Добавить связанную компанию
                    </ModalHeader>
                    <ModalBody className={'add-bank-user-modal'}>
                        <div className="user-add-form">
                            <div>
                                <div>Компании:</div>
                                <div>
                                    <CompanySelect
                                        uid={this.clsName}
                                        onChange={({ value }) =>
                                            this.setState({
                                                mainCompanyId: Number(
                                                    this.props.match.params.id
                                                ),
                                                subCompanyId: value
                                                    ? value
                                                    : undefined,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            <Button
                                className="company-details__button"
                                onClick={() => this.saveAndSend()}
                                disabled={!this.state.subCompanyId}
                            >
                                Сохранить
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
                {/*Update Company role Modal*/}
                <Modal
                    isOpen={this.state.open_update_company_role}
                    toggle={() => this.toggle_update_company_role()}
                >
                    <ModalHeader
                        toggle={() => this.toggle_update_company_role()}
                    >
                        Редактирование ролей
                    </ModalHeader>
                    <ModalBody className={'add-user-modal'}>
                        <div className="user-add-form">
                            <div className="user-add-form__info column" key={1}>
                                <span>Роли</span>
                                {this.state.info && (
                                    <MultipleSelect
                                        value={bankStandartRoles}
                                        initialState={this.state.info.roles}
                                        onChange={(roles) =>
                                            this.setState({
                                                info: {
                                                    ...this.state.info,
                                                    roles,
                                                },
                                            })
                                        }
                                    />
                                )}
                            </div>
                            <Button
                                className="company-details__button"
                                onClick={() => this.updateCompanyRoles()}
                            >
                                Сохранить
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    updateCompanyUser(id) {
        post(URL.updateMerchantUserUrl + id, {
            fullName: this.state.fullName,
            email: this.state.email,
            cellPhone: this.state.cellPhone,
            bankBlocked: this.state.bankBlocked,
        })
            .then((r) => {
                this.setState({ open_update_user: false });
                this.props.dispatch(
                    setNeedsUpdate(
                        usersTableName + this.props.match.params.id,
                        true
                    )
                );
                this.props.dispatch(
                    createNotification(
                        {
                            title: 'Редактирование пользователя',
                            message: 'Пользователь успешно изменен',
                        },
                        'success'
                    )
                );
            })
            .catch((r) => {
                log(r);
                this.setState({ open_update_user: false });
            });
    }

    resetMerchantUser(id) {
        get(URL.resetMerchantUserPassword + id)
            .then((r) => {
                this.setState({ open_update_user: false });
                this.props.dispatch(
                    createNotification(
                        {
                            title: 'Сброс пароля',
                            message: 'Пароль пользователя сброшен.',
                        },
                        'info'
                    )
                );
            })
            .catch((r) => {
                this.setState({ open_update_user: false });
                log(r);
            });
    }

    getCompanyInfo() {
        post(URL.companyUrl + '/' + this.props.match.params.id)
            .then((r) => {
                return this.setState({ info: r.data.payload });
            })
            .catch((r) => log(r));
    }

    getCompanyRoles() {
        get(URL.companyRoles)
            .then((r) => this.setState({ bankStandartRoles: r.data.payload }))
            .catch((r) => log(r));
    }

    updateCompanyRoles() {
        post(
            URL.companyRoles + '/' + this.props.match.params.id,
            this.state.info.roles
        )
            .then((r) => {
                this.setState({ open_update_company_role: false });
                this.props.dispatch(
                    setNeedsUpdate(
                        merchantKeysTableName + this.props.match.params.id,
                        true
                    )
                );
            })
            .catch((r) => log(r));
    }

    getUserHistory(id) {
        get(URL.userAttempts + id)
            .then(({ data: { payload } }) => {
                this.setState({ userHistory: payload });
            })
            .catch((error) => log(error));
    }

    getUserInfo(id) {
        get(URL.merchantUserInfoUrl + id)
            .then((r) => {
                let {
                    id,
                    cellPhone,
                    email,
                    fullName,
                    bankBlocked,
                    statusName,
                    roles,
                } = r.data.payload;
                this.setState({
                    id,
                    bankBlocked,
                    statusName,
                    fullName,
                    email,
                    cellPhone,
                    roles,
                });
                if (this.state.showHistory) {
                    this.setState({ open_user_history: true });
                } else {
                    this.setState({ open_update_user: true });
                }
            })
            .catch((r) => {
                this.setState({ open_update_user: false });
                log(r);
            });
    }

    createCompanyUser() {
        post(URL.companyCreateUsersUrl + this.props.match.params.id, {
            fullName: this.state.fullName,
            email: this.state.email,
            cellPhone: this.state.cellPhone,
        })
            .then((r) => {
                this.setState({ open_add_user: false });
                this.props.dispatch(
                    setNeedsUpdate(
                        usersTableName + this.props.match.params.id,
                        true
                    )
                );
                this.props.dispatch(
                    createNotification(
                        {
                            title: 'Создание пользователя',
                            message: 'Пользователь успешно создан',
                        },
                        'success'
                    )
                );
            })
            .catch((r) => {
                log(r);
                this.setState({ open_add_user: false });
            });
    }
}

export { cashboxesTableName };
export default withRouter(connect()(CompanyDetailsPage));
