import React from 'react';

const FilterSelectorIcon = (
    <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            y="1.35711"
            width="19.6785"
            height="1.35714"
            rx="0.678569"
            fill="#002D59"
        />
        <rect
            y="8.8212"
            width="19.6785"
            height="1.35714"
            rx="0.678569"
            fill="#002D59"
        />
        <rect
            y="16.2857"
            width="19.6785"
            height="1.35714"
            rx="0.678569"
            fill="#002D59"
        />
        <circle
            cx="14.67"
            cy="16.9643"
            r="1.53571"
            fill="#002E5A"
            stroke="#002D59"
        />
        <circle
            cx="5.00849"
            cy="9.50025"
            r="1.53571"
            fill="#002E5A"
            stroke="#002D59"
        />
        <circle
            cx="14.67"
            cy="2.03571"
            r="1.53571"
            fill="#002E5A"
            stroke="#002D59"
        />
    </svg>
);

export { FilterSelectorIcon };
