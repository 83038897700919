import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import Optional from 'optional-js';
import { Link } from 'react-router-dom';
import { post } from '../../shared/lib/request-wrapper';
import { log } from '../../shared/lib/util';
import { URL } from 'src/app/config';
import { checkAppTitle, classNames, sortArray } from 'src/shared/lib';
import PropTypes from 'prop-types';
import './table.scss';
import { DisplayDataStatus } from './ui/display-data-status';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    setDataIsFetching,
    setNeedsUpdate,
    setPersistedTableState,
    setOffset,
    setTotalCountForPagination,
} from 'src/redux/actions/table';
import { firePageContentChangedEvent } from 'src/shared/ui';
import { dataCache } from 'src/shared/lib/cache';

/**
 * @onItemClick - make <Table/> clickable and send table row in callback
 * @url - address ti request for table, make it then request is full (offset + size) and this data new
 * @actions - JSX to display on the right side of table header
 * @transformBody - function to change request body properties before sending request
 */

let defaultTable = {
    code: 0,
    message: 'Success',
    payload: [
        {
            name: 'Сумма (₽)',
            groupName: 'Терминал',
            paramNames: [
                'Дата',
                'Терминал',
                'Успешных',
                'Неуспешных',
                'Возвратов',
                'Всего',
            ],
            tuples: [],
        },
        {
            name: 'Количество',
            groupName: 'Терминал',
            paramNames: [
                'Дата',
                'Терминал',
                'Успешных',
                'Неуспешных',
                'Возвратов',
                'Всего',
            ],
            tuples: [],
        },
    ],
};

class StoreAwareTable extends Component {
    constructor(props) {
        super(props);
        this.subscription = null;
        this.state = {
            table: undefined,
            size: undefined,
            sizeLastPage: undefined,
            offset: undefined,
            filter: undefined,
            fetch: false,
            initialFetch: true,
            isEmpty: true,
            lastRequestCanceled: false,
            forceLoadingScreen: false,
            forceNoDataScreen: false,
        };
        this.props.dispatch(setDataIsFetching(props.tableName, false));
    }

    checkUrlAndTerminalIds(url, terminalIds) {
        const urlToСheck = [
            URL.statisticTableUrl,
            URL.operationsUrl,
            URL.settlementUrl,
            URL.settlementDetailsUrl,
        ];

        const { isMerchantApp } = checkAppTitle();
        const clearUrl = url.replace(/[\d]/g, '');

        if (
            isMerchantApp &&
            urlToСheck.includes(clearUrl) &&
            isEmpty(terminalIds)
        ) {
            this.props.dispatch(
                setTotalCountForPagination(this.props.tableName, 0)
            );
            this.setState({
                table: {},
                initialFetch: true,
            });
            return false;
        }

        return true;
    }

    forceLoadingScreen() {
        this.setState({ forceLoadingScreen: true });
        setTimeout(() => this.setState({ forceLoadingScreen: false }), 500);
    }

    forceNoDataScreen() {
        this.setState({
            forceNoDataScreen: true,
        });
        setTimeout(() => this.setState({ forceNoDataScreen: false }), 500);
    }

    componentDidUpdate(previousProps) {
        if (this.state.lastRequestCanceled) {
            this.forceNoDataScreen();
            this.setState({ lastRequestCanceled: false });
        }

        if (this.props.table?.[this.props.tableName]?.needsUpdate) {
            const keysBySeparator = this.props.tableName.split('/');
            dataCache.clear(keysBySeparator);
            this.getTable();
            this.props.dispatch(setNeedsUpdate(this.props.tableName, false));
            return;
        }

        if (
            this.props.table?.[this.props.tableName]?.offset !== undefined &&
            this.props.table?.[this.props.tableName]?.pageSize !== undefined
        ) {
            const paginationChanged = this.paginationChanged(
                previousProps,
                this.state
            );

            if (this.needUpdate(previousProps)) {
                if (
                    this.checkUrlAndTerminalIds(
                        this.props.url,
                        this.props.useCommonFilters
                            ? this.props.commonFilters?.terminalIds
                            : this.props.table?.[this.props.tableName]?.filters
                                  ?.terminalIds
                    )
                ) {
                    this.getTable();
                    // reset pagination offset on filters change
                    !paginationChanged &&
                        this.props.dispatch(setOffset(this.props.tableName, 0));
                }
            }
        } else {
            log('Aware not full request');
        }
    }

    getTable(
        request = {
            size: this.props.table?.[this.props.tableName]?.pageSize,
            offset: this.props.table?.[this.props.tableName]?.offset,
            ...this.props.table?.[this.props.tableName]?.filters,
            ...(this.props.useCommonFilters && this.props.commonFilters),
            endDate: (this.props.useCommonFilters
                ? this.props.commonFilters?.endDate
                : this.props.table?.[this.props.tableName]?.endDate
            )?.format('YYYY-MM-DD'),
            startDate: (this.props.useCommonFilters
                ? this.props.commonFilters?.startDate
                : this.props.table?.[this.props.tableName]?.startDate
            )?.format('YYYY-MM-DD'),
        }
    ) {
        const backendRequest = {
            ...request,
            size: request.size,
            offset: request.offset,
        };

        if (this.props.updateTableCallback)
            this.props.updateTableCallback(backendRequest);

        if (this.props.transformBody) {
            this.props.transformBody(backendRequest);
        }

        if (this.props.emptyTerminalResetData) {
            if (backendRequest.terminalIds.length === 0) {
                this.setState({
                    fetch: false,
                    table: defaultTable,
                    end: 0,
                    sizeLastPage: 0,
                    isEmpty: true,
                });
                this.props.dispatch(
                    setDataIsFetching(this.props.tableName, false)
                );
                return;
            }
        }

        if (this.props.isRequestDisabled) {
            this.setState({
                fetch: false,
                table: defaultTable,
                end: 0,
                sizeLastPage: 0,
                isEmpty: true,
                totalCountForPagination: 0,
            });
            this.props.dispatch(setDataIsFetching(this.props.tableName, false));
            this.props.dispatch(
                setTotalCountForPagination(this.props.tableName, 0)
            );
            return;
        }

        const cacheKey = this.generateCacheKey(backendRequest);
        const keysBySeparator = this.props.tableName.split('/');
        const cachedData = dataCache.get([...keysBySeparator, cacheKey]);

        if (cachedData) {
            this.setState({
                table: cachedData,
                end: this.countPageToEnd(cachedData),
                sizeLastPage: this.getSizeLastPage(cachedData),
                isEmpty,
            });

            this.props.dispatch(
                setPersistedTableState(this.props.tableName, cachedData)
            );
            this.props.dispatch(
                setTotalCountForPagination(
                    this.props.tableName,
                    cachedData.totalCountForPagination
                )
            );
        } else {
            this.setState({ fetch: true });
            this.props.dispatch(setDataIsFetching(this.props.tableName, true));
            this.setState({ initialFetch: false });
            this.forceLoadingScreen();

            this.requestData(backendRequest);
        }
    }

    requestData(backendRequest) {
        post(this.props.url, backendRequest, undefined, this.props.token)
            .then((response) => {
                this.setState({ fetch: false });
                this.props.dispatch(
                    setDataIsFetching(this.props.tableName, false)
                );
                Optional.ofNullable(response.data)
                    .map((data) => data.payload)
                    .ifPresent((payload) => {
                        let { data } = this.tableDataMapper(payload);
                        let isEmpty = data.length === 0;
                        this.setState({
                            table: payload,
                            end: this.countPageToEnd(payload),
                            sizeLastPage: this.getSizeLastPage(payload),
                            isEmpty,
                        });

                        const cacheKey = this.generateCacheKey(backendRequest);
                        const keysBySeparator = this.props.tableName.split('/');
                        dataCache.set([...keysBySeparator, cacheKey], payload);

                        this.props.dispatch(
                            setPersistedTableState(
                                this.props.tableName,
                                payload
                            )
                        );
                        // hack to obtain total pagination count from api response
                        // for now count is on top level or in an array at top level
                        this.props.dispatch(
                            setTotalCountForPagination(
                                this.props.tableName,
                                payload?.[0]?.totalCountForPagination ||
                                    payload.totalCountForPagination
                            )
                        );
                        if (this.props.onGetTable)
                            this.props.onGetTable(payload);
                        if (this.props.makeScroll) this.props.makeScroll();
                    });

                firePageContentChangedEvent();
            })
            .catch((response) => {
                // Unset fetch here only if it's for reasons other than cancel
                if (response === 'request canceled') {
                    this.setState({ lastRequestCanceled: true });
                    return;
                }
                this.setState({ fetch: false });
                this.props.dispatch(
                    setDataIsFetching(this.props.tableName, false)
                );
                log(response);
            });
    }

    paginationChanged(previousProps) {
        return (
            previousProps.table?.[this.props.tableName]?.offset !==
                this.props.table?.[this.props.tableName]?.offset ||
            previousProps.table?.[this.props.tableName]?.pageSize !==
                this.props.table?.[this.props.tableName]?.pageSize
        );
    }

    commonFiltersChanged(previousProps) {
        return (
            previousProps.commonFilters?.endDate !==
                this.props.commonFilters?.endDate ||
            previousProps.commonFilters?.startDate !==
                this.props.commonFilters?.startDate ||
            JSON.stringify(previousProps.commonFilters?.terminalIds) !==
                JSON.stringify(this.props.commonFilters?.terminalIds)
        );
    }

    needUpdateCommonFilters(previousProps) {
        return (
            this.commonFiltersChanged(previousProps) ||
            this.paginationChanged(previousProps)
        );
    }

    needUpdateTableIdSpecific(previousProps) {
        return (
            this.filtersChanged(previousProps) ||
            this.paginationChanged(previousProps)
        );
    }

    filtersChanged(previousProps) {
        return (
            previousProps.table?.[this.props.tableName]?.endDate !==
                this.props.table?.[this.props.tableName]?.endDate ||
            previousProps.table?.[this.props.tableName]?.startDate !==
                this.props.table?.[this.props.tableName]?.startDate ||
            JSON.stringify(
                previousProps.table?.[this.props.tableName]?.filters
            ) !==
                JSON.stringify(
                    this.props.table?.[this.props.tableName]?.filters
                )
        );
    }

    needUpdate(previousProps) {
        return (
            this.needUpdateCommonFilters(previousProps) ||
            this.needUpdateTableIdSpecific(previousProps)
        );
    }

    countPageToEnd(payload) {
        if (this.props.toggle !== undefined) {
            if (!isEmpty(payload)) {
                return Math.ceil(
                    (payload[0].tuples.length - this.state.size) /
                        this.state.size
                );
            } else {
                return 0;
            }
        } else {
            return Math.ceil(
                (payload.tuples.length - this.state.size) / this.state.size
            );
        }
    }

    getSizeLastPage(payload) {
        let count = 0;
        if (Array.isArray(payload)) {
            count = payload[0].tuples.length;
        } else {
            count = payload.tuples.length;
        }

        if (count < this.state.size) {
            return count;
        } else {
            return this.state.size;
        }
    }

    generateCacheKey(filters) {
        return JSON.stringify(
            Object.fromEntries(Object.entries(filters).sort())
        );
    }

    componentDidMount() {
        if (
            this.props.table?.[this.props.tableName]?.tableData &&
            !this.props?.location?.state?.redirectedThroughNavbar
        ) {
            this.setState({
                table: this.props.table?.[this.props.tableName]?.tableData,
            });
            window.history.replaceState({}, document.title);
            return;
        }
        if (
            this.checkUrlAndTerminalIds(
                this.props.url,
                this.props.useCommonFilters
                    ? this.props.commonFilters?.terminalIds
                    : this.props.table?.[this.props.tableName]?.filters
                          ?.terminalIds
            ) &&
            this.props.table?.[this.props.tableName]?.offset !== undefined &&
            this.props.table?.[this.props.tableName]?.pageSize !== undefined
        ) {
            this.getTable();
        }
    }

    filter(filter) {
        return filter ? filter : this.state.filter;
    }

    componentWillUnmount() {
        if (this.subscription) this.subscription.unsubscribe();
    }

    render() {
        const { data, header } = this.tableDataMapper(this.state.table);

        const totalCount =
            this.props.table?.[this.props.tableName]?.totalCountForPagination;
        return (
            <>
                <div className={'table-header-wrapper'}>
                    {!this.props.totalPaginationCountHidden && (
                        <span className={'total-count-wrapper'}>
                            Найдено записей{' '}
                            <span className={'highlight'}>
                                {totalCount > 0 ? totalCount : 0}
                            </span>
                        </span>
                    )}
                    {totalCount > 0 && (
                        <div className="actions-wrapper">
                            {this.props.actions}
                        </div>
                    )}
                </div>

                <Table
                    fetch={this.state.fetch}
                    forceLoadingScreen={this.state.forceLoadingScreen}
                    forceNoDataScreen={this.state.forceNoDataScreen}
                    onItemClick={this.props.onItemClick}
                    data={data}
                    headers={header}
                    initialFetch={this.state.initialFetch}
                    {...this.props}
                />
            </>
        );
    }

    tableDataMapper(table) {
        let { toggle } = this.props;
        if (table && (!isEmpty(table.tuples) || isArray(table))) {
            if (toggle !== undefined) {
                return convert(
                    table[toggle].tuples.slice(0, this.state.size),
                    table[toggle].paramNames
                );
            } else {
                return convert(
                    table.tuples.slice(0, this.state.size),
                    table.paramNames
                );
            }
        } else {
            return { data: [], header: [] };
        }

        function convert(data, header) {
            return {
                data: [
                    ...data.map((i) => {
                        return { data: [...i.values], ID: i.name };
                    }),
                ],
                header: [...header],
            };
        }
    }
}

class Table extends Component {
    numericalColumn = [];

    isNumericalColumn(name) {
        return [
            'спешных',
            'Возвратов',
            'Всего',
            'Сумма',
            'Кол-во',
            'Комиссия',
            'Возмещение',
            'Количество',
        ].some((value) => name.includes(value));
    }

    editableRow = (type, rowId) => {
        return {
            removeCompanyOption: (
                <span
                    title="удалить компанию?"
                    onClick={(event) => {
                        event.preventDefault(); // Standard
                        this.props.deleting(this.props.mainCompanyId, rowId);
                    }}
                >
                    <i className="mdi mdi-remove-circle-outline" />
                </span>
            ),
            editOption: this.props.editing ? (
                <span
                    title="редактировать?"
                    onClick={(event) => {
                        event.preventDefault(); // Standard
                        this.props.editing(rowId);
                    }}
                >
                    <i className="mdi mdi-edit" />
                </span>
            ) : null,
        }[type];
    };

    drawRow = (row, index) => {
        if (this.props.link || this.props.redirect) {
            let rowCls =
                this.props.lastSelected === row.ID
                    ? 'hover lastSelected'
                    : 'hover';
            return (
                <tr key={`${row.ID}${index}`} className={rowCls}>
                    {row.data.map((rowBlock, secondIndex) =>
                        this.drawRowBlock(
                            row.ID,
                            rowBlock,
                            index,
                            secondIndex,
                            row.ID,
                            this.props.redirect
                                ? this.props.redirect(row)
                                : undefined,
                            this.props.locationUrl !== URL.einvoice
                                ? row.ID
                                : undefined,
                            row.data.length
                        )
                    )}
                </tr>
            );
        } else {
            if (this.props.onItemClick) {
                if (row.ID === undefined) {
                    return (
                        <tr key={`${row.ID}${index}`} className="no-hover">
                            {row.data.map((rowBlock, secondIndex) =>
                                this.drawRowBlock(
                                    row.ID,
                                    rowBlock,
                                    index,
                                    secondIndex
                                )
                            )}
                        </tr>
                    );
                } else {
                    return (
                        <tr
                            onClick={(e) => this.props.onItemClick(row)}
                            key={`${row.ID}${index}`}
                            className="hover"
                        >
                            {row.data.map((rowBlock, secondIndex) =>
                                this.drawRowBlock(
                                    row.ID,
                                    rowBlock,
                                    index,
                                    secondIndex
                                )
                            )}
                        </tr>
                    );
                }
            } else {
                return (
                    <tr key={`${row.ID}${index}`} className="no-hover">
                        {row.data.map((rowBlock, secondIndex) =>
                            this.drawRowBlock(
                                row.ID,
                                rowBlock,
                                index,
                                secondIndex
                            )
                        )}
                    </tr>
                );
            }
        }
    };

    drawRowBlock = (
        rowName,
        rowBlock,
        index,
        secondIndex,
        redirectKey,
        redirect,
        rowId = undefined,
        rowLength = undefined
    ) => {
        let cls = this.numericalColumn.includes(secondIndex)
            ? 'number'
            : 'string';
        // hack to inline html in request for terminals
        if (
            this.props.headers.includes('Id терминала') &&
            secondIndex === 0 &&
            (window.location.pathname.includes('terminals') ||
                window.location.pathname.includes('inventory'))
        ) {
            return (
                <td
                    className={cls}
                    key={`${rowName}${index}${secondIndex}`}
                    style={{ padding: '0.75rem' }}
                    dangerouslySetInnerHTML={{ __html: rowBlock }}
                ></td>
            );
        }
        return this.props.link || this.props.redirect ? (
            <td
                className={cls}
                title={rowBlock}
                key={`${rowName}${index}${secondIndex}`}
            >
                <Link
                    style={
                        rowId
                            ? {
                                  display: 'flex',
                                  justifyContent: 'space-between',
                              }
                            : null
                    }
                    to={redirect ? redirect : this.props.link + redirectKey}
                >
                    {rowBlock}
                    {!this.props.readonly &&
                    secondIndex &&
                    rowId &&
                    secondIndex === rowLength - 1
                        ? this.editableRow(this.props.type, rowId)
                        : null}
                </Link>
            </td>
        ) : this.props.companyHistory &&
          secondIndex === this.props.headers.indexOf('Последний вход') ? (
            <td
                className={cls}
                key={`${rowName}${index}${secondIndex}`}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.75rem',
                }}
            >
                {rowBlock}
                <span
                    onClick={this.props.companyHistory}
                    title="показать историю?"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 15,
                    }}
                >
                    <i className="mdi mdi-history" />
                </span>
            </td>
        ) : (
            <td
                className={cls}
                key={`${rowName}${index}${secondIndex}`}
                style={{ padding: '0.75rem' }}
            >
                {rowBlock}
            </td>
        );
    };

    sortData(data, type) {
        if (this.props.link === '/operations/') {
            return this.sortOperationsData(data, type);
        }
        return data;
    }

    sortOperationsData(array, type = '') {
        const order = [8, 0, 2, 3, 4, 1, 5, 6, 7, 9, 10];

        if (type === 'headers') {
            return sortArray(array, order);
        } else {
            const result = [];
            array.forEach((item) => {
                result.push({ ...item, data: sortArray(item.data, order) });
            });
            return result;
        }
    }

    render() {
        this.numericalColumn = [];
        const { headers, data, fetch, forceLoadingScreen, forceNoDataScreen } =
            this.props;

        return (
            <div
                className={classNames('common-table', {
                    'with-total': !this.props.totalPaginationCountHidden,
                })}
            >
                {data && data.length > 0 && !forceLoadingScreen && !fetch ? (
                    <table className="table" cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr className="table-header">
                                {this.sortData(headers, 'headers').map(
                                    (name, i) => {
                                        if (this.isNumericalColumn(name)) {
                                            this.numericalColumn.push(i);
                                            return (
                                                <th
                                                    className={'number'}
                                                    key={name}
                                                >
                                                    {name}
                                                </th>
                                            );
                                        }
                                        return <th key={name}>{name}</th>;
                                    }
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {this.sortData(data).map((e, i) =>
                                this.drawRow(e, i)
                            )}
                        </tbody>
                    </table>
                ) : (fetch || forceLoadingScreen) && !forceNoDataScreen ? (
                    <DisplayDataStatus
                        newStatuses={this.props.newStatuses}
                        tableName={this.props.tableName}
                        variant={DisplayDataStatus.variant.LOADING}
                    />
                ) : (
                    <DisplayDataStatus
                        newStatuses={this.props.newStatuses}
                        tableName={this.props.tableName}
                        variant={
                            this.props.initialFetch || forceNoDataScreen
                                ? DisplayDataStatus.variant.INITIAL
                                : DisplayDataStatus.variant.NO_DATA
                        }
                    />
                )}
            </div>
        );
    }
}

StoreAwareTable.propTypes = {
    onGetTable: PropTypes.func,
    updateTableCallback: PropTypes.func,
    makeScroll: PropTypes.func,
    onItemClick: PropTypes.func,
    lastSelected: PropTypes.string,
    transformBody: PropTypes.func,
    tableName: PropTypes.string,
};

const mapStateToProps = (state) => ({
    table: state.table,
    commonFilters: state.table?.commonFilters,
});

export default withRouter(
    connect(mapStateToProps, null, null, {
        forwardRef: true,
    })(StoreAwareTable)
);
