import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { changePassword } from 'src/redux/actions/auth';
import { PublicFormWidget } from 'src/widgets';
import {
    ChangePasswordFormFields,
    ChangePasswordRequest,
    schema,
} from './config';
import { NewPasswordInput } from 'src/features';
import { FormField, Button, InputPassword } from 'src/shared/ui';

interface Props {
    inline?: boolean;
}

export const Form: FC<Props> = ({ inline }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation<{ login: string }>();
    const { fetch } = useSelector((state) => state.fetch);
    const { email } = useSelector((state) => state.auth);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        watch,
    } = useForm<ChangePasswordFormFields>({
        defaultValues: {
            currentPassword: '',
            newPassword: '',
            repeatPassword: '',
        },
        resolver: yupResolver(schema),
    });

    const currentPassword = watch('currentPassword');

    const submit = (values: ChangePasswordFormFields) => {
        let body: ChangePasswordRequest = {
            email: email ?? location.state.login,
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
        };
        dispatch(changePassword(body, history));
    };

    const Form = (
        <form onSubmit={handleSubmit(submit)}>
            <FormField>
                <InputPassword
                    {...register('currentPassword')}
                    type={'password'}
                    placeholder="Введите текущий пароль"
                    autoComplete="new-password"
                    error={errors.currentPassword?.message}
                    fluid
                />
            </FormField>
            <FormField>
                <Controller
                    name="newPassword"
                    control={control}
                    render={({ field }) => (
                        <NewPasswordInput
                            error={errors['newPassword']?.message}
                            field={field}
                            placeholder="Введите новый пароль"
                            inputProps={{ fluid: true }}
                            currentPassword={
                                inline ? currentPassword : undefined
                            }
                        />
                    )}
                />
            </FormField>
            <FormField>
                <InputPassword
                    {...register('repeatPassword')}
                    placeholder="Повторите новый пароль"
                    error={errors.repeatPassword?.message}
                    fluid
                    type={'password'}
                />
            </FormField>
            <Button fluid type="submit" loading={fetch} disabled={fetch}>
                Сменить пароль
            </Button>
        </form>
    );

    return inline ? (
        Form
    ) : (
        <PublicFormWidget header="Изменение пароля">
            <div>{Form}</div>
        </PublicFormWidget>
    );
};
