import { Changelog } from './changelog-types';

export const BANK_CHANGELOG: Changelog = {
    '2.3.0 30-11-2023': {
        title: 'Журнал изменений, кеширование, общие фильтры',
        description: [
            'Добавлен журнал изменений, здесь, начиная с текущей версии будут появляться описания изменений внесенных в приложение.',
            'На экранах "Операции", "Операции СБП", "Возмещения" после перехода в детали и обратно теперь используются ранее загруженные данные.',
            'Страницы "Операции" и "Возмещения" теперь имеют "общие" фильтры дат и терминалов, изменения значений этих фильтров на одной из страниц автоматически применяются к остальным.',
        ],
    },
    '2.4.0 04-12-2023': {
        title: 'Фильтрация счетов',
        description: ['Добавлена фильтрация счетов по способу оплаты Mir Pay'],
    },
    '2.4.12 19-03-2024': {
        title: 'Общие исправления',
        description: [
            'Исправлено отображение версии приложения как ссылки для мерчанта',
            'Удалены кнопки редактирования в таблицах счетов',
            'Исправлено отображение календаря в фильтрации счетов на странице управления ТСП',
            'Исправлено отображение строки поиска по ключу мерчанта',
            'Исправлено отображение таблицы счетов в ЛК банка',
            'Исправлено кеширование таблиц на страницах',
            'Исправлено обновление данных в таблицах при удалении счета или компании',
            'Модальное окно логов изменений отображается только для банка',
            'Исправлено отображение клавиатуры телефона при выборе даты для создания счета',
            'Исправлено отображение графиков в ряд при горизонтальном положении экрана телефона',
            'Исправлена ошибка отображения графика конверсии при наведении',
            'В мобильной версии модального окна подписки исправлено отображение элементов поверх друг друга',
            'Исправлено отображение фильтров в мобильной версии динамики',
            'Исправлено выпадение элементов за контейнер модального окна',
            'Исправлено отсутствие уведомления при создании пользователя',
            'Исправлена ошибка при создании счета',
        ],
    },
    '2.5.0 26-03-2024': {
        title: 'Кеширование, журнал изменений',
        description: [
            'Добавлено кеширование всех страниц таблиц по фильтрам',
            'Изменен порядок логов изменений на "Сначала новые"',
        ],
    },
    '2.5.1 24-04-2024': {
        title: 'Кеширование',
        description: [
            'Исправлено отображение идентификаторов терминалов в фильтре на странице статистики',
            'Исправлено отображение устаревших данных после создания или обновления',
            'Исправлено отображение одинаковых данных для разных таблиц',
            'Скрыта кнопка об отправке квитанции в ЛК банка',
        ],
    },
    '2.5.2 06-05-2024': {
        title: 'Кеширование, графики статистики, мелкие исправления',
        description: [
            'Исправлено отстутствие идентификаторов терминалов в фильтрации',
            'Исправлены ошибки в графиках на странице статистики при наличии только одного столбца',
            'Исправлено отсутствие ошибки при создании пользователя с существующим email',
            'Исправлено отстутствие уведомлений при удалении и добавлении связанной компании в управлении ТСП',
            'Исправлено кеширование в управлении ТСП',
        ],
    },
    '2.5.3 17-05-2024': {
        title: 'Операции, инвентарь, возвраты',
        description: [
            'На странице операций в фильтрации по терминалам удалено отображение идентификатора организации',
            'Исправлена смена группы терминала на "Без группы"',
            'Исправлено отображение количество записей на странице операций СБП',
            'Исправлено некорректное отображение данных при смене фильтров на странице возвратов',
        ],
    },
    '2.5.4 31-05-2024': {
        title: 'Выгрузка файлов, уведомления, мелкие исправления',
        description: [
            'Исправлена загрузка данных без выбранных идентификаторов на странице "Операции СБП"',
            'Исправлена фильтрация при выгрузке файлов на страницах "Возмещение" и "Операции СБП"',
            'Исправлена возможность внести сумму возврата больше, чем сумма платежа на странице "Операции СБП"',
            'Исправлена ошибка при формировании QR-кода с несколькими символами перехода на следующую строку на странице "Счета"',
            'Исправлено отсутствие уведомлений при создании или редактировании на страницах "Настройки", "Управление ТСП - Пользователи", "Управление пользователями", "Управление ТСП - Счета"',
        ],
    },
    '2.5.5 17-06-2024': {
        title: 'Мелкие исправления',
        description: [
            'Исправлены проблемы с фильтром "Идентификатор СБП" на странице "Операции СБП"',
            'Исправлена фильтрация по организации на странице "Возмещения" в ЛК Банка',
            'Исправлено отображение устаревших данных в таблице "Группы" при редактировании групп терминалов на странице "Терминалы"',
            'Исправлено отсутствие уведомлений при редактировании пользователей на странице "Управление ТСП - Пользователи"',
        ],
    },
    '2.5.6 15-07-2024': {
        title: 'Мелкие исправления',
        description: [
            'Исправлена выгрузка файлов с фильтрацией по типу QR в ЛК Банка',
            'Исправлена ошибка на странице "Возвраты"',
        ],
    },
};
