import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { URL } from 'src/app/config';
import AwareTable from 'src/components/AwareTable';
import { localLoad, localSave } from 'src/shared/lib';
import { OverflowContainer, SearchField, Paginator } from 'src/shared/ui';
import { FiltersContainer } from 'src/widgets';

import './cashboxes-page.scss';
import { setFilters } from 'src/redux/actions/table';

const tableName = 'Company-Cashboxes';

export const CashBoxes = () => {
    return (
        <div className="cashboxes-page">
            <CashBoxesFilter pageTitle={tableName} />
            <Paginator name={tableName}>
                <OverflowContainer>
                    <AwareTable
                        isStoreTable
                        url={URL.cashBoxes}
                        link="/company/"
                        tableName={tableName}
                    />
                </OverflowContainer>{' '}
            </Paginator>
        </div>
    );
};

export const CashBoxesFilter = () => {
    const queryLocalName = `${tableName}-query`;
    const dispatch = useDispatch();
    const searchQuery = useSelector(
        (state) => state.table?.[tableName]?.filters.searchQuery
    );
    dispatch(
        setFilters(tableName, { searchQuery: localLoad(queryLocalName, '') })
    );

    return (
        <div className={'cashboxes-filter-wrapper'}>
            <FiltersContainer>
                <SearchField
                    fullsize
                    placeholder="Введите компанию или ключ мерчанта"
                    key={searchQuery}
                    value={searchQuery}
                    onChange={(value) => {
                        dispatch(setFilters(tableName, { searchQuery: value }));
                        localSave(queryLocalName, value);
                    }}
                />
            </FiltersContainer>
        </div>
    );
};
