import React, { Component } from 'react';
import { connect } from 'react-redux';

import { navRoutes, URL } from 'src/app/config';
import { getInitialFilter, LOCAL_STORAGE_TABLE_NAMES } from 'src/shared/lib';

import AwareTable from 'src/components/AwareTable';

import OperationsFilter from './OperationsFilter';
import { OverflowContainer, Paginator } from 'src/shared';
import { DownloadReportStore } from 'src/features/download-report';

import './operations-page.scss';

const pageTitle = LOCAL_STORAGE_TABLE_NAMES.operationsSBP;

class OperationsSBPPage extends Component {
    render() {
        return (
            <div className="operations-page">
                <OperationsFilter pageTitle={pageTitle} />

                <Paginator name={pageTitle}>
                    <OverflowContainer>
                        <AwareTable
                            isRequestDisabled={
                                !this.props.table?.[pageTitle]?.filters
                                    ?.merchantIds?.length
                            }
                            token={this.props.cancelationToken}
                            newStatuses
                            tableName={pageTitle}
                            url={URL.operationsSBPUrl}
                            link={navRoutes.operationsSBP}
                            isStoreTable
                            actions={[
                                <DownloadReportStore
                                    pageTitle={pageTitle}
                                    defaultFileName="Операции СБП"
                                    url={URL.operationsSBPFileDownloadUrl}
                                    availableFormats={['XLSX']}
                                    filterSubject={this.filterSubject}
                                    getFilterCallback={() =>
                                        getInitialFilter(
                                            ['searchQuery', 'merchantIds'],
                                            pageTitle
                                        )
                                    }
                                />,
                            ]}
                        />
                    </OverflowContainer>
                </Paginator>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        fetch: state.fetch.fetch,
        cancelationToken: state.requestCancelation.token,
        table: state.table,
    };
}

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(OperationsSBPPage);
