import React, {
    FC,
    MutableRefObject,
    useState,
    useEffect,
    useCallback,
    CSSProperties,
    useLayoutEffect,
    useRef,
} from 'react';
import { isMobile } from 'react-device-detect';
import { classNames, useComponentDidMount } from 'src/shared/lib';
import { Portal } from '../portal/portal';
import styles from './popup.module.scss';

export const enum Position {
    TOP_LEFT = 'top/left',
    TOP_RIGHT = 'top/right',
    TOP_CENTER = 'top/center',
    BOTTOM_LEFT = 'bottom/left',
    BOTTOM_RIGHT = 'bottom/right',
    BOTTOM_CENTER = 'bottom/center',
    HIDDEN = 'hidden',
}

// TODO нужно дописать расчеты для всех сценариев
const getPositionPopup = (
    widthWindow: number,
    heightWindow: number,
    triggerRect: DOMRect,
    marginFromTrigger: number,
    maxHeightPopup: number,
    parentRect?: DOMRect
) => {
    const bottomPopup = triggerRect.bottom + marginFromTrigger + maxHeightPopup;

    if (parentRect) {
        if (triggerRect.top < parentRect.top) {
            return Position.HIDDEN;
        }

        if (triggerRect.bottom > parentRect.bottom) {
            return Position.HIDDEN;
        }
    }

    if (triggerRect.top >= heightWindow) {
        return Position.HIDDEN;
    }

    if (triggerRect.bottom <= 0) {
        return Position.HIDDEN;
    }

    if (bottomPopup >= heightWindow && triggerRect.top < heightWindow) {
        return Position.TOP_LEFT;
    }

    return Position.BOTTOM_LEFT;
};

const maxDistanceFromWindowBottom = (
    element: HTMLElement | null,
    bottomPopup: number
) => {
    if (!element) {
        return 0;
    }

    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const distanceFromWindowBottom = windowHeight - rect.bottom;

    return bottomPopup > distanceFromWindowBottom
        ? distanceFromWindowBottom
        : bottomPopup;
};

interface PopupProps {
    triggerRef: MutableRefObject<HTMLElement | null>;
    maxHeightPopup: number;
    hiddenPopup: () => void;
    idScrollElement?: string; // id родительского элемента в котором расположен trigger
    maxWidthPopup?: 'max' | 'trigger';
    position?: Position;
    marginFromTrigger?: number;
    className?: string;
    hideWhenResizing?: boolean;
    maxWidth?: number;
}

export const Popup: FC<PopupProps> = (props) => {
    const {
        triggerRef,
        idScrollElement,
        maxHeightPopup: defaultMaxHeightPopup,
        hiddenPopup,
        maxWidthPopup,
        maxWidth,
        position,
        marginFromTrigger = 4,
        className,
        children,
        hideWhenResizing = false,
    } = props;

    const isMounted = useComponentDidMount();
    const parentElementWithScroll = useRef<HTMLElement | null>(null);
    const bodyRef = useRef<HTMLDivElement | null>(null);
    const timerRef = useRef() as MutableRefObject<
        ReturnType<typeof setTimeout>
    >;

    const [widthPopup, setWidthPopup] = useState<number | undefined>(undefined);
    const [topPopup, setTopPopup] = useState<number | undefined>(undefined);
    const [bottomPopup, setBottomPopup] = useState<number | undefined>(
        undefined
    );
    const [leftPopup, setLeftPopup] = useState<number | undefined>(undefined);
    const [rightPopup, setRightPopup] = useState<number | undefined>(undefined);

    const maxHeightPopup =
        defaultMaxHeightPopup > window.innerHeight
            ? window.innerHeight
            : defaultMaxHeightPopup;

    const setRectPopup = useCallback(
        (position?: Position) => {
            if (triggerRef?.current && bodyRef?.current) {
                const fullWidthWindow = window.innerWidth; // включая scrollbar

                // задает ширину документа в зависимости от наличия scrollbar
                const widthWindow = idScrollElement
                    ? fullWidthWindow
                    : document.body.clientWidth;
                const heightWindow = document.body.clientHeight;

                const triggerRect = triggerRef?.current.getBoundingClientRect();

                const parentRect =
                    parentElementWithScroll?.current?.getBoundingClientRect();
                // Обязательно нужно отключать горизонтальный скролл в idScrollElement
                const widthScrollParentElement = isMounted
                    ? 0
                    : (parentRect &&
                          Math.ceil(
                              parentRect?.width -
                                  (parentElementWithScroll?.current
                                      ?.scrollWidth || 0)
                          )) ||
                      0;

                const bodyRect = bodyRef?.current.getBoundingClientRect();

                const positionPopup =
                    position ||
                    getPositionPopup(
                        widthWindow,
                        heightWindow,
                        triggerRect,
                        marginFromTrigger,
                        maxHeightPopup,
                        parentRect
                    );

                switch (positionPopup) {
                    case Position.TOP_LEFT:
                        setTopPopup(undefined);
                        setBottomPopup(
                            heightWindow - triggerRect.top + marginFromTrigger
                        );

                        setLeftPopup(
                            triggerRect.left + widthScrollParentElement
                        );
                        setRightPopup(undefined);
                        break;

                    case Position.TOP_CENTER:
                        setTopPopup(undefined);
                        setBottomPopup(
                            heightWindow - triggerRect.top + marginFromTrigger
                        );

                        setLeftPopup(
                            triggerRect.left +
                                widthScrollParentElement +
                                (bodyRect.width - triggerRect.width) / 2
                        );
                        setRightPopup(undefined);
                        break;

                    case Position.TOP_RIGHT:
                        setTopPopup(undefined);
                        setBottomPopup(
                            heightWindow - triggerRect.top + marginFromTrigger
                        );
                        setLeftPopup(undefined);
                        setRightPopup(
                            widthWindow -
                                triggerRect.right -
                                widthScrollParentElement
                        );
                        break;

                    case Position.BOTTOM_LEFT:
                        setTopPopup(triggerRect.bottom + marginFromTrigger);
                        setBottomPopup(undefined);
                        setLeftPopup(
                            triggerRect.left + widthScrollParentElement
                        );
                        setRightPopup(undefined);
                        break;

                    case Position.BOTTOM_CENTER:
                        setTopPopup(triggerRect.bottom + marginFromTrigger);
                        setBottomPopup(undefined);

                        setLeftPopup(
                            triggerRect.left +
                                widthScrollParentElement +
                                (bodyRect.width - triggerRect.width) / 2
                        );
                        setRightPopup(undefined);
                        break;

                    case Position.BOTTOM_RIGHT:
                        setTopPopup(triggerRect.bottom + marginFromTrigger);
                        setBottomPopup(undefined);
                        setLeftPopup(undefined);
                        setRightPopup(
                            widthWindow -
                                triggerRect.right -
                                widthScrollParentElement
                        );
                        break;

                    case Position.HIDDEN:
                        hiddenPopup();
                        break;

                    default:
                        hiddenPopup();
                        break;
                }

                if (maxWidthPopup === 'trigger') {
                    setWidthPopup(triggerRect.width + 1); // 1 -> поправка
                }

                if (maxWidthPopup === 'max') {
                    setRightPopup(widthWindow - (parentRect?.right || 0));

                    if (isMobile) {
                        setLeftPopup(parentRect?.left);
                    }
                }
            }
        },
        [
            maxWidthPopup,
            triggerRef,
            maxHeightPopup,
            marginFromTrigger,
            idScrollElement,
            hiddenPopup,
            isMounted,
        ]
    );

    const handleResize = useCallback(() => {
        if (hideWhenResizing) hiddenPopup();
        setRectPopup();
    }, [hiddenPopup, hideWhenResizing, setRectPopup]);

    const positionPopup = useCallback(() => {
        setRectPopup();
    }, [setRectPopup]);

    const onClose = useCallback(
        (e: globalThis.MouseEvent) => {
            const childrenElement = e.target as HTMLElement;

            if (bodyRef.current && !bodyRef.current.contains(childrenElement)) {
                hiddenPopup();
            }
        },
        [hiddenPopup]
    );

    const onKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                hiddenPopup();
            }
        },
        [hiddenPopup]
    );

    // первая отрисовка компонента
    useLayoutEffect(() => {
        if (idScrollElement) {
            parentElementWithScroll.current =
                document.getElementById(idScrollElement);
        } else {
            parentElementWithScroll.current = document.body;
        }

        setRectPopup(position);
        // gives correct position on click
        positionPopup();
    }, [setRectPopup, position, idScrollElement, positionPopup]);

    // контролирует события мыши и клавиатуры
    useEffect(() => {
        timerRef.current = setTimeout(() => {
            document.addEventListener('click', onClose);
            document.addEventListener('keydown', onKeyDown);
        }, 50);

        return () => {
            clearTimeout(timerRef.current);
            document.removeEventListener('click', onClose);
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [onClose, onKeyDown]);

    // контролирует позициорирование
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        parentElementWithScroll.current?.addEventListener(
            'scroll',
            positionPopup
        );

        return () => {
            window.removeEventListener('resize', handleResize);
            parentElementWithScroll.current?.removeEventListener(
                'scroll',
                positionPopup
            );
        };
    }, [handleResize, positionPopup, idScrollElement, setRectPopup]);

    // TODO если фильтр открыт на момент исчезновения ShowsMessageFromBank
    // он останется висеть оторваным от своей кнопки
    const styleProperties: CSSProperties = {
        position: 'absolute',
        zIndex: 100000,
        top: topPopup,
        bottom: maxDistanceFromWindowBottom(bodyRef.current, bottomPopup || 0),
        left: leftPopup,
        right: rightPopup,
        width: widthPopup,
        maxWidth: maxWidth,
        maxHeight: maxHeightPopup,
    };

    return (
        <Portal>
            <div
                ref={bodyRef}
                style={styleProperties}
                className={classNames(styles.popup, {}, [className])}
            >
                {children}
            </div>
        </Portal>
    );
};
