import { object, string } from 'yup';

interface Credentials {
    login: string;
    password: string;
}

const schema = object({
    login: string().required('Обязательное поле'),
    password: string().required('Обязательное поле'),
});

export type { Credentials };

export { schema };
