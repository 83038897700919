import React, { FC, memo, useEffect, useState } from 'react';
import { filter } from 'rxjs/operators';
import { FileFormat, SubjectValues } from '../../model/types';
import { ModalBody } from '../modal-body/modal-body';
import { TriggerButton } from '../trigger-button/trigger-button';

type Filters = Record<string, unknown>;

interface DownloadReportProps {
    defaultFileName: string;
    url: string;
    initialFilter?: Filters;
    filterSubject?: any;
    isDetailed?: boolean;
    availableFormats?: FileFormat[];
    themeTriggerButton?: 'custom' | 'default';
    nameTriggerButton?: string;
    className?: string;
}

export const DownloadReport: FC<DownloadReportProps> = memo((props) => {
    const {
        themeTriggerButton,
        nameTriggerButton,
        className,
        initialFilter,
        filterSubject,
        ...otherProps
    } = props;

    const [isOpen, setOpen] = useState(false);
    const [filters, setFilters] = useState<Filters>(initialFilter || {});

    const changeToggleModal = () => {
        setOpen((prev) => !prev);
    };

    useEffect(() => {
        const subscription = filterSubject
            ?.pipe(filter((s: SubjectValues) => !!s.filter))
            ?.subscribe((s: SubjectValues) => setFilters({ ...s.filter }));

        return () => {
            subscription?.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TriggerButton
                theme={themeTriggerButton}
                onClick={changeToggleModal}
                className={className}
            >
                {nameTriggerButton}
            </TriggerButton>
            {isOpen && (
                <ModalBody
                    filters={filters}
                    onToggle={changeToggleModal}
                    {...otherProps}
                />
            )}
        </>
    );
});
