/**
 * Listens for hold on element, returns cleanup function that removes event listeners
 *
 */
export const detectTouchEventHold = (
    onHold: () => void,
    onRelease: () => void,
    element: HTMLElement | null
) => {
    let timerId: number;
    const handleTerminalHold = () =>
        (timerId = window.setTimeout(() => onHold(), 500));

    const handleTerminalTouchEnd = () => {
        clearTimeout(timerId);
        onRelease();
    };

    element?.addEventListener('touchstart', handleTerminalHold);
    element?.addEventListener('touchend', handleTerminalTouchEnd);

    return () => {
        element?.removeEventListener('touchend', handleTerminalTouchEnd);
        element?.removeEventListener('touchstart', handleTerminalHold);
    };
};
