import React, { FC, useEffect, useState } from 'react';
import { browserName, browserVersion } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserConfig } from 'src/entities/help';

import { createNotification } from 'src/redux/actions/notification';

import {
    getNotificationWasShown,
    saveNotificationWasShown,
} from '../model/notification-showed-storage';

export const BrowserCheck: FC = ({ children }) => {
    const [wasShown, setWasShown] = useState<boolean>(
        getNotificationWasShown()
    );

    const { logged } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        const isOutdated = () => {
            return BrowserConfig[browserName] > Number(browserVersion);
        };

        // показать уведомление один раз при входе в приложение, если браузер устарел
        if (logged && !wasShown && isOutdated()) {
            setWasShown(true);
            dispatch(
                createNotification(
                    {
                        title: 'Ваш браузер устарел',
                        message:
                            'Пожалуйста, обновите ваш браузер для корректной работы приложения. Список поддерживаемых браузеров можно найти в разделе "Поддержка"',
                    },
                    'error'
                )
            );
        }
    }, [dispatch, wasShown, logged]);

    useEffect(() => {
        // обновлять значение в localStorage, чтобы не показывать сообщение
        // при перезагрузке приложения
        saveNotificationWasShown(wasShown);
    }, [wasShown]);

    useEffect(() => {
        if (!logged) {
            // если пользователь разлогинился, показать снова после логина
            setWasShown(false);
        }
    }, [logged]);

    return <div>{children}</div>;
};
