import React, {
    FC,
    memo,
    MouseEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { SelectedIcon } from 'src/shared';
import { useComponentDidMount } from 'src/shared/lib';
import { checkLengthString, classNames, normalizeData } from 'src/shared/lib';
import {
    addValueInObject,
    deleteKeyFromObject,
    filterArray,
    getCountKeyInObject,
} from '../../lib/helper-functions';
import { SelectOption } from '../../model/types';
import { ModalUiHeader } from '../modal-ui-header/modal-ui-header';
import { ModalUiPopup } from '../modal-ui-popup/modal-ui-popup';
import styles from './selecting-items-from-list.module.scss';

export enum DisplayTheme {
    MODAL = 'MODAL',
    FILTER = 'FILTER',
}

export enum TypeOptions {
    TERMINALS = 'TERMINALS',
    ROLES = 'ROLES',
}

export type SelectedOptions = Record<string, string>;

interface SelectingItemsFromListProps {
    name: string;
    options: SelectOption[];
    onChange: (array: string[]) => void;
    theme: DisplayTheme;
    typeOptions: TypeOptions;
    initOptions?: string[] | number[];
    className?: string;
}

export const SelectingItemsFromList: FC<SelectingItemsFromListProps> = memo(
    (props) => {
        const {
            name,
            options,
            initOptions,
            theme = DisplayTheme.FILTER,
            typeOptions,
            onChange,
            className,
        } = props;

        const triggerRef = useRef<HTMLDivElement | null>(null);
        const selectedOptions = useRef<SelectedOptions>({}).current;
        const [entities] = normalizeData<SelectOption>(options, 'value');

        const [isOpen, setOpen] = useState(false);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, setUpdateComponent] = useState(false);
        const [search, setSearch] = useState('');
        const isComponentDidMount = useComponentDidMount();

        const updateComponent = () => {
            setUpdateComponent((prev) => !prev);
        };

        const onToggle = useCallback(() => {
            setOpen((prev) => !prev);
        }, []);

        const initSelectedOptions = (initOptions?: Array<string | number>) => {
            if (initOptions && initOptions?.length > 0) {
                initOptions.forEach((value) => {
                    addValueInObject(String(value), selectedOptions);
                });
                updateComponent();
            }
        };

        // не работает т.к. onChange всегда новая и в useEffect вызывает бесконечный рендер компоннета
        const onChangeHandler = useCallback(
            (selectedOptions: SelectedOptions) => {
                const newArray = [...Object.keys(selectedOptions)];
                onChange(newArray);
            },
            [onChange]
        );

        const onClickSelectedOption = useCallback(
            (e: MouseEvent<HTMLDivElement>) => {
                const value = e.currentTarget.parentElement?.dataset.value;

                if (value && selectedOptions[value]) {
                    deleteKeyFromObject(value, selectedOptions);
                    updateComponent();

                    if (!isOpen) {
                        onChangeHandler(selectedOptions);
                    }
                }
            },
            [isOpen, selectedOptions, onChangeHandler]
        );

        const onClickOptionInList = (e: MouseEvent<HTMLDivElement>) => {
            const { value } = e.currentTarget.dataset;

            if (value && !selectedOptions[value]) {
                addValueInObject(value, selectedOptions);
            } else if (value && selectedOptions[value]) {
                deleteKeyFromObject(value, selectedOptions);
            }
            updateComponent();
        };

        const onSelectedAll = () => {
            options.forEach((option) => {
                addValueInObject(option.value, selectedOptions);
            });
            updateComponent();
        };

        const onReset = () => {
            Object.keys(selectedOptions).forEach((key) => {
                deleteKeyFromObject(key, selectedOptions);
            });
            updateComponent();
        };

        const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            setSearch(value);
        };

        useEffect(() => {
            if (isComponentDidMount && !isOpen) {
                onChangeHandler(selectedOptions);
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isComponentDidMount, isOpen, selectedOptions]);

        useEffect(() => {
            initSelectedOptions(initOptions);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [initOptions]);

        const renderOptionsList = filterArray(search, options)?.map((opt) => (
            <div
                key={opt.value}
                data-value={opt.value}
                className={classNames(styles.item, {
                    [styles.selected]: !!selectedOptions[opt.value],
                })}
                onClick={onClickOptionInList}
            >
                <span>{SelectedIcon}</span>
                {checkLengthString(opt.content, 67)}
            </div>
        ));

        if (theme === DisplayTheme.MODAL) {
            return (
                <div className={classNames(styles.wrapper, {}, [className])}>
                    <div ref={triggerRef}>
                        <ModalUiHeader
                            name={name}
                            typeOptions={typeOptions}
                            entitiesOptions={entities}
                            selectedOptions={selectedOptions}
                            onClickButton={onToggle}
                            onClickSelectedOption={onClickSelectedOption}
                            numberSelectedItems={getCountKeyInObject(
                                selectedOptions
                            )}
                        />
                    </div>
                    {isOpen && (
                        <ModalUiPopup
                            triggerRef={triggerRef}
                            hiddenPopup={onToggle}
                            search={search}
                            onSearch={onSearch}
                            onSelectedAll={onSelectedAll}
                            onReset={onReset}
                        >
                            {renderOptionsList}
                        </ModalUiPopup>
                    )}
                </div>
            );
        }

        // по умолчанию должен возвращать вариант DisplayTheme.FILTER
        return null;
    }
);
