import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { ReplaySubject } from 'rxjs';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { URL } from 'src/app/config';
import AwareTable from '../../components/AwareTable';

import EpaymentsFilter from './EpaymentsFilter';

import { isEmptyObj, log, sendToSubject } from '../../shared/lib/util';
import { localLoad } from '../../shared/lib/localStorage';
import { get } from '../../shared/lib/request-wrapper';

import './epayments-page.scss';
import { OverflowContainer, Paginator } from 'src/shared';
import { LOCAL_STORAGE_TABLE_NAMES } from 'src/shared/lib';

const pageTitle = LOCAL_STORAGE_TABLE_NAMES.ePayment;

class EpaymentsPage extends Component {
    filterSubject = new ReplaySubject(1);

    constructor(props) {
        super(props);
        this.send = sendToSubject.bind(this);
        this.receipt = {
            id: '',
            companyName: '',
            address: '',
            inn: '',
            fiscalReceiptNumber: '',
            shiftNumber: '',
            taxation: '',
            email: '',
            phone: '',
            emailCompany: '',
            deviceCode: '',
            items: [],
            totalFiscal: '',
            cashless: '',
            fnNumber: '',
            ecrRegistrationNumber: '',
            fiscalDocumentNumber: '',
            fiscalDocumentAttribute: '',
            ffdVersion: '',
        };
        this.state = {
            ...this.receipt,
            open_receipt_info: false,
            isEmptyReceipt: false,
            receipt: {},
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.send({
            offset: localLoad('cashboxes-offset', 0),
            size: localLoad('cashboxes-range', 10),
        });
    };
    toggle_receipt_info = () => {
        this.setState({
            open_receipt_info: !this.state.open_receipt_info,
        });
    };

    getCashboxReceiptInfo = (rnn) => {
        get(URL.recipes + rnn)
            .then((r) => {
                this.setState({
                    receipt: r.data.payload,
                });

                let {
                    id,
                    companyName,
                    address,
                    inn,
                    fiscalReceiptNumber,
                    shiftNumber,
                    taxation,
                    email,
                    phone,
                    emailCompany,
                    deviceCode,
                    items,
                    totalFiscal,
                    cashless,
                    fnNumber,
                    ecrRegistrationNumber,
                    fiscalDocumentNumber,
                    fiscalDocumentAttribute,
                    ffdVersion,
                } = r.data.payload;

                this.setState({
                    id,
                    companyName,
                    address,
                    inn,
                    fiscalReceiptNumber,
                    shiftNumber,
                    taxation,
                    email,
                    phone,
                    emailCompany,
                    deviceCode,
                    items,
                    totalFiscal,
                    cashless,
                    fnNumber,
                    ecrRegistrationNumber,
                    fiscalDocumentNumber,
                    fiscalDocumentAttribute,
                    ffdVersion,
                });
            })
            .catch((r) => {
                this.setState({ open_receipt_info: false });
                log(r);
            });
    };

    render() {
        return (
            <div className="cashboxes-page">
                <EpaymentsFilter pageTitle={pageTitle} />
                <Paginator name={pageTitle}>
                    <OverflowContainer>
                        <AwareTable
                            token={this.props.cancelationToken}
                            newStatuses
                            tableName={pageTitle}
                            url={URL.payments}
                            link={URL.payment}
                            isStoreTable
                            // onItemClick={e => {
                            //     this.toggle_receipt_info();
                            //     this.getCashboxReceiptInfo(e.ID);
                            //
                            // }
                            // }
                        />
                    </OverflowContainer>
                </Paginator>

                {/* TODO эта модалка уже реализована в фиче ShowsInfoAboutReceipt */}
                <Modal
                    isOpen={this.state.open_receipt_info}
                    toggle={this.toggle_receipt_info}
                >
                    <ModalHeader toggle={this.toggle_receipt_info}>
                        Информация о чеке
                    </ModalHeader>
                    <ModalBody className="receipt-modal">
                        <div className="receipt-form">
                            {isEmptyObj(this.state.receipt) ? (
                                <div className="receipt-form__info">
                                    <span>нет чеков</span>
                                </div>
                            ) : (
                                <Fragment>
                                    <div className="receipt-form__header">
                                        <div className="receipt-form__header-info">
                                            <span>
                                                {this.state.receipt.companyName}
                                            </span>
                                        </div>
                                        <div className="receipt-form__header-info">
                                            <span>
                                                ИНН {this.state.receipt.inn}
                                            </span>
                                        </div>
                                        <div className="receipt-form__header-info">
                                            <span>
                                                Место расчётов:{' '}
                                                {this.state.receipt.address}
                                            </span>
                                        </div>
                                        <div className="main-header">
                                            <h5>
                                                КАССОВЫЙ ЧЕК №{' '}
                                                {
                                                    this.state.receipt
                                                        .fiscalReceiptNumber
                                                }{' '}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="receipt-form__info">
                                        <span>
                                            <span className="text">Приход</span>
                                            {this.state.receipt.created}
                                        </span>
                                    </div>
                                    <div className="receipt-form__info">
                                        <span>
                                            <span className="text">Смена</span>
                                            {this.state.receipt.shiftNumber}
                                        </span>
                                    </div>
                                    <div className="receipt-form__info">
                                        <span>
                                            <span className="text">
                                                Применяемая система
                                                налогообложения
                                            </span>
                                            {this.state.receipt.taxation}
                                        </span>
                                    </div>
                                    <div className="receipt-form__info">
                                        <span>
                                            <span className="text">
                                                Телефон покупател
                                            </span>
                                            {this.state.receipt.phone}
                                        </span>
                                    </div>
                                    <div className="receipt-form__info">
                                        <span>
                                            <span className="text">
                                                Электронный адрес покупателя
                                            </span>
                                            {this.state.receipt.email}
                                        </span>
                                    </div>
                                    <div className="receipt-form__info">
                                        <span>
                                            <span className="text">
                                                Адрес электронной почти
                                                отпровителя чека
                                            </span>
                                            {this.state.receipt.emailCompany}
                                        </span>
                                    </div>
                                    <div className="receipt-form__info">
                                        <span>
                                            <span className="text">
                                                Номер автомата
                                            </span>
                                            {this.state.receipt.deviceCode}
                                        </span>
                                    </div>
                                    <hr />
                                    <div className="receipt-form__item">
                                        {this.state.receipt.items.length
                                            ? this.state.receipt.items.map(
                                                  (
                                                      {
                                                          name,
                                                          price,
                                                          quantity,
                                                          amount,
                                                          tax,
                                                          amountTax,
                                                          paymentMethod,
                                                          paymentObject,
                                                      },
                                                      i
                                                  ) => (
                                                      <Fragment key={i}>
                                                          <span>
                                                              <h5 className="receipt-form__item-header">
                                                                  Наименование
                                                                  товара {name}
                                                              </h5>
                                                              <div className="receipt-form__item-quantity">
                                                                  <span>
                                                                      {quantity}{' '}
                                                                      x {price}
                                                                  </span>
                                                              </div>
                                                          </span>
                                                          <div className="receipt-form__item-content">
                                                              <span>
                                                                  <span className="text">
                                                                      Общая
                                                                      стоимость
                                                                      позиции с
                                                                      учетом
                                                                      скидок и
                                                                      наценок
                                                                  </span>
                                                                  {amount}
                                                              </span>
                                                              <span>
                                                                  <span className="text">
                                                                      Ставка НДС
                                                                  </span>
                                                                  {tax}
                                                              </span>
                                                              <span>
                                                                  <span className="text">
                                                                      Сумма НДС
                                                                  </span>
                                                                  {amountTax}
                                                              </span>
                                                              <span>
                                                                  <span className="text">
                                                                      Предмет
                                                                      рассчёта
                                                                  </span>
                                                                  {
                                                                      paymentObject
                                                                  }
                                                              </span>
                                                              <span>
                                                                  <span className="text">
                                                                      Способ
                                                                      рассчёта
                                                                  </span>
                                                                  {
                                                                      paymentMethod
                                                                  }
                                                              </span>
                                                          </div>
                                                          <hr />
                                                      </Fragment>
                                                  )
                                              )
                                            : null}
                                    </div>
                                    <div className="receipt-form__info">
                                        <span>
                                            <span className="text">
                                                <h4>ИТОГ</h4>
                                            </span>
                                            <h4>
                                                {this.state.receipt.totalFiscal}
                                            </h4>
                                        </span>
                                    </div>
                                    <div className="receipt-form__info">
                                        <span>
                                            <span className="text">
                                                <h5>БЕЗНАЛИЧНЫМИ</h5>
                                            </span>
                                            <h5>
                                                {this.state.receipt.cashless}
                                            </h5>
                                        </span>
                                    </div>
                                    <hr />
                                    <div className="receipt-form__info">
                                        <span>
                                            <span className="text">№ ФН</span>
                                            {this.state.receipt.fnNumber}
                                        </span>
                                    </div>
                                    <div className="receipt-form__info">
                                        <span>
                                            <span className="text">
                                                Регистрационный номер ККТ
                                            </span>
                                            {
                                                this.state.receipt
                                                    .ecrRegistrationNumber
                                            }
                                        </span>
                                    </div>
                                    <div className="receipt-form__info">
                                        <span>
                                            <span className="text">№ ФД</span>
                                            {
                                                this.state.receipt
                                                    .fiscalDocumentNumber
                                            }
                                        </span>
                                    </div>
                                    <div className="receipt-form__info">
                                        <span>
                                            <span className="text">№ ФП</span>
                                            {
                                                this.state.receipt
                                                    .fiscalDocumentAttribute
                                            }
                                        </span>
                                    </div>
                                    <div className="receipt-form__info">
                                        <span>
                                            <span className="text">
                                                Версия ФФД
                                            </span>
                                            {this.state.receipt.ffdVersion}
                                        </span>
                                    </div>
                                    <hr />
                                    <div className="receipt-form__footer">
                                        <span>
                                            <span className="text">
                                                Адрес сайта для проверки чека{' '}
                                                <a
                                                    href={
                                                        this.state.receipt
                                                            .fnsSite
                                                    }
                                                >
                                                    {this.state.receipt.fnsSite}
                                                </a>
                                            </span>
                                        </span>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        fetch: state.fetch.fetch,
        cancelationToken: state.requestCancelation.token,
    };
}

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(EpaymentsPage);
