import React from 'react';
import { NavLink } from 'react-router-dom';
import { Logo } from '../../../media/logotypes/sdm-full-logo';
import { MobileNavigation } from './mobile-navigation';

import styles from './navigation.module.scss';

interface Props {
    homePageUrl: string;
    userName: string;
    userDropdownNavigation: React.ReactNode;
}

export const Navigation: React.FC<Props> = ({
    children,
    homePageUrl,
    userName,
    userDropdownNavigation,
}) => {
    return (
        <>
            <MobileNavigation
                userDropdownNavigation={userDropdownNavigation}
                homePageUrl={homePageUrl}
                userName={userName}
            >
                {children}
            </MobileNavigation>
            <nav className={styles['navigation']}>
                <div className={styles['logo']}>
                    <NavLink to={homePageUrl}>
                        <>{Logo}</>
                    </NavLink>
                </div>
                {children}
            </nav>
        </>
    );
};
