import React, { PureComponent } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Chart } from 'chart.js';

import { mc, visa, mir } from 'src/shared/ui/media';
import Container from 'src/components/Container';
import { roundsNumber, ticksMpsFormatter } from 'src/shared/lib';

import './mps-chart.scss';

const createPsGradients = () => {
    // TODO: responsive gradient
    var bar_ctx = document.getElementById('mps-chart').getContext('2d');

    var visa = bar_ctx.createLinearGradient(0, 0, 270, 0);
    visa.addColorStop(0, '#26255F');
    visa.addColorStop(0.9, '#174191');

    var mc = bar_ctx.createLinearGradient(270, 0, 500, 0);
    mc.addColorStop(0, '#DF1612');
    mc.addColorStop(1, '#F59E00');

    var mir = bar_ctx.createLinearGradient(700, 0, 730, 0);
    mir.addColorStop(0, '#24A23F');
    mir.addColorStop(0.9, '#00A3E1');
    return { visa, mir, mc };
};

class MpsChart extends PureComponent {
    constructor(props) {
        super(props);
        this.ticksFormatter = ticksMpsFormatter.bind(this);
        this.chartProps = this.props;
        this.chart = null;
        this.total = 0;
        this.colors = {
            first: 'rgb(255, 242, 226)',
            second: 'rgb(208, 237, 248)',
            third: 'rgb(198, 235, 189)',
            defaultColor: 'rgb(229, 229, 229)',
        };
        this.state = {
            chartConfig: {
                chartType: 'bar',
                responsive: false,
                maintainAspectRatio: false,
                data: {
                    labels: ['Visa', 'MasterCard', 'Мир'],
                    datasets: [
                        {
                            barThickness: 8,
                            data: [],
                            borderRadius: 6,
                            barPercentage: 0.33,
                            categoryPercentage: 0.5,
                            borderSkipped: 'middle',
                            label: 'Visa',
                        },
                        {
                            barThickness: 8,
                            data: [],
                            borderRadius: 6,
                            barPercentage: 0.33,
                            categoryPercentage: 0.5,
                            label: 'MasterCard',
                        },
                        {
                            barThickness: 8,
                            data: [],
                            borderRadius: 6,
                            barPercentage: 0.33,
                            categoryPercentage: 0.5,
                            label: 'Мир',
                        },
                    ],
                },
                options: {
                    indexAxis: 'y',
                    layout: {
                        padding: {
                            top: 15,
                        },
                        autoPadding: false,
                    },
                    scales: {
                        x: {
                            display: false,
                            stacked: true,
                        },
                        y: {
                            stacked: true,
                            display: false,
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                            position: 'right',
                        },
                        tooltip: {
                            enabled: false,
                        },
                    },
                },
            },
        };
    }

    clearChart() {
        if (this.chart) this.chart.destroy();
    }

    configureCharts(data) {
        this.prepareData(data);
        let ctx = this.refs.canvas.getContext('2d');
        this.clearChart();
        this.chart = new Chart(ctx, {
            type: this.state.chartConfig.chartType,
            data: this.state.chartConfig.data,
            options: this.state.chartConfig.options,
        });
    }

    componentWillUnmount() {
        this.clearChart();
    }

    renderPs() {
        const logoSrc = (label) => {
            if (label === 'Мир') {
                return mir;
            } else if (label === 'Visa') {
                return visa;
            }
            return mc;
        };

        const renderLabel = (dataset, idx) => {
            let width =
                (Number(dataset.data[0]) * 100) / Number(this.state.total) -
                1.5;
            return (
                <div
                    style={{
                        width: `${width}%`,
                    }}
                    className="mps-chart__ps-label"
                    key={idx}
                >
                    <span className="mps-chart__ps-label-logo">
                        {logoSrc(dataset.label)}
                    </span>
                    <span className="mps-chart__ps-label-amount">
                        {dataset.data[0]}
                    </span>
                </div>
            );
        };

        let datasets = this.state.chartConfig.data.datasets;
        return <>{datasets.map(renderLabel)}</>;
    }

    prepareData(data) {
        let newChart = this.state.chartConfig;
        const gradient = createPsGradients();
        const datasets = newChart.data.datasets;
        let total = 0;

        data.tuples.forEach((item) => {
            let idx = datasets.findIndex(
                (dataset) => dataset.label === item.name
            );
            if (idx !== -1) {
                newChart.data.labels.push(newChart.data.datasets[idx].label);
                newChart.data.datasets[idx].data = [item.values[0]];
                total += Number(item.values[0]);
                if (item.name === 'Visa') {
                    newChart.data.datasets[idx].backgroundColor = [
                        gradient.visa,
                    ];
                } else if (item.name === 'MasterCard') {
                    newChart.data.datasets[idx].backgroundColor = [gradient.mc];
                } else {
                    newChart.data.datasets[idx].backgroundColor = [
                        gradient.mir,
                    ];
                }
            }
        });
        this.setState({ chartConfig: newChart, total });
    }

    get chartRenderCheck() {
        return !!(
            this.props.data && this.props.data[this.props.toggle].tuples.length
        );
    }

    componentDidUpdate() {
        if (this.chartRenderCheck)
            this.configureCharts(this.props.data[this.props.toggle]);
    }

    render() {
        if (!this.chartRenderCheck) return null;
        return (
            <Container header background headerName="Платежные системы">
                <div className="mps-chart">
                    <div className="mps-chart__total">
                        Всего:{' '}
                        <span className="mps-chart__total-text">
                            {roundsNumber(this.state.total)?.toString()}
                        </span>
                    </div>
                    <div className="mps-chart__canvas">
                        <canvas ref="canvas" id="mps-chart" />
                    </div>
                    <div className="mps-chart__ps">{this.renderPs()}</div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({ data: state.charts.mps });

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
})(MpsChart);
