import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import Optional from 'optional-js';
import { Link } from 'react-router-dom';
import { post } from '../../shared/lib/request-wrapper';
import { filter } from 'rxjs/operators';
import { log, sendToSubject } from '../../shared/lib/util';
import { URL } from 'src/app/config';
import { checkAppTitle, sortArray } from 'src/shared/lib';
import PropTypes from 'prop-types';
import TableHeader from '../TableHeader/TableHeader';
import './table.scss';

/**
 * @onItemClick - make <Table/> clickable and send table row in callback
 * @url - address ti request for table, make it then request is full (offset + size) and this data new
 * @actions - JSX to display on the right side of table header
 * @transformBody - function to change request body properties before sending request
 */

let defaultTable = {
    code: 0,
    message: 'Success',
    payload: [
        {
            name: 'Сумма (₽)',
            groupName: 'Терминал',
            paramNames: [
                'Дата',
                'Терминал',
                'Успешных',
                'Неуспешных',
                'Возвратов',
                'Всего',
            ],
            tuples: [],
        },
        {
            name: 'Количество',
            groupName: 'Терминал',
            paramNames: [
                'Дата',
                'Терминал',
                'Успешных',
                'Неуспешных',
                'Возвратов',
                'Всего',
            ],
            tuples: [],
        },
    ],
};

class AwareTable extends Component {
    constructor(props) {
        super(props);
        this.subscription = null;
        this.state = {
            table: undefined,
            size: undefined,
            sizeLastPage: undefined,
            offset: undefined,
            filter: undefined,
            fetch: false,
            isEmpty: true,
        };
        this.send = sendToSubject.bind(this);
    }

    checkUrlAndTerminalIds(url, terminalIds) {
        const urlToСheck = [
            URL.statisticTableUrl,
            URL.operationsUrl,
            URL.settlementUrl,
            URL.settlementDetailsUrl,
        ];
        const { isMerchantApp } = checkAppTitle();
        const clearUrl = url.replace(/[\d]/g, '');

        if (isMerchantApp && urlToСheck.includes(clearUrl)) {
            return !isEmpty(terminalIds);
        }

        return true;
    }

    componentDidUpdate(prevP, prev) {
        const { offset, size, filter } = this.state;

        if (offset !== undefined && size !== undefined) {
            if (this.needUpdate(prev, this.state)) {
                if (
                    this.checkUrlAndTerminalIds(
                        this.props.url,
                        filter?.terminalIds
                    )
                ) {
                    this.getTable();
                }
            }
        } else {
            log('Aware not full request');
        }
    }

    getTable(
        request = {
            size: this.state.size,
            offset: this.state.offset,
            ...this.state.filter,
        }
    ) {
        const backendRequest = {
            ...request,
            size: request.size * 3,
            offset: (request.offset - 1) * request.size,
        };
        if (this.props.updateTableCallback)
            this.props.updateTableCallback(backendRequest);
        this.setState({ fetch: true });
        if (this.props.transformBody) {
            this.props.transformBody(backendRequest);
        }
        if (this.props.emptyTerminalResetData) {
            if (backendRequest.terminalIds.length === 0) {
                this.send({
                    end: -1,
                });
                this.setState({
                    fetch: false,
                    table: defaultTable,
                    end: 0,
                    sizeLastPage: 0,
                    isEmpty: true,
                });
                return;
            }
        }
        post(this.props.url, backendRequest)
            .then((response) => {
                this.setState({ fetch: false });
                Optional.ofNullable(response.data)
                    .map((data) => data.payload)
                    .ifPresent((payload) => {
                        let { data } = this.tableDataMapper(payload);
                        let isEmpty = data.length === 0;
                        this.send({
                            end: this.countPageToEnd(payload),
                            isEmpty,
                        });
                        this.setState({
                            table: payload,
                            end: this.countPageToEnd(payload),
                            sizeLastPage: this.getSizeLastPage(payload),
                            isEmpty,
                        });

                        if (this.props.onGetTable)
                            this.props.onGetTable(payload);
                        if (this.props.makeScroll) this.props.makeScroll();
                    });
            })
            .catch((response) => {
                this.setState({ fetch: false });
                log(response);
            });
    }

    needUpdate(pre, cur) {
        return (
            pre.offset !== cur.offset ||
            pre.size !== cur.size ||
            JSON.stringify(pre.filter) !== JSON.stringify(cur.filter)
        );
    }

    countPageToEnd(payload) {
        if (this.props.toggle !== undefined) {
            if (!isEmpty(payload)) {
                return Math.ceil(
                    (payload[0].tuples.length - this.state.size) /
                        this.state.size
                );
            } else {
                return 0;
            }
        } else {
            return Math.ceil(
                (payload.tuples.length - this.state.size) / this.state.size
            );
        }
    }

    getSizeLastPage(payload) {
        let count = 0;
        if (Array.isArray(payload)) {
            count = payload[0].tuples.length;
        } else {
            count = payload.tuples.length;
        }

        if (count < this.state.size) {
            return count;
        } else {
            return this.state.size;
        }
    }

    componentDidMount() {
        if (this.props.subject) {
            this.subscription = this.props.subject
                .pipe(
                    filter(
                        (s) =>
                            !!s.filter ||
                            !!s.size ||
                            !!s.offset ||
                            s.forceRequest
                    )
                )
                .subscribe((s) => {
                    if (s.forceRequest) {
                        this.getTable();
                    } else {
                        this.setState({ ...s });
                    }
                });
        }
    }

    filter(filter) {
        return filter ? filter : this.state.filter;
    }

    componentWillUnmount() {
        if (this.subscription) this.subscription.unsubscribe();
    }

    reloadData() {
        this.getTable();
    }

    render() {
        let { data, header } = this.tableDataMapper(this.state.table);
        return (
            <>
                <TableHeader
                    offset={this.state.offset}
                    size={this.state.size}
                    sizeLastPage={this.state.sizeLastPage}
                    actions={this.props.actions}
                    isEmpty={this.state.isEmpty}
                />
                <Table
                    fetch={this.state.fetch}
                    onItemClick={this.props.onItemClick}
                    data={data}
                    headers={header}
                    {...this.props}
                />
            </>
        );
    }

    tableDataMapper(table) {
        let { toggle } = this.props;
        if (table && (!isEmpty(table.tuples) || isArray(table))) {
            if (toggle !== undefined) {
                return convert(
                    table[toggle].tuples.slice(0, this.state.size),
                    table[toggle].paramNames
                );
            } else {
                return convert(
                    table.tuples.slice(0, this.state.size),
                    table.paramNames
                );
            }
        } else {
            return { data: [], header: [] };
        }

        function convert(data, header) {
            return {
                data: [
                    ...data.map((i) => {
                        return { data: [...i.values], ID: i.name };
                    }),
                ],
                header: [...header],
            };
        }
    }
}

class Table extends Component {
    numericalColumn = [];

    isNumericalColumn(name) {
        return [
            'спешных',
            'Возвратов',
            'Всего',
            'Сумма',
            'Кол-во',
            'Комиссия',
            'Возмещение',
            'Количество',
        ].some((value) => name.includes(value));
    }

    editableRow = (type, rowId) => {
        return {
            removeCompanyOption: (
                <span
                    title="удалить компанию?"
                    onClick={(event) => {
                        event.preventDefault(); // Standard
                        this.props.deleting(this.props.mainCompanyId, rowId);
                    }}
                >
                    <i className="mdi mdi-remove-circle-outline" />
                </span>
            ),
            editOption: (
                <span
                    title="редактировать?"
                    onClick={(event) => {
                        event.preventDefault(); // Standard
                        this.props.editing(rowId);
                    }}
                >
                    <i className="mdi mdi-edit" />
                </span>
            ),
        }[type];
    };

    drawRow = (row, index) => {
        if (this.props.link || this.props.redirect) {
            let rowCls =
                this.props.lastSelected === row.ID
                    ? 'hover lastSelected'
                    : 'hover';
            return (
                <tr key={`${row.ID}${index}`} className={rowCls}>
                    {row.data.map((rowBlock, secondIndex) =>
                        this.drawRowBlock(
                            row.ID,
                            rowBlock,
                            index,
                            secondIndex,
                            row.ID,
                            this.props.redirect
                                ? this.props.redirect(row)
                                : undefined,
                            this.props.locationUrl !== URL.einvoice
                                ? row.ID
                                : undefined,
                            row.data.length
                        )
                    )}
                </tr>
            );
        } else {
            if (this.props.onItemClick) {
                if (row.ID === undefined) {
                    return (
                        <tr key={`${row.ID}${index}`} className="no-hover">
                            {row.data.map((rowBlock, secondIndex) =>
                                this.drawRowBlock(
                                    row.ID,
                                    rowBlock,
                                    index,
                                    secondIndex
                                )
                            )}
                        </tr>
                    );
                } else {
                    return (
                        <tr
                            onClick={(e) => this.props.onItemClick(row)}
                            key={`${row.ID}${index}`}
                            className="hover"
                        >
                            {row.data.map((rowBlock, secondIndex) =>
                                this.drawRowBlock(
                                    row.ID,
                                    rowBlock,
                                    index,
                                    secondIndex
                                )
                            )}
                        </tr>
                    );
                }
            } else {
                return (
                    <tr key={`${row.ID}${index}`} className="no-hover">
                        {row.data.map((rowBlock, secondIndex) =>
                            this.drawRowBlock(
                                row.ID,
                                rowBlock,
                                index,
                                secondIndex
                            )
                        )}
                    </tr>
                );
            }
        }
    };

    drawRowBlock = (
        rowName,
        rowBlock,
        index,
        secondIndex,
        redirectKey,
        redirect,
        rowId = undefined,
        rowLength = undefined
    ) => {
        let cls = this.numericalColumn.includes(secondIndex)
            ? 'number'
            : 'string';
        // hack to inline html in request for terminals
        if (
            this.props.headers.includes('Id терминала') &&
            secondIndex === 0 &&
            (window.location.pathname.includes('terminals') ||
                window.location.pathname.includes('inventory'))
        ) {
            return (
                <td
                    className={cls}
                    key={`${rowName}${index}${secondIndex}`}
                    style={{ padding: '0.75rem' }}
                    dangerouslySetInnerHTML={{ __html: rowBlock }}
                ></td>
            );
        }
        return this.props.link || this.props.redirect ? (
            <td
                className={cls}
                title={rowBlock}
                key={`${rowName}${index}${secondIndex}`}
            >
                <Link
                    style={
                        rowId
                            ? {
                                  display: 'flex',
                                  justifyContent: 'space-between',
                              }
                            : null
                    }
                    to={redirect ? redirect : this.props.link + redirectKey}
                >
                    {rowBlock}
                    {!this.props.readonly &&
                    secondIndex &&
                    rowId &&
                    secondIndex === rowLength - 1
                        ? this.editableRow(this.props.type, rowId)
                        : null}
                </Link>
            </td>
        ) : this.props.companyHistory &&
          secondIndex === this.props.headers.indexOf('Последний вход') ? (
            <td
                className={cls}
                key={`${rowName}${index}${secondIndex}`}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.75rem',
                }}
            >
                {rowBlock}
                <span
                    onClick={this.props.companyHistory}
                    title="показать историю?"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 15,
                    }}
                >
                    <i className="mdi mdi-history" />
                </span>
            </td>
        ) : (
            <td
                className={cls}
                key={`${rowName}${index}${secondIndex}`}
                style={{ padding: '0.75rem' }}
            >
                {rowBlock}
            </td>
        );
    };

    sortData(data, type) {
        if (this.props.link === '/operations/') {
            return this.sortOperationsData(data, type);
        }
        return data;
    }

    sortOperationsData(array, type = '') {
        const order = [8, 0, 2, 3, 4, 1, 5, 6, 7, 9, 10];

        if (type === 'headers') {
            return sortArray(array, order);
        } else {
            const result = [];
            array.forEach((item) => {
                result.push({ ...item, data: sortArray(item.data, order) });
            });
            return result;
        }
    }

    render() {
        this.numericalColumn = [];
        const { headers, data, fetch } = this.props;

        return (
            <div className="common-table">
                {data && data.length > 0 ? (
                    <table className="table" cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr className="table-header">
                                {this.sortData(headers, 'headers').map(
                                    (name, i) => {
                                        if (this.isNumericalColumn(name)) {
                                            this.numericalColumn.push(i);
                                            return (
                                                <th
                                                    className={'number'}
                                                    key={name}
                                                >
                                                    {name}
                                                </th>
                                            );
                                        }
                                        return <th key={name}>{name}</th>;
                                    }
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {this.sortData(data).map((e, i) =>
                                this.drawRow(e, i)
                            )}
                        </tbody>
                    </table>
                ) : fetch ? (
                    <span className="common-table__no-data">Загрузка</span>
                ) : (
                    <span className="common-table__no-data">Нет данных</span>
                )}
            </div>
        );
    }
}

AwareTable.propTypes = {
    onGetTable: PropTypes.func,
    updateTableCallback: PropTypes.func,
    makeScroll: PropTypes.func,
    onItemClick: PropTypes.func,
    lastSelected: PropTypes.string,
    transformBody: PropTypes.func,
};

export default AwareTable;
